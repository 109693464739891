import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './MedicationRecordsFormHelpers';
import WarnItemLocationSaveButton from './WarnItemLocationSaveButton';

export const transformMedicationRecords = rec => {
    const newRecord: any = {
        administered_by_id: rec.administered_by_id,
        specimen_id: rec.specimen_id,
        medication_id: rec.medication_id,
        item_location_id: rec.item_location_id,
        unit_of_measure_id: rec.unit_of_measure_id,
        administered_at: rec.administered_at,
        medication_route_id: rec.medication_route_id,
        quantity: rec.quantity,
        notes: rec.notes,
        is_active: rec.is_active,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

const SimpleCreate = () => (
    <Create title="Create Medication Record">
        <SimpleForm
            toolbar={
                <CustomToolbar
                    customSaveButton={<WarnItemLocationSaveButton />}
                    transform={transformMedicationRecords}
                />
            }
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Medication Record">
        <TabbedForm
            toolbar={
                <CustomToolbar
                    customSaveButton={<WarnItemLocationSaveButton />}
                    transform={transformMedicationRecords}
                />
            }
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Medication Record" actions={<CustomActionsEditForm />}>
        <SimpleForm
            toolbar={
                <CustomToolbar
                    customSaveButton={<WarnItemLocationSaveButton edit />}
                    transform={transformMedicationRecords}
                />
            }
            mode={formMode}
        >
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Medication Record" actions={<CustomActionsEditForm />}>
        <TabbedForm
            toolbar={
                <CustomToolbar
                    customSaveButton={<WarnItemLocationSaveButton edit />}
                    transform={transformMedicationRecords}
                />
            }
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const MedicationRecordsEditForm = () => (
    <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />
);

export const MedicationRecordsCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);
