import React from 'react';
import { DateField, TextField, Show, Tab, TabbedShowLayout } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getMedicationRoutesFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="parent_name" label="Parent" reference="medication-routes" passProps />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const MedicationRoutesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getMedicationRoutesFields} {...props} />
);

const MedicationRoutesTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getMedicationRoutesFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const MedicationRoutesSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <MedicationRoutesShowFields {...props} />
    </Show>
);

export const MedicationRoutesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <MedicationRoutesSimpleShow {...props} />
    ) : (
        <MedicationRoutesTabbedShow {...props} />
    );
