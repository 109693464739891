import React from 'react';
import { styled } from '@mui/material/styles';
import { SimpleFormIterator } from 'react-admin';

const StyledSimpleFormIterator = styled(SimpleFormIterator)(() => ({
    [`& .ra-input`]: {
        minWidth: '25vw',
    },
}));

const CustomSimpleFormIterator = props => <StyledSimpleFormIterator {...props} />;

export default CustomSimpleFormIterator;
