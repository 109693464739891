const getRedirectData = (type, record) => {
    switch (type) {
        case 'ReportNotification':
            return {
                redirectType: 'download',
                pathname: record.id,
                fileName: record.file_name,
                resource: 'Report',
                message: 'Report downloaded',
                label: 'Download Report',
            };

        default:
            return null;
    }
};

export default getRedirectData;
