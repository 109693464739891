import React from 'react';
import { DatagridProps, useListContext, warning } from 'react-admin';
import { Divider, Tabs, Tab } from '@mui/material';

interface TabbedListProps extends DatagridProps {
    source: string;
    children: any;
}

const TabbedList: React.FC<TabbedListProps> = ({ source, children, ...props }) => {
    const listContext = useListContext();
    const { displayedFilters, filterValues, setFilters } = listContext;

    warning(children === undefined, `You must provide child lists for tab support`);

    warning(source === undefined, `You must provide the source prop`);

    const tabs = React.useMemo(
        () =>
            children &&
            children.map(({ props: childProps }) => ({
                label: childProps.label || childProps.value,
                value: childProps.value,
            })),
        [children]
    );

    // Set initial filter to first tab
    React.useEffect(() => {
        if (!filterValues[source]) {
            setFilters({ ...filterValues, [source]: tabs[0].value }, displayedFilters);
        }
    }, [displayedFilters, filterValues, setFilters, source, tabs]);

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters({ ...filterValues, [source]: value }, displayedFilters);
        },
        [displayedFilters, filterValues, setFilters, source]
    );

    if (!filterValues[source]) {
        return null;
    }

    return (
        <>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues[source]}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            {React.Children.map(
                children,
                (child, index) => filterValues[source] === tabs[index].value && React.cloneElement(child)
            )}
        </>
    );
};

export default TabbedList;
