import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { BooleanInput, required, TextInput, SelectInput, CheckboxGroupInput, useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { DateTimeInput } from '../../lib/customComponents/formComponents';
import RecurrenceIntervalInput from './RecurrenceIntervalInput';
import { getScheduleTime } from '../index';
import { frequencyDayChoices, intervalChoices, frequencyMonthDayChoices, frequencyChoices } from './userReportFormData';
import { mustNotBePastDate, mustBeFutureDate } from '../../lib/customComponents/formComponents/validation';
import { requestGetByID } from '../../../dataProvider/RestClient';

const daysOfWeek = frequencyDayChoices.map(day => day.name);

const validateStartedAt = [required(), mustNotBePastDate];
const validateEndedAt = [required(), mustNotBePastDate, mustBeFutureDate];

export const GeneralInputs: ({
    edit,
    style,
    fullWidth,
}: {
    [x: string]: any;
    edit?: boolean;
    style?: any;
    fullWidth?: boolean;
}) => JSX.Element = ({ edit = false, style, fullWidth }) => {
    const record = useRecordContext();

    const isRecurringEdit = record && 'recurring' === record.classification;

    return (
        <Stack sx={style}>
            <TextInput source="description" validate={required()} fullWidth={fullWidth} />
            <DateTimeInput
                type="date"
                source="started_at"
                label="Start Date"
                options={{ disabled: isRecurringEdit, disablePast: !isRecurringEdit }}
                validate={isRecurringEdit ? required() : validateStartedAt}
                fullWidth={fullWidth}
            />
            <DateTimeInput
                type="date"
                source="ended_at"
                label="Expiration Date"
                options={{ disablePast: true }}
                validate={validateEndedAt}
                fullWidth={fullWidth}
            />
            {edit && <BooleanInput label="Active" source="is_active" />}
        </Stack>
    );
};

type TIntervalInputs = {
    [x: string]: any;
    edit?: boolean | undefined;
    analyticsReport?: boolean;
    schemaDateFields?: any;
    recordResource?: string;
    fullWidth?: boolean;
    style?: any;
    record?: any;
};

export const IntervalInputs: React.FC<TIntervalInputs> = ({
    edit = false,
    analyticsReport,
    schemaDateFields,
    recordResource,
    fullWidth,
    style,
}) => {
    const [dateFieldsFromLocalSchemaCall, setDateFieldsFromLocalSchemaCall] = useState<any>([]);
    const [showIntervalField, setShowIntervalField] = useState<boolean>(true);
    const [disableIntervalField, setDisableIntervalField] = useState<boolean>(false);

    const record = useRecordContext();

    const { setValue } = useFormContext();

    const rangeFilter = useMemo(
        () =>
            edit &&
            record &&
            record.request_payload.filters.conditions.find(item => !!item.field && 'interval' === item.operator),
        [record, edit]
    );

    const getLocalSchemaFromResource = useCallback(() => {
        const queryResource =
            recordResource ||
            (record && record.settings && record.settings.config && record.settings.config.resource) ||
            undefined;
        const isAnalyticsEdit = undefined === queryResource && !!record;
        if (isAnalyticsEdit) {
            const {
                request_payload: {
                    filters: { conditions },
                },
            } = record;

            const prevIntervalVal = conditions && conditions[0] && conditions[0].field;
            if (prevIntervalVal) {
                setDateFieldsFromLocalSchemaCall([{ id: 1, name: prevIntervalVal }]);

                setValue('interval_field', prevIntervalVal);
            }
            setDisableIntervalField(true);
        } else {
            requestGetByID(queryResource, 'schema')
                .then(res => {
                    const timeDateFields = res.data.fields.filter(
                        dateField => 'datetime' === dateField.type || 'timestamp' === dateField.type
                    );
                    setDateFieldsFromLocalSchemaCall(timeDateFields);
                })
                .catch(err => console.error(err.message));
        }
    }, [record, recordResource, setValue]);

    useEffect(() => {
        if ((recordResource && recordResource.includes('schedule-report')) || analyticsReport) {
            setShowIntervalField(false);
        } else if (!schemaDateFields && !dateFieldsFromLocalSchemaCall.length) {
            if (record && record.interval_field) {
                setDateFieldsFromLocalSchemaCall([{ name: record.interval_field }]);
            }
            getLocalSchemaFromResource();
        }
    }, [
        analyticsReport,
        dateFieldsFromLocalSchemaCall.length,
        getLocalSchemaFromResource,
        record,
        recordResource,
        schemaDateFields,
    ]);

    return useMemo(
        () => (
            <Stack sx={style}>
                {showIntervalField && (
                    <SelectInput
                        source="interval_field"
                        label="Interval Field"
                        choices={schemaDateFields || dateFieldsFromLocalSchemaCall}
                        disabled={disableIntervalField}
                        defaultValue={edit && rangeFilter && rangeFilter.field ? rangeFilter.field : ''}
                        optionValue="name"
                        validate={required()}
                        fullWidth={fullWidth}
                        emptyText="Clear Selection"
                    />
                )}
                <SelectInput
                    source="interval_date"
                    label="Interval Date Range"
                    choices={intervalChoices}
                    defaultValue={edit && rangeFilter && rangeFilter.value ? rangeFilter.value : ''}
                    optionValue="name"
                    validate={required()}
                    fullWidth={fullWidth}
                    emptyText="Clear Selection"
                />
            </Stack>
        ),
        [
            dateFieldsFromLocalSchemaCall,
            showIntervalField,
            edit,
            disableIntervalField,
            style,
            rangeFilter,
            fullWidth,
            schemaDateFields,
        ]
    );
};

export const ScheduleInputs: ({
    edit,
    style,
    fullWidth,
}: {
    [x: string]: any;
    edit?: boolean;
    style?: any;
    fullWidth?: boolean;
}) => JSX.Element = ({ edit = false, style, fullWidth }) => {
    const [initialSchedule, setInitialSchedule] = useState<string | Date | null>(null);
    const [displayTextField, setDisplayTextField] = useState<boolean>(false);

    const record = useRecordContext();

    const { setValue } = useFormContext();

    const recurrenceFrequency = useWatch({ name: 'recurrence_frequency' });

    const handleFrequencyChange = useCallback(
        ev => {
            const { value } = ev.target;
            if ('Daily' === value) {
                setValue('recurrence_days', daysOfWeek);
                setValue('recurrence_month_days', []);
            } else if ('Weekly' === value) {
                setValue('recurrence_days', ['MO']);
                setValue('recurrence_month_days', []);
            } else if ('Monthly' === value) {
                setValue('recurrence_days', []);
                setValue('recurrence_month_days', [1, 15]);
            }
        },
        [setValue]
    );

    useEffect(() => {
        if (edit && record && !initialSchedule) {
            const schedule = getScheduleTime(record.schedule);

            setDisplayTextField(true);
            setInitialSchedule(schedule);
        }
    }, [edit, initialSchedule, record]);

    return useMemo(
        () => (
            <Stack sx={style}>
                <SelectInput
                    source="recurrence_frequency"
                    label="Frequency"
                    choices={frequencyChoices}
                    defaultValue={
                        edit && record && record.recurrence && record.recurrence.frequency
                            ? record.recurrence.frequency
                            : ''
                    }
                    optionValue="name"
                    onChange={val => handleFrequencyChange(val)}
                    validate={required()}
                    fullWidth={fullWidth}
                />
                <RecurrenceIntervalInput fullWidth={fullWidth} />
                {recurrenceFrequency && 'Monthly' !== recurrenceFrequency && (
                    <CheckboxGroupInput
                        source="recurrence_days"
                        label="Frequency Day of Week"
                        choices={frequencyDayChoices}
                        defaultValue={
                            edit && record && record.recurrence && record.recurrence.days ? record.recurrence.days : ''
                        }
                        optionValue="name"
                        optionText="label"
                        validate={'Monthly' === recurrenceFrequency ? undefined : required()}
                        fullWidth={fullWidth}
                    />
                )}
                {recurrenceFrequency && 'Monthly' === recurrenceFrequency && (
                    <CheckboxGroupInput
                        source="recurrence_month_days"
                        label="Frequency Days of Month"
                        choices={frequencyMonthDayChoices}
                        defaultValue={
                            edit && record && record.recurrence && record.recurrence.month_days
                                ? record.recurrence.month_days
                                : ''
                        }
                        optionValue="name"
                        optionText="label"
                        validate={'Monthly' === recurrenceFrequency ? required() : undefined}
                        fullWidth={fullWidth}
                    />
                )}
                {edit && displayTextField ? (
                    <TextInput
                        source="schedule-placeholder"
                        label="Scheduled Delivery Time"
                        defaultValue={initialSchedule}
                        disabled
                        helperText="Click to edit scheduled time"
                        onClick={() => setDisplayTextField(false)}
                    />
                ) : (
                    <DateTimeInput
                        type="time"
                        source="schedule"
                        label="Scheduled Delivery Time"
                        validate={required()}
                        fullWidth={fullWidth}
                    />
                )}
            </Stack>
        ),
        [edit, fullWidth, handleFrequencyChange, record, recurrenceFrequency, displayTextField, initialSchedule, style]
    );
};
