import { formatDate } from '../../../Components/lib/helpers';

const getReportHeader = record => {
    let dateRange = '';
    if (record.intervals) {
        const parsedIntervals = JSON.parse(record.intervals);
        if (!parsedIntervals || !parsedIntervals.length || !parsedIntervals[0].value) {
            return `${record.description}`;
        }

        const fromDate = parsedIntervals[0].value[0] && formatDate(parsedIntervals[0].value[0], 'date-time');
        const toDate = parsedIntervals[0].value[1] && formatDate(parsedIntervals[0].value[1], 'date-time');
        dateRange = `(start: ${fromDate}, end: ${toDate})`;

        if (!parsedIntervals[0].field) {
            return `${record.description} ${dateRange}`;
        }

        return ` ${record.description} ${parsedIntervals[0].field}: ${dateRange}`;
    }
};

const getHeaderText = (type, record) => {
    switch (type) {
        case 'ReportNotification':
            return getReportHeader(record);
        default:
            return 'Unknown Notification';
    }
};

export default getHeaderText;
