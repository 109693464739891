import React from 'react';
import { TextField, DateField, NumberField, BooleanField, Show, TabbedShowLayout, Tab } from 'react-admin';
import { FilteredFieldsRenderer, CustomResourceTitle } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { ReviewRuleActionItems, ReviewRuleConditions } from './index';

const getReviewRulesFields = () => [
    <TextField source="name" />,
    <TextField source="description" />,
    <NumberField source="review_entity_id" label="Review Entity ID" />,
    <TextField source="review_entity_name" label="Review Entity" />,
    <NumberField source="priority" label="Priority" />,
    <BooleanField label="Active" source="is_active" />,
    <DateField label="Created At" source="created_at" showTime />,
    <DateField label="Updated At" source="updated_at" showTime />,
];

export const ReviewRuleShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getReviewRulesFields} {...props}>
        <ReviewRuleActionItems {...props} />
        <ReviewRuleConditions {...props} />
    </FilteredFieldsRenderer>
);

const ReviewRuleTabbedShow = props => (
    <Show
        title={<CustomResourceTitle customName="Review Rule" />}
        actions={<CustomActionsShowView {...props} />}
        {...props}
    >
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getReviewRulesFields} isTabbedShow {...props} />
            <Tab label="Actions" path="actions">
                <ReviewRuleActionItems {...props} />
            </Tab>
            <Tab label="Conditions" path="conditions">
                <ReviewRuleConditions {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const ReviewRuleSimpleShow = props => (
    <Show
        title={<CustomResourceTitle customName="Review Rule" />}
        actions={<CustomActionsShowView {...props} />}
        {...props}
    >
        <ReviewRuleShowFields {...props} />
    </Show>
);

export const ReviewRuleShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <ReviewRuleSimpleShow {...props} />
    ) : (
        <ReviewRuleTabbedShow {...props} />
    );
