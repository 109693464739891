import React from 'react';
import { useNotify, useResourceContext } from 'react-admin';
import { requestGetByID, requestUpdateCurrentUser } from '../../../../dataProvider/RestClient';

export type TView = {
    columns: string[];
    filters: object;
    sort?: {
        field: string;
        order: string;
    };
    favorite?: boolean;
};

export type TColumn = {
    name?: string;
    source: string;
    label?: string;
    type?: string;
    active?: boolean;
    enabled?: boolean;
};

type TgetColumnData = ({
    data,
    optionValue,
    optionText,
    initialCols,
    additionalColNames,
}: {
    data: any;
    optionValue?: string | undefined;
    optionText?: string | undefined;
    initialCols?: string[];
    additionalColNames?: (string | undefined)[] | undefined;
}) => any;

type ViewContextType = {
    preferences: { [x: string]: TView } | null;
    currentViewName: string;
    favoriteViewName: string | null;
    selectView: (selectViewNameArg: string) => void;
    saveView: (
        saveViewNameArg: string,
        filters: object,
        sort?: object,
        displayedFilters?: any,
        isFavorite?: boolean
    ) => void;
    favoriteView: (favoriteViewNameArg: string, resource: string, unfavorite?: boolean) => void;
    deleteView: (deleteViewNameArg: string, resource: string) => void;
    schema: {
        fields?: TColumn[];
        relations?: any[];
    };
    columns: TColumn[];
    defaultListViewColumns: TColumn[];
    currentViewColumns: TColumn[];
    additionalColumns: TColumn[] | undefined;
    defaultColumns: string[];
    setColumns: React.Dispatch<React.SetStateAction<TColumn[]>>;
    getColumnData: TgetColumnData;
};

const sortByTemplateColumnIndex = (a, b, defaultArray) => {
    if (-1 === defaultArray.indexOf(a.source) || !a.enabled) {
        return 1;
    }
    if (-1 === defaultArray.indexOf(b.source) || !b.enabled) {
        return -1;
    }

    return defaultArray.indexOf(a.source) - defaultArray.indexOf(b.source);
};

const sortAndActivateColumnsByArray = (columnsToSort, templateArray) =>
    columnsToSort
        .map((col: TColumn) => ({ ...col, active: templateArray.includes(col.source) }))
        .sort((a, b) => sortByTemplateColumnIndex(a, b, templateArray));

const getColumnData: TgetColumnData = ({
    data,
    optionValue = 'name',
    optionText = 'label',
    initialCols,
    additionalColNames,
}) =>
    data.map((field: TColumn) => {
        const enabled = additionalColNames && additionalColNames.includes(field[optionValue]) ? true : field.enabled;
        return {
            source: field[optionValue],
            label: field[optionText] || field[optionValue],
            type: field.type,
            enabled,
            active: initialCols && initialCols.includes(field[optionValue]) && enabled,
        };
    });

const defaultViewName = 'System Default';

const useViewController: ({
    additionalColumns,
    defaultColumns,
}: {
    additionalColumns?: TColumn[];
    defaultColumns: string[];
}) => ViewContextType = ({ additionalColumns, defaultColumns }) => {
    const resource = useResourceContext();
    const preferences = React.useMemo(() => JSON.parse(localStorage.getItem('preferences') || '{}'), []);
    const resourcePreferences = React.useMemo(
        () => preferences && resource && preferences[resource],
        [preferences, resource]
    );

    const [preferenceState, setPreferenceState] = React.useState(resourcePreferences);
    const [currentViewName, setCurrentViewName] = React.useState<string>(defaultViewName);
    const [favoriteViewName, setFavoriteViewName] = React.useState<string | null>(null);
    const [defaultListViewColumns, setDefaultListViewColumns] = React.useState<Array<TColumn>>([]);
    const [currentViewColumns, setCurrentViewColumns] = React.useState<Array<TColumn>>([]);
    const [columns, setColumns] = React.useState<Array<TColumn>>([]);
    const [schema, setSchema] = React.useState({});

    const notify = useNotify();

    const getSchema = React.useCallback(async () => {
        await requestGetByID(resource, 'schema')
            .then((res: any) => {
                const { fields } = res.data;

                const consolidatedFields =
                    additionalColumns && additionalColumns.length ? [...fields, ...additionalColumns] : fields;

                const additionalColNames: (string | undefined)[] | undefined =
                    (additionalColumns && additionalColumns.map(col => col.name)) || undefined;

                let initialCols = defaultColumns;

                const listViewDefaultColumns = getColumnData({
                    data: consolidatedFields,
                    initialCols,
                    additionalColNames,
                });

                const sortedListViewDefaults = [...listViewDefaultColumns].sort((a, b) =>
                    sortByTemplateColumnIndex(a, b, initialCols)
                );

                setDefaultListViewColumns(sortedListViewDefaults);

                if (resourcePreferences) {
                    const favView = Object.entries(resourcePreferences).find(
                        ([viewName, view]: [string, any]) => view.favorite
                    );
                    if (favView) {
                        const [name, view]: [string, any] = favView;
                        setCurrentViewName(name);
                        setFavoriteViewName(name);
                        const { columns: favColumns } = view;
                        initialCols = favColumns;
                    }
                }

                const mappedColumns = [...listViewDefaultColumns]
                    .map(col => ({
                        ...col,
                        active: initialCols && initialCols.includes(col.source) && col.enabled,
                    }))
                    .sort((a, b) => sortByTemplateColumnIndex(a, b, initialCols));

                setCurrentViewColumns(mappedColumns);
                setColumns(mappedColumns);
                setSchema(res.data);
            })
            .catch(() => {
                notify(`Problem getting schema for ${resource}`, { type: 'warning' });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalColumns, defaultColumns, notify, resource, resourcePreferences]);

    React.useEffect(() => {
        if (!columns.length && !Object.keys(schema).length && resource && defaultColumns) {
            getSchema();
        }
    }, [columns, defaultColumns, getSchema, resource, schema]);

    const selectView = React.useCallback(
        selectViewNameArg => {
            if (preferenceState && preferenceState[selectViewNameArg]) {
                const viewColumns = preferenceState[selectViewNameArg].columns;
                setCurrentViewName(selectViewNameArg);
                const sortedColumns = sortAndActivateColumnsByArray(columns, viewColumns);
                setCurrentViewColumns(sortedColumns);
                setColumns(sortedColumns);
            }
        },
        [columns, preferenceState]
    );

    const saveView = React.useCallback(
        (saveViewNameArg, filters, sort, displayedFilters, isFavorite = false) => {
            const filteredColumnSources = [...columns].reduce(
                (newArr: string[], col: TColumn) => (col.active ? [...newArr, col.source] : newArr),
                []
            );

            const payloadObj: {
                filters: object;
                columns: string[];
                sort: object;
                favorite?: boolean;
                displayedFilters?: any;
            } = {
                filters,
                columns: filteredColumnSources,
                sort,
                displayedFilters,
            };

            if (isFavorite) {
                payloadObj.favorite = isFavorite;
            }

            const getNewPreferences = () => {
                if (preferences) {
                    return preferenceState
                        ? {
                              ...preferences,
                              [resource]: {
                                  ...preferenceState,
                                  [saveViewNameArg]: payloadObj,
                              },
                          }
                        : {
                              ...preferences,
                              [resource]: {
                                  [saveViewNameArg]: payloadObj,
                              },
                          };
                }
                if (!preferences) {
                    return {
                        [resource]: {
                            [saveViewNameArg]: payloadObj,
                        },
                    };
                }
            };

            const newPreferences = getNewPreferences();

            const payload = {
                meta: {
                    preferences: getNewPreferences(),
                },
            };

            requestUpdateCurrentUser(payload)
                .then(() => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(newPreferences[resource]);
                    setCurrentViewName(saveViewNameArg);
                    const sortedColumns = sortAndActivateColumnsByArray(columns, filteredColumnSources);
                    setCurrentViewColumns(sortedColumns);
                    notify(`List view "${saveViewNameArg}" successfully saved`, { type: 'success' });
                })
                .catch(() => {
                    notify('Problem saving list view!', { type: 'warning' });
                });
        },
        [columns, notify, preferences, resource, preferenceState]
    );

    const favoriteView = React.useCallback(
        (favoriteViewNameArg, currentResource, unfavorite = false) => {
            const processedViews = {};

            Object.keys(preferenceState).map(item => {
                const newItem = { ...preferenceState[item] };
                delete newItem.favorite;
                if (item === favoriteViewNameArg && !unfavorite) {
                    newItem.favorite = true;
                }
                return (processedViews[item] = newItem);
            });

            const newPreferences = {
                ...preferences,
                [currentResource]: processedViews,
            };

            const payload = {
                meta: {
                    preferences: newPreferences,
                },
            };

            requestUpdateCurrentUser(payload)
                .then(() => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(processedViews);
                    if (unfavorite) {
                        setFavoriteViewName(null);
                    } else {
                        setFavoriteViewName(favoriteViewNameArg);
                    }
                    notify(`List view "${favoriteViewNameArg}" ${unfavorite ? 'unfavorited' : 'favorited'}`, {
                        type: unfavorite ? 'info' : 'success',
                    });
                })
                .catch(() => {
                    notify('Problem setting favorite list view!', { type: 'warning' });
                });
        },
        [notify, preferences, preferenceState]
    );

    const deleteView = React.useCallback(
        (deleteViewNameArg, currentResource) => {
            const filteredViews = {};
            if (preferences && preferenceState) {
                Object.keys(preferenceState).forEach(view => {
                    if (view !== deleteViewNameArg && preferences && preferenceState[view]) {
                        return (filteredViews[view] = preferenceState[view]);
                    }
                });
            }

            const newPreferences = { ...preferences, [currentResource]: filteredViews };

            const payload = {
                meta: {
                    preferences: newPreferences,
                },
            };

            requestUpdateCurrentUser(payload)
                .then(() => {
                    localStorage.setItem('preferences', JSON.stringify(newPreferences));
                    setPreferenceState(filteredViews);
                    if (deleteViewNameArg === currentViewName) {
                        setCurrentViewName(defaultViewName);
                    }
                    notify(`List view "${deleteViewNameArg}" deleted`);
                })
                .catch(() => {
                    notify('Problem deleting list view!', { type: 'warning' });
                });
        },
        [currentViewName, notify, preferences, preferenceState]
    );

    return {
        preferences: preferenceState,
        currentViewName,
        favoriteViewName,
        selectView,
        favoriteView,
        saveView,
        deleteView,
        schema,
        columns,
        defaultListViewColumns,
        currentViewColumns,
        additionalColumns,
        defaultColumns,
        setColumns,
        getColumnData,
    };
};

const ViewContext = React.createContext<ViewContextType | undefined>(undefined);

ViewContext.displayName = 'ViewContext';

const ViewContextProvider = ({ children, value }) => (
    <ViewContext.Provider value={value}>{children}</ViewContext.Provider>
);

const useViewContext = () => {
    const context = React.useContext(ViewContext);
    return context;
};

export {
    sortByTemplateColumnIndex,
    useViewController,
    ViewContext,
    useViewContext,
    ViewContextProvider,
    defaultViewName,
};
