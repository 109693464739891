import React from 'react';
import PropTypes from 'prop-types';
import { EditButton, useRecordContext, usePermissions, useResourceContext } from 'react-admin';
import { shouldRender } from '../../helpers';

const sanitizedButtonProps = ({ userAllowed, recordAllowed, resourceOverride, allowAllUsers, ...rest }: any) => rest;

const VariableEditButton = props => {
    const { userAllowed, recordAllowed, resourceOverride, allowAllUsers = false } = props;
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const { permissions } = usePermissions();
    const processedResource = resourceOverride || resource;
    const validRecord = 'function' === typeof recordAllowed ? true === recordAllowed(record) : recordAllowed;

    const isAllowed = React.useMemo(
        () => (true === userAllowed(permissions, processedResource) || allowAllUsers) && validRecord,
        [permissions, processedResource, userAllowed, validRecord, allowAllUsers]
    );

    return isAllowed ? <EditButton resource={processedResource} {...sanitizedButtonProps(props)} /> : null;
};

VariableEditButton.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    userAllowed: PropTypes.func,
    recordAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    resourceOverride: PropTypes.string,
    allowAllUsers: PropTypes.bool,
};

VariableEditButton.defaultProps = {
    userAllowed: (permissions, resource) => shouldRender(permissions, resource, 'can_update'),
    recordAllowed: true,
};

export default VariableEditButton;
