import React from 'react';
import { ListProps, SimpleShowLayout } from 'react-admin';
import { formatDate } from '../../lib/helpers';
import { ListWithViewContext, ReferenceDrawerField, RelationTable } from '../../lib/customComponents/showComponents';

export const BreedingRecordSpecimens = props => (
    <RelationTable
        columns={{
            name: 'Name',
            gender: 'Gender',
            born_at: 'Born At',
        }}
        title="Specimen"
        relation="specimens"
        additionalFields={['id']}
        formatToDrawerLink={[
            {
                name: 'name',
                idField: 'id',
                resource: 'specimens',
            },
        ]}
        formatToDate={[{ name: 'born_at', format: 'date-time' }]}
        {...props}
    />
);

const BreedingRecordsExpand = props => (
    <SimpleShowLayout {...props}>
        <BreedingRecordSpecimens {...props} />
    </SimpleShowLayout>
);

const breedingRecordsDefaultColumns = [
    'id',
    'administered_by_name',
    'specimen_name',
    'male_specimen_name',
    'bred_at',
    'type',
    'specimen_weight_pounds',
    'specimen_score',
    'male_specimen_weight_pounds',
    'male_specimen_score',
    'notes',
    'is_active',
];

const breedingRecordsSpecialColumns = () => [
    <ReferenceDrawerField source="administered_by_name" label="Administered By" reference="contacts" />,
    <ReferenceDrawerField source="specimen_name" label="Specimen" />,
    <ReferenceDrawerField source="male_specimen_name" label="Male Specimen" reference="specimens" />,
];

export const BreedingRecordsList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Breeding Records"
        sort={{ field: 'bred_at', order: 'DESC' }}
        expand={<BreedingRecordsExpand />}
        defaultColumns={breedingRecordsDefaultColumns}
        specialCols={breedingRecordsSpecialColumns}
        primaryText={record => record?.administered_by_name}
        secondaryText={record => record?.specimen_name}
        tertiaryText={record => record?.bred_at && `Bred: ${formatDate(record.bred_at, 'short-date-time')}`}
        {...props}
    />
));

export default BreedingRecordsList;
