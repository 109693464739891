import React from 'react';
import { BooleanInput, SelectInput, TextInput, useNotify, required } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTimeInput, FormattedNumberInput } from '../../../lib/customComponents/formComponents';
import { ColumnShowView, FullScreen, SingleColumn } from '../../../lib/styles/StyledComponents';
import {
    ContactInput,
    ItemLocationInput,
    SpecimenInput,
    MedicationInput,
    UnitsOfMeasureInput,
} from '../../../lib/customComponents/formComponents/CommonFormInputs';
import { requestGetByID, requestGetList } from '../../../../dataProvider/RestClient';

const OverviewInputs = ({ edit = false, ...props }) => {
    const [contactRecord, setContactRecord] = React.useState({ id: null });
    const [unitsNames, setUnitsNames] = React.useState({ singular: null, plural: null });
    const [medRoutesChoices, setMedRoutesChoices] = React.useState<any[]>([]);

    const notify = useNotify();

    const { setValue, getValues } = useFormContext();

    const unitOfMeasureName = useWatch({ name: 'unit_of_measure_name' });
    const medID = useWatch({ name: 'medication_id' });

    const today = React.useMemo(() => new Date(), []);

    const user = React.useMemo(
        () => localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}'),
        []
    );

    const handleUnitOfMeasureChange = React.useCallback(
        async ev => {
            const { value } = ev.target;
            if (value) {
                await requestGetByID('units-of-measure', value)
                    .then(({ data }) => {
                        const { name, plural_name: pluralName } = data;

                        const qty = getValues('quantity');
                        if (qty && 0 < qty) {
                            setValue('unit_of_measure_name', pluralName);
                        } else {
                            setValue('unit_of_measure_name', name);
                        }

                        setUnitsNames({ singular: name, plural: pluralName });
                    })
                    .catch(() => notify('Problem getting units of measure!', { type: 'warning' }));
            }
        },
        [setValue, getValues, notify]
    );

    const handleQuantityChange = React.useCallback(
        ev => {
            const { value } = ev.target;
            if (value) {
                if (1 < value && unitsNames.plural) {
                    setValue('unit_of_measure_name', unitsNames.plural);
                } else if (unitsNames.singular) {
                    setValue('unit_of_measure_name', unitsNames.singular);
                }
            }
        },
        [setValue, unitsNames.plural, unitsNames.singular]
    );

    const getDefaultContact = React.useCallback(
        async userID => {
            if (!userID) {
                return;
            }
            await requestGetList('contacts', { user_id: userID }, 'id', 'ASC', 2, ['id', 'name', 'user_id'])
                .then(res => {
                    if (res.data[0]) {
                        setContactRecord(res.data[0]);
                    }
                })
                .catch(() => notify('Problem getting contact record, defaults could not load', { type: 'warning' }));
        },
        [notify]
    );

    const handleMedicationChange = React.useCallback(
        async medicationID => {
            if (medicationID) {
                await requestGetByID('medications', medicationID)
                    .then(({ data }) => {
                        const {
                            unit_of_measure_id: unitOfMeasureID,
                            unit_of_measure_name: unitOfMeasureNameVal,
                            medication_routes: medRoutes,
                        } = data;

                        setMedRoutesChoices(medRoutes);

                        setValue('unit_of_measure_id', unitOfMeasureID);
                        setValue('unit_of_measure_name', unitOfMeasureNameVal);
                    })
                    .catch(() => notify('Problem getting units of measure!', { type: 'warning' }));
            }
        },
        [notify, setValue]
    );

    React.useEffect(() => {
        if (user?.id) {
            getDefaultContact(user.id);
        }
    }, [user, getDefaultContact]);

    return (
        <>
            <ColumnShowView>
                <ContactInput source="administered_by_id" label="Administered By" defaultValue={contactRecord.id} />
            </ColumnShowView>
            <ColumnShowView>
                <SpecimenInput validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <MedicationInput onChange={val => handleMedicationChange(val)} validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <SelectInput
                    source="medication_route_id"
                    label="Medication Route"
                    disabled={!medID}
                    validate={required()}
                    choices={medRoutesChoices}
                    emptyText="Clear selection"
                />
            </ColumnShowView>
            <ColumnShowView>
                <TextInput disabled source="unit_of_measure_name" sx={{ display: 'none' }} />
                <UnitsOfMeasureInput
                    filter={{ type: ['Volume'] }}
                    defaultCreateValues={{ type: 'Volume' }}
                    validate={required()}
                    onChange={handleUnitOfMeasureChange}
                />
            </ColumnShowView>
            <ColumnShowView>
                <ItemLocationInput />
            </ColumnShowView>
            <ColumnShowView>
                <DateTimeInput
                    source="administered_at"
                    label="Administered At"
                    validate={required()}
                    defaultValue={today}
                    options={{ disableFuture: true }}
                />
            </ColumnShowView>
            <ColumnShowView>
                <FormattedNumberInput
                    source="quantity"
                    decimalScale={4}
                    validate={required()}
                    suffix={unitOfMeasureName}
                    onChange={handleQuantityChange}
                />
            </ColumnShowView>
            <FullScreen>
                <TextInput multiline source="notes" />
            </FullScreen>
            <SingleColumn>
                <BooleanInput source="is_active" label="Active" defaultValue />
            </SingleColumn>
        </>
    );
};

export default OverviewInputs;
