import React, { useCallback, useMemo } from 'react';
import { Create, SimpleForm, useRecordContext, usePermissions } from 'react-admin';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { DialogForm, CustomToolbar } from '../formComponents';
import { shouldRender } from '../../helpers';
import OverviewInputs from '../../../Operations/Transfers/TransfersForms/TransferFormHelpers';
import TransferLinesForm from '../../../Operations/Transfers/TransfersForms/TransferLinesForm';
import { transformTransfer } from '../../../Operations/Transfers/TransfersForms';

const sanitizeProps = ({ record, hasShow, hasCreate, hasEdit, hasList, ...rest }: any) => rest;

const TransferButton = props => {
    const record = useRecordContext(props);
    const { permissions } = usePermissions();

    const canCreateSpecimenTransfers = useMemo(
        () => shouldRender(permissions, 'specimen-transfers', 'can_create'),
        [permissions]
    );

    const defaultValues = useMemo(
        () =>
            // ZTODO: Fix the form when we have acces to specimen location
            record ? { specimen_transfer_lines: [{ specimen_id: record.id }] } : {},
        [record]
    );

    const onSuccess = useCallback(({ data, closeDialog }) => {
        closeDialog();
    }, []);

    if (!canCreateSpecimenTransfers) {
        return null;
    }

    return (
        <DialogForm
            title="Create Specimen Transfer"
            label="Transfer"
            maxWidth="md"
            icon={<SyncAltIcon />}
            passCloseDialogToForm={false}
            buttonMarginLeft={1}
        >
            <Create component="div" resource="specimen-transfers" {...sanitizeProps(props)}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            resourceOverride="specimen-transfers"
                            transform={transformTransfer}
                            onSuccess={onSuccess}
                            noInitialRequirement
                            {...sanitizeProps(props)}
                        />
                    }
                    defaultValues={defaultValues}
                    redirect={false}
                >
                    <OverviewInputs />
                    <TransferLinesForm />
                </SimpleForm>
            </Create>
        </DialogForm>
    );
};

export default TransferButton;
