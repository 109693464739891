import React, { useCallback, useMemo } from 'react';
import { Create, SimpleForm, useRecordContext, usePermissions, useResourceContext } from 'react-admin';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { DialogForm, CustomToolbar } from '../formComponents';
import { shouldRender } from '../../helpers';
import OverviewInputs from '../../../Operations/MedicationRecords/MedicationRecordsForms/MedicationRecordsFormHelpers';
import { transformMedicationRecords } from '../../../Operations/MedicationRecords/MedicationRecordsForms';

const sanitizeProps = ({ record, hasShow, hasCreate, hasEdit, hasList, ...rest }: any) => rest;

const MedicateButton = props => {
    const record = useRecordContext(props);
    const { permissions } = usePermissions();
    const resource = useResourceContext(props);

    const canCreateMedicationRecords = useMemo(
        () => shouldRender(permissions, 'medication-records', 'can_create'),
        [permissions]
    );

    const defaultValues = useMemo(
        () =>
            // eslint-disable-next-line no-nested-ternary
            record
                ? 'medications' === resource
                    ? { medication_id: record.id, unit_of_measure_id: record.unit_of_measure_id }
                    : { specimen_id: record.id }
                : {},
        [resource, record]
    );

    const onSuccess = useCallback(({ data, closeDialog }) => {
        closeDialog();
    }, []);

    if (!canCreateMedicationRecords || !['medications', 'specimens'].includes(resource)) {
        return null;
    }

    return (
        <DialogForm
            title="Create Medication Record"
            label="Medicate"
            maxWidth="md"
            icon={<LocalHospitalIcon />}
            passCloseDialogToForm={false}
            buttonMarginLeft={1}
        >
            <Create component="div" resource="medication-records" {...sanitizeProps(props)}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            resourceOverride="medication-records"
                            transform={transformMedicationRecords}
                            onSuccess={onSuccess}
                            noInitialRequirement
                            {...sanitizeProps(props)}
                        />
                    }
                    defaultValues={defaultValues}
                    redirect={false}
                >
                    <OverviewInputs />
                </SimpleForm>
            </Create>
        </DialogForm>
    );
};

export default MedicateButton;
