import useShouldShowPrompt from './useShouldShowPrompt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const isIOS = () => {
    if ((navigator as any).standalone) {
        // user has already installed the app
        return false;
    }
    const userPlatform = window.navigator.platform;
    const isIPad = !!userPlatform.match(/iPad/i);
    const isIPhone = !!userPlatform.match(/iPhone/i);
    return isIPad || isIPhone;
};

const useIosInstallPrompt = () => {
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt);

    return [isIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};

export default useIosInstallPrompt;
