import React from 'react';
import { useNotify, Button } from 'react-admin';
import { Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { requestDownloadAttachment } from '../../../../../dataProvider/RestClient';
import { saveFile } from './helpers';

const FilePreview = ({ file, setIsDownloading, ...props }) => {
    const [erroredFile, setErroredFile] = React.useState(false);

    const { id: fileID, name: fileName } = file;

    const isPDF = fileName.includes('.pdf') || fileName.includes('.PDF');

    const notify = useNotify();

    const handleDownloadClick = React.useCallback(() => {
        setIsDownloading(true);
        if (fileID) {
            requestDownloadAttachment(fileID)
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    saveFile(url, fileName);
                    notify('Download successful!');
                    setIsDownloading(false);
                })
                .catch(err => {
                    notify('Problem downloading attachment!', { type: 'warning' });
                    setErroredFile(true);
                    setIsDownloading(false);
                });
        }
    }, [fileID, fileName, notify, setIsDownloading]);

    if (erroredFile) {
        return (
            <Typography variant="h6" color="error">
                {fileName ? `Error: ${fileName} not found` : 'Error: File not found'}
            </Typography>
        );
    }

    return (
        <li role="presentation">
            <Button
                label={fileName}
                startIcon={isPDF ? <PictureAsPdfIcon /> : <InsertDriveFileIcon />}
                color={isPDF ? 'secondary' : 'primary'}
                variant="contained"
                size="medium"
                onMouseUp={handleDownloadClick}
                sx={{
                    maxHeight: '15rem',
                    margin: '0.5rem',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            />
        </li>
    );
};

export default FilePreview;
