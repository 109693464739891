import React from 'react';
import { Button } from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import FiltersTable from './FiltersTable';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
        margin: theme.spacing(1),
    },
}));

const ViewFiltersButton = ({
    filters,
    hideLabel = 'Hide Your Selected Filters',
    viewLabel = 'View Your Selected Filters',
    disabled = false,
}) => {
    const [modalOpened, setModalOpened] = React.useState(false);

    const hasFilters = filters && 'object' === typeof filters && 0 < Object.keys(filters).length;

    const handleOpen = () => {
        setModalOpened(true);
    };

    const handleClose = () => {
        setModalOpened(false);
    };

    React.useEffect(() => {
        if (disabled) {
            setModalOpened(false);
        }
    }, [disabled]);

    return (
        <Root>
            {hasFilters && (
                <>
                    {modalOpened ? (
                        <Button label={hideLabel} onClick={handleClose} disabled={disabled}>
                            <VisibilityOffIcon />
                        </Button>
                    ) : (
                        <Button label={viewLabel} onClick={handleOpen} disabled={disabled}>
                            <VisibilityIcon />
                        </Button>
                    )}
                    {modalOpened && <FiltersTable data={filters} />}
                </>
            )}
        </Root>
    );
};

export default ViewFiltersButton;
