import { getTimeZone, getPermissions } from './authHelpers';
import apiClient from '../dataProvider/apiClient';

export const setLocalStorage = ({ user }) => {
    const timeZoneData = getTimeZone();
    const { meta, roles, ...userData } = user;
    const theme = meta && meta.theme ? meta.theme : '';
    const userSettings = meta && meta.settings ? meta.settings : {};
    const permissions = getPermissions(roles);
    const savedViews = meta && meta.preferences ? meta.preferences : {};

    localStorage.setItem('user', JSON.stringify({ ...userData, ...timeZoneData }));
    localStorage.setItem('theme', JSON.stringify(theme));
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
    localStorage.setItem('permissions', JSON.stringify(permissions));
    localStorage.setItem('preferences', JSON.stringify(savedViews));
};

const canReadUsers = { users: { can_read: true } };

const userLogin = async ({ username, password, remember = false }) => {
    const auth = { email: username, password, remember };

    if ('development' === process.env.REACT_APP_APP_ENV) {
        console.log('Logging in development');
        localStorage.setItem('user', JSON.stringify({ id: 2, name: 'Zach Selindh' }));
        localStorage.setItem('permissions', JSON.stringify(canReadUsers));
        return Promise.resolve();
    }

    const userData = await apiClient.get(`/csrf-cookie`).then(cookies =>
        apiClient
            .post(`/login`, auth)
            .then(response => {
                if (200 > response.status || 300 <= response.status) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(res => res.data.data)
            .catch(err => {
                throw new Error(err);
            })
    );

    setLocalStorage({ user: userData });
    return Promise.resolve();
};

const userLogout = async () => {
    const logoutInfo = await apiClient
        .post('/logout')
        .then(res => res)
        .catch(err => {
            localStorage.clear();
            return Promise.resolve();
        });
    if (logoutInfo) {
        localStorage.clear();
        return Promise.resolve();
    }
};

const checkAuthorization = async () => {
    const user = localStorage.getItem('user');

    if (user) {
        return Promise.resolve();
    }

    return Promise.reject();
};

const userGetPermissions = () => {
    const permissions = JSON.parse(localStorage.getItem('permissions'));

    if (!permissions) {
        return Promise.reject();
    }
    return Promise.resolve(permissions);
};

const authProvider = {
    login: params => userLogin(params),
    logout: () => userLogout(),
    checkError: error => {
        const { status } = error;

        if (status) {
            if (403 === status) {
                const { config } = error;
                const resource = config && config.url && config.url.split('v1/')[0];
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    redirectTo: `/unauthorized?resource=${resource || ''}`,
                    logoutUser: false,
                });
            }

            if (401 === status || 419 === status) {
                localStorage.clear();
                return Promise.reject();
            }
            return Promise.resolve();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id, name } = JSON.parse(localStorage.getItem('user'));
            return Promise.resolve({ id, fullName: name });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    checkAuth: () => checkAuthorization(),
    getPermissions: () => userGetPermissions(),
};

export default authProvider;
