import React from 'react';
import { Pagination, NumberField, useRecordContext } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import { getUserSettings } from '../../helpers';
import AttachmentPreviewField from './AttachmentPreviewField';
import BreedButton from './BreedButton';
import CustomFileField from './CustomFileField';
import CustomizeColumnsButton from './CustomizeColumnsButton';
import DatagridWithViewContext from './DatagridWithViewContext';
import { DateTimeFilter, DateFilter } from './CommonFilterInputs';
import DialogTable from './DialogTable';
import DrawerPreview, { DrawerFeatures } from './Drawers/DrawerPreview';
import ExpandableAuditsTable from './ExpandableAuditsTable';
import ExpandableSimpleList from './ExpandableSimpleList';
import ExportSelectedButton from './ExportSelectedButton';
import FilteredFieldsRenderer from './FilteredFieldsRenderer';
import ItemsListing from './ItemsListing';
import ListWithViewContext from './ListWithViewContext';
import LocalReferenceField from './LocalReferenceField';
import MedicateButton from './MedicateButton';
import Notification from './CustomNotification';
import OptionsMenu from './OptionsMenu';
import PreviewDrawerWrapper from './Drawers/PreviewDrawerWrapper';
import PrintTitle from './PrintTitle';
import ReferenceDrawerField from './ReferenceDrawerField';
import RelationTable from './RelationTable';
import SaveViewButton from './SaveViewButton';
import TabbedList from './TabbedList';
import TransferButton from './TransferButton';
import VariableEditButton from './VariableEditButton';

const CustomPagination = props => <Pagination rowsPerPageOptions={[25, 100, 250, 500]} {...props} />;

const CustomResourceTitle = ({ customName }) => {
    const record = useRecordContext();
    return (
        <span>
            {customName}
            {record ? ` #${record.id}` : ''}
        </span>
    );
};

const ResponsiveList = ({ desktopList, mobileList, ...props }) => {
    const isNotSmall = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const forceDesktopView = true === getUserSettings('force_desktop_view', false);

    if (!mobileList || isNotSmall || forceDesktopView) {
        return React.cloneElement(desktopList, { ...props });
    }

    return React.cloneElement(mobileList, { ...props });
};

const USDFormattedNumberField = ({ source, label }) => (
    <NumberField
        source={source}
        label={label}
        options={{ style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 6 }}
    />
);

export {
    AttachmentPreviewField,
    BreedButton,
    CustomFileField,
    CustomizeColumnsButton,
    CustomPagination,
    CustomResourceTitle,
    DatagridWithViewContext,
    DateTimeFilter,
    DateFilter,
    DialogTable,
    DrawerFeatures,
    DrawerPreview,
    ExpandableAuditsTable,
    ExpandableSimpleList,
    ExportSelectedButton,
    FilteredFieldsRenderer,
    ItemsListing,
    ListWithViewContext,
    LocalReferenceField,
    MedicateButton,
    Notification,
    OptionsMenu,
    PreviewDrawerWrapper,
    ReferenceDrawerField,
    RelationTable,
    PrintTitle,
    SaveViewButton,
    ResponsiveList,
    TabbedList,
    TransferButton,
    USDFormattedNumberField,
    VariableEditButton,
};
