import moment from 'moment';

const getOperator = value => {
    if ('string' === typeof value && value.match(/^%.*%$/gm)) {
        return 'like';
    }
    if ('object' === typeof value && Array.isArray(value)) {
        return 'in';
    }
    return '=';
};

const isValid = value => {
    // Reject empty like search filter values
    if ('string' == typeof value) {
        const sanitizedString = value.replace(/%/g, '').trim();
        if (!sanitizedString) {
            return false;
        }
    }

    // Reject empty select filters
    if (Array.isArray(value) && 0 === value.length) {
        return false;
    }

    if ('undefined' === typeof value) {
        return false;
    }

    return true;
};

const excludeFilters = [
    'secondarySort',
    'complexFilterLogic',
    'complexPayloadLogic',
    'fieldSelectOnFilterLine',
    'relationsOnFilterLine',
    'joinRelation',
    'getChoicesTrigger',
];

const getConditions = filters => {
    const conditions = [];

    for (let [field, value] of Object.entries(filters)) {
        if (excludeFilters.includes(field) || field.includes('-RELATION-')) {
            return null;
        }
        let operator = getOperator(value);

        if ('string' === typeof value && value.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g)) {
            if (field.endsWith('_from')) {
                field = field.replace('_from', '');
                operator = '>=';
            } else if (field.endsWith('_to')) {
                field = field.replace('_to', '');
                operator = '<=';
            }
        }

        if ('object' === typeof value && moment(value).isValid()) {
            if (field.endsWith('_from')) {
                field = field.replace('_from', '');
                operator = '>=';
                value = moment(value).toISOString();
            } else if (field.endsWith('_to')) {
                field = field.replace('_to', '');
                operator = '<=';
                value = moment(value).toISOString();
            }
        }

        if (field.startsWith('NOT_')) {
            field = field.replace('NOT_', '');
            operator = '<>';
        }

        if (isValid(value)) {
            conditions.push({
                field,
                operator,
                value,
            });
        }
    }

    return conditions;
};

const getComplexFilters = filters => {
    const complexFilters = [];
    const regularFilters = {};
    const isComplex = val => Array.isArray(val) && val[0] && val[0].field;

    Object.entries(filters).forEach(([name, value]: [string, any]) => {
        if (excludeFilters.includes(name) || name.includes('-RELATION-')) {
            return null;
        }
        if (value && value.conditions) {
            complexFilters.push({
                logic: value.logic || 'and',
                conditions: value.conditions,
            });
        } else if (isComplex(value)) {
            complexFilters.push(...value);
        } else {
            regularFilters[name] = value;
        }
    });

    return { complexFilters, regularFilters };
};

const setCustomFilters: ({
    logic,
    complexPayloadLogic,
    complexFilterLogic,
    ...filters
}: {
    [x: string]: any;
    logic?: string;
    complexPayloadLogic?: string;
    complexFilterLogic?: string;
}) => any = ({ logic = 'and', complexPayloadLogic = 'and', complexFilterLogic, ...filters }) => {
    let customFilters = {};

    if (filters && Object.keys(filters).length) {
        const { complexFilters, regularFilters } = getComplexFilters(filters);
        const conditions = getConditions(regularFilters);

        customFilters =
            complexFilterLogic !== undefined
                ? {
                      logic: complexPayloadLogic,
                      conditions: [
                          {
                              logic,
                              conditions,
                          },
                          {
                              logic: complexFilterLogic,
                              conditions: complexFilters,
                          },
                      ],
                  }
                : {
                      logic,
                      conditions: conditions ? [...complexFilters, ...conditions] : complexFilters,
                  };
    }

    return customFilters;
};

export default setCustomFilters;
