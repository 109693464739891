import React, { useCallback } from 'react';
import { useRedirect, useNotify, useUserMenu } from 'react-admin';
import FaceIcon from '@mui/icons-material/Face';
import { ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import { setLocalStorage } from '../../authProvider';
import { requestGetCurrentUser } from '../../dataProvider/RestClient';

const LoginAsOriginalUserButton = ({ user }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const { onClose } = useUserMenu();

    const handleClick = useCallback(async () => {
        onClose();
        requestGetCurrentUser()
            .then(response => {
                localStorage.removeItem('originalUser');
                setLocalStorage({ user: response });
                notify(`Now logged in as ${response.name}.`);
                redirect('/');
                window.location.reload();
            })
            .catch(error => {
                notify(`Error trying to set user data for ID#${user.id}`, { type: 'warning' });
            });

        setLocalStorage({ user });
    }, [onClose, notify, redirect, user]);

    return (
        <Tooltip title={`Login as ${user.name}`} placement="right">
            <MenuItem sx={{ color: 'palette.text.secondary' }} onClick={handleClick}>
                <ListItemIcon sx={{ minWidth: 5 }}>
                    <FaceIcon />
                </ListItemIcon>
                {`Login as ${user.name}`}
            </MenuItem>
        </Tooltip>
    );
};

export default LoginAsOriginalUserButton;
