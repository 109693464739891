import React from 'react';
import { BooleanInput, TextInput, SelectInput, required, useResourceContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { ColumnShowView, SingleColumn } from '../../../lib/styles/StyledComponents';

const typeChoices = [
    { id: 1, value: 'Farm', name: 'Farm' },
    { id: 2, value: 'Vendor', name: 'Vendor' },
    { id: 3, value: 'Customer', name: 'Customer' },
];

const getDefaultType = resource => {
    switch (resource) {
        case 'customers':
            return 'Customer';
        case 'vendors':
            return 'Vendor';
        default:
            break;
    }
};

const OverviewInputs = ({ edit = false, ...props }) => {
    const resource = useResourceContext();

    const isOrgType = ['vendors', 'customers'].includes(resource);

    const type = useWatch({ name: 'type', defaultValue: isOrgType ? resource.slice(0, resource.length - 1) : null });

    const { reset } = useFormContext();

    const handleTypeChange = React.useCallback(
        ev => {
            const { value } = ev.target;
            reset({ type: value });
        },
        [reset]
    );

    return (
        <>
            <ColumnShowView>
                <SelectInput
                    source="type"
                    choices={typeChoices}
                    optionValue="value"
                    defaultValue={getDefaultType(resource)}
                    disabled={'customers' === resource || 'vendors' === resource}
                    onChange={handleTypeChange}
                    validate={required()}
                    emptyText="Clear Selection"
                />
            </ColumnShowView>
            {!!type && (
                <>
                    <ColumnShowView>
                        <TextInput source="name" validate={required()} />
                    </ColumnShowView>
                    <SingleColumn>
                        <BooleanInput source="is_active" label="Active" defaultValue />
                    </SingleColumn>
                </>
            )}
        </>
    );
};

export default OverviewInputs;
