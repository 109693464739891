import React from 'react';
import { useRecordContext, useRedirect, usePermissions, useNotify } from 'react-admin';
import { MenuItem } from '@mui/material';
import { OptionsMenu } from '../../lib/customComponents/showComponents';
import { shouldRender } from '../../lib/helpers';

const ItemsActionsMenu = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const isMedication = React.useMemo(() => 'Medication' === record?.item_type_name, [record.item_type_name]);
    const [canReadMeds, canCreateMedRecs, editAllowed] = React.useMemo(
        () => [
            isMedication && shouldRender(permissions, 'medications'),
            isMedication && shouldRender(permissions, 'medication-records', 'can_create'),
            shouldRender(permissions, 'items', 'can_update'),
        ],
        [permissions, isMedication]
    );

    const handleClick = React.useCallback(
        (type: string) => {
            if ('show' === type) {
                if (isMedication && canReadMeds) {
                    return redirect('show', 'medications', record.id);
                }
                return redirect('show', 'items', record.id);
            }
            if ('medicate' === type) {
                notify('Medication added to Medication Record form', { type: 'success' });
                return redirect(
                    'create',
                    'medication-records',
                    undefined,
                    {},
                    { record: { medication_id: record.id, unit_of_measure_id: record.unit_of_measure_id } }
                );
            }

            if (isMedication) {
                return redirect('edit', 'medications', record.id);
            }

            return redirect('edit', 'items', record.id);
        },
        [canReadMeds, isMedication, record.id, record.unit_of_measure_id, notify, redirect]
    );

    return (
        <OptionsMenu>
            <MenuItem onClick={ev => handleClick('show')}>Show</MenuItem>
            {editAllowed && <MenuItem onClick={ev => handleClick('edit')}>Edit</MenuItem>}
            {canCreateMedRecs && <MenuItem onClick={ev => handleClick('medicate')}>Medicate</MenuItem>}
        </OptionsMenu>
    );
};

export default ItemsActionsMenu;
