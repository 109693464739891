import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { formatDate, formatUSD } from '../../lib/helpers';
import { ListWithViewContext, RelationTable, USDFormattedNumberField } from '../../lib/customComponents/showComponents';

export const recordAllowed = record => {
    if (record && record.purchase_order) {
        return !record.purchase_order.id;
    }

    if (record && 2 === record.purchase_order_type_id) {
        return record && true === record.is_currently_published;
    }

    return record && !['void', 'deny', 'ordered'].includes(record.status);
};

const sortRequisitionLinesByType = lines => {
    const expensesLines = [];
    const itemsLines = [];

    lines.forEach(line => {
        const { type } = line;

        switch (type) {
            case 'item':
                itemsLines.push(line);
                break;
            case 'expense':
                expensesLines.push(line);
                break;
            default:
                break;
        }
    });

    return {
        Items: itemsLines,
        Expenses: expensesLines,
    };
};

export const RequisitionLineItems = React.memo(props => (
    <RelationTable
        columns={{
            account_name: 'Account',
            item_name: 'Item',
            description: 'Description',
            quantity: 'Qty',
            receiving_quantity: 'Qty Remaining',
            rate: 'Rate',
            value_added_rate: 'Value Added Rate',
            total_rate: 'Total Rate',
            customer_name: 'Customer',
            amount: 'Amount',
        }}
        relation="purchase_requisition_lines"
        sortLinesBy={sortRequisitionLinesByType}
        additionalFields={['type', 'account_id', 'item_id']}
        excludeColumns={{
            Items: ['Account'],
            Expenses: ['Item'],
        }}
        formatToDrawerLink={[
            {
                name: 'item_name',
                resource: 'items',
                idField: 'item_id',
            },
            {
                name: 'account_name',
                resource: 'accounts',
                idField: 'account_id',
            },
        ]}
        formatToMoney={['rate', 'value_added_rate', 'total_rate', 'amount']}
        {...props}
    />
));

const RequisitionExpand = React.memo(props => (
    <SimpleShowLayout {...props}>
        <RequisitionLineItems {...props} />
    </SimpleShowLayout>
));

const requisitionDefaultColumns = ['id', 'reference_number', 'requisitioned_at', 'amount', 'status'];

const requisitionSpecialColumns = () => [<USDFormattedNumberField label="Amount" source="amount" />];

export const RequisitionsList = React.memo(props => (
    <ListWithViewContext
        title="Requisitions"
        sort={{ field: 'created_at', order: 'DESC' }}
        expand={<RequisitionExpand />}
        specialCols={requisitionSpecialColumns}
        defaultColumns={requisitionDefaultColumns}
        recordAllowed={recordAllowed}
        primaryText={record => record && `ID: ${record.id} | Status: ${record.status}`}
        secondaryText={record => record && `Requisitioned: ${formatDate(record.requisitioned_at, 'short-date-time')}`}
        tertiaryText={record => record && formatUSD.format(record.amount)}
        {...props}
    />
));
