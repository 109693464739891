import React from 'react';
import { TextField, Show, TabbedShowLayout, Tab } from 'react-admin';
import { FilteredFieldsRenderer } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { RoleUsers, RoleCapabilities } from './index';

const getRolesFields = () => [
    <TextField source="id" />,
    <TextField source="name" />,
    <TextField source="slug" />,
    <TextField source="description" />,
];

export const RolesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getRolesFields} {...props}>
        <RoleCapabilities {...props} />
        <RoleUsers {...props} />
    </FilteredFieldsRenderer>
);

const RolesTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getRolesFields} isTabbedShow {...props} />
            <Tab label="Capabilities" path="capabilities">
                <RoleCapabilities {...props} />
            </Tab>
            <Tab label="Users" path="users">
                <RoleUsers {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const RolesSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <RolesShowFields {...props} />
    </Show>
);

export const RolesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <RolesSimpleShow {...props} />
    ) : (
        <RolesTabbedShow {...props} />
    );
