const setRecurrence = formValues => {
    const {
        recurrence_frequency: frequency,
        recurrence_interval: interval,
        recurrence_days: days,
        recurrence_month_days: monthDays,
        recurrence_time: time,
    } = formValues;

    return {
        frequency,
        days: days || ['MO'],
        month_days: monthDays,
        interval,
        time,
    };
};

export default setRecurrence;
