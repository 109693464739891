import React from 'react';
import { Resource } from 'react-admin';
import { shouldRender } from './Components/lib/helpers';
import { NoAccess, DebugPage } from './Layout';

const reloadPage = () => window.location.reload();

const SpecimenList = React.lazy(() =>
    import('./Components/Operations/Specimen')
        .then(module => ({ default: module.SpecimensList }))
        .catch(reloadPage)
);
const SpecimenShow = React.lazy(() =>
    import('./Components/Operations/Specimen/SpecimenShow')
        .then(module => ({ default: module.SpecimensShow }))
        .catch(reloadPage)
);
const SpecimenCreate = React.lazy(() =>
    import('./Components/Operations/Specimen/SpecimenForms')
        .then(module => ({ default: module.SpecimenCreateForm }))
        .catch(reloadPage)
);
const SpecimenEdit = React.lazy(() =>
    import('./Components/Operations/Specimen/SpecimenForms')
        .then(module => ({ default: module.SpecimenEditForm }))
        .catch(reloadPage)
);

const PensList = React.lazy(() =>
    import('./Components/Settings/Pens')
        .then(module => ({ default: module.PensList }))
        .catch(reloadPage)
);
const PensShow = React.lazy(() =>
    import('./Components/Settings/Pens/PensShow')
        .then(module => ({ default: module.PensShow }))
        .catch(reloadPage)
);
const PensCreate = React.lazy(() =>
    import('./Components/Settings/Pens/PensForms')
        .then(module => ({ default: module.PenCreateForm }))
        .catch(reloadPage)
);
const PensEdit = React.lazy(() =>
    import('./Components/Settings/Pens/PensForms')
        .then(module => ({ default: module.PenEditForm }))
        .catch(reloadPage)
);

const FacilitiesList = React.lazy(() =>
    import('./Components/Settings/Facilities')
        .then(module => ({ default: module.FacilitiesList }))
        .catch(reloadPage)
);
const FacilitiesShow = React.lazy(() =>
    import('./Components/Settings/Facilities/FacilitiesShow')
        .then(module => ({ default: module.FacilitiesShow }))
        .catch(reloadPage)
);
const FacilitiesCreate = React.lazy(() =>
    import('./Components/Settings/Facilities/FacilitiesForms')
        .then(module => ({ default: module.FacilitiesCreateForm }))
        .catch(reloadPage)
);
const FacilitiesEdit = React.lazy(() =>
    import('./Components/Settings/Facilities/FacilitiesForms')
        .then(module => ({ default: module.FacilitiesEditForm }))
        .catch(reloadPage)
);

const MedicationRecordsList = React.lazy(() =>
    import('./Components/Operations/MedicationRecords')
        .then(module => ({ default: module.MedicationRecordsList }))
        .catch(reloadPage)
);
const MedicationRecordsShow = React.lazy(() =>
    import('./Components/Operations/MedicationRecords/MedicationRecordsShow')
        .then(module => ({ default: module.MedicationRecordsShow }))
        .catch(reloadPage)
);
const MedicationRecordsCreate = React.lazy(() =>
    import('./Components/Operations/MedicationRecords/MedicationRecordsForms')
        .then(module => ({
            default: module.MedicationRecordsCreateForm,
        }))
        .catch(reloadPage)
);
const MedicationRecordsEdit = React.lazy(() =>
    import('./Components/Operations/MedicationRecords/MedicationRecordsForms')
        .then(module => ({
            default: module.MedicationRecordsEditForm,
        }))
        .catch(reloadPage)
);

const BreedingRecordsList = React.lazy(() =>
    import('./Components/Operations/BreedingRecords')
        .then(module => ({ default: module.BreedingRecordsList }))
        .catch(reloadPage)
);
const BreedingRecordsShow = React.lazy(() =>
    import('./Components/Operations/BreedingRecords/BreedingRecordsShow')
        .then(module => ({ default: module.BreedingRecordsShow }))
        .catch(reloadPage)
);
const BreedingRecordsCreate = React.lazy(() =>
    import('./Components/Operations/BreedingRecords/BreedingRecordsForms')
        .then(module => ({
            default: module.BreedingRecordsCreateForm,
        }))
        .catch(reloadPage)
);
const BreedingRecordsEdit = React.lazy(() =>
    import('./Components/Operations/BreedingRecords/BreedingRecordsForms')
        .then(module => ({
            default: module.BreedingRecordsEditForm,
        }))
        .catch(reloadPage)
);

const TransfersList = React.lazy(() =>
    import('./Components/Operations/Transfers')
        .then(module => ({ default: module.TransfersList }))
        .catch(reloadPage)
);
const TransfersShow = React.lazy(() =>
    import('./Components/Operations/Transfers/TransfersShow')
        .then(module => ({ default: module.TransfersShow }))
        .catch(reloadPage)
);
const TransfersCreate = React.lazy(() =>
    import('./Components/Operations/Transfers/TransfersForms')
        .then(module => ({
            default: module.TransferCreateForm,
        }))
        .catch(reloadPage)
);
const TransfersEdit = React.lazy(() =>
    import('./Components/Operations/Transfers/TransfersForms')
        .then(module => ({
            default: module.TransferEditForm,
        }))
        .catch(reloadPage)
);

const ReviewsList = React.lazy(() =>
    import('./Components/Reviews')
        .then(module => ({
            default: module.ReviewList,
        }))
        .catch(reloadPage)
);
const ReviewsShow = React.lazy(() =>
    import('./Components/Reviews/ReviewsShowView')
        .then(module => ({ default: module.ReviewShow }))
        .catch(reloadPage)
);

const AccountsList = React.lazy(() =>
    import('./Components/SupplyChain/Accounts')
        .then(module => ({ default: module.AccountsList }))
        .catch(reloadPage)
);
const AccountsShow = React.lazy(() =>
    import('./Components/SupplyChain/Accounts/AccountsShowView')
        .then(module => ({ default: module.AccountsShow }))
        .catch(reloadPage)
);
const AccountsCreate = React.lazy(() =>
    import('./Components/SupplyChain/Accounts/AccountsForms')
        .then(module => ({
            default: module.AccountsCreateForm,
        }))
        .catch(reloadPage)
);
const AccountsEdit = React.lazy(() =>
    import('./Components/SupplyChain/Accounts/AccountsForms')
        .then(module => ({
            default: module.AccountsEditForm,
        }))
        .catch(reloadPage)
);

const BillsList = React.lazy(() =>
    import('./Components/SupplyChain/Bills')
        .then(module => ({ default: module.BillsList }))
        .catch(reloadPage)
);
const BillsShow = React.lazy(() =>
    import('./Components/SupplyChain/Bills/BillsShowView')
        .then(module => ({ default: module.BillsShow }))
        .catch(reloadPage)
);
const BillsCreate = React.lazy(() =>
    import('./Components/SupplyChain/Bills/BillsForms')
        .then(module => ({
            default: module.BillsCreateForm,
        }))
        .catch(reloadPage)
);
const BillsEdit = React.lazy(() =>
    import('./Components/SupplyChain/Bills/BillsForms')
        .then(module => ({
            default: module.BillsEditForm,
        }))
        .catch(reloadPage)
);

const PaymentTermsList = React.lazy(() =>
    import('./Components/SupplyChain/PaymentTerms')
        .then(module => ({ default: module.PaymentTermsList }))
        .catch(reloadPage)
);
const PaymentTermsShow = React.lazy(() =>
    import('./Components/SupplyChain/PaymentTerms/PaymentTermsShowView')
        .then(module => ({ default: module.PaymentTermsShow }))
        .catch(reloadPage)
);
const PaymentTermsCreate = React.lazy(() =>
    import('./Components/SupplyChain/PaymentTerms/PaymentTermsForms')
        .then(module => ({ default: module.PaymentTermsCreateForm }))
        .catch(reloadPage)
);
const PaymentTermsEdit = React.lazy(() =>
    import('./Components/SupplyChain/PaymentTerms/PaymentTermsForms')
        .then(module => ({ default: module.PaymentTermsEditForm }))
        .catch(reloadPage)
);

const PaymentsList = React.lazy(() =>
    import('./Components/SupplyChain/Payments')
        .then(module => ({ default: module.PaymentsList }))
        .catch(reloadPage)
);
const PaymentsShow = React.lazy(() =>
    import('./Components/SupplyChain/Payments/PaymentsShowView')
        .then(module => ({ default: module.PaymentsShow }))
        .catch(reloadPage)
);
const PaymentsCreate = React.lazy(() =>
    import('./Components/SupplyChain/Payments/PaymentsForms')
        .then(module => ({ default: module.PaymentsCreateForm }))
        .catch(reloadPage)
);
const PaymentsEdit = React.lazy(() =>
    import('./Components/SupplyChain/Payments/PaymentsForms')
        .then(module => ({ default: module.PaymentsEditForm }))
        .catch(reloadPage)
);

const InventoryAdjustmentsList = React.lazy(() =>
    import('./Components/SupplyChain/InventoryAdjustments')
        .then(module => ({ default: module.InventoryAdjustmentsList }))
        .catch(reloadPage)
);
const InventoryAdjustmentsShow = React.lazy(() =>
    import('./Components/SupplyChain/InventoryAdjustments/InventoryAdjustmentsShowView')
        .then(module => ({ default: module.InventoryAdjustmentsShow }))
        .catch(reloadPage)
);

const InventoryReportsList = React.lazy(() =>
    import('./Components/SupplyChain/InventoryReports')
        .then(module => ({ default: module.InventoryReportsList }))
        .catch(reloadPage)
);
const InventoryReportsShow = React.lazy(() =>
    import('./Components/SupplyChain/InventoryReports/InventoryReportsShowView')
        .then(module => ({ default: module.InventoryReportsShow }))
        .catch(reloadPage)
);
const InventoryReportsCreate = React.lazy(() =>
    import('./Components/SupplyChain/InventoryReports/InventoryReportsForms')
        .then(module => ({ default: module.InventoryReportsCreateForm }))
        .catch(reloadPage)
);
const InventoryReportsEdit = React.lazy(() =>
    import('./Components/SupplyChain/InventoryReports/InventoryReportsForms')
        .then(module => ({ default: module.InventoryReportsEditForm }))
        .catch(reloadPage)
);

const InventoryByLocationList = React.lazy(() =>
    import('./Components/SupplyChain/InventoryByLocation')
        .then(module => ({ default: module.InventoryByLocation }))
        .catch(reloadPage)
);

const PurchaseOrdersList = React.lazy(() =>
    import('./Components/SupplyChain/PurchaseOrders')
        .then(module => ({ default: module.PurchaseOrdersList }))
        .catch(reloadPage)
);
const PurchaseOrdersShow = React.lazy(() =>
    import('./Components/SupplyChain/PurchaseOrders/PurchaseOrdersShowView')
        .then(module => ({ default: module.PurchaseOrdersShow }))
        .catch(reloadPage)
);
const PurchaseOrdersCreate = React.lazy(() =>
    import('./Components/SupplyChain/PurchaseOrders/PurchaseOrdersForms')
        .then(module => ({
            default: module.PurchaseOrdersCreateForm,
        }))
        .catch(reloadPage)
);
const PurchaseOrdersEdit = React.lazy(() =>
    import('./Components/SupplyChain/PurchaseOrders/PurchaseOrdersForms')
        .then(module => ({
            default: module.PurchaseOrdersEditForm,
        }))
        .catch(reloadPage)
);

const RequisitionsList = React.lazy(() =>
    import('./Components/SupplyChain/Requisitions')
        .then(module => ({ default: module.RequisitionsList }))
        .catch(reloadPage)
);
const RequisitionsShow = React.lazy(() =>
    import('./Components/SupplyChain/Requisitions/RequisitionsShowView')
        .then(module => ({
            default: module.RequisitionsShow,
        }))
        .catch(reloadPage)
);
const RequisitionCreate = React.lazy(() =>
    import('./Components/SupplyChain/Requisitions/RequisitionsForms')
        .then(module => ({
            default: module.RequisitionCreateForm,
        }))
        .catch(reloadPage)
);
const RequisitionEdit = React.lazy(() =>
    import('./Components/SupplyChain/Requisitions/RequisitionsForms')
        .then(module => ({
            default: module.RequisitionEditForm,
        }))
        .catch(reloadPage)
);

const RequisitionLinesShow = React.lazy(() =>
    import('./Components/SupplyChain/Requisitions/RequisitionLinesShowView')
        .then(module => ({
            default: module.RequisitionLinesShow,
        }))
        .catch(reloadPage)
);

const ItemReceiptsList = React.lazy(() =>
    import('./Components/SupplyChain/ItemReceipts')
        .then(module => ({ default: module.ItemReceiptsList }))
        .catch(reloadPage)
);
const ItemReceiptsShow = React.lazy(() =>
    import('./Components/SupplyChain/ItemReceipts/ItemReceiptsShowView')
        .then(module => ({
            default: module.ItemReceiptsShow,
        }))
        .catch(reloadPage)
);
const ItemReceiptsCreate = React.lazy(() =>
    import('./Components/SupplyChain/ItemReceipts/ItemReceiptForms')
        .then(module => ({
            default: module.ItemReceiptsCreateForm,
        }))
        .catch(reloadPage)
);
const ItemReceiptsEdit = React.lazy(() =>
    import('./Components/SupplyChain/ItemReceipts/ItemReceiptForms')
        .then(module => ({
            default: module.ItemReceiptsEditForm,
        }))
        .catch(reloadPage)
);

const ItemLocationsList = React.lazy(() =>
    import('./Components/SupplyChain/ItemLocations')
        .then(module => ({ default: module.ItemLocationsList }))
        .catch(reloadPage)
);
const ItemLocationsShow = React.lazy(() =>
    import('./Components/SupplyChain/ItemLocations/ItemLocationsShowView')
        .then(module => ({
            default: module.ItemLocationsShow,
        }))
        .catch(reloadPage)
);
const ItemLocationsCreate = React.lazy(() =>
    import('./Components/SupplyChain/ItemLocations/ItemLocationsForms')
        .then(module => ({
            default: module.ItemLocationsCreateForm,
        }))
        .catch(reloadPage)
);
const ItemLocationsEdit = React.lazy(() =>
    import('./Components/SupplyChain/ItemLocations/ItemLocationsForms')
        .then(module => ({
            default: module.ItemLocationsEditForm,
        }))
        .catch(reloadPage)
);

const VendorsList = React.lazy(() =>
    import('./Components/SupplyChain/Vendors')
        .then(module => ({ default: module.VendorsList }))
        .catch(reloadPage)
);

const CustomersList = React.lazy(() =>
    import('./Components/SupplyChain/Customers')
        .then(module => ({ default: module.CustomersList }))
        .catch(reloadPage)
);

const ItemsList = React.lazy(() =>
    import('./Components/Settings/Items')
        .then(module => ({ default: module.ItemsList }))
        .catch(reloadPage)
);
const ItemsShow = React.lazy(() =>
    import('./Components/Settings/Items/ItemsShow')
        .then(module => ({
            default: module.ItemsShow,
        }))
        .catch(reloadPage)
);
const ItemsCreate = React.lazy(() =>
    import('./Components/Settings/Items/ItemsForms')
        .then(module => ({
            default: module.ItemsCreateForm,
        }))
        .catch(reloadPage)
);
const ItemsEdit = React.lazy(() =>
    import('./Components/Settings/Items/ItemsForms')
        .then(module => ({
            default: module.ItemsEditForm,
        }))
        .catch(reloadPage)
);

const ItemTypesList = React.lazy(() =>
    import('./Components/Settings/ItemTypes')
        .then(module => ({ default: module.ItemTypesList }))
        .catch(reloadPage)
);
const ItemTypesShow = React.lazy(() =>
    import('./Components/Settings/ItemTypes/ItemTypesShowView')
        .then(module => ({
            default: module.ItemTypesShow,
        }))
        .catch(reloadPage)
);
// const ItemTypesCreate = React.lazy(() =>
//     import('./Components/Settings/ItemTypes/ItemTypesForms')
//         .then(module => ({
//             default: module.ItemTypesCreateForm,
//         }))
//         .catch(reloadPage)
// );
// const ItemTypesEdit = React.lazy(() =>
//     import('./Components/Settings/ItemTypes/ItemTypesForms')
//         .then(module => ({
//             default: module.ItemTypesEditForm,
//         }))
//         .catch(reloadPage)
// );

const ItemSubtypesList = React.lazy(() =>
    import('./Components/Settings/ItemSubtypes')
        .then(module => ({ default: module.ItemSubtypesList }))
        .catch(reloadPage)
);
const ItemSubtypesShow = React.lazy(() =>
    import('./Components/Settings/ItemSubtypes/ItemSubtypesShowView')
        .then(module => ({
            default: module.ItemSubtypesShow,
        }))
        .catch(reloadPage)
);
const ItemSubtypesCreate = React.lazy(() =>
    import('./Components/Settings/ItemSubtypes/ItemSubtypesForms')
        .then(module => ({
            default: module.ItemSubtypesCreateForm,
        }))
        .catch(reloadPage)
);
const ItemSubtypesEdit = React.lazy(() =>
    import('./Components/Settings/ItemSubtypes/ItemSubtypesForms')
        .then(module => ({
            default: module.ItemSubtypesEditForm,
        }))
        .catch(reloadPage)
);

const MedicationsList = React.lazy(() =>
    import('./Components/Settings/Medications')
        .then(module => ({ default: module.MedicationsList }))
        .catch(reloadPage)
);

const MedicationRoutesList = React.lazy(() =>
    import('./Components/Settings/MedicationRoutes')
        .then(module => ({ default: module.MedicationRoutesList }))
        .catch(reloadPage)
);
const MedicationRoutesShow = React.lazy(() =>
    import('./Components/Settings/MedicationRoutes/MedicationRoutesShow')
        .then(module => ({ default: module.MedicationRoutesShow }))
        .catch(reloadPage)
);

const UnitsOfMeasureList = React.lazy(() =>
    import('./Components/Settings/UnitsOfMeasure')
        .then(module => ({ default: module.UnitsOfMeasureList }))
        .catch(reloadPage)
);
const UnitsOfMeasureShow = React.lazy(() =>
    import('./Components/Settings/UnitsOfMeasure/UnitsOfMeasureShow')
        .then(module => ({
            default: module.UnitsOfMeasureShow,
        }))
        .catch(reloadPage)
);
const UnitsOfMeasureCreate = React.lazy(() =>
    import('./Components/Settings/UnitsOfMeasure/UnitsOfMeasureForms')
        .then(module => ({
            default: module.UnitsOfMeasureCreateForm,
        }))
        .catch(reloadPage)
);
const UnitsOfMeasureEdit = React.lazy(() =>
    import('./Components/Settings/UnitsOfMeasure/UnitsOfMeasureForms')
        .then(module => ({
            default: module.UnitsOfMeasureEditForm,
        }))
        .catch(reloadPage)
);

const UsersList = React.lazy(() =>
    import('./Components/Admin/Users')
        .then(module => ({ default: module.UsersList }))
        .catch(reloadPage)
);
const UserShow = React.lazy(() =>
    import('./Components/Admin/Users/UserShow')
        .then(module => ({
            default: module.UsersShow,
        }))
        .catch(reloadPage)
);
const UserCreate = React.lazy(() =>
    import('./Components/Admin/Users/UserForms')
        .then(module => ({ default: module.UserCreateForm }))
        .catch(reloadPage)
);
const UserEdit = React.lazy(() =>
    import('./Components/Admin/Users/UserForms')
        .then(module => ({ default: module.UserEditForm }))
        .catch(reloadPage)
);

const RolesList = React.lazy(() =>
    import('./Components/Admin/Roles')
        .then(module => ({ default: module.RolesList }))
        .catch(reloadPage)
);
const RolesShow = React.lazy(() =>
    import('./Components/Admin/Roles/RolesShow')
        .then(module => ({
            default: module.RolesShow,
        }))
        .catch(reloadPage)
);
const RoleCreate = React.lazy(() =>
    import('./Components/Admin/Roles/RolesForms')
        .then(module => ({ default: module.RolesCreateForm }))
        .catch(reloadPage)
);
const RoleEdit = React.lazy(() =>
    import('./Components/Admin/Roles/RolesForms')
        .then(module => ({ default: module.RolesEditForm }))
        .catch(reloadPage)
);

const ReviewRulesList = React.lazy(() =>
    import('./Components/Admin/ReviewRules')
        .then(module => ({
            default: module.ReviewRulesList,
        }))
        .catch(reloadPage)
);
const ReviewRulesShow = React.lazy(() =>
    import('./Components/Admin/ReviewRules/ReviewRuleShowView')
        .then(module => ({
            default: module.ReviewRuleShow,
        }))
        .catch(reloadPage)
);
const ReviewRuleCreateForm = React.lazy(() =>
    import('./Components/Admin/ReviewRules/ReviewRulesForms')
        .then(module => ({ default: module.ReviewRuleCreateForm }))
        .catch(reloadPage)
);
const ReviewRuleEditForm = React.lazy(() =>
    import('./Components/Admin/ReviewRules/ReviewRulesForms')
        .then(module => ({ default: module.ReviewRuleEditForm }))
        .catch(reloadPage)
);

const OrganizationsList = React.lazy(() =>
    import('./Components/Settings/Organizations')
        .then(module => ({ default: module.OrganizationsList }))
        .catch(reloadPage)
);
const OrganizationsShow = React.lazy(() =>
    import('./Components/Settings/Organizations/OrganizationsShow')
        .then(module => ({ default: module.OrganizationsShow }))
        .catch(reloadPage)
);
const OrganizationCreate = React.lazy(() =>
    import('./Components/Settings/Organizations/OrganizationsForms')
        .then(module => ({
            default: module.OrganizationCreateForm,
        }))
        .catch(reloadPage)
);
const OrganizationEdit = React.lazy(() =>
    import('./Components/Settings/Organizations/OrganizationsForms')
        .then(module => ({
            default: module.OrganizationEditForm,
        }))
        .catch(reloadPage)
);

const CodonsList = React.lazy(() =>
    import('./Components/Settings/Codons')
        .then(module => ({ default: module.CodonsList }))
        .catch(reloadPage)
);
const CodonsShow = React.lazy(() =>
    import('./Components/Settings/Codons/CodonsShow')
        .then(module => ({ default: module.CodonsShow }))
        .catch(reloadPage)
);
const CodonCreate = React.lazy(() =>
    import('./Components/Settings/Codons/CodonsForms')
        .then(module => ({
            default: module.CodonCreateForm,
        }))
        .catch(reloadPage)
);
const CodonEdit = React.lazy(() =>
    import('./Components/Settings/Codons/CodonsForms')
        .then(module => ({
            default: module.CodonEditForm,
        }))
        .catch(reloadPage)
);

const ContactsList = React.lazy(() =>
    import('./Components/Settings/Contacts')
        .then(module => ({ default: module.ContactsList }))
        .catch(reloadPage)
);
const ContactsShow = React.lazy(() =>
    import('./Components/Settings/Contacts/ContactsShow')
        .then(module => ({ default: module.ContactsShow }))
        .catch(reloadPage)
);
const ContactCreate = React.lazy(() =>
    import('./Components/Settings/Contacts/ContactsForms')
        .then(module => ({
            default: module.ContactCreateForm,
        }))
        .catch(reloadPage)
);
const ContactEdit = React.lazy(() =>
    import('./Components/Settings/Contacts/ContactsForms')
        .then(module => ({
            default: module.ContactEditForm,
        }))
        .catch(reloadPage)
);

const UserReportList = React.lazy(() =>
    import('./Components/UserReports')
        .then(module => ({ default: module.UserReportList }))
        .catch(reloadPage)
);
const UserReportShow = React.lazy(() =>
    import('./Components/UserReports/RecurringReportShow')
        .then(module => ({ default: module.RecurringReportsShow }))
        .catch(reloadPage)
);
const UserReportEditForm = React.lazy(() =>
    import('./Components/UserReports/UserReportForms')
        .then(module => ({ default: module.UserReportEditForm }))
        .catch(reloadPage)
);

const NotificationsShowView = React.lazy(() =>
    import('./Components/Notifications/NotificationShowView')
        .then(module => ({ default: module.NotificationShow }))
        .catch(reloadPage)
);

const SpecimenListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SpecimenList {...props} />
    </React.Suspense>
);
const SpecimenShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SpecimenShow {...props} />
    </React.Suspense>
);
const SpecimenCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SpecimenCreate {...props} />
    </React.Suspense>
);
const SpecimenEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <SpecimenEdit {...props} />
    </React.Suspense>
);

const PensListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PensList {...props} />
    </React.Suspense>
);
const PensShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PensShow {...props} />
    </React.Suspense>
);
const PensCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PensCreate {...props} />
    </React.Suspense>
);
const PensEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PensEdit {...props} />
    </React.Suspense>
);

const FacilitiesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <FacilitiesList {...props} />
    </React.Suspense>
);
const FacilitiesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <FacilitiesShow {...props} />
    </React.Suspense>
);
const FacilitiesCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <FacilitiesCreate {...props} />
    </React.Suspense>
);
const FacilitiesEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <FacilitiesEdit {...props} />
    </React.Suspense>
);

const MedicationRecordsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRecordsList {...props} />
    </React.Suspense>
);
const MedicationRecordsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRecordsShow {...props} />
    </React.Suspense>
);
const MedicationRecordsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRecordsCreate {...props} />
    </React.Suspense>
);
const MedicationRecordsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRecordsEdit {...props} />
    </React.Suspense>
);

const BreedingRecordsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BreedingRecordsList {...props} />
    </React.Suspense>
);
const BreedingRecordsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BreedingRecordsShow {...props} />
    </React.Suspense>
);
const BreedingRecordsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BreedingRecordsCreate {...props} />
    </React.Suspense>
);
const BreedingRecordsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BreedingRecordsEdit {...props} />
    </React.Suspense>
);

const TransfersListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <TransfersList {...props} />
    </React.Suspense>
);
const TransfersShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <TransfersShow {...props} />
    </React.Suspense>
);
const TransfersCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <TransfersCreate {...props} />
    </React.Suspense>
);
const TransfersEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <TransfersEdit {...props} />
    </React.Suspense>
);

const ReviewsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewsList {...props} />
    </React.Suspense>
);
const ReviewsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewsShow {...props} />
    </React.Suspense>
);

const AccountsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <AccountsList {...props} />
    </React.Suspense>
);
const AccountsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <AccountsShow {...props} />
    </React.Suspense>
);
const AccountsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <AccountsCreate {...props} />
    </React.Suspense>
);
const AccountsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <AccountsEdit {...props} />
    </React.Suspense>
);

const BillsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BillsList {...props} />
    </React.Suspense>
);
const BillsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BillsShow {...props} />
    </React.Suspense>
);
const BillsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BillsCreate {...props} />
    </React.Suspense>
);
const BillsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <BillsEdit {...props} />
    </React.Suspense>
);

const PaymentTermsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentTermsList {...props} />
    </React.Suspense>
);
const PaymentTermsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentTermsShow {...props} />
    </React.Suspense>
);
const PaymentTermsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentTermsCreate {...props} />
    </React.Suspense>
);
const PaymentTermsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentTermsEdit {...props} />
    </React.Suspense>
);

const PaymentsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentsList {...props} />
    </React.Suspense>
);
const PaymentsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentsShow {...props} />
    </React.Suspense>
);
const PaymentsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentsCreate {...props} />
    </React.Suspense>
);
const PaymentsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PaymentsEdit {...props} />
    </React.Suspense>
);

const InventoryByLocationListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryByLocationList {...props} />
    </React.Suspense>
);

const InventoryAdjustmentsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryAdjustmentsList {...props} />
    </React.Suspense>
);
const InventoryAdjustmentsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryAdjustmentsShow {...props} />
    </React.Suspense>
);

const InventoryReportsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryReportsList {...props} />
    </React.Suspense>
);
const InventoryReportsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryReportsShow {...props} />
    </React.Suspense>
);
const InventoryReportsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryReportsCreate {...props} />
    </React.Suspense>
);
const InventoryReportsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <InventoryReportsEdit {...props} />
    </React.Suspense>
);

const PurchaseOrdersListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PurchaseOrdersList {...props} />
    </React.Suspense>
);
const PurchaseOrdersShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PurchaseOrdersShow {...props} />
    </React.Suspense>
);
const PurchaseOrdersCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PurchaseOrdersCreate {...props} />
    </React.Suspense>
);
const PurchaseOrdersEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <PurchaseOrdersEdit {...props} />
    </React.Suspense>
);

const RequisitionsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RequisitionsList {...props} />
    </React.Suspense>
);
const RequisitionsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RequisitionsShow {...props} />
    </React.Suspense>
);
const RequisitionsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RequisitionCreate {...props} />
    </React.Suspense>
);
const RequisitionsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RequisitionEdit {...props} />
    </React.Suspense>
);

const RequisitionLinesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RequisitionLinesShow {...props} />
    </React.Suspense>
);

const ItemReceiptsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemReceiptsList {...props} />
    </React.Suspense>
);
const ItemReceiptsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemReceiptsShow {...props} />
    </React.Suspense>
);
const ItemReceiptsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemReceiptsCreate {...props} />
    </React.Suspense>
);
const ItemReceiptsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemReceiptsEdit {...props} />
    </React.Suspense>
);

const ItemLocationsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemLocationsList {...props} />
    </React.Suspense>
);
const ItemLocationsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemLocationsShow {...props} />
    </React.Suspense>
);
const ItemLocationsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemLocationsCreate {...props} />
    </React.Suspense>
);
const ItemLocationsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemLocationsEdit {...props} />
    </React.Suspense>
);

const VendorsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <VendorsList {...props} />
    </React.Suspense>
);

const CustomersListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <CustomersList {...props} />
    </React.Suspense>
);

const ItemsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemsList {...props} />
    </React.Suspense>
);
const ItemsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemsShow {...props} />
    </React.Suspense>
);
const ItemsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemsCreate {...props} />
    </React.Suspense>
);
const ItemsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemsEdit {...props} />
    </React.Suspense>
);

const ItemTypesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemTypesList {...props} />
    </React.Suspense>
);
const ItemTypesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemTypesShow {...props} />
    </React.Suspense>
);
// const ItemTypesCreateAsync = props => (
//     <React.Suspense fallback={<div>Loading...</div>}>
//         <ItemTypesCreate {...props} />
//     </React.Suspense>
// );
// const ItemTypesEditAsync = props => (
//     <React.Suspense fallback={<div>Loading...</div>}>
//         <ItemTypesEdit {...props} />
//     </React.Suspense>
// );

const ItemSubtypesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemSubtypesList {...props} />
    </React.Suspense>
);
const ItemSubtypesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemSubtypesShow {...props} />
    </React.Suspense>
);
const ItemSubtypesCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemSubtypesCreate {...props} />
    </React.Suspense>
);
const ItemSubtypesEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ItemSubtypesEdit {...props} />
    </React.Suspense>
);

const MedicationsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationsList {...props} />
    </React.Suspense>
);

const MedicationRoutesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRoutesList {...props} />
    </React.Suspense>
);
const MedicationRoutesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <MedicationRoutesShow {...props} />
    </React.Suspense>
);

const UnitsOfMeasureListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UnitsOfMeasureList {...props} />
    </React.Suspense>
);
const UnitsOfMeasureShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UnitsOfMeasureShow {...props} />
    </React.Suspense>
);
const UnitsOfMeasureCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UnitsOfMeasureCreate {...props} />
    </React.Suspense>
);
const UnitsOfMeasureEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UnitsOfMeasureEdit {...props} />
    </React.Suspense>
);

const UsersListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UsersList {...props} />
    </React.Suspense>
);
const UserShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserShow {...props} />
    </React.Suspense>
);
const UserCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserCreate {...props} />
    </React.Suspense>
);
const UserEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserEdit {...props} />
    </React.Suspense>
);

const OrganizationsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <OrganizationsList {...props} />
    </React.Suspense>
);
const OrganizationsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <OrganizationsShow {...props} />
    </React.Suspense>
);
const OrganizationsCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <OrganizationCreate {...props} />
    </React.Suspense>
);
const OrganizationsEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <OrganizationEdit {...props} />
    </React.Suspense>
);

const CodonsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <CodonsList {...props} />
    </React.Suspense>
);
const CodonShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <CodonsShow {...props} />
    </React.Suspense>
);
const CodonCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <CodonCreate {...props} />
    </React.Suspense>
);
const CodonEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <CodonEdit {...props} />
    </React.Suspense>
);

const ContactsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ContactsList {...props} />
    </React.Suspense>
);
const ContactShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ContactsShow {...props} />
    </React.Suspense>
);
const ContactCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ContactCreate {...props} />
    </React.Suspense>
);
const ContactEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ContactEdit {...props} />
    </React.Suspense>
);

const RolesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RolesList {...props} />
    </React.Suspense>
);
const RoleShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RolesShow {...props} />
    </React.Suspense>
);
const RoleCreateAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RoleCreate {...props} />
    </React.Suspense>
);
const RoleEditAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <RoleEdit {...props} />
    </React.Suspense>
);

const ReviewRulesListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewRulesList {...props} />
    </React.Suspense>
);
const ReviewRulesShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewRulesShow {...props} />
    </React.Suspense>
);
const ReviewRuleCreateFormAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewRuleCreateForm {...props} />
    </React.Suspense>
);
const ReviewRuleEditFormAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <ReviewRuleEditForm {...props} />
    </React.Suspense>
);

const UserReportsListAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportList {...props} />
    </React.Suspense>
);
const UserReportsShowAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportShow {...props} />
    </React.Suspense>
);
const UserReportEditFormAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <UserReportEditForm {...props} />
    </React.Suspense>
);

const NotificationsShowViewAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <NotificationsShowView {...props} />
    </React.Suspense>
);

const getResources = permissions => [
    <Resource
        name="specimens"
        list={shouldRender(permissions, 'specimens') ? SpecimenListAsync : NoAccess}
        show={shouldRender(permissions, 'specimens') ? SpecimenShowAsync : NoAccess}
        create={shouldRender(permissions, 'specimens', 'can_create') ? SpecimenCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'specimens', 'can_update') ? SpecimenEditAsync : NoAccess}
    />,
    <Resource
        name="pens"
        list={shouldRender(permissions, 'pens') ? PensListAsync : NoAccess}
        show={shouldRender(permissions, 'pens') ? PensShowAsync : NoAccess}
        create={shouldRender(permissions, 'pens', 'can_create') ? PensCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'pens', 'can_update') ? PensEditAsync : NoAccess}
    />,
    <Resource
        name="facilities"
        list={shouldRender(permissions, 'facilities') ? FacilitiesListAsync : NoAccess}
        show={shouldRender(permissions, 'facilities') ? FacilitiesShowAsync : NoAccess}
        create={shouldRender(permissions, 'facilities', 'can_create') ? FacilitiesCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'facilities', 'can_update') ? FacilitiesEditAsync : NoAccess}
    />,
    <Resource
        name="medication-records"
        list={shouldRender(permissions, 'medication-records') ? MedicationRecordsListAsync : NoAccess}
        show={shouldRender(permissions, 'medication-records') ? MedicationRecordsShowAsync : NoAccess}
        create={shouldRender(permissions, 'medication-records', 'can_create') ? MedicationRecordsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'medication-records', 'can_update') ? MedicationRecordsEditAsync : NoAccess}
    />,
    <Resource
        name="breeding-records"
        list={shouldRender(permissions, 'breeding-records') ? BreedingRecordsListAsync : NoAccess}
        show={shouldRender(permissions, 'breeding-records') ? BreedingRecordsShowAsync : NoAccess}
        create={shouldRender(permissions, 'breeding-records', 'can_create') ? BreedingRecordsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'breeding-records', 'can_update') ? BreedingRecordsEditAsync : NoAccess}
    />,
    <Resource
        name="specimen-transfers"
        list={shouldRender(permissions, 'specimen-transfers') ? TransfersListAsync : NoAccess}
        show={shouldRender(permissions, 'specimen-transfers') ? TransfersShowAsync : NoAccess}
        create={shouldRender(permissions, 'specimen-transfers', 'can_create') ? TransfersCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'specimen-transfers', 'can_update') ? TransfersEditAsync : NoAccess}
    />,
    <Resource
        name="reviews"
        list={shouldRender(permissions, 'reviews') ? ReviewsListAsync : NoAccess}
        show={shouldRender(permissions, 'reviews') ? ReviewsShowAsync : NoAccess}
    />,
    <Resource
        name="accounts"
        list={shouldRender(permissions, 'accounts') ? AccountsListAsync : NoAccess}
        show={shouldRender(permissions, 'accounts') ? AccountsShowAsync : NoAccess}
        create={shouldRender(permissions, 'accounts', 'can_create') ? AccountsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'accounts', 'can_update') ? AccountsEditAsync : NoAccess}
    />,
    <Resource
        name="bills"
        list={shouldRender(permissions, 'bills') ? BillsListAsync : NoAccess}
        show={shouldRender(permissions, 'bills') ? BillsShowAsync : NoAccess}
        create={shouldRender(permissions, 'bills', 'can_create') ? BillsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'bills', 'can_update') ? BillsEditAsync : NoAccess}
    />,
    <Resource
        name="payment-terms"
        list={shouldRender(permissions, 'payment-terms') ? PaymentTermsListAsync : NoAccess}
        show={shouldRender(permissions, 'payment-terms') ? PaymentTermsShowAsync : NoAccess}
        create={shouldRender(permissions, 'payment-terms', 'can_create') ? PaymentTermsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'payment-terms', 'can_update') ? PaymentTermsEditAsync : NoAccess}
    />,
    <Resource
        name="bill-payments"
        list={shouldRender(permissions, 'bill-payments') ? PaymentsListAsync : NoAccess}
        show={shouldRender(permissions, 'bill-payments') ? PaymentsShowAsync : NoAccess}
        create={shouldRender(permissions, 'bill-payments', 'can_create') ? PaymentsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'bill-payments', 'can_update') ? PaymentsEditAsync : NoAccess}
    />,
    <Resource
        name="purchase-orders"
        list={shouldRender(permissions, 'purchase-orders') ? PurchaseOrdersListAsync : NoAccess}
        show={shouldRender(permissions, 'purchase-orders') ? PurchaseOrdersShowAsync : NoAccess}
        create={shouldRender(permissions, 'purchase-orders', 'can_create') ? PurchaseOrdersCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'purchase-orders', 'can_update') ? PurchaseOrdersEditAsync : NoAccess}
    />,
    <Resource
        name="purchase-requisitions"
        list={shouldRender(permissions, 'purchase-requisitions') ? RequisitionsListAsync : NoAccess}
        show={shouldRender(permissions, 'purchase-requisitions') ? RequisitionsShowAsync : NoAccess}
        create={shouldRender(permissions, 'purchase-requisitions', 'can_create') ? RequisitionsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'purchase-requisitions', 'can_update') ? RequisitionsEditAsync : NoAccess}
    />,
    <Resource
        name="purchase-requisition-lines"
        show={shouldRender(permissions, 'purchase-requisition-lines') ? RequisitionLinesShowAsync : NoAccess}
    />,
    <Resource
        name="item-receipts"
        list={shouldRender(permissions, 'item-receipts') ? ItemReceiptsListAsync : NoAccess}
        show={shouldRender(permissions, 'item-receipts') ? ItemReceiptsShowAsync : NoAccess}
        create={shouldRender(permissions, 'item-receipts', 'can_create') ? ItemReceiptsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'item-receipts', 'can_update') ? ItemReceiptsEditAsync : NoAccess}
    />,
    <Resource
        name="item-locations"
        list={shouldRender(permissions, 'item-locations') ? ItemLocationsListAsync : NoAccess}
        show={shouldRender(permissions, 'item-locations') ? ItemLocationsShowAsync : NoAccess}
        create={shouldRender(permissions, 'item-locations', 'can_create') ? ItemLocationsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'item-locations', 'can_update') ? ItemLocationsEditAsync : NoAccess}
    />,
    <Resource
        name="inventory-by-location"
        list={shouldRender(permissions, 'item-item-location') ? InventoryByLocationListAsync : NoAccess}
    />,
    <Resource
        name="inventory-adjustments"
        list={shouldRender(permissions, 'inventory-adjustments') ? InventoryAdjustmentsListAsync : NoAccess}
        show={shouldRender(permissions, 'inventory-adjustments') ? InventoryAdjustmentsShowAsync : NoAccess}
        // create={shouldRender(permissions, 'inventory-adjustments', 'can_create') ? InventoryAdjustmentsCreateAsync : NoAccess}
        // edit={shouldRender(permissions, 'inventory-adjustments', 'can_update') ? InventoryAdjustmentsEditAsync : NoAccess}
    />,
    <Resource
        name="inventory-reports"
        list={shouldRender(permissions, 'inventory-reports') ? InventoryReportsListAsync : NoAccess}
        show={shouldRender(permissions, 'inventory-reports') ? InventoryReportsShowAsync : NoAccess}
        create={shouldRender(permissions, 'inventory-reports', 'can_create') ? InventoryReportsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'inventory-reports', 'can_update') ? InventoryReportsEditAsync : NoAccess}
    />,
    <Resource
        name="vendors"
        list={shouldRender(permissions, 'vendors') ? VendorsListAsync : NoAccess}
        show={shouldRender(permissions, 'vendors') ? OrganizationsShowAsync : NoAccess}
        create={shouldRender(permissions, 'vendors', 'can_create') ? OrganizationsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'vendors', 'can_update') ? OrganizationsEditAsync : NoAccess}
    />,
    <Resource
        name="customers"
        list={shouldRender(permissions, 'customers') ? CustomersListAsync : NoAccess}
        show={shouldRender(permissions, 'customers') ? OrganizationsShowAsync : NoAccess}
        create={shouldRender(permissions, 'customers', 'can_create') ? OrganizationsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'customers', 'can_update') ? OrganizationsEditAsync : NoAccess}
    />,
    <Resource
        name="items"
        list={shouldRender(permissions, 'items') ? ItemsListAsync : NoAccess}
        show={shouldRender(permissions, 'items') ? ItemsShowAsync : NoAccess}
        create={shouldRender(permissions, 'items', 'can_create') ? ItemsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'items', 'can_update') ? ItemsEditAsync : NoAccess}
    />,
    <Resource
        name="item-types"
        list={shouldRender(permissions, 'item-types') ? ItemTypesListAsync : NoAccess}
        show={shouldRender(permissions, 'item-types') ? ItemTypesShowAsync : NoAccess}
        // create={shouldRender(permissions, 'item-types', 'can_create') ? ItemTypesCreateAsync : NoAccess}
        // edit={shouldRender(permissions, 'item-types', 'can_update') ? ItemTypesEditAsync : NoAccess}
    />,
    <Resource
        name="item-subtypes"
        list={shouldRender(permissions, 'item-subtypes') ? ItemSubtypesListAsync : NoAccess}
        show={shouldRender(permissions, 'item-subtypes') ? ItemSubtypesShowAsync : NoAccess}
        create={shouldRender(permissions, 'item-subtypes', 'can_create') ? ItemSubtypesCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'item-subtypes', 'can_update') ? ItemSubtypesEditAsync : NoAccess}
    />,
    <Resource
        name="medications"
        list={shouldRender(permissions, 'medications') ? MedicationsListAsync : NoAccess}
        show={shouldRender(permissions, 'medications') ? ItemsShowAsync : NoAccess}
        create={shouldRender(permissions, 'medications', 'can_create') ? ItemsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'medications', 'can_update') ? ItemsEditAsync : NoAccess}
    />,
    <Resource
        name="medication-routes"
        list={shouldRender(permissions, 'medication-routes') ? MedicationRoutesListAsync : NoAccess}
        show={shouldRender(permissions, 'medication-routes') ? MedicationRoutesShowAsync : NoAccess}
        // create={shouldRender(permissions, 'medication-routes', 'can_create') ? MedicationRoutesCreateAsync : NoAccess}
        // edit={shouldRender(permissions, 'medication-routes', 'can_update') ? MedicationRoutesEditAsync : NoAccess}
    />,
    <Resource
        name="units-of-measure"
        list={shouldRender(permissions, 'units-of-measure') ? UnitsOfMeasureListAsync : NoAccess}
        show={shouldRender(permissions, 'units-of-measure') ? UnitsOfMeasureShowAsync : NoAccess}
        create={shouldRender(permissions, 'units-of-measure', 'can_create') ? UnitsOfMeasureCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'units-of-measure', 'can_update') ? UnitsOfMeasureEditAsync : NoAccess}
    />,
    <Resource
        name="organizations"
        list={shouldRender(permissions, 'organizations') ? OrganizationsListAsync : NoAccess}
        show={shouldRender(permissions, 'organizations') ? OrganizationsShowAsync : NoAccess}
        create={shouldRender(permissions, 'organizations', 'can_create') ? OrganizationsCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'organizations', 'can_update') ? OrganizationsEditAsync : NoAccess}
    />,
    <Resource
        name="codons"
        list={shouldRender(permissions, 'codons') ? CodonsListAsync : NoAccess}
        show={shouldRender(permissions, 'codons') ? CodonShowAsync : NoAccess}
        create={shouldRender(permissions, 'codons', 'can_create') ? CodonCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'codons', 'can_update') ? CodonEditAsync : NoAccess}
    />,
    <Resource
        name="contacts"
        list={shouldRender(permissions, 'contacts') ? ContactsListAsync : NoAccess}
        show={shouldRender(permissions, 'contacts') ? ContactShowAsync : NoAccess}
        create={shouldRender(permissions, 'contacts', 'can_create') ? ContactCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'contacts', 'can_update') ? ContactEditAsync : NoAccess}
    />,
    <Resource
        name="users"
        list={shouldRender(permissions, 'users') ? UsersListAsync : NoAccess}
        show={shouldRender(permissions, 'users') ? UserShowAsync : NoAccess}
        create={shouldRender(permissions, 'users', 'can_create') ? UserCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'users', 'can_update') ? UserEditAsync : NoAccess}
    />,
    <Resource
        name="roles"
        list={shouldRender(permissions, 'roles') ? RolesListAsync : NoAccess}
        show={shouldRender(permissions, 'roles') ? RoleShowAsync : NoAccess}
        create={shouldRender(permissions, 'roles', 'can_create') ? RoleCreateAsync : NoAccess}
        edit={shouldRender(permissions, 'roles', 'can_update') ? RoleEditAsync : NoAccess}
    />,
    <Resource
        name="review-rules"
        list={shouldRender(permissions, 'review-rules') ? ReviewRulesListAsync : NoAccess}
        show={shouldRender(permissions, 'review-rules') ? ReviewRulesShowAsync : NoAccess}
        create={shouldRender(permissions, 'review-rules', 'can_create') ? ReviewRuleCreateFormAsync : NoAccess}
        edit={shouldRender(permissions, 'review-rules', 'can_update') ? ReviewRuleEditFormAsync : NoAccess}
    />,
    <Resource name="reports" list={UserReportsListAsync} show={UserReportsShowAsync} edit={UserReportEditFormAsync} />,
    <Resource name="notifications" show={NotificationsShowViewAsync} />,
    <Resource name="unauthorized" list={NoAccess} />,
    <Resource name="debug" list={DebugPage} />,
];

export default getResources;
