import React from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { DynamicFilter } from '../../lib/customComponents/filterComponents';

const extraLargeDropDown = { minWidth: '12em' };

const ItemsFilters = ({ ...props }) => (
    <DynamicFilter {...props}>
        <ReferenceArrayInput source="medication_route-RELATion-id" reference="medication-routes">
            <SelectArrayInput sx={extraLargeDropDown} label="Medication Route" />
        </ReferenceArrayInput>
    </DynamicFilter>
);

export default ItemsFilters;
