import React from 'react';
import {
    modifiedName,
    getInputComponent,
    getFieldComponent,
    getType,
    getDynamicComponents,
} from './getDynamicComponents';

type TUseGetDynamicComponents = {
    standardComponents: any;
    getSpecialComponents: any;
    componentType?: string | undefined;
    extraProps?: any;
};

const useGetDynamicComponents: ({
    standardComponents,
    getSpecialComponents,
    componentType,
    extraProps,
}: TUseGetDynamicComponents) => React.ReactNode[] = ({
    standardComponents,
    getSpecialComponents,
    componentType = 'fields',
    extraProps,
}) => {
    const [dynamicComponents, setDynamicComponents] = React.useState<Array<React.ReactNode>>([]);

    const hasStandardComponents =
        standardComponents && 0 < standardComponents.length && '' !== standardComponents[0].source;

    const specialComponents = React.useMemo(() => {
        if ('function' === typeof getSpecialComponents) {
            if (extraProps) {
                return getSpecialComponents(extraProps);
            }
            return getSpecialComponents();
        }
        return [];
    }, [extraProps, getSpecialComponents]);

    React.useMemo(() => {
        if (hasStandardComponents) {
            const defaultSources = specialComponents ? specialComponents.map(cmpnt => cmpnt.props.source) : [];
            const components = getDynamicComponents(standardComponents, defaultSources, componentType);

            const mergedComponents = specialComponents ? components.concat(specialComponents) : components;

            return setDynamicComponents(mergedComponents);
        }
        if (false === standardComponents) {
            setDynamicComponents(specialComponents);
        }
    }, [standardComponents, componentType, hasStandardComponents, specialComponents]);

    return dynamicComponents;
};

export { modifiedName, getInputComponent, getDynamicComponents, getFieldComponent, getType, useGetDynamicComponents };
