import * as React from 'react';
import { useEffect, ReactNode, FunctionComponent } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

interface Props {
    children: ReactNode;
    className?: string;
    onRemove: () => void;
    disableRemove?: Function;
    file: any;
}

const FileInputPreview: FunctionComponent<Props> = props => {
    const { children, onRemove, disableRemove, file, ...rest } = props;

    const translate = useTranslate();

    useEffect(
        () => () => {
            const preview = file.rawFile ? file.rawFile.preview : file.preview;

            if (preview) {
                window.URL.revokeObjectURL(preview);
            }
        },
        [file]
    );

    const disabled = (disableRemove && 'function' === typeof disableRemove && disableRemove(file)) || false;

    return (
        <div {...rest}>
            <IconButton
                onClick={onRemove}
                aria-label={translate('ra.action.delete')}
                title={translate('ra.action.delete')}
                disabled={disabled}
            >
                <RemoveCircleIcon color={disabled ? 'disabled' : 'error'} />
            </IconButton>
            {children}
        </div>
    );
};

FileInputPreview.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    file: PropTypes.object,
    onRemove: PropTypes.func.isRequired,
    disableRemove: PropTypes.func,
};

FileInputPreview.defaultProps = {
    file: undefined,
};

export default FileInputPreview;
