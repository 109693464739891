import React from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Save as SaveIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { CustomConfirm as Confirm, LoadingWindow, ErrorWindow } from '../../../lib/customComponents';
import { requestGetList } from '../../../../dataProvider/RestClient';

interface ISpecimenSaveButton {
    edit?: boolean;
    disabled?: boolean;
    handleClick?: (ev: any, data?: { [x: string]: any }) => void;
}

const LoadingMessage = () => (
    <Typography gutterBottom variant="body1" align="center">
        Validating specimen name...
    </Typography>
);

const ErrorMessage = () => (
    <Typography color="error" variant="caption">
        Specimen name must be unique
    </Typography>
);

const SpecimenSaveButton: React.FC<ISpecimenSaveButton> = ({ edit = false, disabled, handleClick, ...props }) => {
    const [noteWindow, setNoteWindow] = React.useState<boolean>(false);
    const [loadingWindow, setLoadingWindow] = React.useState<boolean>(false);
    const [errorWindow, setErrorWindow] = React.useState<boolean>(false);

    const { getValues } = useFormContext();

    const notify = useNotify();
    const record = useRecordContext();

    const setNoteAndSave = React.useCallback(
        ev => {
            setNoteWindow(false);
            handleClick(ev);
        },
        [handleClick]
    );

    const getMatchingSpecimenName = React.useCallback(
        async name => {
            setLoadingWindow(true);
            const match = await requestGetList('specimens', { name })
                .then(({ data }) => {
                    setLoadingWindow(false);
                    return data[0] || null;
                })
                .catch(err => {
                    setLoadingWindow(false);
                    notify('Problem validating specimen name', { type: 'warning' });
                });
            return match || null;
        },
        [notify]
    );

    const handleSave = React.useCallback(
        async ev => {
            if (disabled && handleClick && 'function' === typeof handleClick) {
                return handleClick(ev);
            }

            const [name, breedingRecID] = getValues(['name', 'breeding_record_id']);

            if (name && (!edit || (edit && record?.name !== name))) {
                const matchingSpecimen = await getMatchingSpecimenName(name);
                if (matchingSpecimen) {
                    return setErrorWindow(true);
                }
            }

            if (breedingRecID) {
                return handleClick(ev);
            }

            setNoteWindow(true);
        },
        [handleClick, getValues, getMatchingSpecimenName, disabled, record, edit]
    );

    return (
        <>
            <Confirm
                isOpen={noteWindow}
                title="No Breeding Record"
                content={`You are about to ${
                    edit ? 'update' : 'create'
                } a specimen record without an associated breeding record, are you sure?`}
                onConfirm={setNoteAndSave}
                onClose={() => {
                    setNoteWindow(false);
                }}
            />
            <LoadingWindow isLoading={loadingWindow} content={<LoadingMessage />} />
            <ErrorWindow
                isOpen={errorWindow}
                title="Form error"
                content={<ErrorMessage />}
                onConfirm={() => setErrorWindow(false)}
            />
            <Button label="Save" variant="contained" size="medium" disabled={disabled} onMouseUp={handleSave}>
                <SaveIcon />
            </Button>
        </>
    );
};

export default SpecimenSaveButton;
