import React from 'react';
import lodash from 'lodash';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    ListItem,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { formatUSD, formatDate } from '../../helpers';

type TAlign = 'right' | 'left' | 'center';

type TRenderCellsProps = {
    [x: string]: any;
    align?: TAlign;
    filter?: any;
    columns?: any;
    hasActions?: boolean;
    hasRemove?: boolean;
    handleRemoveClick?: (record: any, index: any) => void;
    data?: any;
    visibleRecords?: any;
    classes?: any;
    excludeColByTitle?: any;
    canReadRelationFields?: any;
};

type TRenderColumnHeadersProps = {
    align?: TAlign;
    columns?: any;
    hasActions?: boolean;
    hasRemove?: boolean;
    handleRemoveClick?: (record: any, index: any) => void;
    excludeColByTitle?: any;
    canReadRelationFields?: any;
};

type TExpandButtonRowProps = {
    fullyClosed?: boolean;
    fullyExpanded?: boolean;
    isLoadingPage?: boolean;
    onExpandClick?: () => Promise<void>;
    onCollapseClick?: (isRefresh?: boolean) => void;
};

type TDialogTableBaseProps = {
    [x: string]: any;
    columnGroups?: any;
    hasActions?: boolean;
    hasRemove?: boolean;
    isDrawer?: boolean;
    hasExpandButton?: boolean;
    formatToMoney?: string[];
    formatToDate?: {
        name: string;
        format: string;
    }[];
    size?: 'medium' | 'small';
    align?: TAlign;
};

const formatToNumber = (value, name, formatToMoney) => {
    if (!value && 0 !== value) {
        return null;
    }

    // 1. Return if not number
    if ('string' === typeof value) {
        return value;
    }

    // 2. If currency, format as USD
    for (let i = 0; i < formatToMoney.length; i++) {
        if (name === formatToMoney[i]) {
            return formatUSD.format(value);
        }
    }

    // 3. If not, format as number.
    return Intl.NumberFormat().format(value);
};

const FormattedValue = ({ colValue, colName, recordLine, ...props }) => {
    const { formatToDate, formatToCustom, formatToMoney, formatToDrawerLink, openPreviewDrawer } = props;
    const drawerData = lodash.find(formatToDrawerLink, item => item.name === colName);
    const dateData = lodash.find(formatToDate, item => item.name === colName);
    const customData = lodash.find(formatToCustom, item => item.name === colName);

    if ('undefined' === typeof colValue || null === colValue) {
        return '';
    }

    if (drawerData && openPreviewDrawer && 'function' === typeof openPreviewDrawer) {
        const { name, resource } = drawerData;
        const displayField = drawerData.displayField || drawerData.name;
        const idField = recordLine[drawerData.idField] || recordLine[name];
        return openPreviewDrawer(idField, resource, recordLine[displayField]);
    }
    if (dateData) {
        return formatDate(colValue, dateData.format);
    }
    if (customData) {
        return customData.formatter(recordLine);
    }

    return formatToNumber(colValue, colName, formatToMoney);
};

const RenderColumnHeaders: React.FC<TRenderColumnHeadersProps> = ({
    align,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    excludeColByTitle,
    canReadRelationFields,
}) => {
    const filteredColumnNames = [];
    Object.entries(columns).forEach(([colVal, colTitle]) => {
        if (
            (!excludeColByTitle || !excludeColByTitle.includes(colTitle)) &&
            ((canReadRelationFields && canReadRelationFields.includes(colVal)) || !canReadRelationFields)
        ) {
            filteredColumnNames.push(colTitle);
        }
    });

    return (
        <>
            {hasRemove && handleRemoveClick && 'function' === typeof handleRemoveClick && (
                <TableCell key="remove" align={align} padding="checkbox" />
            )}
            {filteredColumnNames.map(columnName => (
                <TableCell key={columnName} align={align}>
                    {columnName}
                </TableCell>
            ))}
            {hasActions && <TableCell key="actions" align={align} />}
        </>
    );
};

const RenderColumnGroupHeaders = ({ align, columnGroups }) => (
    <TableRow>
        {Object.keys(columnGroups).map((group, i) => {
            if (0 === i) {
                return (
                    <TableCell
                        colSpan={columnGroups[group]}
                        key={group}
                        align={align}
                        sx={{ borderRight: '1.5px solid rgba(224, 224, 224, 1)', fontWeight: '600' }}
                    >
                        {group}
                    </TableCell>
                );
            }
            return (
                <TableCell colSpan={columnGroups[group]} key={group} align={align} sx={{ fontWeight: '600' }}>
                    {group}
                </TableCell>
            );
        })}
    </TableRow>
);

const RenderActions = ({ hasActions, recordLine, lineIndex, ...props }) => {
    if (false === hasActions) {
        return null;
    }

    const childrenWithProps = React.Children.map(props.children, child =>
        React.cloneElement(child, {
            record: recordLine,
            index: lineIndex,
        })
    );

    return <TableCell key={`${recordLine.id}_customAction`}>{childrenWithProps}</TableCell>;
};

const RenderCells: React.FC<TRenderCellsProps> = ({
    align,
    filter,
    columns,
    hasActions,
    hasRemove,
    handleRemoveClick,
    data,
    visibleRecords,
    excludeColByTitle,
    canReadRelationFields,
    ...props
}) => {
    const setSXConditional = recordLine =>
        recordLine?.invalid ? { backgroundColor: '#ef5350', color: 'white' } : null;
    let dataToRender = filter ? filter(data) : data;

    if (false === Array.isArray(data)) {
        dataToRender = [data];
    }

    const filteredColumns = {};
    Object.entries(columns).forEach(([colVal, colTitle]) => {
        if (
            (!excludeColByTitle || !excludeColByTitle.includes(colTitle)) &&
            ((canReadRelationFields && canReadRelationFields.includes(colVal)) || !canReadRelationFields)
        ) {
            filteredColumns[colVal] = colTitle;
        }
    });

    return dataToRender.map((recordLine, lineIndex) => {
        if ((visibleRecords && lineIndex + 1 > visibleRecords) || !recordLine) {
            return null;
        }
        return (
            <TableRow key={`${recordLine.id}-${lineIndex}`} sx={setSXConditional(recordLine)} data-cy="dialogTableRow">
                {hasRemove && handleRemoveClick && 'function' === typeof handleRemoveClick && (
                    <TableCell
                        key={`${recordLine.id}_closeAction`}
                        padding="checkbox"
                        align={align}
                        sx={setSXConditional(recordLine)}
                    >
                        <IconButton
                            color="secondary"
                            size="small"
                            disabled={dataToRender && 1 === dataToRender.length}
                            onClick={() => handleRemoveClick(recordLine, lineIndex)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </TableCell>
                )}
                {Object.keys(filteredColumns).map((columnName, i) => {
                    if (0 === i) {
                        return (
                            <TableCell
                                key={recordLine.id + columnName}
                                component="th"
                                scope="row"
                                align={align}
                                sx={setSXConditional(recordLine)}
                            >
                                <FormattedValue
                                    recordLine={recordLine}
                                    colValue={recordLine[columnName]}
                                    colName={columnName}
                                    {...props}
                                />
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell key={recordLine.id + columnName} align="right" sx={setSXConditional(recordLine)}>
                            <FormattedValue
                                recordLine={recordLine}
                                colValue={recordLine[columnName]}
                                colName={columnName}
                                {...props}
                            />
                        </TableCell>
                    );
                })}
                <RenderActions recordLine={recordLine} lineIndex={lineIndex} hasActions={hasActions} {...props} />
            </TableRow>
        );
    });
};

export const ExpandButtonRow: React.FC<TExpandButtonRowProps> = ({
    fullyClosed,
    fullyExpanded,
    isLoadingPage,
    onExpandClick,
    onCollapseClick,
}) => (
    <ListItem sx={{ textAlign: 'end', display: 'block' }}>
        {isLoadingPage ? (
            <CircularProgress />
        ) : (
            <>
                <IconButton
                    disabled={fullyClosed || !(onCollapseClick && 'function' === typeof onCollapseClick)}
                    onClick={() => onCollapseClick(true)}
                    aria-label="refresh"
                >
                    <RefreshIcon />
                </IconButton>
                <IconButton
                    sx={{ marginLeft: 'auto', transform: 'rotate(180deg)' }}
                    disabled={fullyClosed || !(onCollapseClick && 'function' === typeof onCollapseClick)}
                    onClick={() => onCollapseClick()}
                    aria-label="show less"
                >
                    <ExpandMoreIcon />
                </IconButton>
                <IconButton
                    sx={{ marginLeft: 'auto', transform: 'rotate(0deg)' }}
                    disabled={fullyExpanded || !(onExpandClick && 'function' === typeof onExpandClick)}
                    onClick={() => onExpandClick()}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </>
        )}
    </ListItem>
);

type StyledPaperProps = {
    isDrawer: any;
};

const StyledPaper = styled(Paper, {
    shouldForwardProp: prop => 'isDrawer' !== prop,
})<StyledPaperProps>(({ theme, isDrawer }) => ({
    overflowX: 'auto',
    marginTop: isDrawer ? 3 : 1,
    maxWidth: isDrawer ? '85vw' : null,
    width: isDrawer ? null : '100%',
    [theme.breakpoints.down('md')]: {
        maxWidth: '85vw',
        marginTop: 3,
    },
}));

const DialogTableBase: React.FC<TDialogTableBaseProps> = ({
    columnGroups,
    hasActions = false,
    hasRemove = false,
    isDrawer = false,
    hasExpandButton = false,
    formatToMoney = ['rate', 'cost', 'amount', 'cost_rate'],
    formatToDate = [
        { name: 'created_at', format: 'date-time' },
        { name: 'updated_at', format: 'date-time' },
    ],
    size = 'medium',
    align = 'right',
    ...props
}) => (
    <StyledPaper isDrawer={isDrawer}>
        <Table size={size}>
            <TableHead>
                {columnGroups && <RenderColumnGroupHeaders align={align} columnGroups={columnGroups} {...props} />}
                <TableRow>
                    <RenderColumnHeaders align={align} hasActions={hasActions} hasRemove={hasRemove} {...props} />
                </TableRow>
            </TableHead>
            <TableBody>
                <RenderCells
                    align={align}
                    formatToMoney={formatToMoney}
                    formatToDate={formatToDate}
                    hasActions={hasActions}
                    hasRemove={hasRemove}
                    {...props}
                />
            </TableBody>
        </Table>
        {hasExpandButton && <ExpandButtonRow {...props} />}
    </StyledPaper>
);

export default DialogTableBase;
