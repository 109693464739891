import React from 'react';
import { TextInput, ReferenceInput, SelectInput, required, useRecordContext } from 'react-admin';
import { ColumnShowView, FullScreen } from '../../../lib/styles/StyledComponents';

export const OverviewInputs = ({ edit = false, ...props }) => {
    const record = useRecordContext();

    return (
        <>
            <ColumnShowView>
                <TextInput label="Name" source="name" validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <ReferenceInput
                    source="parent_id"
                    reference="item-locations"
                    filter={edit && record?.id ? { NOT_id: record.id } : undefined}
                    perPage={500}
                >
                    <SelectInput label="Parent" optionText="name" emptyText="Clear Selection" />
                </ReferenceInput>
            </ColumnShowView>
            <FullScreen>
                <TextInput multiline source="notes" label="Notes" />
            </FullScreen>
        </>
    );
};
