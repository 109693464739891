import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './ContactsFormHelpers';

const transformContact = rec => {
    const newRecord: any = {
        name: rec.name,
        email: rec.email,
        organization_id: rec.organization_id,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

const TabbedCreate = () => (
    <Create title="Create Contact">
        <TabbedForm toolbar={<CustomToolbar transform={transformContact} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Contact">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformContact} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Contact" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformContact} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Contact" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformContact} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ContactEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const ContactCreateForm = () => <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />;
