import React, { useState, useEffect, useCallback } from 'react';
import { BooleanInput, PasswordInput, TextInput, useLogin, required, email as mustBeEmail } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ResetPasswordRequestForm from '../ResetPasswordRequestForm';

interface CustomizedLocationState {
    nextPathname: string;
}

const PREFIX = 'LoginInputs';
const classes = {
    root: `${PREFIX}-root`,
    submitButton: `${PREFIX}-submitButton`,
};
const Root = styled('div')(() => ({
    width: '18em',
    margin: 'auto',
    textAlign: 'center',
    [`& .${classes.submitButton}`]: {
        width: '100%',
    },
}));

const validateEmail = [required(), mustBeEmail()];
const validatePassword = required();

const LoginInputs = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [prevPath, setPrevPath] = useState('/');
    const [showErrMessage, setShowErrMessage] = useState(false);
    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

    const login = useLogin();
    const location = useLocation();
    const state = location.state as CustomizedLocationState;
    const email = useWatch({ name: 'email' });
    const password = useWatch({ name: 'password' });
    const remember = useWatch({ name: 'remember' });

    const maybeLoginFromURL = useCallback((loc, loginCallback) => {
        const provider = loc.search.replace('?provider=', '');

        if ('azure' === provider) {
            setIsLoading(true);
            return loginCallback({ provider });
        }
    }, []);

    const loginWithLaravel = useCallback(
        ({ username, provider = 'direct' }) =>
            login({ username, password, remember, provider }, prevPath).catch(err => {
                setIsLoading(false);
                setShowErrMessage(true);
            }),
        [login, prevPath, password, remember]
    );

    useEffect(() => {
        if (state && state.nextPathname) {
            setPrevPath(state.nextPathname);
        }

        maybeLoginFromURL(location, loginWithLaravel);
    }, [location, loginWithLaravel, maybeLoginFromURL, state]);

    const handleLoginSubmit = useCallback(() => {
        setShowErrMessage(false);
        setIsLoading(true);

        loginWithLaravel({ username: email });
    }, [loginWithLaravel, email]);

    const handleKeypress = e => {
        // it triggers by pressing the enter key
        if (13 === e.keyCode) {
            handleLoginSubmit();
        }
    };

    return (
        <Root>
            {isLoading ? (
                <CircularProgress size={150} sx={{ m: '1em' }} />
            ) : (
                <>
                    <TextInput
                        source="email"
                        label="Email"
                        type="email"
                        sx={{ width: '100%' }}
                        validate={validateEmail}
                        {...props}
                    />
                    <PasswordInput
                        source="password"
                        label="Password"
                        sx={{ width: '100%' }}
                        validate={validatePassword}
                        {...props}
                    />
                    <BooleanInput source="remember" label="Remember Me" {...props} />
                    {showErrMessage && (
                        <Typography color="error" variant="body2" gutterBottom align="center">
                            Login failed
                        </Typography>
                    )}
                    {showPasswordResetMessage && (
                        <Typography color="primary" variant="body2" gutterBottom align="center">
                            Password reset email sent
                        </Typography>
                    )}
                    <Button
                        className={classes.submitButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleLoginSubmit}
                        onKeyDown={handleKeypress}
                        disabled={!(email && password)}
                        size="medium"
                    >
                        Sign In
                    </Button>
                    {/* <ResetPasswordRequestForm setShowPasswordResetMessage={setShowPasswordResetMessage} /> */}
                </>
            )}
        </Root>
    );
};

export default LoginInputs;
