import React from 'react';
import {
    TextField,
    EmailField,
    Show,
    BooleanField,
    TabbedShowLayout,
    Tab,
    usePermissions,
    useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
    FilteredFieldsRenderer,
    ExpandableAuditsTable,
    LocalReferenceField,
} from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import DebugLinkButton from './DebugLinkButton';
import { userAllowed, userCanRead, UserRoles, UserSettingsTable, UserContact } from './index';

const getUserFields = () => [
    <TextField source="id" />,
    <TextField source="name" />,
    <EmailField source="email" />,
    <LocalReferenceField source="organization_name" passProps />,
    <BooleanField label="Is Active" source="is_active" />,
    <BooleanField label="Is Super User" source="is_super_user" />,
];

export const UserShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getUserFields} {...props}>
        <UserSettingsTable {...props} />
        <UserRoles {...props} />
        <UserContact {...props} />
        {/* <ExpandableAuditsTable {...props} /> */}
    </FilteredFieldsRenderer>
);

const UsersTabbedShow = ({ actions, ...props }) => (
    <Show actions={actions} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getUserFields} isTabbedShow {...props} />
            <Tab label="Settings" path="settings">
                <UserSettingsTable {...props} />
            </Tab>
            <Tab label="Roles" path="roles">
                <UserRoles {...props} />
            </Tab>
            <Tab label="Contact" path="contact">
                <UserContact {...props} />
            </Tab>
            {/* <Tab label="Audits" path="audits">
                <ExpandableAuditsTable {...props} />
            </Tab> */}
        </TabbedShowLayout>
    </Show>
);

const UsersSimpleShow = ({ actions, ...props }) => (
    <Show actions={actions} {...props}>
        <UserShowFields {...props} />
    </Show>
);

export const UsersShow = () => {
    const redirect = useRedirect();
    const { permissions, isLoading } = usePermissions();
    const { pathname } = useLocation();
    const id = pathname && pathname.split('/')[2];

    const currentUser = JSON.parse(localStorage.getItem('user'));
    const isUserProfile = parseInt(id) === currentUser?.id;
    const canRead = React.useMemo(() => userCanRead(permissions), [permissions]);

    const userAllowedOrOwnRecord = (permissionsProp, resourceProp) => {
        if (isUserProfile) {
            return true;
        }
        return userAllowed(permissionsProp, resourceProp);
    };

    React.useEffect(() => {
        if (!canRead && !isUserProfile && !isLoading) {
            redirect('/unauthorized');
        }
    }, [canRead, isUserProfile, isLoading, redirect]);

    const actions = (
        <CustomActionsShowView userAllowed={userAllowedOrOwnRecord} hasList={canRead}>
            <DebugLinkButton isUserProfile={isUserProfile} />
        </CustomActionsShowView>
    );

    if ('simple' === getUserSettings('show_view_format', 'tabbed')) {
        return <UsersSimpleShow actions={actions} />;
    }

    return <UsersTabbedShow actions={actions} />;
};
