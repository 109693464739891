import React from 'react';
import { useRedirect } from 'react-admin';
import { useEvent, usePrivateChannel } from '../../Components/lib/hooks';
import authProvider, { setLocalStorage } from '../../authProvider';
import { requestGetCurrentUser } from '../../dataProvider/RestClient';
import { Notification } from '../../Components/lib/customComponents/showComponents';

const UserChangedNotification = () => {
    const [notificationMessage, setNotificationMessage] = React.useState(false);

    const redirect = useRedirect();

    const userData = JSON.parse(localStorage.getItem('user') || '{}');

    const handleNotificationClick = () => {
        setNotificationMessage(false);
        window.location.reload();
    };

    const entityDataParser = React.useCallback(
        async ({ data, action, id, resource }) => {
            // ZTODO: Revisit after login as button is reactivated
            // i.e. userData may represent an aliased user rather than the "current" user
            if (userData?.id === id && data?.id === userData.id && 'users' === resource && 'update' === action) {
                requestGetCurrentUser()
                    .then(user => {
                        if (false === user.is_active) {
                            return authProvider.logout();
                        }
                        setLocalStorage({ user });
                        setNotificationMessage(true);
                    })
                    .catch(() => authProvider.logout());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const entityChangedChannel = usePrivateChannel(`api.entity-changed.users.${userData?.id}`);

    useEvent(entityChangedChannel, 'EntityChanged', entityDataParser);

    const sessionDataParser = React.useCallback(
        async ({ action, user_id: userID }) => {
            if (userID && userData?.id === userID) {
                if ('logout' === action) {
                    localStorage.clear();
                    redirect('/login');
                } else if ('refresh' === action) {
                    window.location.reload();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [redirect]
    );

    const sessionChangedChannel = usePrivateChannel(`api.session-changed.users.${userData?.id}`);

    useEvent(sessionChangedChannel, 'SessionChanged', sessionDataParser);

    return (
        <Notification
            handleUndo={handleNotificationClick}
            undoable
            undoButtonText="Refresh Page"
            message="Your user record has been updated, please refresh"
            notification={notificationMessage}
        />
    );
};

export default UserChangedNotification;
