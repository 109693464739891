import moment, { Moment } from 'moment';

const mustContainNoSpaces = value => (value.includes(' ') ? 'No spaces allowed' : undefined);

const mustBeAfterSpecifiedDate = (value, date) =>
    moment(value).isAfter(moment(date).startOf('day')) ? undefined : `Date must be after ${date}`;

const mustContainSpecialCharacter = value =>
    /[!@#$%^&*(),.?":{}|<>]/g.test(value) ? undefined : 'Must contain at least one special character';

const mustContainCapitalLetter = value =>
    /[A-Z]/g.test(value) ? undefined : 'Must contain at least one capital letter';

const mustContainLowercaseLetter = value =>
    /[a-z]/g.test(value) ? undefined : 'Must contain at least one lowercase letter';

const mustContainNumber = value => (/[0-9]+/g.test(value) ? undefined : 'Must contain at least one number');

const passwordsMustMatch = (value, allValues) =>
    allValues.password && value && allValues.password !== value
        ? 'Password and confirm password must match'
        : undefined;

const passwordsMustNotBeSameAsOld = (value, allValues) =>
    allValues.old_password && value && allValues.old_password === value
        ? 'New password may not be the same as your old password'
        : undefined;

const mustNotBePastDate = value =>
    moment(value).isBefore(moment().subtract(1, 'days').toISOString()) ? 'Date must be after today' : undefined;

const mustBeFutureDate = value =>
    moment(value).isAfter(moment().toISOString()) ? undefined : 'Date must be further into future';

const maxDate: (
    maxValue?: Moment | ((vals: any) => Moment),
    comparedLabel?: string | null
) => (value: Moment, allValues: any) => string | undefined =
    (maxValue, comparedLabel = 'current time') =>
    (value, allValues) => {
        let maxMoment;

        if (maxValue && 'function' === typeof maxValue) {
            const retrievedFormValue = maxValue(allValues);

            maxMoment = moment(retrievedFormValue);
        } else {
            maxMoment = maxValue && moment.isMoment(maxValue) ? moment(maxValue) : moment();
        }

        if (value && moment(value).isAfter(maxMoment)) {
            return `Date/Time must not be after ${comparedLabel ? `${comparedLabel}: ` : ''} ${maxMoment.format(
                'MM/DD/YYYY, hh:mm A'
            )}`;
        }

        return undefined;
    };

const minDate = (comparedField, comparedLabel) => (value, allValues) => {
    let comparedValue;

    if (comparedField && 'function' === typeof comparedField) {
        comparedValue = comparedField(allValues);
    } else {
        comparedValue = allValues[comparedField];
    }

    if (value && comparedValue && moment(value).isSameOrBefore(comparedValue, 'minute')) {
        return `Date/Time must be after ${comparedLabel}: ${moment(comparedValue).format('MM/DD/YYYY, hh:mm A')}`;
    }

    return undefined;
};

export {
    mustContainNoSpaces,
    mustBeAfterSpecifiedDate,
    mustContainCapitalLetter,
    mustContainLowercaseLetter,
    mustContainSpecialCharacter,
    mustContainNumber,
    passwordsMustMatch,
    passwordsMustNotBeSameAsOld,
    mustNotBePastDate,
    mustBeFutureDate,
    minDate,
    maxDate,
};
