import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useInput, useTranslate, FieldTitle, InputHelperText, sanitizeInputRestProps } from 'react-admin';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Event from '@mui/icons-material/Event';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// This code copied from WiXSL/react-admin-date-inputs/blob/mui-pickers-v3/ because the repo host key was not maintained

const leftPad =
    (nb = 2) =>
    value =>
        ('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

/**
 * @param {Date} value value to convert
 * @returns {String} A standardized datetime (yyyy-MM-ddThh:mm), to be passed to an <input type="datetime-local" />
 */
const convertDateToString = value => {
    if (!(value instanceof Date) || isNaN(value.getDate())) {
        return '';
    }

    const yy = leftPad4(value.getFullYear());
    const MM = leftPad2(value.getMonth() + 1);
    const dd = leftPad2(value.getDate());
    const hh = leftPad2(value.getHours());
    const mm = leftPad2(value.getMinutes());
    return `${yy}-${MM}-${dd}T${hh}:${mm}`;
};

// yyyy-MM-ddThh:mm
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

/**
 * Converts a date from the Redux store, with timezone, to a date string
 * without timezone for use in an <input type="datetime-local" />.
 *
 * @param {Date | String} value date string or object
 */
const formatDateTime = (value: string | Date) => {
    // null, undefined and empty string values should not go through convertDateToString
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (null == value || '' === value) {
        return '';
    }

    if (value instanceof Date) {
        return convertDateToString(value);
    }
    // valid dates should not be converted
    if (dateTimeRegex.test(value)) {
        return value;
    }

    return convertDateToString(new Date(value));
};

/**
 * Converts a datetime string without timezone to a date object
 * with timezone, using the browser timezone.
 *
 * @param {String} value Date string, formatted as yyyy-MM-ddThh:mm
 * @return {Date}
 */

const parseDateTime = (value: string) => (value ? new Date(value) : null);

const Picker = ({
    PickerComponent,
    defaultValue,
    format = formatDateTime,
    label,
    clearable,
    className,
    options,
    source,
    resource,
    helperText,
    fullWidth = false,
    inputSize = 'small',
    inputVariant = 'filled',
    margin = 'dense',
    onChange,
    onOpen,
    onClose,
    parse = parseDateTime,
    validate,
    stringFormat = 'ISO',
    ...rest
}) => {
    const [open, setOpen] = useState<boolean>(false);

    const translate = useTranslate();

    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
        id,
        isRequired,
    } = useInput({
        defaultValue,
        format,
        parse,
        onChange,
        resource,
        source,
        validate,
        ...rest,
    });

    const handleChange = useCallback(
        value =>
            Date.parse(value)
                ? field.onChange('ISO' === stringFormat ? value.toISOString() : value.toString())
                : field.onChange(null),
        [field, stringFormat]
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PickerComponent
                {...sanitizeInputRestProps(rest)}
                {...field}
                {...options}
                id={id}
                open={open}
                label={<FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />}
                renderInput={props => (
                    <TextField
                        size={inputSize}
                        variant={inputVariant}
                        margin={margin}
                        fullWidth={fullWidth}
                        onClick={() => {
                            if (!props.disabled) {
                                setOpen(true);
                            }
                        }}
                        {...props}
                    />
                )}
                error={(isTouched || isSubmitted) && invalid}
                helperText={
                    <InputHelperText
                        touched={isTouched || isSubmitted}
                        error={error?.message}
                        helperText={helperText}
                    />
                }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Event />
                        </InputAdornment>
                    ),
                }}
                clearLabel={translate('ra.action.clear_input_value')}
                cancelLabel={translate('ra.action.cancel')}
                className={clsx('ra-input', `ra-input-${source}`, className)}
                clearable={clearable}
                value={field.value ? new Date(field.value) : null}
                onChange={date => handleChange(date)}
                onOpen={onOpen}
                onClose={() => {
                    if (onClose && 'function' === typeof onClose) {
                        onClose();
                    }
                    setOpen(false);
                }}
            />
        </LocalizationProvider>
    );
};

Picker.propTypes = {
    PickerComponent: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    inputSize: PropTypes.string,
    inputVariant: PropTypes.string,
    labelTime: PropTypes.string,
    margin: PropTypes.string,
    variant: PropTypes.string,
    className: PropTypes.string,
    stringFormat: PropTypes.string,
};

Picker.defaultProps = {
    isRequired: false,
    label: '',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
};

// const DateInput = props => <Picker PickerComponent={DatePicker} {...props} />;
const DateInput = props => <Picker PickerComponent={MobileDatePicker} {...props} />;
// const TimeInput = props => <Picker PickerComponent={TimePicker} {...props} />;
const TimeInput = props => <Picker PickerComponent={MobileTimePicker} {...props} />;
// const DateTimeInput = props => <Picker PickerComponent={DateTimePicker} {...props} />;
const DateTimeInput = props => <Picker PickerComponent={MobileDateTimePicker} {...props} />;

export { DateInput, TimeInput, DateTimeInput };
