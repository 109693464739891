import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';

import { useTranslate, useCreateSuggestionContext } from 'react-admin';

const CreateSuggestionCancelButton = ({ label = 'ra.action.cancel' }) => {
    const translate = useTranslate();
    const createSuggestionContext = useCreateSuggestionContext();

    if (!createSuggestionContext) {
        return null;
    }

    return (
        <Button sx={{ margin: '0.5em 1em', position: 'relative' }} onClick={() => createSuggestionContext.onCancel()}>
            <IconCancel sx={{ paddingRight: '0.5em' }} />
            {label && translate(label, { _: label })}
        </Button>
    );
};

CreateSuggestionCancelButton.propTypes = {
    label: PropTypes.string,
};

export default CreateSuggestionCancelButton;
