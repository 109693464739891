import lodash from 'lodash';
import moment from 'moment-timezone';

const getTimeZone = () => {
    const userTimeZone = moment.tz.guess();
    const userTimeZoneAbbr = moment.tz(userTimeZone).zoneAbbr();

    return { time_zone: userTimeZone, time_zone_abbr: userTimeZoneAbbr };
};

const getCanReadFieldsMerge = (capabilityFields, accessFields) => {
    if (!capabilityFields || !accessFields) {
        return null;
    }
    const uniqueFields = accessFields.filter(field => !capabilityFields.includes(field));

    return capabilityFields ? capabilityFields.concat(uniqueFields) : uniqueFields;
};

const getPermissions = roles => {
    const permissions = {};

    lodash.forEach(lodash.map(roles, 'capabilities'), (value, key) => {
        lodash.forEach(value, (capability, valKey) => {
            if (lodash.includes(lodash.keys(permissions), capability.slug)) {
                const access = lodash.get(permissions, capability.slug);
                permissions[capability.slug] = {
                    can_create: capability.can_create || access.can_create,
                    can_update: capability.can_update || access.can_update,
                    can_delete: capability.can_delete || access.can_delete,
                    can_read: capability.can_read || access.can_read,
                    can_export: capability.can_export || access.can_export,
                    can_read_fields: getCanReadFieldsMerge(capability.can_read_fields, access.can_read_fields),
                };
            } else {
                permissions[capability.slug] = {
                    can_create: capability.can_create,
                    can_update: capability.can_update,
                    can_delete: capability.can_delete,
                    can_read: capability.can_read,
                    can_export: capability.can_export,
                    can_read_fields: capability.can_read_fields,
                };
            }
        });
    });

    return permissions;
};

export { getTimeZone, getPermissions };
