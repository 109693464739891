import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './BreedingRecordsFormHelpers';

const getZeroOrNullValues = val => {
    if (0 === val) {
        return 0;
    }
    if (val) {
        return val;
    }
    return null;
};

export const transformBreedingRecords = rec => {
    const newRecord: any = {
        administered_by_id: rec.administered_by_id,
        specimen_id: rec.specimen_id,
        male_specimen_id: rec.male_specimen_id,
        type: rec.type,
        bred_at: rec.bred_at,
        specimen_weight_pounds: getZeroOrNullValues(rec.specimen_weight_pounds),
        specimen_score: getZeroOrNullValues(rec.specimen_score),
        male_specimen_weight_pounds: getZeroOrNullValues(rec.male_specimen_weight_pounds),
        male_specimen_score: getZeroOrNullValues(rec.male_specimen_score),
        notes: rec.notes,
        is_active: rec.is_active,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Breeding Record">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformBreedingRecords} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Breeding Record">
        <TabbedForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Breeding Record" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Breeding Record" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformBreedingRecords} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const BreedingRecordsEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const BreedingRecordsCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);
