import React from 'react';
import { SimpleShowLayout, Tab, usePermissions, useRecordContext, useResourceContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import { shouldRender, sanitizeTabbedShowProps } from '../../helpers';
import { DrawerFeatures, PrintTitle } from '.';
import { useCreateFields } from '../../hooks';

const sanitizeFieldProps = ({ passProps, passClasses, mapResourcePath, ...rest }: any) => rest;

const sanitizeSimpleShowProps = ({
    userAllowed,
    isDrawer,
    recordAllowed,
    openPreviewDrawer,
    printView,
    setIsLoadingQRForPrint,
    mapResourcePath,
    ...rest
}: any) => rest;

const PREFIX = 'FilteredFieldsRenderer';
const classes = {
    drawer: `${PREFIX}-drawer`,
    columnShowView: `${PREFIX}-columnShowView`,
    fullScreen: `${PREFIX}-fullScreen`,
};
const ShowViewStyles = ({ theme }) => ({
    [`& .${classes.drawer}`]: {
        '& span': {
            display: 'inline-block',
            minWidth: '25vw',
            maxWidth: '78vw',
        },
    },
    [`& .${classes.columnShowView}`]: {
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: { width: '100%' },
        '@media print': { width: '50%', '& div': { maxWidth: '35vw' } },
        width: '50%',
        '& span': { width: '100%' },
        '& div': { [theme.breakpoints.up('sm')]: { maxWidth: '70vw' } },
    },
    [`& .${classes.fullScreen}`]: { width: '100%', display: 'inline-block', '& div': { width: '100%' } },
});
const StyledSimpleShowLayout = styled(SimpleShowLayout)(ShowViewStyles);
const StyledTab = styled(Tab)(ShowViewStyles);

type TFilteredFieldsRenderedProps = {
    [x: string]: any;
    getFieldsFunction: () => JSX.Element[];
    isTabbedShow?: boolean;
    label?: string;
    children?: any;
};

const FilteredFieldsRenderer: React.FC<TFilteredFieldsRenderedProps> = ({
    getFieldsFunction,
    isTabbedShow = false,
    label = 'Overview',
    children,
    ...props
}) => {
    const { permissions } = usePermissions();
    const resource = useResourceContext(props);
    const record = useRecordContext(props);

    const { isDrawer, recordAllowed, userAllowed } = props;

    const canReadFields = React.useMemo(
        () => shouldRender(permissions, resource, 'can_read_fields') || null,
        [permissions, resource]
    );

    const dyanmicFilteredFields: React.ReactNode[] = useCreateFields(getFieldsFunction, isDrawer, canReadFields);

    const fields = React.useMemo(
        () =>
            dyanmicFilteredFields
                .map((field, index) => {
                    if (React.isValidElement(field)) {
                        if (field.props && field.props.render && 'function' === typeof field.props.render) {
                            if (!field.props.render(record)) {
                                return null;
                            }
                        }
                        const fieldProps = {
                            ...sanitizeFieldProps(field.props),
                            key: `${field.props.source}-${index}`,
                            record,
                        };
                        if (field.props && field.props.passClasses) {
                            fieldProps.classes = classes;
                        } else {
                            const fieldClassName = isDrawer ? classes.drawer : classes.columnShowView;
                            fieldProps.className = field.props.className
                                ? classes[field.props.className]
                                : fieldClassName;
                        }
                        return React.cloneElement(
                            field,
                            field.props.passProps ? { ...fieldProps, ...sanitizeFieldProps(props) } : { ...fieldProps }
                        );
                    }
                    return null;
                })
                .filter(field => !!field),
        [dyanmicFilteredFields, isDrawer, props, record]
    );

    return React.useMemo(
        () =>
            isTabbedShow ? (
                <StyledTab label={label} {...sanitizeTabbedShowProps(props)}>
                    {fields}
                    {children}
                </StyledTab>
            ) : (
                <StyledSimpleShowLayout {...sanitizeSimpleShowProps(props)}>
                    <PrintTitle {...props} />
                    {fields}
                    {children}
                    <DrawerFeatures recordAllowed={recordAllowed} userAllowed={userAllowed} {...props} />
                </StyledSimpleShowLayout>
            ),
        [children, fields, isTabbedShow, label, recordAllowed, userAllowed, props]
    );
};

export default FilteredFieldsRenderer;
