import React from 'react';
import { useGetDynamicComponents } from './useGetDynamicComponents';

const useCreateFields = (
    getComponents?: (extraProps?: any) => any,
    isDrawer: boolean = false,
    canReadFields: string[] | null = null
) => {
    const [displayFields, setDisplayFields] = React.useState<Array<React.ReactNode>>([]);

    const generatedFields = useGetDynamicComponents({
        standardComponents: false,
        getSpecialComponents: getComponents,
        componentType: 'fields',
        extraProps: isDrawer,
    });

    const hasGeneratedFields = generatedFields && 0 < generatedFields.length;

    React.useEffect(() => {
        if (hasGeneratedFields) {
            if (canReadFields && canReadFields.length) {
                const filteredFields = generatedFields.filter((field: any) =>
                    canReadFields.includes(field.props.source)
                );
                setDisplayFields(filteredFields);
            } else {
                setDisplayFields(generatedFields);
            }
        }
    }, [canReadFields, generatedFields, hasGeneratedFields]);

    return displayFields;
};

export default useCreateFields;
