import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BooleanInput, TextInput, NumberInput, required } from 'react-admin';
import { ColumnShowView, SingleColumn } from '../../../lib/styles/StyledComponents';
import { AttachmentsInput } from '../../../lib/customComponents/formComponents';
import { FacilityInput, UnitsOfMeasureInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const OverviewInputs = ({ edit = false, ...props }) => {
    const { setValue } = useFormContext();

    return (
        <>
            <ColumnShowView>
                <TextInput source="name" validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <FacilityInput validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <UnitsOfMeasureInput
                    onChange={() => {
                        setValue('length', null);
                        setValue('width', null);
                    }}
                    validate={required()}
                />
            </ColumnShowView>
            <ColumnShowView>
                <NumberInput source="length" label="Length" validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <NumberInput source="width" label="Width" validate={required()} />
            </ColumnShowView>
            <SingleColumn>
                <BooleanInput source="is_active" label="Active" defaultValue />
            </SingleColumn>
            <AttachmentsInput />
        </>
    );
};

export default OverviewInputs;
