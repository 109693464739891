import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, NumberField, BooleanField } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getMedicationRecordsFields = () => [
    <TextField source="id" label="ID" />,
    <LocalReferenceField source="administered_by_name" label="Administered By" reference="contacts" passProps />,
    <LocalReferenceField source="specimen_name" label="Specimen" passProps />,
    <NumberField source="specimen_age_years" label="Specimen Age (yrs)" />,
    <NumberField source="specimen_weight_pounds" label="Specimen Weight (lbs)" />,
    <LocalReferenceField source="medication_name" label="Medication" passProps />,
    <LocalReferenceField
        source="unit_of_measure_name"
        reference="units-of-measure"
        label="Unit of Measure"
        passProps
    />,
    <LocalReferenceField source="item_location_name" label="Item Location" passProps />,
    <NumberField source="quantity" />,
    <LocalReferenceField source="medication_route_name" label="Medication Route" passProps />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="administered_at" label="Administered At" showTime />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
    <TextField source="notes" className="fullScreen" />,
];

export const MedicationRecordsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getMedicationRecordsFields} {...props} />
);

const MedicationRecordsTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getMedicationRecordsFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const MedicationRecordsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <MedicationRecordsShowFields {...props} />
    </Show>
);

export const MedicationRecordsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <MedicationRecordsSimpleShow {...props} />
    ) : (
        <MedicationRecordsTabbedShow {...props} />
    );
