import React from 'react';
import { Datagrid } from 'react-admin';
import { LinearProgress } from '@mui/material';
import ColumnsWrapper from './ColumnsWrapper';

const DatagridWithViewContext: ({
    children,
    loading,
    ...props
}: {
    [x: string]: any;
    children: any;
    loading?: boolean;
}) => JSX.Element = ({ children, loading, ...props }) => {
    const isLoading = !children[0] || loading;
    return (
        <ColumnsWrapper>{isLoading ? <LinearProgress /> : <Datagrid {...props}>{children}</Datagrid>}</ColumnsWrapper>
    );
};

export default DatagridWithViewContext;
