import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
}));

export const ColumnShowView = styled('div')(({ theme }) => ({
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: { width: '100%' },
    '@media print': { width: '50%', '& div': { maxWidth: '35vw' } },
    '& span': { width: '100%' },
    '& div': { [theme.breakpoints.up('sm')]: { maxWidth: '70vw' } },
    [`& .ra-input`]: {
        minWidth: '30vw',
    },
}));

export const ColumnForceWidth = styled('div')(({ theme }) => ({
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: { width: '100%' },
    '@media print': { width: '50%', '& div': { maxWidth: '35vw', width: '256px' } },
    width: '50%',
    '& div': { [theme.breakpoints.up('sm')]: { maxWidth: '35vw' }, width: '256px' },
}));

export const FullScreen = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'inline-block',
    [`& .ra-input`]: { width: '100%' },
}));

export const SingleColumn = styled('div')(({ theme }) => ({ width: '100%', display: 'inline-block' }));

export const SmallModal = styled('div')(({ theme }) => ({ width: '16em' }));
