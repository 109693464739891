import React from 'react';
import { BooleanField, BooleanInput, DateField, NumberField, NumberInput, TextField } from 'react-admin';
import { DateTimeFilter, TextFilter } from '../../customComponents/showComponents/CommonFilterInputs';
import { TColumn } from '../../contexts/ViewContext';

const isText = type => type.includes('varchar') || 'text' === type;
const isDateTime = type => 'datetime' === type || 'timestamp' === type;
const isInt = type => type.includes('int') || type.includes('decimal');
const isBool = name => name.startsWith('is_');

export const modifiedName = name => name.replace(/_id|_description|_name/, '');

export const getInputComponent = value => {
    switch (value) {
        case 'int':
            return <NumberInput source="" />;
        case 'bool':
            return <BooleanInput source="" />;
        case 'dateTime':
            return <DateTimeFilter source="" label="" />;
        case 'text':
        default:
            return <TextFilter />;
    }
};

export const getFieldComponent = value => {
    switch (value) {
        case 'int':
            return <NumberField />;
        case 'bool':
            return <BooleanField />;
        case 'dateTime':
            return <DateField showTime />;
        case 'text':
        default:
            return <TextField />;
    }
};

export const getType = (name, type) => {
    if (isBool(name)) {
        return 'bool';
    }
    if (isText(type)) {
        return 'text';
    }
    if (isDateTime(type)) {
        return 'dateTime';
    }
    if (isInt(type)) {
        return 'int';
    }
};

export const getDynamicComponents = (
    fields: TColumn[],
    filterSources: string[] = [],
    componentType: string = 'fields',
    alwaysOnFilters: string[] = []
) => {
    const fieldArr: any[] = [];

    if ('inputs' === componentType) {
        fields.forEach(({ name, label, type, enabled }, index) => {
            if (false === filterSources.includes(modifiedName(name))) {
                const fieldType = getType(name, type);
                const matchingComponent = getInputComponent(fieldType);
                if (matchingComponent !== undefined) {
                    let componentProps: any = {
                        ...matchingComponent.props,
                        key: `${index}${name}`,
                        source: name,
                        label,
                        disabled: !enabled,
                    };
                    if (alwaysOnFilters && alwaysOnFilters.length && name && alwaysOnFilters.includes(name)) {
                        componentProps = { ...componentProps, alwaysOn: true };
                    }
                    fieldArr.push(
                        React.cloneElement(matchingComponent, {
                            ...componentProps,
                        })
                    );
                }
            }
        });
    }

    if ('fields' === componentType) {
        fields.forEach(({ source, label, type }, index) => {
            if (false === filterSources.includes(source)) {
                const fieldType = getType(source, type);
                const matchingComponent = getFieldComponent(fieldType);
                if (matchingComponent !== undefined) {
                    fieldArr.push(
                        React.cloneElement(matchingComponent, {
                            ...matchingComponent.props,
                            key: source + index,
                            source,
                            label,
                        })
                    );
                }
            }
        });
    }

    return fieldArr;
};
