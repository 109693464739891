import setCustomFilters from './setCustomFilters';

const getRelationsFromFilters = (filters, relations, relationsOnFilterLine, shouldJoinRelations = false) => {
    const relationsObj = {};
    let shouldJoin = shouldJoinRelations;

    const getRelationFilteredFilters = fils => {
        if (Array.isArray(fils)) {
            fils.forEach(arrFilItem => getRelationFilteredFilters(arrFilItem));
        }
        if (fils && Object.keys(fils).length) {
            const relFilters = {};
            Object.keys(fils).forEach(fil => {
                if ('conditions' === fil) {
                    getRelationFilteredFilters(fils[fil]);
                }
                if (fil.includes('-RELATION-')) {
                    shouldJoin = true;

                    const [relName, filterField] = fil.split('-RELATION-');
                    const filterVal = { [filterField]: fils[fil] };

                    relFilters[relName] = Object.keys(relFilters).includes(relName)
                        ? { ...relFilters[relName], ...filterVal }
                        : (relFilters[relName] = filterVal);
                }
            });
            Object.entries(relFilters).forEach(([rel, fil]) => {
                relationsObj[rel] = {
                    name: rel,
                    filters: setCustomFilters(fil),
                };
            });
        }
    };

    getRelationFilteredFilters(filters);

    let processedRelations;

    const fillRelation = rel => {
        if (rel.filters) {
            return { ...rel, filters: setCustomFilters(rel.filters) };
        }
        return rel;
    };

    if (relations && relations.length) {
        if (relationsObj && Object.keys(relationsObj).length) {
            const relArr = Object.values(relationsObj);
            processedRelations = relArr.map((rel: any) => {
                const overlappedRel = relations.find(prevRel => rel.name === prevRel.name);
                if (overlappedRel) {
                    const mergedRelFils = {
                        ...rel.filters,
                        // eslint-disable-next-line no-nested-ternary
                        conditions: rel.filters.conditions
                            ? overlappedRel.filters.conditions
                                ? [...rel.filters.conditions, ...overlappedRel.filters.conditions]
                                : rel.filters.conditions
                            : [],
                    };
                    return { name: rel.name, filters: mergedRelFils };
                }
                return rel;
            });
        } else {
            processedRelations = relations.map(rel => fillRelation(rel));
        }
    } else if (Object.keys(relationsObj).length) {
        processedRelations = Object.values(relationsObj).map(rel => fillRelation(rel));
    }

    if (relationsOnFilterLine && relationsOnFilterLine.length) {
        if (processedRelations) {
            const overlapped = processedRelations.find(rel =>
                relationsOnFilterLine.some(filRel => filRel.name === rel.name)
            );
            if (overlapped) {
                const overlappedOnLine = relationsOnFilterLine.find(rel => rel.name === overlapped.name);
                processedRelations = [
                    ...processedRelations.filter(rel => rel.name !== overlapped.name),
                    {
                        ...overlapped,
                        filters: {
                            // eslint-disable-next-line no-nested-ternary
                            conditions: overlapped.filters.conditions
                                ? overlappedOnLine.filters.conditions
                                    ? [...overlapped.filters.conditions, ...overlappedOnLine.filters.conditions]
                                    : overlapped.filters.conditions
                                : [],
                        },
                    },
                ];
            } else {
                const relsWithFilterLineRel = [...processedRelations];
                relationsOnFilterLine.forEach(relOnFil => relsWithFilterLineRel.push(relOnFil));
                processedRelations = relsWithFilterLineRel;
            }
        } else if (!processedRelations) {
            processedRelations = relationsOnFilterLine;
        }
    }

    return { processedRelations, shouldJoin };
};

export default getRelationsFromFilters;
