import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, BooleanField } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getFacilitiesFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="organization_name" passProps />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const FacilitiesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getFacilitiesFields} {...props} />
);

const FacilitiesTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getFacilitiesFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const FacilitiesSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <FacilitiesShowFields {...props} />
    </Show>
);

export const FacilitiesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <FacilitiesSimpleShow {...props} />
    ) : (
        <FacilitiesTabbedShow {...props} />
    );
