import React from 'react';
import {
    SelectInput,
    TextInput,
    required,
    useNotify,
    useRecordContext,
    useResourceContext,
    useRedirect,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { ColumnShowView, FullScreen } from '../../../lib/styles/StyledComponents';
import {
    ItemTypeInput,
    ItemSubtypeInput,
    UnitsOfMeasureInput,
} from '../../../lib/customComponents/formComponents/CommonFormInputs';
import { CustomConfirm as Confirm } from '../../../lib/customComponents';
import { requestGetByID, requestGetList } from '../../../../dataProvider/RestClient';

const itemAccountTypeChoices = [
    { id: 1, value: 'Inventory Item', name: 'Inventory Item' },
    { id: 2, value: 'Fixed Asset Item', name: 'Fixed Asset Item' },
    { id: 3, value: 'Group Item', name: 'Group Item' },
    { id: 4, value: 'Inventory Assembly Item', name: 'Inventory Assembly Item' },
    { id: 5, value: 'Other Charge Item', name: 'Other Charge Item' },
    { id: 6, value: 'Sales Tax Item', name: 'Sales Tax Item' },
    { id: 7, value: 'Sales Tax Group Item', name: 'Sales Tax Group Item' },
    { id: 8, value: 'Service Item', name: 'Service Item' },
    { id: 9, value: 'Non-inventory Item', name: 'Non-inventory Item' },
];

const OverviewInputs = ({ edit = false, ...props }) => {
    const [navigateWindow, setNavigateWindow] = React.useState<boolean>(false);

    const { setValue } = useFormContext();
    const redirect = useRedirect();

    const record = useRecordContext();
    const resource = useResourceContext();

    const itemTypeID = useWatch({ name: 'item_type_id' });
    const itemTypeName = useWatch({
        name: 'item_type_name',
        defaultValue: edit && record?.item_type_name ? record.item_type_name : null,
    });

    const notify = useNotify();

    const isMedicationForm = React.useMemo(
        () => (edit && 'Medication' === itemTypeName) || 'medications' === resource,
        [itemTypeName, edit, resource]
    );

    const handleTypeChange = React.useCallback(
        async ev => {
            const { value } = ev.target;
            if (value) {
                await requestGetByID('item-types', value)
                    .then(({ data }) => {
                        const { name: itemTypeNameVal } = data;
                        setValue('item_type_name', itemTypeNameVal);
                        if (!edit) {
                            if ('Medication' === itemTypeNameVal) {
                                setNavigateWindow(true);
                            }
                        }
                    })
                    .catch(() => notify('Problem getting item type, defaults could not load', { type: 'warning' }));
            }
        },
        [notify, setValue, edit]
    );

    const handleNavigateConfirm = React.useCallback(() => {
        setNavigateWindow(false);
        redirect('create', 'medications');
    }, [redirect]);

    const handleNavigateWindowClose = React.useCallback(() => {
        setValue('item_type_id', '');
        setValue('item_type_name', '');
        setNavigateWindow(false);
    }, [setValue]);

    React.useEffect(() => {
        if ('medications' === resource && !itemTypeID && !edit) {
            requestGetList('item-types', { name: 'Medication' }, 'id', 'ASC', 2, ['id', 'name'])
                .then(({ data }) => {
                    if (data[0]) {
                        const { id, name: itemTypeNameVal } = data[0];
                        setValue('item_type_id', id);
                        setValue('item_type_name', itemTypeNameVal);
                    }
                })
                .catch(() => notify('Problem getting item type, defaults could not load', { type: 'warning' }));
        } else if ('items' === resource && edit && 'Medication' === itemTypeName && record?.id) {
            redirect('edit', 'medications', record.id);
        }
    }, [resource, itemTypeID, itemTypeName, edit, notify, setValue, record, redirect]);

    return (
        <>
            <Confirm
                isOpen={navigateWindow}
                title="Create Medication"
                content="Would you like to create a medication?"
                confirmText="Yes"
                cancelText="No"
                onConfirm={handleNavigateConfirm}
                onClose={handleNavigateWindowClose}
            />
            <ColumnShowView>
                <ItemTypeInput validate={required()} onChange={handleTypeChange} disabled={isMedicationForm} />
                <TextInput source="item_type_name" sx={{ display: 'none' }} />
            </ColumnShowView>
            {!!itemTypeID && (
                <>
                    <ColumnShowView>
                        <TextInput source="name" validate={required()} />
                    </ColumnShowView>
                    <ColumnShowView>
                        <UnitsOfMeasureInput validate={required()} />
                    </ColumnShowView>
                    <ColumnShowView>
                        <ItemSubtypeInput
                            defaultCreateValues={{ parent_id: itemTypeID }}
                            filter={{ parent_id: itemTypeID }}
                            validate={required()}
                        />
                    </ColumnShowView>
                    <ColumnShowView>
                        <SelectInput
                            source="account_type"
                            label="Account Type"
                            choices={itemAccountTypeChoices}
                            optionValue="value"
                            validate={required()}
                            emptyText="Clear Selection"
                        />
                    </ColumnShowView>
                    <FullScreen>
                        <TextInput source="recommended_use" label="Recommended Use" />
                    </FullScreen>
                    <FullScreen>
                        <TextInput multiline source="notes" />
                    </FullScreen>
                </>
            )}
        </>
    );
};

export default OverviewInputs;
