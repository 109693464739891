import React from 'react';
import {
    List,
    sanitizeListRestProps,
    ShowButton,
    SimpleList,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useResourceDefinition,
    useUnselectAll,
} from 'react-admin';
import { useViewController, ViewContextProvider } from '../../contexts/ViewContext';
import { getUserSettings, shouldRender } from '../../helpers';
import { CustomActionsList } from '../../helpers/customActions';
import { useCustomizableColumns } from '../../hooks';
import { CustomPagination, DatagridWithViewContext, ResponsiveList, VariableEditButton } from '.';
import { DynamicFilter } from '../filterComponents';

type TDefaultMobileList = {
    leftIcon?: (record: any) => any;
    primaryText?: (record: any) => any;
    secondaryText?: (record: any) => any;
    tertiaryText?: (record: any) => any;
    linkType?: ((record: any) => string) | string | null;
};

type TDefaultDesktopList = {
    bulkActionButtons?: JSX.Element | boolean;
    recordAllowed?: ((record: any) => boolean) | boolean;
    expand?: JSX.Element;
    rowClick?: ((id?: number, resource?: string, record?: any) => 'edit' | 'show') | string;
    additionalButtons?: JSX.Element | boolean;
    specialCols?: () => any[];
    resourceOverride?: string;
    mapResourcePath?: (rec: any) => string;
};

const sanitizeMobileListProps = ({
    additionalButtons,
    additionalColumns,
    bulkActionButtons,
    defaultColumns,
    filterDefaultValues,
    mapResourcePath,
    recordAllowed,
    rowClick,
    specialCols,
    ...rest
}: any) => sanitizeListRestProps(rest);

const DefaultMobileList: React.FC<TDefaultMobileList> = ({
    leftIcon,
    primaryText,
    secondaryText,
    tertiaryText,
    linkType = 'show',
    ...props
}) => (
    <SimpleList
        leftIcon={leftIcon}
        primaryText={primaryText}
        secondaryText={secondaryText}
        tertiaryText={tertiaryText}
        linkType={linkType}
        {...sanitizeMobileListProps(props)}
    />
);

const ResourceMappedShowButton = props => {
    const record = useRecordContext(props);
    const { mapResourcePath } = props;

    const mappedResource =
        record && mapResourcePath && 'function' === typeof mapResourcePath && mapResourcePath(record);

    if (!mappedResource) {
        return <ShowButton />;
    }

    return <ShowButton resource={mappedResource} />;
};

const ResourceMappedEditButton = ({ mapResourcePath, ...props }) => {
    const record = useRecordContext(props);

    const mappedResource =
        record && mapResourcePath && 'function' === typeof mapResourcePath && mapResourcePath(record);

    if (!mappedResource) {
        return <VariableEditButton {...props} />;
    }

    return <VariableEditButton {...props} resourceOverride={mappedResource} />;
};

const DefaultDesktopList: React.FC<TDefaultDesktopList> = ({
    recordAllowed = true,
    expand,
    rowClick: rowClickOverride,
    additionalButtons = false,
    bulkActionButtons = false,
    specialCols,
    resourceOverride,
    mapResourcePath,
}) => {
    const [isInitialRender, setIsInitialRender] = React.useState<boolean>(true);

    const isMountedRef = React.useRef(false);
    const resource = useResourceContext();
    const unselectAll = useUnselectAll(resource);
    const { hasEdit } = useResourceDefinition({ resource });

    const { permissions } = usePermissions();

    const rowClick = (id, resourceArg, record) => {
        let clickRecordAllowed;
        if (recordAllowed) {
            if ('function' === typeof recordAllowed) {
                clickRecordAllowed = recordAllowed(record);
            } else {
                clickRecordAllowed = recordAllowed;
            }
        }
        if (clickRecordAllowed && shouldRender(permissions, resource, 'can_update') && hasEdit) {
            return 'edit';
        }
        return 'show';
    };

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    React.useEffect(() => {
        if (isInitialRender && isMountedRef.current && 'toggleSelection' === rowClickOverride) {
            unselectAll();
            setIsInitialRender(false);
        }
    }, [isInitialRender, rowClickOverride, unselectAll]);

    const cloneBulkActions =
        (React.isValidElement(bulkActionButtons) && React.cloneElement(bulkActionButtons)) || bulkActionButtons;

    const clonedAdditionalButtons =
        additionalButtons && Array.isArray(additionalButtons)
            ? additionalButtons.map((button, index) =>
                  React.cloneElement(button, { ...button.props, key: `additional-btn-${index}` })
              )
            : React.isValidElement(additionalButtons) && React.cloneElement(additionalButtons);

    const columns = useCustomizableColumns(specialCols);

    return (
        <DatagridWithViewContext
            bulkActionButtons={cloneBulkActions}
            rowClick={rowClickOverride || rowClick}
            expand={React.isValidElement(expand) ? React.cloneElement(expand) : undefined}
        >
            {columns}
            <ResourceMappedShowButton mapResourcePath={mapResourcePath} />
            {hasEdit && (
                <ResourceMappedEditButton
                    mapResourcePath={mapResourcePath}
                    recordAllowed={recordAllowed}
                    resourceOverride={resourceOverride}
                />
            )}
            {clonedAdditionalButtons}
        </DatagridWithViewContext>
    );
};

const defaultFilters = <DynamicFilter />;

const ListWithViewContext: ({
    additionalColumns,
    sort,
    alwaysOnFilters,
    excludeFilters,
    filter,
    filters,
    filterDefaultValues,
    pagination,
    perPage,
    bulkActionButtons,
    desktopList,
    mobileList,
    actions,
    children,
    ...props
}: {
    [x: string]: any;
    sort?: {
        field: string;
        order: string;
    };
    alwaysOnFilters?: string[];
    excludeFilters?: string[];
    filter?: any;
    filters?:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
    filterDefaultValues?: any;
    pagination?: false | JSX.Element;
    perPage?: number;
    bulkActionButtons?: boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    desktopList?: JSX.Element;
    mobileList?: JSX.Element;
    actions?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    children?: any;
}) => JSX.Element = ({
    additionalColumns,
    alwaysOnFilters,
    defaultColumns,
    excludeFilters,
    filter,
    filters = defaultFilters,
    filterDefaultValues = undefined,
    perPage = getUserSettings('default_per_page', 25),
    sort = { field: 'id', order: 'DESC' },
    title,
    ...props
}) => {
    const { resourceOverride } = props;

    const viewControllerProps = useViewController({ additionalColumns, defaultColumns });

    const clonedFilters =
        // eslint-disable-next-line no-nested-ternary
        React.isValidElement(filters) && 'object' === typeof filters.props
            ? alwaysOnFilters
                ? React.cloneElement<any>(filters, { ...filters.props, alwaysOnFilters, excludeFilters })
                : React.cloneElement<any>(filters, { ...filters.props, excludeFilters })
            : null;

    return (
        <ViewContextProvider value={viewControllerProps}>
            <List
                title={title}
                sort={sort}
                filter={filter}
                filters={clonedFilters}
                filterDefaultValues={filterDefaultValues}
                actions={<CustomActionsList resourceOverride={resourceOverride} />}
                pagination={<CustomPagination />}
                perPage={perPage}
            >
                <ResponsiveList desktopList={<DefaultDesktopList />} mobileList={<DefaultMobileList />} {...props} />
            </List>
        </ViewContextProvider>
    );
};

export default ListWithViewContext;
