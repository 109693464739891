import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, Tab } from 'react-admin';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import {
    AttachmentPreviewField,
    CustomResourceTitle,
    ExpandableAuditsTable,
    FilteredFieldsRenderer,
    USDFormattedNumberField,
} from '../../lib/customComponents/showComponents';
import { recordAllowed, RequisitionLineItems } from './index';

const getRequisitionFields = () => [
    <TextField source="reference_number" label="Reference No." />,
    <USDFormattedNumberField source="amount" label="Amount" />,
    <TextField source="status" />,
    <TextField source="memo" className="fullScreen" />,
    <DateField source="requisitioned_at" label="Requisitioned At" />,
];

export const RequisitionsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getRequisitionFields} recordAllowed={recordAllowed} {...props}>
        <RequisitionLineItems {...props} />
        {/* <ExpandableAuditsTable {...props} /> */}
        <AttachmentPreviewField {...props} />
    </FilteredFieldsRenderer>
);

const RequisitionsTabbedShow = props => (
    <Show
        title={<CustomResourceTitle customName="Requisition" />}
        actions={<CustomActionsShowView recordAllowed={recordAllowed} {...props} />}
        {...props}
    >
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer
                getFieldsFunction={getRequisitionFields}
                recordAllowed={recordAllowed}
                isTabbedShow
                {...props}
            />
            <Tab label="Items" path="items">
                <RequisitionLineItems {...props} />
            </Tab>
            {/* <Tab label="Audits" path="audits">
                <ExpandableAuditsTable {...props} />
            </Tab> */}
            <Tab label="Attachments" path="attachments">
                <AttachmentPreviewField {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const RequisitionsSimpleShow = props => (
    <Show
        title={<CustomResourceTitle customName="Requisition" />}
        actions={<CustomActionsShowView recordAllowed={recordAllowed} {...props} />}
        {...props}
    >
        <RequisitionsShowFields {...props} />
    </Show>
);

export const RequisitionsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <RequisitionsSimpleShow {...props} />
    ) : (
        <RequisitionsTabbedShow {...props} />
    );
