import React from 'react';
import { DateField, TextField, Show, Tab, TabbedShowLayout, NumberField, BooleanField } from 'react-admin';
import {
    AttachmentPreviewField,
    FilteredFieldsRenderer,
    LocalReferenceField,
} from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getPensFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <NumberField source="length" label="Length" />,
    <NumberField source="width" label="Width" />,
    <LocalReferenceField source="facility_name" label="Facility" reference="facilities" passProps />,
    <LocalReferenceField
        source="unit_of_measure_name"
        label="Unit of Measure"
        reference="units-of-measure"
        passProps
    />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const PensShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getPensFields} {...props}>
        <AttachmentPreviewField {...props} />
    </FilteredFieldsRenderer>
);

const PensTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getPensFields} isTabbedShow {...props} />
            <Tab label="Attachments" path="attachments">
                <AttachmentPreviewField {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const PensSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <PensShowFields {...props} />
    </Show>
);

export const PensShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <PensSimpleShow {...props} />
    ) : (
        <PensTabbedShow {...props} />
    );
