import React from 'react';
import { Button, useRefresh } from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Tooltip,
    Typography,
    IconButton,
    useMediaQuery,
    Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IDialogForm {
    [x: string]: any;
    children: React.ReactNode;
    title?: string;
    label?: string;
    dialogContent?: any;
    dialogContentTitle?: string;
    icon?: JSX.Element;
    button?: any;
    disabled?: boolean;
    onBeforeOpen?: () => Promise<boolean | void>;
    onClose?: () => void;
    content?: any;
    buttonMarginLeft?: string | number;
    maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
    passCloseDialogToForm?: boolean;
}

const setToolbarWithProps: (
    toolbar: any,
    resource: string,
    closeDialog: () => void
) => React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement> | null = (
    toolbar,
    resource,
    closeDialog
) => {
    const hasToolbar = React.isValidElement(toolbar);

    return hasToolbar
        ? React.cloneElement(toolbar, {
              // @ts-ignore
              basePath: `/${resource}`,
              resource,
              // @ts-ignore
              resourceOverride: toolbar.props.resourceOverride || resource,
              onSuccess: data => {
                  // @ts-ignore
                  if (toolbar.props && 'function' === typeof toolbar.props.onSuccess) {
                      // @ts-ignore
                      return toolbar.props.onSuccess({ data, closeDialog });
                  }
                  closeDialog();
              },
              onError: data => {
                  // @ts-ignore
                  if (toolbar.props && 'function' === typeof toolbar.props.onError) {
                      // @ts-ignore
                      return toolbar.props.onError({ data, closeDialog });
                  }
                  closeDialog();
              },
          })
        : null;
};

const FormWithProps = ({ form, closeDialog, passCloseDialogToForm }) => {
    const { children: innerChild, resource, ...rest } = form.props;

    if (!React.isValidElement(form)) {
        throw new Error('Form must be valid React element!');
    }

    let formElementProps = {
        title: ' ',
        children:
            React.isValidElement(innerChild) && React.Children.only(innerChild)
                ? React.cloneElement(innerChild, {
                      // @ts-ignore
                      toolbar: setToolbarWithProps(innerChild.props.toolbar, resource, closeDialog),
                  })
                : undefined,
        ...rest,
    };

    if (passCloseDialogToForm) {
        formElementProps = { ...formElementProps, closeDialog };
    }

    const FormElement = React.cloneElement(form, { ...formElementProps });

    return FormElement;
};

const DialogForm: React.FC<IDialogForm> = ({
    children,
    title,
    label,
    dialogContent,
    dialogContentTitle,
    icon,
    button: buttonOverride,
    disabled,
    onBeforeOpen,
    onClose,
    content,
    maxWidth = 'sm',
    buttonMarginLeft = 'auto',
    passCloseDialogToForm = true,
    ...props
}) => {
    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    const isMountedRef = React.useRef(false);

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const refresh = useRefresh();

    const handleClick = React.useCallback(
        async ev => {
            ev.stopPropagation();
            if (onBeforeOpen && 'function' === typeof onBeforeOpen) {
                await onBeforeOpen()
                    .then(() => setShowDialog(true))
                    .catch(err => {
                        throw new Error(err);
                    });
            } else {
                setShowDialog(true);
            }
        },
        [onBeforeOpen]
    );

    const handleCloseClick = React.useCallback(
        (refreshView = false) => {
            if (refreshView && isMountedRef.current) {
                refresh();
            }
            if ('function' === typeof onClose && isMountedRef.current) {
                onClose();
            }
            if (isMountedRef.current) {
                setShowDialog(false);
            }
        },
        [onClose, refresh]
    );

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return (
        <>
            {buttonOverride ? (
                React.cloneElement(buttonOverride, {
                    onClick: handleClick,
                    label: label || title,
                })
            ) : (
                <Button onClick={handleClick} label={label || title} sx={{ ml: buttonMarginLeft }} disabled={disabled}>
                    {icon}
                </Button>
            )}
            <Dialog
                fullWidth
                maxWidth={maxWidth}
                open={showDialog}
                onClose={(event, reason) => {
                    if ('backdropClick' !== reason) {
                        handleCloseClick();
                    }
                }}
                onClick={ev => ev.stopPropagation()}
                disableEscapeKeyDown
                {...props}
            >
                <div
                    style={
                        isSmall
                            ? undefined
                            : {
                                  display: 'flex',
                                  flexGrow: '1',
                              }
                    }
                >
                    <DialogTitle sx={{ flexGrow: '1' }}>{title}</DialogTitle>
                    <Tooltip title="Close">
                        <IconButton
                            onClick={() => handleCloseClick()}
                            sx={{ color: 'darkgray', m: 'auto', height: 'fit-content' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {'string' === typeof content ? <Typography>{content}</Typography> : content}
                    <FormWithProps
                        form={children}
                        closeDialog={handleCloseClick}
                        passCloseDialogToForm={passCloseDialogToForm}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DialogForm;
