import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { formatDate } from '../../lib/helpers';
import { ItemsListing, ListWithViewContext } from '../../lib/customComponents/showComponents';

export const ReviewRuleActionItems = props => (
    <ItemsListing
        type="review_rule_actions"
        title="Actions"
        columns={{
            approver_name: 'Approver',
            alternate_approver_name: 'Alternate Approver',
        }}
        formatToDrawerLink={[
            {
                name: 'approver_name',
                resource: 'users',
                idField: 'approver_id',
            },
            {
                name: 'alternate_approver_name',
                resource: 'users',
                idField: 'alternate_approver_id',
            },
        ]}
        {...props}
    />
);

export const ReviewRuleConditions = props => (
    <ItemsListing
        type="review_rule_conditions"
        title="Conditions"
        columns={{
            field_name: 'Field',
            sub_field_name: 'Sub Field',
            operator: 'Operator',
            sub_field_operation: 'Sub Field Operator',
            value: 'Value',
        }}
        {...props}
    />
);

const ReviewRulesExpand = props => (
    <SimpleShowLayout {...props}>
        <ReviewRuleActionItems {...props} />
        <ReviewRuleConditions {...props} />
    </SimpleShowLayout>
);

const reviewRulesDefaultColumns = ['name', 'description', 'review_entity_name', 'is_active', 'updated_at'];

export const ReviewRulesList = React.memo(props => (
    <ListWithViewContext
        title="Review Rules"
        defaultColumns={reviewRulesDefaultColumns}
        alwaysOnFilters={['name']}
        expand={<ReviewRulesExpand />}
        primaryText={record => record?.name}
        secondaryText={record => record?.review_entity_name}
        tertiaryText={record => record?.updated_at && `Updated: ${formatDate(record.updated_at)}`}
        {...props}
    />
));
