import React from 'react';
import { useNotify } from 'react-admin';
import { CustomConfirm as Confirm } from '../../Components/lib/customComponents';
import { useIosInstallPrompt } from '../../Components/lib/hooks/pwa-install';
import { Notification } from '../../Components/lib/customComponents/showComponents';

const InstallNotification = () => {
    const [installPromptOpen, setInstallPromptOpen] = React.useState(false);
    const [userIosOnChrome, setUserIosOnChrome] = React.useState(false);
    const [isInstalledNotification, setIsInstalledNotification] = React.useState(false);

    const notify = useNotify();

    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();

    window.addEventListener('appinstalled', () => {
        console.log('Application installed as PWA');
        setIsInstalledNotification(true);
    });

    React.useEffect(() => {
        if (iosInstallPrompt) {
            setInstallPromptOpen(true);
            const isChrome = /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent);
            if (isChrome) {
                setUserIosOnChrome(true);
            }
        }
    }, [iosInstallPrompt, notify]);

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <>
            <Notification
                handleUndo={reloadPage}
                undoable
                undoButtonText="Application Installed"
                message="Application successfully installed, please click to refresh."
                autoHideDuration={10000}
                notification={isInstalledNotification}
            />
            <Confirm
                isOpen={installPromptOpen}
                simpleMessage
                onConfirm={() => {
                    setInstallPromptOpen(false);
                    if (handleIOSInstallDeclined && 'function' === typeof handleIOSInstallDeclined) {
                        handleIOSInstallDeclined();
                    }
                }}
                content={
                    userIosOnChrome
                        ? 'This application can be downloaded to your iPhone. To unlock all features, please reopen in Safari.'
                        : "This application can be downloaded to your iPhone, click the share icon below and select 'Add to Home Screen.'"
                }
                title="Download application"
            />
        </>
    );
};

export default InstallNotification;
