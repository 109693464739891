import React from 'react';
import { ErrorWindow } from '../../index';
import useGetErrors from './useGetErrors';

const ToolbarErrorWindow = ({ isOpen, onClose, errors }) => {
    const { errorContent } = useGetErrors({ errors });

    return (
        <ErrorWindow
            isOpen={isOpen}
            title="Submit Failed"
            errorText="The form is not valid"
            simpleMessage={false}
            cancelText="Close"
            hasConfirm={false}
            error
            content={errorContent}
            onClose={onClose}
        />
    );
};

export default ToolbarErrorWindow;
