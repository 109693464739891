import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './ItemsFormHelpers';
import MedRoutesLinesForm from './MedRoutesLinesForm';
import CheckMedRoutesSaveButton from './CheckMedRoutesSaveButton';

const sanitizedMedicationRoutes = methods => methods.map(method => ({ id: method.id }));

const transformItem = rec => {
    const newRecord: any = {
        name: rec.name,
        unit_of_measure_id: rec.unit_of_measure_id,
        item_type_id: rec.item_type_id,
        item_subtype_id: rec.item_subtype_id,
        account_type: rec.account_type,
        recommended_use: rec.recommended_use,
        notes: rec.notes,
        medication_routes: sanitizedMedicationRoutes(rec.medication_routes),
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Item">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={
                <CustomToolbar
                    customSaveButton={<CheckMedRoutesSaveButton />}
                    transform={transformItem}
                    onSuccess={onSuccess}
                />
            }
            mode={formMode}
        >
            <OverviewInputs />
            <MedRoutesLinesForm />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Item">
        <TabbedForm
            toolbar={<CustomToolbar customSaveButton={<CheckMedRoutesSaveButton />} transform={transformItem} />}
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs />
                <MedRoutesLinesForm />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Item" actions={<CustomActionsEditForm />}>
        <SimpleForm
            toolbar={<CustomToolbar customSaveButton={<CheckMedRoutesSaveButton />} transform={transformItem} />}
            mode={formMode}
        >
            <OverviewInputs edit />
            <MedRoutesLinesForm edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Item" actions={<CustomActionsEditForm />}>
        <TabbedForm
            toolbar={<CustomToolbar customSaveButton={<CheckMedRoutesSaveButton />} transform={transformItem} />}
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs edit />
                <MedRoutesLinesForm edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ItemsEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const ItemsCreateForm = () => <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />;
