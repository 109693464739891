import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Tooltip, IconButton, PopoverOrigin, Menu, MenuItem, ListItemIcon } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useNotify, useTheme } from 'react-admin';
import { lightTheme, darkTheme } from '../themes';
import { requestUpdateCurrentUser } from '../../dataProvider/RestClient';

const AnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const TransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

export const getTheme = themeName => {
    switch (themeName) {
        case 'dark':
            return darkTheme;
        case 'light':
        default:
            return lightTheme;
    }
};

const themeOptions = [
    { name: 'Light', icon: <Brightness5Icon /> },
    { name: 'Dark', icon: <Brightness7Icon /> },
];

const ThemeButton = ({ themeName, icon, handleChangeTheme }) => (
    <MenuItem onClick={() => handleChangeTheme(themeName.toLowerCase())}>
        <ListItemIcon sx={{ minWidth: 5 }}>{icon}</ListItemIcon>
        {themeName}
    </MenuItem>
);

export const ThemeSelectButton = () => {
    const [hasInitialTheme, setHasInitialTheme] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentThemeName, setCurrentThemeName] = useState<string | null>(null);

    const [, setTheme] = useTheme(lightTheme);

    const notify = useNotify();

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = useCallback(() => setAnchorEl(null), []);
    const open = Boolean(anchorEl);

    const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}');
    const userTheme = localStorage.getItem('theme') && JSON.parse(localStorage.getItem('theme') || '');

    const handleChangeTheme = React.useCallback(
        themeName => {
            setTheme(getTheme(themeName));
            setCurrentThemeName(themeName);
            setAnchorEl(null);
            if (user && user.name && user.email) {
                const { name, email } = user;
                requestUpdateCurrentUser({
                    name,
                    email,
                    meta: { theme: themeName },
                }).catch(() => notify('Problem updating user theme!', { type: 'warning' }));
            }
        },
        [notify, setTheme, user]
    );

    useEffect(() => {
        if (!hasInitialTheme) {
            const newTheme = getTheme(userTheme);
            setTheme(newTheme);
            setHasInitialTheme(true);
            setCurrentThemeName(userTheme);
        }
    }, [hasInitialTheme, userTheme, setTheme]);

    const currentIcon = themeOptions.find(option => option.name.toLowerCase() === currentThemeName)?.icon || (
        <Brightness4Icon />
    );

    return (
        <>
            <Tooltip title="Select Theme" enterDelay={300}>
                <IconButton color="inherit" onClick={handleMenu} aria-label="Select Theme">
                    {currentIcon}
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-theme"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                open={open}
                onClose={handleClose}
            >
                {themeOptions.map(({ name, icon }) => (
                    <ThemeButton key={name} themeName={name} icon={icon} handleChangeTheme={handleChangeTheme} />
                ))}
            </Menu>
        </>
    );
};
