import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { InputAdornment } from '@mui/material';
import {
    useInput,
    FieldTitle,
    CommonInputProps,
    ResettableTextField,
    ResettableTextFieldProps,
    sanitizeInputRestProps,
    InputHelperText,
} from 'react-admin';

export type TextInputProps = CommonInputProps & Omit<ResettableTextFieldProps, 'label' | 'helperText'>;

type FormattedInputProps = {
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    thousandSeparator?: any;
    suffix?: string;
    helperText?: any;
    options?: any;
};

type FormattedNumberInputProps = TextInputProps & FormattedInputProps;

const NumberFormatCustom = React.forwardRef((props: any, ref) => {
    const { onChange: formatterOnChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                if ('undefined' !== typeof values.floatValue) {
                    formatterOnChange({
                        target: {
                            value: values.floatValue,
                            name: props.name,
                        },
                    });
                }
            }}
            thousandSeparator
            fixedDecimalScale={false}
            decimalScale={4}
        />
    );
});

const FormattedNumberInput = ({
    suffix,
    prefix,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    ...props
}: FormattedNumberInputProps) => {
    const {
        className,
        defaultValue = '',
        label,
        format,
        helperText,
        onBlur,
        onChange,
        parse,
        resource,
        source,
        type = 'text',
        validate,
        disabled,
        ...rest
    } = props;

    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
        id,
        isRequired,
    } = useInput({
        defaultValue,
        format,
        parse,
        resource,
        source,
        validate,
        onBlur,
        onChange,
        ...rest,
    });

    return (
        <ResettableTextField
            {...field}
            id={id}
            disabled={disabled}
            className={clsx('ra-input', `ra-input-${source}`, className)}
            error={(isTouched || isSubmitted) && invalid}
            label={
                '' !== label &&
                false !== label && (
                    <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
                )
            }
            helperText={
                <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
            }
            margin="dense"
            variant="filled"
            type={type}
            InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
            }}
            {...sanitizeInputRestProps(rest)}
        />
    );
};

FormattedNumberInput.propTypes = {
    className: PropTypes.string,
    source: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    format: PropTypes.func,
    helperText: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

FormattedNumberInput.defaultProps = {
    options: {},
    prefix: '',
    suffix: '',
};

export default FormattedNumberInput;
