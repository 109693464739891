import React from 'react';
import { BooleanField, DateField, TextField, TabbedShowLayout, NumberField, Show } from 'react-admin';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { FilteredFieldsRenderer } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';

const getPaymentTermsFields = () => [
    <NumberField source="id" />,
    <TextField source="name" />,
    <NumberField source="standard_due_days" label="Standard Due Days" />,
    <NumberField source="standard_discount_days" label="Standard Discount Days" />,
    <NumberField source="discount_percent" label="Discount (%)" options={{ maximumFractionDigits: 6 }} />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const PaymentTermsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getPaymentTermsFields} {...props} />
);

const PaymentTermsTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getPaymentTermsFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const PaymentTermsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <PaymentTermsShowFields {...props} />
    </Show>
);

export const PaymentTermsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <PaymentTermsSimpleShow {...props} />
    ) : (
        <PaymentTermsTabbedShow {...props} />
    );
