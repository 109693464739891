import React, { useCallback, useEffect, useState } from 'react';
import { useNotify, useRedirect, useLogout } from 'react-admin';
import { useGetSessionExpires } from '../../Components/lib/hooks';
import { CustomConfirm as Confirm } from '../../Components/lib/customComponents';
import { setLocalStorage } from '../../authProvider';
import { requestGetCurrentUser } from '../../dataProvider/RestClient';

const SessionTimer = () => {
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

    const { countdown, timerWarning, timerExpired } = useGetSessionExpires();

    const notify = useNotify();
    const redirect = useRedirect();
    const logout = useLogout();

    const handleRefreshSession = useCallback(() => {
        setIsRefreshing(true);
        requestGetCurrentUser()
            .then(res => {
                setIsRefreshing(false);
                setShowRefreshModal(false);
                setLocalStorage({ user: res });
            })
            .catch(err => {
                setShowRefreshModal(false);
                setIsRefreshing(false);
                notify('No current user found', { type: 'warning' });
                redirect('/login');
            });
    }, [notify, redirect]);

    useEffect(() => {
        if (timerWarning) {
            setShowRefreshModal(true);
        }
    }, [timerWarning]);

    useEffect(() => {
        if (timerExpired) {
            logout();
        }
    }, [timerExpired, redirect, logout]);

    return (
        <Confirm
            isOpen={showRefreshModal}
            title="Session expires soon"
            content={`You will be automatically logged out in ${countdown}, would you like to continue your session?`}
            confirmText="Continue Session"
            cancelText="Logout"
            onConfirm={handleRefreshSession}
            onClose={() => logout()}
            loading={isRefreshing}
            fullWidth
        />
    );
};

export default SessionTimer;
