import React from 'react';
import { ListProps, SimpleShowLayout, useRecordContext, useResourceContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import { formatDate } from '../../lib/helpers';
import { ListWithViewContext, ReferenceDrawerField, RelationTable } from '../../lib/customComponents/showComponents';
import ItemsFilters from './ItemsFilters';
import ItemsActionsMenu from './ItemsActionsMenu';

const LocationPivot = rec =>
    rec.pivot ? (
        <Chip
            color={0 < parseFloat(rec.pivot.quantity) ? 'primary' : 'secondary'}
            key={rec.pivot.id}
            label={rec.pivot.quantity}
        />
    ) : (
        <Chip color="secondary" key={rec.id} label="0" />
    );

export const LocationsTable = React.memo((props: any) => (
    <RelationTable
        columns={{
            id: 'Location ID',
            name: 'Name',
            pivot: 'Quantity',
        }}
        title="Item Locations"
        relation="item_locations"
        formatToDrawerLink={[
            {
                name: 'name',
                resource: 'item-locations',
                idField: 'id',
            },
        ]}
        formatToCustom={[
            {
                name: 'pivot',
                formatter: LocationPivot,
            },
        ]}
        {...props}
    />
));

export const MedicationRoutesTable = React.memo((props: any) => {
    const record = useRecordContext();
    const resource = useResourceContext();

    if ('Medication' !== record?.item_type_name || 'medications' !== resource) {
        return null;
    }

    return (
        <RelationTable
            columns={{
                id: 'ID',
                name: 'Name',
            }}
            title="Medication Routes"
            relation="medication_routes"
            formatToDrawerLink={[
                {
                    name: 'name',
                    resource: 'medication-routes',
                    idField: 'id',
                },
            ]}
            {...props}
        />
    );
});

const itemsDefaultColumns = [
    'id',
    'name',
    'item_type_name',
    'item_subtype_name',
    'unit_of_measure_name',
    'account_type',
    'updated_at',
];

const itemsSpecialColumns = () => [
    <ReferenceDrawerField source="item_type_name" label="Item Type" />,
    <ReferenceDrawerField source="unit_of_measure_name" label="Unit of Measure" reference="units-of-measure" />,
    <ReferenceDrawerField source="item_subtype_name" label="Subtype" />,
];

const ItemsExpand = props => (
    <SimpleShowLayout {...props}>
        <LocationsTable {...props} />
        <MedicationRoutesTable {...props} />
    </SimpleShowLayout>
);

const ItemsActions = () => <ItemsActionsMenu />;

export const mapItemsToMedications = rec => ('Medication' === rec?.item_type_name ? 'medications' : 'items');

export const ItemsList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Items"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOnFilters={['name']}
        filters={<ItemsFilters />}
        defaultColumns={itemsDefaultColumns}
        specialCols={itemsSpecialColumns}
        rowClick="expand"
        expand={<ItemsExpand />}
        mapResourcePath={mapItemsToMedications}
        filter={{ relationsOnFilterLine: [{ name: 'item_locations' }] }}
        rightIcon={ItemsActions}
        primaryText={record => record?.name}
        secondaryText={record => record?.item_type_name}
        tertiaryText={record => record?.updated_at && `Updated: ${formatDate(record.updated_at, 'short-date-time')}`}
        linkType={false}
        {...props}
    />
));

export default ItemsList;
