import React from 'react';
import { TextInput, required, useRecordContext } from 'react-admin';
import { ColumnShowView, FullScreen } from '../../../lib/styles/StyledComponents';
import { CodonInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const OverviewInputs = ({ edit = false, ...props }) => {
    const record = useRecordContext();

    return (
        <>
            <ColumnShowView>
                <TextInput source="name" validate={required()} />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="parent_id" label="Parent" filter={edit ? { NOT_id: record.id } : {}} />
            </ColumnShowView>
            <FullScreen>
                <TextInput multiline source="notes" />
            </FullScreen>
        </>
    );
};

export default OverviewInputs;
