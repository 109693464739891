import React from 'react';
import { ListProps, SimpleShowLayout } from 'react-admin';
import Chip from '@mui/material/Chip';
import { formatDate } from '../../lib/helpers';
import { ListWithViewContext, RelationTable } from '../../lib/customComponents/showComponents';

export const ItemSubtypesTable = React.memo((props: any) => (
    <RelationTable
        columns={{
            id: 'ID',
            name: 'Name',
        }}
        title="Item Subtypes"
        relation="item_subtypes"
        idField="parent_id"
        formatToDrawerLink={[
            {
                name: 'name',
                resource: 'item-subtypes',
                idField: 'id',
            },
        ]}
        {...props}
    />
));

const itemsDefaultColumns = ['id', 'name', 'created_at', 'updated_at'];

const ItemTypesExpand = props => (
    <SimpleShowLayout {...props}>
        <ItemSubtypesTable {...props} />
    </SimpleShowLayout>
);

export const ItemTypesList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Item Types"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOnFilters={['name']}
        defaultColumns={itemsDefaultColumns}
        expand={<ItemTypesExpand />}
        primaryText={record => record?.name}
        secondaryText={record => record?.parent_name && `Parent: ${record.parent_name}`}
        tertiaryText={record => record?.updated_at && `Updated: ${formatDate(record.updated_at, 'short-date-time')}`}
        {...props}
    />
));
