import React, { memo } from 'react';
import { DateField, TextField, useRecordContext } from 'react-admin';
import Link from '@mui/material/Link';
import { useReferenceSource } from '../../hooks';
import ReferenceDrawerField from './ReferenceDrawerField';

interface ILocalReferenceField {
    [x: string]: any;
    className?: string;
    idSource?: string;
    descSource?: string;
    reference?: string;
    record?: any;
    label?: string;
    source: string;
    isDrawer?: boolean;
    link?: string;
    printView?: boolean;
    isDateField?: boolean;
}

const LocalReferenceField: React.FC<ILocalReferenceField> = memo(
    ({
        className,
        idSource: idOverride,
        descSource: descOverride,
        reference: refOverride,
        source,
        isDrawer = false,
        link = isDrawer ? 'show' : 'drawer',
        printView = false,
        isDateField = false,
        ...rest
    }) => {
        const record = useRecordContext(rest);

        const { nameField, refResource, recordID } = useReferenceSource({
            source,
            record,
            idOverride,
            descOverride,
            refOverride,
        });

        const linkPath = `/#/${refResource}/${recordID}${'show' === link ? '/show' : ''}`;

        if (!recordID || !record) {
            return null;
        }

        if (printView) {
            return isDateField ? (
                <DateField source={nameField} record={record} showTime />
            ) : (
                <TextField source={nameField} record={record} />
            );
        }

        if ('drawer' === link) {
            return (
                <ReferenceDrawerField
                    className={className}
                    idSource={idOverride}
                    descSource={descOverride}
                    reference={refOverride}
                    record={record}
                    source={source}
                    isDateField={isDateField}
                    {...rest}
                />
            );
        }

        return (
            <Link href={linkPath}>
                {isDateField ? (
                    <DateField
                        source={nameField}
                        record={record}
                        showTime
                        sx={{
                            textDecoration: 'underline',
                        }}
                    />
                ) : (
                    <TextField
                        source={nameField}
                        record={record}
                        sx={{
                            textDecoration: 'underline',
                        }}
                    />
                )}
            </Link>
        );
    }
);

export default LocalReferenceField;
