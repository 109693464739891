import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './OrganizationsFormHelpers';

const transformOrganization = rec => {
    const newRecord: any = {
        name: rec.name,
        type: rec.type,
        is_active: rec.is_active,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Organization">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformOrganization} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Organization">
        <TabbedForm toolbar={<CustomToolbar transform={transformOrganization} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Organization" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformOrganization} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Organization" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformOrganization} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const OrganizationEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const OrganizationCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);
