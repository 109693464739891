import React from 'react';
import { DateField, TextField, Show, Tab, TabbedShowLayout } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { ItemSubtypesTable } from './index';

const getItemTypesFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="parent_name" label="Parent" reference="item-types" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const ItemTypesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getItemTypesFields} {...props}>
        <ItemSubtypesTable {...props} />
    </FilteredFieldsRenderer>
);

const ItemTypesTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getItemTypesFields} isTabbedShow {...props} />
            <Tab label="Subtypes" path="subtypes">
                <ItemSubtypesTable {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const ItemTypesSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <ItemTypesShowFields {...props} />
    </Show>
);

export const ItemTypesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <ItemTypesSimpleShow {...props} />
    ) : (
        <ItemTypesTabbedShow {...props} />
    );
