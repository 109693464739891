import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, Tab, NumberField, BooleanField } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { BreedingRecordSpecimens } from './index';

const getBreedingRecordsFields = () => [
    <TextField source="id" label="ID" />,
    <LocalReferenceField source="administered_by_name" label="Administered By" reference="contacts" passProps />,
    <LocalReferenceField source="specimen_name" label="Specimen" passProps />,
    <NumberField source="specimen_weight_pounds" label="Specimen Weight (lbs)" />,
    <NumberField source="specimen_age_years" label="Specimen Age (yrs)" />,
    <NumberField source="specimen_score" label="Specimen Score" />,
    <LocalReferenceField source="male_specimen_name" label="Male Specimen" reference="specimens" passProps />,
    <NumberField source="male_specimen_weight_pounds" label="Male Specimen Weight (lbs)" />,
    <NumberField source="male_specimen_age_years" label="Male Specimen Age (yrs)" />,
    <NumberField source="male_specimen_score" label="Male Specimen Score" />,
    <TextField source="type" />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="bred_at" label="Bred At" showTime />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
    <TextField source="notes" className="fullScreen" />,
];

export const BreedingRecordsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getBreedingRecordsFields} {...props}>
        <BreedingRecordSpecimens {...props} />
    </FilteredFieldsRenderer>
);

const BreedingRecordsTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getBreedingRecordsFields} isTabbedShow {...props} />
            <Tab label="Specimen" path="specimen">
                <BreedingRecordSpecimens {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const BreedingRecordsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <BreedingRecordsShowFields {...props} />
    </Show>
);

export const BreedingRecordsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <BreedingRecordsSimpleShow {...props} />
    ) : (
        <BreedingRecordsTabbedShow {...props} />
    );
