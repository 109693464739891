import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { required, NumberInput } from 'react-admin';
import getRecurrenceHelperText from './getRecurrenceHelperText';

const RecurrenceIntervalInput = ({ fullWidth }) => {
    const recurrenceInterval = useWatch({ name: 'recurrence_interval' });
    const recurrenceFrequency = useWatch({ name: 'recurrence_frequency' });
    const recurrenceDays = useWatch({ name: 'recurrence_days' });
    const recurrenceMonthDays = useWatch({ name: 'recurrence_month_days' });
    const recurrenceSchedule = useWatch({ name: 'schedule' });
    const startedAt = useWatch({ name: 'started_at' });

    const helperText = useMemo(
        () =>
            getRecurrenceHelperText({
                recurrenceInterval,
                recurrenceFrequency,
                recurrenceMonthDays,
                recurrenceDays,
                recurrenceSchedule,
                startedAt,
            }),
        [recurrenceInterval, recurrenceFrequency, recurrenceDays, recurrenceMonthDays, recurrenceSchedule, startedAt]
    );

    return (
        <NumberInput
            label="Interval"
            source="recurrence_interval"
            min={1}
            validate={required()}
            helperText={helperText}
            defaultValue={1}
            fullWidth={fullWidth}
        />
    );
};

export default RecurrenceIntervalInput;
