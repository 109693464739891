import React, { useCallback, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { required, SelectInput, SelectArrayInput } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { FormLabel, Button, ButtonGroup } from '@mui/material';
import { TransferInput } from '../../formComponents';
import { useViewContext } from '../../../contexts/ViewContext';
import { getType, getInputComponent } from '../../../hooks/useGetDynamicComponents';

const MAX_ALLOWED_INPUTS = 7;

const RelationButtonGroup = ({ active, setActive, numberOfInputs }) => {
    const relation = useWatch({ name: 'relation' });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <FormLabel component="legend">
                <span>Select Relations</span>
            </FormLabel>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                {[...Array(numberOfInputs)].map((rel, index) => {
                    const relationValue = relation && relation[index - 1];
                    const isDisabled = 0 !== index && (!relationValue || 'None' === relationValue);
                    return (
                        <Button
                            key={index}
                            color={index === active ? 'primary' : 'info'}
                            onClick={() => setActive(index)}
                            disabled={isDisabled}
                        >
                            {index + 1}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </div>
    );
};

type TRelationInputs = {
    [x: string]: any;
    setRelationOptions: React.Dispatch<React.SetStateAction<any[]>>;
    relationOptions: any[];
    columnOptions: any;
    relationFilters: any;
    setRelationFilters: React.Dispatch<React.SetStateAction<{}>>;
    filterComponents: {};
    setFilterComponents: React.Dispatch<React.SetStateAction<{}>>;
    joinedColsByRel?: any[];
    processedRelationFilters?: any;
    index: number;
};

const RelationInputs: React.FC<TRelationInputs> = ({
    setRelationOptions,
    relationOptions,
    columnOptions,
    relationFilters,
    setRelationFilters,
    filterComponents,
    setFilterComponents,
    joinedColsByRel,
    processedRelationFilters,
    index,
    ...rest
}) => {
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [hasInitialRelation, setHasInitialRelation] = useState<boolean>(false);
    const [selectedRelationColumns, setSelectedRelationColumns] = useState({});

    const { setValue } = useFormContext();

    const relation = useWatch({ name: 'relation' });

    const [relationValue, setRelationValue] = React.useState(relation && relation[index]);

    const relationColumns = React.useMemo(
        () => relationValue && columnOptions && columnOptions[relationValue],
        [relationValue, columnOptions]
    );

    const setJoinedRelation = React.useCallback(
        (rel, columns, filters) => {
            setHasInitialRelation(true);
            setValue(`relation.${index}`, rel);
            setValue(`relation_columns.${index}`, columns);
            if (filters && Object.keys(filters).length) {
                const filterFields: string[] = [];
                Object.entries(filters).forEach(([field, val]) => {
                    let processedVal = val;
                    let processedField = field;
                    if (field.includes('_from') || field.includes('_to')) {
                        filterFields.push(field);
                        processedField = field.replace('_from', '').replace('_to', '');
                    }
                    // ZTODO: There has to be a better way to determine if an input needs to be a SelectArrayInput, etc.
                    if (Array.isArray(val)) {
                        processedVal = val.join(' ');
                    }
                    if (!filterFields.includes(processedField)) {
                        filterFields.push(processedField);
                    }
                    const inputValue = `relation_filter_values-${rel}-${field}`;
                    setValue(inputValue, processedVal);
                    setRelationFilters(prev => ({ ...prev, [inputValue]: val }));
                });
                setValue(`relation_filter_fields.${index}`, filterFields);
                setFilterOptions(filterFields);
            }
            setRelationValue(rel);
            const updatedRelations = relationOptions.map(relOption => ({
                ...relOption,
                disabled: relOption.name === rel,
            }));

            setRelationOptions(updatedRelations);
            if (columns && columns.length) {
                setSelectedRelationColumns({ [rel]: columns });
            }
        },
        [setValue, index, relationOptions, setRelationFilters, setRelationOptions]
    );

    useMemo(() => {
        const relationNames = relationOptions.map(relOption => relOption.name);
        if (
            joinedColsByRel &&
            joinedColsByRel[index] &&
            Object.entries(joinedColsByRel[index]) &&
            Object.entries(joinedColsByRel[index]).length &&
            !hasInitialRelation
        ) {
            const [rel, cols] = Object.entries(joinedColsByRel[index])[0];
            const unPluralizedRel = rel.substring(0, rel.length - 1);
            if (relationNames.includes(rel)) {
                const relationListFilters = processedRelationFilters[rel] || {};
                setJoinedRelation(rel, cols, relationListFilters);
            } else if (relationNames.includes(unPluralizedRel)) {
                const relationListFilters = processedRelationFilters[unPluralizedRel] || {};
                setJoinedRelation(unPluralizedRel, cols, relationListFilters);
            }
        } else if (processedRelationFilters && Object.entries(processedRelationFilters).length && !hasInitialRelation) {
            const allFilters = Object.entries(processedRelationFilters);
            if (allFilters && allFilters.length) {
                const filtersAtIndex = allFilters[index];
                if (filtersAtIndex) {
                    const [rel, filters] = filtersAtIndex;
                    if (relationNames.includes(rel)) {
                        setJoinedRelation(rel, [], filters);
                    }
                }
            }
        }
    }, [hasInitialRelation, index, joinedColsByRel, processedRelationFilters, relationOptions, setJoinedRelation]);

    useEffect(() => {
        if (relationColumns && relationColumns.length && filterOptions && filterOptions.length) {
            const filters = relationColumns.filter(item => filterOptions.includes(item.source));
            setFilterComponents(prev => ({ ...prev, [relationValue]: filters }));
        }
    }, [filterOptions, relationColumns, relationValue, setFilterComponents]);

    const handleSelect = useCallback(
        (ev, currentIndex) => {
            const { value } = ev.target;
            setRelationValue(value);

            const activeRelations = relation && relation.filter((val, i) => i !== currentIndex);

            const isDisabled = name => name === value || (activeRelations && activeRelations.includes(name));
            const updatedRelations = relationOptions.map(relOption => ({
                ...relOption,
                disabled: isDisabled(relOption.name),
            }));

            setRelationOptions(updatedRelations);

            if (selectedRelationColumns[value]) {
                return setValue(`relation_columns.${index}`, selectedRelationColumns[value]);
            }
            setValue(`relation_columns.${index}`, []);
        },
        [setValue, index, relationOptions, selectedRelationColumns, setRelationOptions, relation]
    );

    const handleFilterChange = useCallback(
        ev => {
            const { value } = ev.target;

            const previousField = filterOptions.find(
                option => !value.includes(option) && value.length < filterOptions.length
            );
            if (previousField) {
                const formFieldSource = `relation_filter_values-${relationValue}-${previousField}`;
                setValue(formFieldSource, null);
            }
            if (0 === value.length) {
                setFilterComponents({});
                setFilterOptions([]);
            }
            setFilterOptions(value);
        },
        [filterOptions, setValue, relationValue, setFilterComponents]
    );

    const handleChange = useCallback(
        (ev, source) => {
            if (ev && ev.target) {
                const { value, name } = ev.target;
                setRelationFilters(prev => ({ ...prev, [name]: value }));
            } else if (moment(ev).isValid()) {
                setRelationFilters(prev => ({
                    ...prev,
                    [`relation_filter_values-${relationValue}-${source}`]: moment(ev).toISOString(),
                }));
            }
        },
        [relationValue, setRelationFilters]
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <SelectInput
                source={`relation.${index}`}
                label="Export With Relation"
                choices={relationOptions}
                optionValue="name"
                emptyText="None"
                onChange={ev => handleSelect(ev, index)}
                sx={{ minWidth: '16em' }}
            />
            {relationValue && 'None' !== relationValue && (
                <>
                    <TransferInput
                        source={`relation_columns.${index}`}
                        label={`Relation Columns (${relationValue})`}
                        choices={relationColumns}
                        validate={required()}
                        optionValue="source"
                        optionText="label"
                        fullWidth
                        reorderableRightInput
                    />
                    <SelectArrayInput
                        source={`relation_filter_fields.${index}`}
                        label="Add Filter on Relation"
                        choices={relationColumns}
                        onChange={handleFilterChange}
                        optionText="label"
                        optionValue="source"
                        sx={{ minWidth: '16em' }}
                    />
                    {filterComponents[relationValue] &&
                        filterComponents[relationValue].map(({ source, label, type }) => {
                            const component = getInputComponent(getType(source, type));
                            return React.cloneElement(component, {
                                ...component.props,
                                ...rest,
                                key: source + index,
                                source: `relation_filter_values-${relationValue}-${source}`,
                                label,
                                onChange: ev => handleChange(ev, source),
                            });
                        })}
                </>
            )}
        </div>
    );
};

const RelationGroupInputs = ({ relationOptions, setRelationOptions, ...rest }) => {
    const [active, setActive] = useState(0);
    const [relationColumns, setRelationColumns] = useState({});
    const [relationFilters, setRelationFilters] = useState({});
    const [filterComponents, setFilterComponents] = useState({});
    const { schema, getColumnData } = useViewContext()!;
    const numberOfInputs = relationOptions.length < MAX_ALLOWED_INPUTS ? relationOptions.length : MAX_ALLOWED_INPUTS;

    useEffect(() => {
        const columns = {};

        relationOptions.forEach(({ name: rel }) => {
            const relationFields =
                schema && schema.relations && schema.relations.find(({ name }) => name === rel).fields;
            const lineColumns = getColumnData({ data: relationFields });
            columns[rel] = lineColumns;
        });

        setRelationColumns(columns);
    }, [getColumnData, relationOptions, schema, schema.relations]);

    return (
        <>
            <RelationButtonGroup active={active} setActive={setActive} numberOfInputs={numberOfInputs} />
            {[...Array(numberOfInputs)].map(
                (relation, index) =>
                    index === active && (
                        <RelationInputs
                            key={index}
                            index={index}
                            relationOptions={relationOptions}
                            columnOptions={relationColumns}
                            setRelationOptions={setRelationOptions}
                            relationFilters={relationFilters}
                            setRelationFilters={setRelationFilters}
                            filterComponents={filterComponents}
                            setFilterComponents={setFilterComponents}
                            {...rest}
                        />
                    )
            )}
        </>
    );
};

export default RelationGroupInputs;
