import * as React from 'react';
import {
    fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
    SortPayload,
    Exporter,
    FilterPayload,
    usePermissions,
    useResourceContext,
    Button,
    ButtonProps,
    downloadCSV,
} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';
import jsonExport from 'jsonexport/dist';
import { shouldRender } from '.';

interface Props {
    exporter?: Exporter;
    filterValues?: FilterPayload;
    icon?: JSX.Element;
    label?: string;
    maxResults?: number;
    onClick?: (e: Event) => void;
    resource?: string;
    sort?: SortPayload;
}

export type ExportButtonProps = Props & ButtonProps;

const defaultExporter = (data, _, __, resource) => jsonExport(data, (err, csv) => downloadCSV(csv, resource));

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
    filterValues,
    resource,
    ...rest
}: Omit<ExportButtonProps, 'sort' | 'maxResults' | 'label' | 'exporter'>) => rest;

const ExportSingleButton: React.FunctionComponent<ExportButtonProps> = props => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        resource: customResource,
        filterValues: customFilter,
        ...rest
    } = props;

    const { filterValues: filterFromContext, exporter: exporterFromContext, total } = useListContext(props);
    const resourceFromContext = useResourceContext(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { permissions, isLoading } = usePermissions();

    const filter = customFilter || filterFromContext;
    const resource = customResource || resourceFromContext;
    const exporter = customExporter || exporterFromContext || defaultExporter;

    const canExport = shouldRender(permissions, resource, 'can_export');

    const handleClick = React.useCallback(
        event => {
            dataProvider
                .getList(resource, {
                    sort: { field: 'id', order: 'DESC' },
                    filter,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) => exporter && exporter(data, fetchRelatedRecords(dataProvider), dataProvider, resource)
                )
                .catch(error => {
                    notify('ra.notification.http_error', { type: 'warning' });
                });
            if ('function' === typeof onClick) {
                onClick(event);
            }
        },
        [dataProvider, exporter, filter, maxResults, notify, onClick, resource]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={isLoading || 0 === total || !canExport}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

export default ExportSingleButton;
