import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './SpecimenFormHelpers';
import SpecimenSaveButton from './SpecimenSaveButton';

const transformSpecimen = rec => {
    const newRecord: any = {
        name: rec.name,
        gender: rec.gender,
        born_at: rec.born_at,
        died_at: rec.died_at,
        is_active: rec.is_active,
        mother_id: rec.mother_id,
        breeding_record_id: rec.breeding_record_id,
        father_id: rec.father_id,
        dna_number: rec.dna_number,
        tag_number: rec.tag_number,
        state_number: rec.state_number,
        electronic_number: rec.electronic_number,
        codon_37_id: rec.codon_37_id,
        codon_95_id: rec.codon_95_id,
        codon_96_id: rec.codon_96_id,
        codon_116_id: rec.codon_116_id,
        codon_226_id: rec.codon_226_id,
        '50k_gebv': rec['50k_gebv'],
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Specimen">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={
                <CustomToolbar
                    transform={transformSpecimen}
                    onSuccess={onSuccess}
                    customSaveButton={<SpecimenSaveButton />}
                />
            }
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Specimen">
        <TabbedForm
            toolbar={<CustomToolbar transform={transformSpecimen} customSaveButton={<SpecimenSaveButton />} />}
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Specimen" actions={<CustomActionsEditForm />}>
        <SimpleForm
            toolbar={<CustomToolbar transform={transformSpecimen} customSaveButton={<SpecimenSaveButton edit />} />}
            mode={formMode}
        >
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Specimen" actions={<CustomActionsEditForm />}>
        <TabbedForm
            toolbar={<CustomToolbar transform={transformSpecimen} customSaveButton={<SpecimenSaveButton edit />} />}
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const SpecimenEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const SpecimenCreateForm = () => <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />;
