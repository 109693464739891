import React from 'react';
import { SimpleShowLayout, ListProps } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import { AccessGrid, FieldsCell } from '../Users';
import { ListWithViewContext, ItemsListing } from '../../lib/customComponents/showComponents';

const CheckToggle = toggled => (toggled ? <CheckIcon color="primary" /> : '');

export const RoleUsers = props => (
    <ItemsListing
        columns={{
            id: 'ID',
            name: 'Name',
            email: 'Email',
            is_super_user: 'Super User',
        }}
        type="users"
        title="Active Users"
        formatToDrawerLink={[
            {
                name: 'name',
                resource: 'users',
                idField: 'id',
            },
        ]}
        formatToCustom={[
            {
                name: 'is_super_user',
                formatter: record => record && CheckToggle(record.is_super_user),
            },
        ]}
        {...props}
    />
);

export const RoleCapabilities = props => (
    <ItemsListing
        columns={{
            name: 'Name',
            description: 'Description',
            pivot: 'Access',
            id: 'Fields',
        }}
        type="capabilities"
        title="Capabilities"
        formatToCustom={[
            {
                name: 'pivot',
                formatter: record => (record && record.pivot ? AccessGrid(record.pivot) : null),
            },
            {
                name: 'id',
                formatter: record => (record && record.pivot ? FieldsCell(record.pivot.can_read_fields) : null),
            },
        ]}
        {...props}
    />
);

const RolesExpand = props => (
    <SimpleShowLayout {...props}>
        <RoleCapabilities {...props} />
        <RoleUsers {...props} />
    </SimpleShowLayout>
);

const rolesDefaultColumns = ['id', 'name', 'slug', 'description'];

export const RolesList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Roles"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOnFilters={['name', 'description']}
        defaultColumns={rolesDefaultColumns}
        expand={<RolesExpand />}
        primaryText={record => record && record.name}
        secondaryText={record => record && record.description}
        tertiaryText={record => record && record.slug}
        {...props}
    />
));
