import React from 'react';
import { AccountsShowFields } from '../../../../SupplyChain/Accounts/AccountsShowView';
import { BreedingRecordsShowFields } from '../../../../Operations/BreedingRecords/BreedingRecordsShow';
import { CodonsShowFields } from '../../../../Settings/Codons/CodonsShow';
import { ContactsShowFields } from '../../../../Settings/Contacts/ContactsShow';
import { FacilitiesShowFields } from '../../../../Settings/Facilities/FacilitiesShow';
import { ItemsShowFields } from '../../../../Settings/Items/ItemsShow';
import { ItemLocationsShowFields } from '../../../../SupplyChain/ItemLocations/ItemLocationsShowView';
import { ItemTypesShowFields } from '../../../../Settings/ItemTypes/ItemTypesShowView';
import { ItemSubtypesShowFields } from '../../../../Settings/ItemSubtypes/ItemSubtypesShowView';
import { MedicationRecordsShowFields } from '../../../../Operations/MedicationRecords/MedicationRecordsShow';
import { MedicationRoutesShowFields } from '../../../../Settings/MedicationRoutes/MedicationRoutesShow';
import { OrganizationsShowFields } from '../../../../Settings/Organizations/OrganizationsShow';
import { PaymentTermsShowFields } from '../../../../SupplyChain/PaymentTerms/PaymentTermsShowView';
import { PensShowFields } from '../../../../Settings/Pens/PensShow';
import { ReviewRuleShowFields } from '../../../../Admin/ReviewRules/ReviewRuleShowView';
import { RolesShowFields } from '../../../../Admin/Roles/RolesShow';
import { RequisitionsShowFields } from '../../../../SupplyChain/Requisitions/RequisitionsShowView';
import { RequisitionLinesShowFields } from '../../../../SupplyChain/Requisitions/RequisitionLinesShowView';
import { SpecimensShowFields } from '../../../../Operations/Specimen/SpecimenShow';
import { UnitsOfMeasureShowFields } from '../../../../Settings/UnitsOfMeasure/UnitsOfMeasureShow';
import { UserShowFields } from '../../../../Admin/Users/UserShow';

const sanitizedDefaultProps = ({ hasEdit, passProps, passClasses, ...rest }: any) => rest;

const RecordShowFields = ({ resource, record: recordFromProps, ...props }) => {
    const defaultProps = {
        ...sanitizedDefaultProps(props),
        resource,
        record: recordFromProps,
        isDrawer: true,
        id: recordFromProps?.id,
    };

    switch (resource) {
        case 'accounts':
            return <AccountsShowFields {...defaultProps} />;
        case 'breeding-records':
            return <BreedingRecordsShowFields {...defaultProps} />;
        case 'codons':
            return <CodonsShowFields {...defaultProps} />;
        case 'contacts':
            return <ContactsShowFields {...defaultProps} />;
        case 'facilities':
            return <FacilitiesShowFields {...defaultProps} />;
        case 'items':
        case 'medications':
            return <ItemsShowFields {...defaultProps} />;
        case 'medication-routes':
            return <MedicationRoutesShowFields {...defaultProps} />;
        case 'item-locations':
            return <ItemLocationsShowFields {...defaultProps} />;
        case 'item-types':
            return <ItemTypesShowFields {...defaultProps} />;
        case 'item-subtypes':
            return <ItemSubtypesShowFields {...defaultProps} />;
        case 'medication-records':
            return <MedicationRecordsShowFields {...defaultProps} />;
        case 'pens':
            return <PensShowFields {...defaultProps} />;
        case 'customers':
        case 'organizations':
        case 'vendors':
            return <OrganizationsShowFields {...defaultProps} />;
        case 'payment-terms':
            return <PaymentTermsShowFields {...defaultProps} />;
        case 'purchase-requisitions':
            return <RequisitionsShowFields {...defaultProps} />;
        case 'purchase-requisition-lines':
            return <RequisitionLinesShowFields {...defaultProps} />;
        case 'review-rules':
            return <ReviewRuleShowFields {...defaultProps} />;
        case 'roles':
            return <RolesShowFields {...defaultProps} />;
        case 'specimens':
            return <SpecimensShowFields {...defaultProps} />;
        case 'units-of-measure':
            return <UnitsOfMeasureShowFields {...defaultProps} />;
        case 'users':
            return <UserShowFields {...defaultProps} />;
        default:
            return null;
    }
};

export default RecordShowFields;
