import React from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { getUserSettings } from '../../helpers';
import AttachmentsInput from './AttachmentsInput';
import CustomToolbar from './CustomToolbar';
import CustomDraggableList from './CustomDraggableList';
import DateTimeInput from './CustomDateTimeInput';
import DialogForm from './DialogForm';
import FormattedNumberInput from './FormattedNumberInput';
import LinesFormAccordionWrapper from './LinesFormAccordionWrapper';
import QueryWrapper from './QueryWrapper';
import SimpleFormWrapper from './SimpleFormWrapper';
import TransferInput from './TransferInput';

const ResponsiveForm = ({ simpleForm, tabbedForm }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
    const tabbedViewSelected = 'tabbed' === getUserSettings('form_format', 'simple');

    if (!simpleForm || isSmall || tabbedViewSelected) {
        return React.cloneElement(tabbedForm);
    }

    return React.cloneElement(simpleForm);
};

const formMode = 'all';

export {
    AttachmentsInput,
    CustomToolbar,
    CustomDraggableList,
    DateTimeInput,
    DialogForm,
    FormattedNumberInput,
    formMode,
    LinesFormAccordionWrapper,
    ResponsiveForm,
    QueryWrapper,
    SimpleFormWrapper,
    TransferInput,
};
