import React, { useCallback, useEffect, useMemo, useState } from 'react';
import lodash from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Create,
    required,
    SimpleForm,
    useRedirect,
    usePermissions,
    useResourceContext,
    useListContext,
} from 'react-admin';
import { DialogForm, CustomToolbar, TransferInput } from '../../formComponents';
import { Notification } from '../index';
import { useViewContext, TColumn } from '../../../contexts/ViewContext';
import { resourceMapping } from '../../../../../dataProvider';
import ViewFiltersButton from '../ViewFiltersButton';
import RelationGroupInputs from './RelationGroupInputs';
import RecurringReportInputs from './RecurringReportInputs';
import getRequestPayload from './getRequestPayload';
import { transform as transformRecurringInputs } from '../../../../Reports/CreateReportButton';
import { shouldRender } from '../../../helpers';

const sanitizedFormValues = ({ columns, relation, relation_columns: relationColumns, ...vals }: any) => vals;

const transform = ({ listFilters, resource, is_recurring: isRecurring, ...vals }) => {
    const settings = { renderer: 'csv', config: { resource } };
    const requestPayload = getRequestPayload({ listFilters, ...vals });

    if (isRecurring) {
        const recurringValues = transformRecurringInputs({
            resource,
            settings,
            filters: listFilters,
            ...sanitizedFormValues(vals),
        });

        const recurringPayload = {
            ...recurringValues,
            request_payload: lodash.merge(recurringValues.request_payload, requestPayload),
        };

        return recurringPayload;
    }

    const newRecord = {
        request_payload: requestPayload,
        settings: { renderer: 'csv', config: { resource } },
    };

    return newRecord;
};

const FormInputs = ({ schemaRelations, listFilters, ...rest }) => {
    const [relationOptions, setRelationOptions] = useState<any[]>([]);

    const { columns } = useViewContext()!;

    const activeColumns = useMemo(
        () =>
            columns
                ? columns.filter(col => true === col.active && !col.source.includes('.')).map(col => col.source)
                : [],
        [columns]
    );

    const filteredColumns: TColumn[] = useMemo(
        () => (columns ? columns.filter(col => !col.source.includes('.') && col.enabled) : []),
        [columns]
    );

    const activeJoinedColumns: TColumn[] = useMemo(
        () => (columns ? columns.filter(col => col.source.includes('.') && true === col.active) : []),
        [columns]
    );

    const joinedColsByRel: any[] = [];

    if (activeJoinedColumns) {
        const joinedColumnSources: string[] = activeJoinedColumns.map(col => col.source);
        const joinedRels: string[] = joinedColumnSources.reduce(
            (rels: string[], src: string) =>
                src.includes('.') && !rels.includes(src.split('.')[0]) ? [...rels, src.split('.')[0]] : rels,
            []
        );

        joinedRels.forEach(rel => {
            const joinedRelObj = {};
            joinedRelObj[rel] = activeJoinedColumns
                .filter(col => col.source.includes(rel))
                .map(col => col.source.split('.')[1]);
            joinedColsByRel.push(joinedRelObj);
        });
    }

    const processedRelationFilters = useMemo(() => {
        const relObj = {};
        if (listFilters && Object.keys(listFilters).length) {
            Object.entries(listFilters).forEach(([field, filterVal]: any) => {
                if (field.includes('-RELATION-')) {
                    const [relation, relField] = field.split('-RELATION-');
                    relObj[relation] = { ...relObj[relation], [relField]: filterVal };
                }
            });
        }
        return relObj;
    }, [listFilters]);

    useEffect(() => {
        if (schemaRelations) {
            setRelationOptions(schemaRelations);
        }
    }, [schemaRelations]);

    return (
        <>
            <TransferInput
                source="columns"
                choices={filteredColumns}
                validate={required()}
                optionValue="source"
                optionText="label"
                fullWidth
                defaultValue={activeColumns}
                reorderableRightInput
                {...rest}
            />
            {0 < relationOptions.length && (
                <RelationGroupInputs
                    relationOptions={relationOptions}
                    setRelationOptions={setRelationOptions}
                    joinedColsByRel={joinedColsByRel}
                    processedRelationFilters={processedRelationFilters}
                    {...rest}
                />
            )}
        </>
    );
};

const ExportSelectedButton = ({ permanentFilter, resourceOverride: resourceOverrideFromProps, ...props }) => {
    const { permissions } = usePermissions();
    const { schema } = useViewContext()!;
    const resource = useResourceContext(props);
    const { filterValues } = useListContext(props);

    const mappedResource = resourceOverrideFromProps || resourceMapping[resource] || resource;

    const [notificationMessage, setNotificationMessage] = useState(false);
    const [resourceOverride, setResourceOverride] = useState(`${mappedResource}/export-to-csv`);
    const [reportsClassification, setReportsClassification] = useState('nonrecurring');

    const schemaIsLoading = 0 === Object.keys(schema).length;

    const redirect = useRedirect();

    const canExport = React.useMemo(() => shouldRender(permissions, resource, 'can_export'), [permissions, resource]);

    const listFilters = useMemo(() => ({ ...filterValues, ...permanentFilter }), [filterValues, permanentFilter]);

    const schemaDateFields = useMemo(
        () => schema.fields && schema.fields.filter(field => 'datetime' === field.type || 'timestamp' === field.type),
        [schema]
    );

    const schemaRelations = useMemo(
        () => schema.relations && schema.relations.map(({ name }, index) => ({ id: index, name, disabled: false })),
        [schema.relations]
    );

    const onSuccess = useCallback(
        ({ data, closeDialog }) => {
            setResourceOverride(`${mappedResource}/export-to-csv`);
            closeDialog();
            setNotificationMessage(true);
        },
        [mappedResource]
    );

    const redirectToExports = useCallback(() => {
        const url = `/reports?&filter={"classification":"${reportsClassification}"}`;
        redirect(encodeURI(url));
    }, [redirect, reportsClassification]);

    return (
        <>
            <Notification
                handleUndo={redirectToExports}
                undoable
                undoButtonText="View Your Reports"
                message="Report Pending."
                notification={notificationMessage}
            />
            <DialogForm
                title="Select Columns to Export"
                label="Export"
                maxWidth="md"
                content={<ViewFiltersButton filters={listFilters} />}
                icon={<DownloadIcon />}
                disabled={schemaIsLoading || !canExport}
                passCloseDialogToForm={false}
            >
                <Create sx={{ width: '100%' }}>
                    <SimpleForm
                        toolbar={
                            <CustomToolbar
                                resourceOverride={resourceOverride}
                                transform={vals => transform({ listFilters, resource, ...vals })}
                                onSuccess={onSuccess}
                                enableNotification={false}
                                noInitialRequirement
                            />
                        }
                        mode="all"
                    >
                        <RecurringReportInputs
                            schemaDateFields={schemaDateFields}
                            setResourceOverride={setResourceOverride}
                            setReportsClassification={setReportsClassification}
                            resource={resource}
                        />
                        <FormInputs schemaRelations={schemaRelations} listFilters={listFilters} />
                    </SimpleForm>
                </Create>
            </DialogForm>
        </>
    );
};

export default ExportSelectedButton;
