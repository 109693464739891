import React from 'react';
import { Button, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Save as SaveIcon } from '@mui/icons-material';
import { CustomConfirm as Confirm } from '../../../lib/customComponents';
import { requestGetByID } from '../../../../dataProvider/RestClient';

interface IWarnItemLocationSaveButton {
    edit?: boolean;
    disabled?: boolean;
    handleClick?: (ev: any, data?: { [x: string]: any }) => void;
}

const WarnItemLocationSaveButton: React.FC<IWarnItemLocationSaveButton> = ({
    edit = false,
    disabled,
    handleClick,
    ...props
}) => {
    const [noteWindow, setNoteWindow] = React.useState<boolean>(false);
    const [defaultItemLocation, setDefaultItemLocation] = React.useState<{ id: number | null; name: string | null }>({
        id: null,
        name: null,
    });

    const { getValues } = useFormContext();
    const notify = useNotify();

    const setNoteAndSave = React.useCallback(
        ev => {
            setNoteWindow(false);
            handleClick(ev, { item_location_id: defaultItemLocation.id });
        },
        [handleClick, defaultItemLocation.id]
    );

    const getDefaultItemLocation = React.useCallback(async () => {
        const defLoc = await requestGetByID('item-locations', 'default')
            .then(({ data }) => data)
            .catch(() => notify('Problem getting default item location!', { type: 'warning' }));
        if (defLoc) {
            const { name, id } = defLoc;
            setDefaultItemLocation({ id, name });
            setNoteWindow(true);
        }
    }, [notify]);

    const handleSave = React.useCallback(
        ev => {
            if (disabled && handleClick && 'function' === typeof handleClick) {
                return handleClick(ev);
            }

            const itemLocationID = getValues('item_location_id');

            if (itemLocationID) {
                return handleClick(ev);
            }

            getDefaultItemLocation();
        },
        [handleClick, getValues, disabled, getDefaultItemLocation]
    );

    return (
        <>
            <Confirm
                isOpen={noteWindow}
                title="Item Location"
                content={`No item location is entered on the form; Inventory will be updated at ${defaultItemLocation.name}. Is that okay?`}
                confirmText="Yes"
                cancelText="No"
                onConfirm={setNoteAndSave}
                onClose={() => {
                    setNoteWindow(false);
                }}
            />
            <Button label="Save" variant="contained" size="medium" disabled={disabled} onMouseUp={handleSave}>
                <SaveIcon />
            </Button>
        </>
    );
};

export default WarnItemLocationSaveButton;
