import React from 'react';
import { useRecordContext, useRedirect, usePermissions, useNotify } from 'react-admin';
import { MenuItem } from '@mui/material';
import { OptionsMenu } from '../../lib/customComponents/showComponents';
import { shouldRender } from '../../lib/helpers';

const SpecimensActionsMenu = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const editAllowed = React.useMemo(() => shouldRender(permissions, 'specimens', 'can_update'), [permissions]);
    const [breedAllowed, medicateAllowed, transferAllowed] = React.useMemo(
        () => [
            shouldRender(permissions, 'breeding-records', 'can_create'),
            shouldRender(permissions, 'medication-records', 'can_create'),
            shouldRender(permissions, 'specimen-transfers', 'can_create'),
        ],
        [permissions]
    );

    const handleClick = React.useCallback(
        (type: string) => {
            if ('show' === type) {
                return redirect('show', '/specimens', record.id);
            }
            if ('breed' === type) {
                notify('Specimen added to Breeding Record form', { type: 'success' });
                let idField = 'specimen_id';
                if (record?.gender && 'Male' === record.gender) {
                    idField = 'male_specimen_id';
                }
                return redirect('create', `/breeding-records`, undefined, {}, { record: { [idField]: record.id } });
            }
            if ('medicate' === type) {
                notify('Specimen added to Medication Record form', { type: 'success' });
                return redirect('create', `/medication-records`, undefined, {}, { record: { specimen_id: record.id } });
            }
            if ('transfer' === type) {
                notify('Specimen added to Transfers form', { type: 'success' });
                return redirect('create', `/specimen-transfers`, undefined, {}, { record: { specimen_id: record.id } });
            }

            return redirect('edit', '/specimens', record.id);
        },
        [record, notify, redirect]
    );

    return (
        <OptionsMenu>
            <MenuItem onClick={() => handleClick('show')}>Show</MenuItem>
            {editAllowed && <MenuItem onClick={() => handleClick('edit')}>Edit</MenuItem>}
            {breedAllowed && <MenuItem onClick={() => handleClick('breed')}>Breed</MenuItem>}
            {medicateAllowed && <MenuItem onClick={() => handleClick('medicate')}>Medicate</MenuItem>}
            {transferAllowed && <MenuItem onClick={() => handleClick('transfer')}>Transfer</MenuItem>}
        </OptionsMenu>
    );
};

export default SpecimensActionsMenu;
