export const frequencyDayChoices = [
    { id: 1, name: 'MO', label: 'Monday' },
    { id: 2, name: 'TU', label: 'Tuesday' },
    { id: 3, name: 'WE', label: 'Wednesday' },
    { id: 4, name: 'TH', label: 'Thursday' },
    { id: 5, name: 'FR', label: 'Friday' },
    { id: 6, name: 'SA', label: 'Saturday' },
    { id: 7, name: 'SU', label: 'Sunday' },
];

export const intervalChoices = [
    { id: 1, name: 'last week' },
    { id: 2, name: 'last month' },
    { id: 3, name: 'last quarter' },
    { id: 4, name: 'this week' },
    { id: 5, name: 'this month' },
    { id: 6, name: 'this quarter' },
    { id: 7, name: 'last 1 day' },
    { id: 9, name: 'last 7 days' },
    { id: 10, name: 'last 30 days' },
    { id: 11, name: 'last 60 days' },
    { id: 12, name: 'last 90 days' },
    { id: 13, name: 'last 180 days' },
    { id: 14, name: 'last 365 days' },
    { id: 15, name: 'all history' },
];

export const frequencyChoices = [
    { id: 1, name: 'Daily' },
    { id: 2, name: 'Weekly' },
    { id: 3, name: 'Monthly' },
];

export const frequencyMonthDayChoices = [
    { id: 1, name: 1, label: '1st' },
    { id: 2, name: 2, label: '2nd' },
    { id: 3, name: 3, label: '3rd' },
    { id: 4, name: 4, label: '4th' },
    { id: 5, name: 5, label: '5th' },
    { id: 6, name: 6, label: '6th' },
    { id: 7, name: 7, label: '7th' },
    { id: 8, name: 8, label: '8th' },
    { id: 9, name: 9, label: '9th' },
    { id: 10, name: 10, label: '10th' },
    { id: 11, name: 11, label: '11th' },
    { id: 12, name: 12, label: '12th' },
    { id: 13, name: 13, label: '13th' },
    { id: 14, name: 14, label: '14th' },
    { id: 15, name: 15, label: '15th' },
    { id: 16, name: 16, label: '16th' },
    { id: 17, name: 17, label: '17th' },
    { id: 18, name: 18, label: '18th' },
    { id: 19, name: 19, label: '19th' },
    { id: 20, name: 20, label: '20th' },
    { id: 21, name: 21, label: '21th' },
    { id: 22, name: 22, label: '22th' },
    { id: 23, name: 23, label: '23th' },
    { id: 24, name: 24, label: '24th' },
    { id: 25, name: 25, label: '25th' },
    { id: 26, name: 26, label: '26th' },
    { id: 27, name: 27, label: '27th' },
    { id: 28, name: 28, label: '28th' },
    { id: 29, name: 29, label: '29th' },
    { id: 30, name: 30, label: '30th' },
    { id: 31, name: 31, label: '31th' },
];
