import React from 'react';
import { useNotify } from 'react-admin';
import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { requestDownloadAttachment, requestDownloadArrayBufferImage } from '../../../../../dataProvider/RestClient';
import { saveFile } from './helpers';

const StyledImage = styled('img')(() => ({
    maxHeight: '15rem',
    margin: '0.5rem',
    '&:hover': {
        cursor: 'pointer',
    },
}));

const ImagePreview = ({ image, setIsDownloading, setIsLoadingQRForPrint, ...props }) => {
    const { id: imageID, name: imageName, extension } = image;
    const [imageBlobURL, setImageBlobURL] = React.useState('');
    const [svgSource, setSVGSource] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [svgIsLoading, setSvgIsLoading] = React.useState(false);
    const [erroredImage, setErroredImage] = React.useState(false);

    const notify = useNotify();

    const handleDownloadClick = React.useCallback(() => {
        if (imageBlobURL && imageName) {
            saveFile(imageBlobURL, imageName);
            notify('Download successful!');
        }
    }, [imageBlobURL, imageName, notify]);

    const getImagePreview = React.useCallback(() => {
        setIsLoading(true);
        requestDownloadAttachment(imageID)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                setImageBlobURL(url);
                setIsLoading(false);
            })
            .catch(err => {
                setErroredImage(true);
                setIsLoading(false);
            });
    }, [imageID]);

    const getSVGPreview = React.useCallback(() => {
        setSvgIsLoading(true);
        requestDownloadArrayBufferImage(imageID)
            .then(svg => {
                const base64data = btoa(unescape(encodeURIComponent(svg)));
                setSVGSource(base64data);
                setSvgIsLoading(false);
            })
            .catch(err => {
                setErroredImage(true);
                setSvgIsLoading(false);
            });
    }, [imageID]);

    React.useEffect(() => {
        if (imageID && !imageBlobURL) {
            if (('svg' === extension || 'png' === extension) && !svgSource) {
                getSVGPreview();
                getImagePreview();
            } else {
                getImagePreview();
                setSVGSource('');
            }
        }
    }, [imageID, extension, svgSource, imageBlobURL, getSVGPreview, getImagePreview]);

    React.useEffect(() => {
        if ('qr-code.svg' === imageName && svgSource && imageBlobURL && !isLoading && !svgIsLoading) {
            if (setIsLoadingQRForPrint && 'function' === typeof setIsLoadingQRForPrint) {
                setIsLoadingQRForPrint(false);
            }
            // Don't break local/test if attachment doesn't exist
        } else if (
            'qr-code.svg' === imageName &&
            !isLoading &&
            !svgIsLoading &&
            'production' !== process.env.NODE_ENV
        ) {
            if (setIsLoadingQRForPrint && 'function' === typeof setIsLoadingQRForPrint) {
                setIsLoadingQRForPrint(false);
            }
        }
    }, [imageBlobURL, imageName, isLoading, setIsLoadingQRForPrint, svgIsLoading, svgSource]);

    if (erroredImage) {
        return (
            <Typography variant="h6" color="error">
                {imageName ? `Error: ${imageName} not found` : 'Error: Image not found'}
            </Typography>
        );
    }

    if (svgSource) {
        return (
            <li id={svgSource} role="presentation" onClick={handleDownloadClick} onKeyDown={handleDownloadClick}>
                <StyledImage title={imageName} alt={imageName} src={`data:image/svg+xml;base64,${svgSource}`} />
            </li>
        );
    }

    if (imageBlobURL && !svgSource) {
        return (
            <li id={imageBlobURL} role="presentation" onClick={handleDownloadClick} onKeyDown={handleDownloadClick}>
                <StyledImage src={imageBlobURL} title={imageName} alt={imageName} />
            </li>
        );
    }

    if (isLoading || svgIsLoading) {
        return <CircularProgress size={100} />;
    }

    return null;
};

export default ImagePreview;
