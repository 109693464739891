import React from 'react';
import { BooleanInput, TextInput, required } from 'react-admin';
import { ColumnShowView, SingleColumn } from '../../../lib/styles/StyledComponents';
import { OrganizationInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const OverviewInputs = ({ edit = false, ...props }) => (
    <>
        <ColumnShowView>
            <TextInput source="name" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <OrganizationInput validate={required()} />
        </ColumnShowView>
        <SingleColumn>
            <BooleanInput source="is_active" label="Active" defaultValue />
        </SingleColumn>
    </>
);

export default OverviewInputs;
