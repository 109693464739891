import lodash from 'lodash';
import moment, { Moment } from 'moment';

const assetPositionSorting = (a, b) => {
    if (a === b) {
        return 0;
    }
    if (null === a) {
        return 1;
    }
    if (null === b) {
        return -1;
    }
    return a - b;
};

const inputValueMatcher = (input, suggestion, getOptionText) => {
    const optionText = getOptionText(suggestion);

    if (optionText) {
        return input.toLowerCase().trim() === optionText;
    }
};

const calcLineItemAmount = (quantity, cost) => {
    const numQuantity = !lodash.isNaN(parseFloat(quantity)) ? parseFloat(quantity) : 0;
    const numCost = !lodash.isNaN(parseFloat(cost)) ? parseFloat(cost) : 0;

    return Number((numQuantity * numCost).toFixed(2));
};

const calcLineItemCost = (quantity, amount) => {
    const calcQuantity = !lodash.isNaN(parseFloat(quantity)) ? parseFloat(quantity) : 0;
    const calcAmount = !lodash.isNaN(parseFloat(amount)) ? parseFloat(amount) : 0;

    if (0 === calcQuantity) {
        return 0;
    }

    return Number((calcAmount / calcQuantity).toFixed(2));
};

const formatDate = (date: Moment, formatterParams?: string) => {
    if (!date) return '';

    switch (formatterParams) {
        case 'date-time-seconds':
            return moment(date).local().format('M/D/YYYY, h:mm:ss A');
        case 'date-time':
            return moment(date).local().format('MM/DD/YYYY, hh:mm A');
        case 'short-date-time':
            return moment(date).local().format('M/D, h:mm A');
        case 'year':
            return moment(date).local().format('YYYY');
        case 'month':
            return moment(date).local().format('MMM  YYYY');
        case 'quarter':
            return `Q${moment(date).local().format('Q YYYY')}`;
        case 'time':
            return moment(date).local().format('hh:mm A');
        case 'date-month':
            return moment(date).local().format('M/D');
        default:
            return moment(date).local().format('MM/DD/YYYY');
    }
};

const getSortedData = (data, [firstSort, secondSort, thirdSort]) =>
    data.sort((a, b) => {
        if (a[firstSort] === b[firstSort]) {
            if (thirdSort && a[secondSort] === b[secondSort]) {
                if (!a[thirdSort]) {
                    return 1;
                }
                return a[thirdSort].localeCompare(b[thirdSort], 'en', { numeric: true });
            }
            if (!a[secondSort]) {
                return 1;
            }
            return a[secondSort].localeCompare(b[secondSort], 'en', { numeric: true });
        }
        if (!a[firstSort]) {
            return 1;
        }
        return a[firstSort].localeCompare(b[firstSort], 'en', { numeric: true });
    });

const groupStatusData = ({ data, groupByField, defaultField, groups }) => {
    const groupedData = lodash.groupBy(data, rec =>
        groups.includes(rec[groupByField]) ? rec[groupByField] : defaultField
    );

    [...groups, defaultField].forEach(field => {
        if (!groupedData[field]) {
            groupedData[field] = [];
        }
    });

    return groupedData;
};

const formatUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
});

const shouldRender = (permissions, capability, action = 'can_read') => {
    if (!permissions) {
        return false;
    }
    if (Array.isArray(capability)) {
        const filteredArr = capability.filter(cap => permissions[cap] && true === permissions[cap][action]);

        return 0 !== filteredArr.length;
    }
    if (!permissions[capability]) {
        return false;
    }

    return permissions[capability][action];
};

const difference = (object, base, keys = false) => {
    const changes = (obj, changeBase) =>
        lodash.transform(obj, (result, value, key) => {
            if (!lodash.isEqual(value, changeBase[key])) {
                result[key] =
                    lodash.isObject(value) && lodash.isObject(changeBase[key])
                        ? changes(value, changeBase[key])
                        : value;
            }
        });

    const diff = changes(object, base);

    return keys ? lodash.keys(diff) : diff;
};

const getUserSettings = (field, defaultValue) => {
    const settings = JSON.parse(localStorage.getItem('userSettings') || '{}');

    if (settings && field) {
        return 'undefined' !== typeof settings[field] ? settings[field] : defaultValue;
    }
    if (!settings && field) {
        return defaultValue;
    }
    if (settings) {
        return settings;
    }

    return {};
};

const getDefaultDate: (days?: any) => string = days => {
    const date = new Date();

    if (days) {
        date.setDate(date.getDate() + days);

        return moment().add(days, 'day').startOf('day').format();
    }

    return moment().endOf('day').format();
};

const sanitizeRestProps = ({
    ids,
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    filterValues,
    syncWithLocation,
    ...rest
}: any) => rest;

const sanitizeTabbedShowProps = ({
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    printView,
    setIsLoadingQRForPrint,
    userAllowed,
    ...rest
}: any) => rest;

const phoneNumberFormat = num => {
    if (!num) {
        return null;
    }
    const newNum = num.replace(/%|[-]+/g, '');
    let x;
    if (3 >= newNum.length) {
        x = newNum;
    } else if (3 < newNum.length && 6 >= newNum.length) {
        x = `(${newNum.slice(0, 3)})-${newNum.slice(3, 6)}`;
    } else {
        x = `(${newNum.slice(0, 3)})-${newNum.slice(3, 6)}-${newNum.slice(6, 10)}`;
    }
    return x;
};

export {
    assetPositionSorting,
    calcLineItemAmount,
    calcLineItemCost,
    difference,
    formatDate,
    getSortedData,
    groupStatusData,
    formatUSD,
    getUserSettings,
    inputValueMatcher,
    shouldRender,
    getDefaultDate,
    sanitizeRestProps,
    sanitizeTabbedShowProps,
    phoneNumberFormat,
};
