import React from 'react';
import { useWatch } from 'react-hook-form';
import { requestAddAttachments, requestDeleteAttachments } from '../../../../../dataProvider/RestClient';

const useAttachments = resource => {
    const attachments = useWatch({ name: 'attachments' });
    const deletedAttachments = useWatch({ name: 'deleted_attachments' });

    const updateFiles = React.useCallback(
        async res => {
            if (attachments && attachments.length) {
                const entityID = res.id || res.data.id;
                const newFiles = attachments.filter(p => p.rawFile instanceof File);

                if (newFiles && 0 !== newFiles.length) {
                    await requestAddAttachments(resource, entityID, newFiles);
                }
            }

            if (deletedAttachments && deletedAttachments.length) {
                const entityID = res.id || res.data.id;
                const deletedFiles = deletedAttachments.filter(p => !(p.rawFile instanceof File));

                if (deletedFiles && 0 !== deletedFiles.length) {
                    const payload: string[] = [];

                    deletedFiles.map(file => payload.push(file.path));

                    await requestDeleteAttachments(resource, entityID, payload);
                }
            }
        },
        [attachments, deletedAttachments, resource]
    );

    return {
        updateFiles,
    };
};

export default useAttachments;
