import React from 'react';
import { Button, TextInput, RadioButtonGroupInput, useListContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { DialogContent, Divider, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import {
    Storage as StorageIcon,
    Save as SaveIcon,
    Delete as DeleteIcon,
    Star as StarIcon,
    StarOutlined as StarOutlineIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import { SingleColumn } from '../../../styles/StyledComponents';
import { useViewContext, defaultViewName } from '../../../contexts/ViewContext';
import ViewFiltersButton from '../ViewFiltersButton';

const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const ButtonRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

const hiddenStyle = { display: 'none' };

type TSaveAndLoadTabs = {
    tabs: {
        name: string;
        value: string;
    }[];
    visibleTab: string;
    setVisibleTab: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit: (name: any) => void;
    handleFavorite: (name: any, unFavorite?: boolean) => void;
    handleLoad: (name: any, value: any) => void;
    handleDelete: (name: any) => void;
    closeDialog?: (refreshView?: any) => void;
};

const ViewChoice = ({ choice, isFav = false }) => (
    <div>
        {choice.name} {isFav ? <StarIcon style={{ color: yellow[500] }} /> : null}
    </div>
);

const getViewChoice = (choice, isFav) => <ViewChoice choice={choice} isFav={isFav} />;

const SaveAndLoadTabs: React.FC<TSaveAndLoadTabs> = ({
    tabs,
    visibleTab,
    setVisibleTab,
    handleSubmit,
    handleFavorite,
    handleLoad,
    handleDelete,
    closeDialog,
}) => {
    const [listDisplay, setListDisplay] = React.useState({ columns: [], sort: [] });

    const { columns, preferences, currentViewName, favoriteViewName } = useViewContext()!;
    const { filterValues, sort } = useListContext();

    const { setValue } = useFormContext();
    const listViewName = useWatch({ name: 'list_view_name' });
    const savedListView = useWatch({ name: 'saved_list_view', defaultValue: currentViewName });

    const disableFavoriteLoadAndDelete = !savedListView || (savedListView && savedListView === defaultViewName);

    const handleFormSubmit = React.useCallback(() => {
        if (defaultViewName === listViewName) {
            return;
        }
        handleSubmit(listViewName);
        setValue('list_view_name', null);
        setValue('saved_list_view', listViewName);
    }, [handleSubmit, setValue, listViewName]);

    const handleOverWriteCurrentSubmit = React.useCallback(() => {
        handleSubmit(currentViewName);
        setValue('list_view_name', null);
    }, [handleSubmit, setValue, currentViewName]);

    const handleLoadSubmit = React.useCallback(() => {
        const viewValues = preferences && preferences[savedListView];
        handleLoad(savedListView, viewValues);
        if (closeDialog && 'function' === typeof closeDialog) {
            closeDialog();
        }
    }, [closeDialog, handleLoad, preferences, savedListView]);

    const handleFavoriteSubmit = React.useCallback(() => {
        handleFavorite(savedListView);
    }, [handleFavorite, savedListView]);

    const handleUnfavoriteSubmit = React.useCallback(() => {
        handleFavorite(savedListView, true);
    }, [handleFavorite, savedListView]);

    const handleDeleteSubmit = React.useCallback(
        name => {
            setValue('saved_list_view', '');
            handleDelete(name);
        },
        [handleDelete, setValue]
    );

    React.useEffect(() => {
        const listViewObj: any = { columns: [], sort: [] };
        if (columns) {
            if ('save' === visibleTab) {
                const filteredColumnSources = columns.reduce(
                    (newArr: string[], col) => (col.active ? [...newArr, col.source] : newArr),
                    []
                );
                listViewObj.columns = filteredColumnSources;

                if (filterValues) {
                    Object.entries(filterValues).forEach(([fil, val]: [string, any]) => {
                        listViewObj[fil] = val;
                    });
                }

                if (sort) {
                    const { field, order } = sort;
                    listViewObj.sort = [field, order];
                }
            } else if ('load' === visibleTab && savedListView && preferences && preferences[savedListView]) {
                const currentSavedView = preferences && preferences[savedListView];
                listViewObj.columns = currentSavedView.columns;

                if (currentSavedView && currentSavedView.sort) {
                    const { field, order } = currentSavedView.sort;
                    listViewObj.sort = [field, order];
                }

                if (currentSavedView) {
                    Object.entries(currentSavedView.filters).forEach(([fil, val]: [string, any]) => {
                        listViewObj[fil] = val;
                    });
                }
            }
        }
        setListDisplay(listViewObj);
    }, [columns, filterValues, sort, preferences, savedListView, visibleTab]);

    const handleTabChange = React.useCallback(
        (ev, val) => {
            if (currentViewName && 'load' === val) {
                setValue('saved_list_view', currentViewName);
            }
            setVisibleTab(val);
        },
        [setVisibleTab, currentViewName, setValue]
    );

    return (
        <SingleColumn>
            {currentViewName && (
                <StyledDialogContent>
                    <Typography variant="h6" gutterBottom align="center">
                        Current View: {currentViewName}
                    </Typography>
                </StyledDialogContent>
            )}
            <Tabs variant="fullWidth" centered value={visibleTab} indicatorColor="primary" onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab key={tab.name} label={tab.name} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            <StyledDialogContent sx={'save' !== visibleTab ? hiddenStyle : undefined}>
                <ViewFiltersButton
                    viewLabel="Show Current Settings"
                    hideLabel="Hide Current Settings"
                    filters={listDisplay}
                />
                <Button
                    label={defaultViewName === currentViewName ? `OverWrite View` : `Overwrite ${currentViewName}`}
                    color="secondary"
                    onClick={handleOverWriteCurrentSubmit}
                    disabled={!currentViewName || defaultViewName === currentViewName}
                >
                    <EditIcon />
                </Button>
                <TextInput
                    source="list_view_name"
                    label="List View Name"
                    validate={value =>
                        defaultViewName === value ? 'Cannot save a list view named defaultViewName' : undefined
                    }
                />
                <div />
                <Button
                    label="Save New View"
                    onClick={handleFormSubmit}
                    disabled={!listViewName || defaultViewName === listViewName}
                >
                    <SaveIcon />
                </Button>
            </StyledDialogContent>
            <StyledDialogContent sx={'load' !== visibleTab ? hiddenStyle : undefined}>
                <ViewFiltersButton
                    viewLabel="Show Saved View"
                    hideLabel="Hide Saved View"
                    filters={listDisplay}
                    disabled={!savedListView || defaultViewName === savedListView}
                />
                {preferences && Object.keys(preferences).length ? (
                    <RadioButtonGroupInput
                        source="saved_list_view"
                        label="Saved List Views"
                        choices={
                            (preferences &&
                                Object.keys(preferences).map((viewName: any, index: number) => ({
                                    id: index + 1,
                                    name: viewName,
                                }))) ||
                            []
                        }
                        optionValue="name"
                        optionText={choice =>
                            getViewChoice(choice, favoriteViewName && choice.name === favoriteViewName)
                        }
                        defaultValue={currentViewName}
                        row={false}
                    />
                ) : (
                    <Typography variant="h6" gutterBottom align="center">
                        No Saved Views Found
                    </Typography>
                )}
                <div />
                <ButtonRow>
                    <Button
                        label="Delete View"
                        color="secondary"
                        onClick={() => handleDeleteSubmit(savedListView)}
                        disabled={disableFavoriteLoadAndDelete}
                    >
                        <DeleteIcon />
                    </Button>
                    {savedListView === favoriteViewName ? (
                        <Button
                            label="Unfavorite View"
                            color="secondary"
                            onClick={handleUnfavoriteSubmit}
                            disabled={disableFavoriteLoadAndDelete}
                        >
                            <StarOutlineIcon />
                        </Button>
                    ) : (
                        <Button
                            label="Favorite View"
                            onClick={handleFavoriteSubmit}
                            disabled={disableFavoriteLoadAndDelete}
                        >
                            <StarIcon />
                        </Button>
                    )}
                    <Button label="Load View" onClick={handleLoadSubmit} disabled={disableFavoriteLoadAndDelete}>
                        <StorageIcon />
                    </Button>
                </ButtonRow>
            </StyledDialogContent>
        </SingleColumn>
    );
};

export default SaveAndLoadTabs;
