import React from 'react';
import {
    BooleanField,
    Button,
    DateField,
    Datagrid,
    FunctionField,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    TextField,
    Labeled,
    useNotify,
    useRecordContext,
} from 'react-admin';
import { Collapse, Chip, IconButton } from '@mui/material';
import {
    AddCircleOutline as AddIcon,
    RemoveCircleOutline as RemoveIcon,
    Assessment as AssessmentIcon,
} from '@mui/icons-material';
import { DialogTable } from '../lib/customComponents';
import { RelationTable, ResponsiveList, VariableEditButton } from '../lib/customComponents/showComponents';
import { getDownloadLink, getScheduleTime, recordAllowed } from './index';
import { formatDate } from '../lib/helpers';

const getIntervalIcon = rec => {
    const { recurrence } = rec;
    if (recurrence && recurrence.frequency && recurrence.days && recurrence.days.length) {
        return (
            <span>
                <Chip color="primary" label={rec.recurrence.days.join(' ')} /> {recurrence.frequency}
            </span>
        );
    }
    if (recurrence && recurrence.frequency && recurrence.month_days && recurrence.month_days.length) {
        return (
            <span>
                <Chip color="secondary" label={rec.recurrence.month_days.join(' ')} /> {recurrence.frequency}
            </span>
        );
    }
    return recurrence.frequency;
};

const MobileList = props => (
    <SimpleList
        primaryText={record => record.description}
        secondaryText={record => getIntervalIcon(record)}
        tertiaryText={record => record.created_at && formatDate(record.created_at)}
        linkType="show"
        {...props}
    />
);

const payloadFormatter = ({ field, value }) => {
    if ('fields' === field && Array.isArray(value)) {
        return value.map((val, index) => (
            <Chip color="primary" size="small" sx={{ margin: '0 .25em' }} key={`${field}_${index}`} label={val} />
        ));
    }

    if ('filters' === field && 'object' === typeof value) {
        const mapConditions = conds =>
            conds.map(({ field: condField, operator, value: condValue }, index) => {
                let formattedValue;
                if (Array.isArray(condValue)) {
                    formattedValue = condValue.join(' ');
                } else {
                    formattedValue = condValue;
                }
                return (
                    <React.Fragment key={`condField.${index}`}>
                        <Chip color="secondary" size="small" key={`${field}_${index}_field`} label={condField} />{' '}
                        <span>{operator}</span>{' '}
                        <Chip color="secondary" size="small" key={`${field}_${index}_value`} label={formattedValue} />
                        <br />
                        {index !== conds.length - 1 && <span>{value.logic}</span>}
                        <br />
                    </React.Fragment>
                );
            });
        if (value.conditions && !Object.keys(value.conditions).includes('logic')) {
            return mapConditions(value.conditions);
        }
        // ZTODO: Add (recursive?) handling for nested conditions
    }

    if ('relations' === field) {
        return value.map((val, index) => (
            <Chip color="primary" size="small" sx={{ margin: '0 .25em' }} key={`${field}_${index}`} label={val.name} />
        ));
    }
    return <Chip color="primary" size="small" sx={{ margin: '0 .25em' }} key={field} label={field} />;
};

export const PayloadShow = () => {
    const [isOpen, setIsOpen] = React.useState(true);

    const record = useRecordContext();
    const data = React.useMemo(() => record && record.request_payload, [record]);

    return (
        <Labeled label="Report Payload">
            <>
                <IconButton onClick={() => setIsOpen(!isOpen)} size="small" sx={{ width: 'fit-content' }}>
                    {isOpen ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <DialogTable
                        data={Object.entries(data).map(([field, value]) => ({ field, value }))}
                        columns={{
                            field: 'Field',
                            value: 'Value',
                        }}
                        formatToCustom={[
                            {
                                name: 'value',
                                formatter: val => payloadFormatter(val),
                            },
                        ]}
                    />
                </Collapse>
            </>
        </Labeled>
    );
};

const getVisualizeLink = id => {
    const visualizeURL = `/#/reports-generated-reports/${id}/show`;

    if (!visualizeURL) {
        return null;
    }

    return (
        <Button
            label="Visualize Report"
            onClick={ev => {
                ev.preventDefault();
                window.open(visualizeURL, '_blank');
            }}
        >
            <AssessmentIcon />
        </Button>
    );
};

export const RecurringReportLines = React.memo((props: any) => {
    const notify = useNotify();

    const notifyError = React.useCallback(() => {
        notify('Error downloading report!', { type: 'warning' });
    }, [notify]);

    return (
        <RelationTable
            columns={{
                scheduled_at: 'Scheduled',
                updated_at: 'Updated',
                status: 'Status',
                id: 'Visualize',
                file_path: 'Download',
            }}
            relation="report_lines"
            title="Generated Reports"
            additionalFields={['report_id', 'file_name']}
            formatToDate={[
                { name: 'scheduled_at', format: 'date-time' },
                { name: 'updated_at', format: 'date-time' },
            ]}
            formatToCustom={[
                {
                    name: 'id',
                    formatter: record =>
                        record &&
                        record.id &&
                        record.status &&
                        'scheduled' !== record.status &&
                        record.settings &&
                        record.settings.renderer &&
                        'Flexmonster' === record.settings.renderer
                            ? getVisualizeLink(record.id)
                            : null,
                },
                {
                    name: 'file_path',
                    formatter: record =>
                        record && record.id && record.file_name
                            ? getDownloadLink(record.id, record.file_name, notifyError)
                            : null,
                },
            ]}
            {...props}
        />
    );
});

const RecurringReportExpand = props => (
    <SimpleShowLayout {...props}>
        <PayloadShow {...props} />
        <RecurringReportLines {...props} />
    </SimpleShowLayout>
);

const DesktopList = props => (
    <Datagrid rowClick="edit" expand={<RecurringReportExpand />} bulkActionButtons={false} {...props}>
        <TextField source="description" />
        <FunctionField
            label="Resource"
            source="class"
            render={record => record && record.class && record.class.split('\\')[2]}
        />
        <FunctionField
            label="Interval Field"
            source="request_payload"
            render={record =>
                (record &&
                    record.request_payload &&
                    record.request_payload.filters &&
                    record.request_payload.filters.conditions &&
                    record.request_payload.filters.conditions.find(
                        item => !!item.field && 'interval' === item.operator
                    ) &&
                    record.request_payload.filters.conditions.find(item => !!item.field && 'interval' === item.operator)
                        .field) ||
                null
            }
        />
        <FunctionField
            label="Interval Date Range"
            source="request_payload"
            sortable={false}
            render={record =>
                (record &&
                    record.request_payload &&
                    record.request_payload.filters &&
                    record.request_payload.filters.conditions &&
                    record.request_payload.filters.conditions.find(
                        item => !!item.field && 'interval' === item.operator
                    ) &&
                    record.request_payload.filters.conditions.find(item => !!item.field && 'interval' === item.operator)
                        .value) ||
                null
            }
        />
        <TextField label="Scheduled Frequency" source="recurrence.frequency" sortable={false} />
        <TextField label="Scheduled Interval" source="recurrence.interval" sortable={false} />
        <FunctionField
            label="Scheduled Days/Dates"
            source="recurrence"
            sortable={false}
            render={record => {
                if (record && record.recurrence && record.recurrence.days && record.recurrence.days.length) {
                    return <Chip label={record.recurrence.days.join(' ')} />;
                }
                if (
                    record &&
                    record.recurrence &&
                    record.recurrence.month_days &&
                    record.recurrence.month_days.length
                ) {
                    return <Chip label={record.recurrence.month_days.join(', ')} />;
                }
            }}
        />
        <FunctionField label="Scheduled Time" source="schedule" render={record => getScheduleTime(record.schedule)} />
        <TextField label="Created By" source="user_name" />
        <DateField label="Started" source="started_at" showTime />
        <DateField label="Ended" source="ended_at" showTime />
        <BooleanField label="Active" source="is_active" />
        <ShowButton />
        <VariableEditButton recordAllowed={recordAllowed} allowAllUsers />
    </Datagrid>
);

const RecurringReportList = (props: any) => (
    <ResponsiveList desktopList={<DesktopList />} mobileList={<MobileList />} {...props} />
);

export default RecurringReportList;
