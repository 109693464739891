import React from 'react';

const useGetErrors = ({ errors }) => {
    const [errorContent, setErrorContent] = React.useState(null);

    const errorsLength = Object.keys(errors).length;

    const hasNoErrors = React.useMemo(() => !errorsLength, [errorsLength]);

    React.useEffect(() => {
        if (errorsLength) {
            const mappedErrors = Object.entries(errors).map(([error, details]: [string, any]) =>
                details?.message ? (
                    <li key={error}>
                        Input for {error} is invalid.
                        <ul style={{ fontSize: '0.75em' }}>
                            <li>{details.message}</li>
                        </ul>
                    </li>
                ) : (
                    <li key={error}>Input for {error} is invalid.</li>
                )
            );
            if (mappedErrors.length) {
                setErrorContent(mappedErrors);
            }
        } else {
            setErrorContent(null);
        }
    }, [errorsLength, errors]);

    return { hasNoErrors, errorContent };
};

export default useGetErrors;
