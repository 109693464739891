import React from 'react';
import { Button } from 'react-admin';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Stack,
    Theme,
    useMediaQuery,
} from '@mui/material';
import { CheckCircle as ActionCheck, ErrorOutline as AlertError } from '@mui/icons-material';

const Confirm: ({
    simpleMessage,
    hasConfirm,
    isOpen,
    title,
    content,
    confirmColor,
    onConfirm,
    onClose,
    error,
    errorText,
    children,
    loading,
    customButtons,
    fullWidth,
    maxWidth,
    confirmText,
    cancelText,
}: {
    simpleMessage?: boolean;
    hasConfirm?: boolean;
    isOpen?: boolean;
    title?: string;
    content?: any;
    confirmColor?: string;
    onConfirm?: any;
    onClose?: any;
    error?: boolean;
    errorText?: string;
    children?: any;
    loading?: boolean;
    customButtons?: any;
    fullWidth?: boolean;
    maxWidth?: any;
    confirmText?: string;
    cancelText?: string;
}) => JSX.Element = ({
    simpleMessage = false,
    hasConfirm = true,
    isOpen = false,
    title,
    content,
    confirmColor = 'primary',
    onConfirm,
    onClose,
    error = false,
    errorText = 'Records in red will not be sent',
    children,
    loading = false,
    fullWidth = false,
    maxWidth = 'md',
    customButtons = null,
    confirmText = 'confirm',
    cancelText = 'cancel',
}) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={isOpen}
            onClose={(event, reason) => {
                if ('backdropClick' !== reason) {
                    onClose(event, reason);
                }
            }}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            disableEscapeKeyDown
        >
            <DialogTitle>{loading ? 'Loading' : title}</DialogTitle>
            <DialogContent>
                <Stack>
                    {error && !loading && (
                        <DialogContentText color="error" variant="caption" data-cy="errorText">
                            {errorText}
                        </DialogContentText>
                    )}
                    {content && (
                        <DialogContentText component="span">
                            {loading ? <CircularProgress size={100} sx={{ ml: '37%' }} /> : content}
                        </DialogContentText>
                    )}
                    {!loading && children}
                </Stack>
            </DialogContent>
            {!loading && (
                <DialogActions>
                    {false === simpleMessage && (
                        <Button
                            onClick={onClose}
                            disabled={loading}
                            color="secondary"
                            variant="text"
                            size="medium"
                            label={`${!isSmall && cancelText}`}
                        >
                            <AlertError />
                        </Button>
                    )}
                    {customButtons && React.cloneElement(customButtons)}
                    {true === hasConfirm && (
                        <Button
                            disabled={error || loading}
                            onClick={onConfirm}
                            color="primary"
                            variant="text"
                            size="medium"
                            label={`${!isSmall && confirmText}`}
                        >
                            {'warning' === confirmColor ? <AlertError /> : <ActionCheck />}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default Confirm;
