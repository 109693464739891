import React from 'react';
import { SimpleForm } from 'react-admin';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import LockIcon from '@mui/icons-material/Lock';
import LoginInputs from './LoginInputs';

const BackGround = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.background.paper,
}));

const PREFIX = 'LoginCard';
const classes = {
    card: `${PREFIX}-card`,
    avatarContainer: `${PREFIX}-avatarContainer`,
    avatar: `${PREFIX}-avatar`,
};
const Root = styled('div')(({ theme }) => ({
    margin: 'auto',
    maxWidth: '25%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    background: 'none',
    [`& .${classes.card}`]: {
        justifyContent: 'center',
        minWidth: 300,
        marginTop: '20%',
    },
    [`& .${classes.avatarContainer}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${classes.avatar}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const CustomLogin = () => (
    <BackGround>
        <Root>
            <Card className={classes.card}>
                <div className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                </div>
                <SimpleForm toolbar={false}>
                    <LoginInputs />
                </SimpleForm>
            </Card>
        </Root>
    </BackGround>
);

export default CustomLogin;
