import React from 'react';
import { DateField, NumberField, TextField, Show, BooleanField } from 'react-admin';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import {
    FilteredFieldsRenderer,
    LocalReferenceField,
    USDFormattedNumberField,
} from '../../lib/customComponents/showComponents';

const getAccountFields = () => [
    <NumberField source="id" />,
    <TextField source="name" />,
    <TextField source="description" />,
    <TextField source="type" />,
    <LocalReferenceField source="parent_name" passProps />,
    <USDFormattedNumberField source="balance" label="Balance" />,
    <USDFormattedNumberField label="Total Balance" source="total_balance" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
    <BooleanField label="Active" source="is_active" />,
    <TextField source="notes" className="fullScreen" />,
];

export const AccountsShowFields = props => <FilteredFieldsRenderer getFieldsFunction={getAccountFields} {...props} />;

export const AccountsShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <AccountsShowFields {...props} />
    </Show>
);
