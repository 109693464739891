import React from 'react';
import { Create, SimpleForm, useNotify, useRefresh, useListContext, useRecordContext } from 'react-admin';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { CustomToolbar, DialogForm } from '../../lib/customComponents/formComponents';
import { RecurringTabs } from '../../lib/customComponents/showComponents/ExportSelectedButton/RecurringReportInputs';
import ViewFiltersButton from '../../lib/customComponents/showComponents/ViewFiltersButton';
import setRecurrence from '../../Reports/CreateReportButton/setRecurrence';
import { resourceMapping } from '../../../dataProvider';

const sanitizedRecord = rec => ({ ...rec, id: null });

const getSanitizedFormValues = ({
    recurrence_frequency: recurrenceFrequency,
    recurrence_interval: recurrenceInterval,
    recurrence_days: recurrenceDays,
    recurrence_month_days: recurrenceMonthDays,
    interval_date: intervalDate,
    interval_field: intervalField,
    request_payload: requestPayload,
    ...values
}: any) => values;

const transform = ({ resource, ...formValues }) => {
    const sanitizedFormValues = getSanitizedFormValues(formValues);
    const { interval_field: intervalField, interval_date: intervalDate } = formValues;
    const endpointReferencedResource = resourceMapping[resource] || resource;

    const getRequestConditions = () => {
        const conditions =
            formValues &&
            formValues.request_payload &&
            formValues.request_payload.filters &&
            formValues.request_payload.filters.conditions
                ? [...formValues.request_payload.filters.conditions]
                : [];
        conditions.push({
            field: intervalField,
            operator: 'interval',
            value: intervalDate,
        });
        return conditions;
    };

    const requestConditions = getRequestConditions();

    const requestPayload = {
        ...formValues.request_payload,
        filters: {
            ...formValues.request_payload.filters,
            conditions: requestConditions,
        },
    };

    const recurrence = setRecurrence(formValues);

    const data = {
        ...sanitizedFormValues,
        endpoint: endpointReferencedResource,
        request_payload: requestPayload,
        recurrence,
        settings: { renderer: 'csv', config: { resource } },
        id: null,
        classification: 'recurring',
    };

    return data;
};

const ConvertToRecurringButton = props => {
    const [filtersFromPayload, setFiltersFromPayload] = React.useState<any>({});

    const record = useRecordContext(props);

    const { resource } = record.settings.config;
    const mappedResource = resourceMapping[resource] || resource;

    const notify = useNotify();
    const refresh = useRefresh();
    const listContext = useListContext();
    const { displayedFilters, filterValues, setFilters } = listContext;

    const onSuccess = React.useCallback(
        ({ data, closeDialog }) => {
            closeDialog();
            notify('Report Pending.');
            refresh();
            setFilters({ ...filterValues, classification: 'recurring' }, displayedFilters);
        },
        [displayedFilters, filterValues, notify, refresh, setFilters]
    );

    return (
        <DialogForm
            title="Create Recurring Report"
            label="Create Recurring Report"
            content={<ViewFiltersButton filters={filtersFromPayload} />}
            icon={<EventNoteIcon />}
        >
            <Create record={sanitizedRecord(record)} resource="reports" component="div" {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            resourceOverride={`${mappedResource}/schedule-report`}
                            transform={vals => transform({ resource, ...vals })}
                            onSuccess={onSuccess}
                            enableNotification={false}
                            noInitialRequirement
                            forceCreate
                            {...props}
                        />
                    }
                >
                    <RecurringTabs
                        record={record}
                        resource={resource}
                        setFiltersFromPayload={setFiltersFromPayload}
                        fullWidth
                        {...props}
                    />
                </SimpleForm>
            </Create>
        </DialogForm>
    );
};

export default ConvertToRecurringButton;
