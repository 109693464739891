import React from 'react';
import { useWatch } from 'react-hook-form';
import { ArrayInput, TextInput, required, FormDataConsumer } from 'react-admin';
import { SimpleFormIterator } from '../../../lib/MarmeCustomComponents';
import { SpecimenInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const TransferLinesForm = ({ edit = false, ...props }) => {
    const type = useWatch({ name: 'type' });

    return (
        <ArrayInput source="specimen_transfer_lines" label="Items" validate={required()} disabled={!type} {...props}>
            <SimpleFormIterator disableReordering>
                <FormDataConsumer label="Specimen">
                    {({ formData, scopedFormData, getSource, ...rest }: any) => (
                        <SpecimenInput
                            source={getSource('specimen_id')}
                            validate={required()}
                            disabled={edit}
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
                <TextInput source="notes" label="Notes" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default TransferLinesForm;
