import React, { useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useNotify, useListContext, usePermissions, Button, downloadCSV, useResourceContext } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import DownloadIcon from '@mui/icons-material/Download';
import { useGetPaginatedData } from '../hooks';
import { LoadingWindow, ErrorWindow } from '../customComponents';
import { shouldRender } from '.';

interface IExportAllButton {
    resourceOverride?: string;
    perPage?: number;
    permanentFilter?: any;
    exporter?: any;
    sort?: any;
}

const ExportAllButton: React.FC<IExportAllButton> = ({
    resourceOverride,
    perPage = 100,
    permanentFilter,
    ...props
}) => {
    const [isExported, setIsExported] = useState<boolean>(false);
    const [errorWindow, setErrorWindow] = useState<boolean>(false);
    const [exportData, setExportData] = useState<object[]>([]);

    const notify = useNotify();
    const resource = useResourceContext<any>(props);
    const { permissions, isLoading: permissionsLoading } = usePermissions();
    const { defaultTitle, isLoading, filterValues, sort } = useListContext(props);
    const { field, order } = sort;

    const memoizedFilters = useMemo(() => ({ ...filterValues, ...permanentFilter }), [filterValues, permanentFilter]);

    const { progress, loading, onCancel, allPagesData, error, paginationText } = useGetPaginatedData({
        resource,
        sortByField: field,
        sortOrder: order,
        perPageCount: perPage,
        query: { fields: [], filters: memoizedFilters, relations: [] },
        chunkSize: 2,
        autoRefresh: true,
        preventInit: !isExported,
    });

    const canExport = React.useMemo(
        () => shouldRender(permissions, resourceOverride || resource, 'can_export'),
        [permissions, resource, resourceOverride]
    );

    const handleExport = useCallback(
        data => {
            const today = moment().format('MM/DD/YYYY, hh:mm A');
            jsonExport(data, (err, csv) => {
                if (err) {
                    return notify(`Error exporting data: ${err}`, { type: 'warning' });
                }
                downloadCSV(csv, `${defaultTitle} ${today}`);
                notify(`${defaultTitle} successfully exported to CSV`, { type: 'success' });
            });
        },
        [defaultTitle, notify]
    );

    const handleClick = useCallback(
        ev => {
            if (!isExported) {
                setIsExported(true);
            } else if (!loading && 100 === progress.percent && exportData.length) {
                handleExport(exportData);
            }
        },
        [exportData, handleExport, isExported, loading, progress.percent]
    );

    const handleCancel = useCallback(event => onCancel(), [onCancel]);

    useEffect(() => {
        if (isExported && 100 === progress.percent && !loading && allPagesData.length && 0 === exportData.length) {
            setExportData(allPagesData);
            handleExport(allPagesData);
        }
    }, [allPagesData, exportData.length, handleExport, isExported, loading, progress.percent]);

    useEffect(() => {
        if (error) {
            setErrorWindow(true);
            setIsExported(false);
            setExportData([]);
        }
    }, [error]);

    return (
        <>
            <ErrorWindow
                title="Export failed!"
                content={error}
                isOpen={errorWindow}
                onConfirm={() => setErrorWindow(false)}
                onClose={() => setErrorWindow(false)}
            />
            <LoadingWindow
                isLoading={!error && loading}
                linear
                completed={progress.percent}
                content={paginationText}
                hasConfirm
                confirmText="Cancel"
                confirmColor="warning"
                onConfirm={handleCancel}
                onClose={handleCancel}
            />
            <Button
                label="Export All"
                disabled={permissionsLoading || isLoading || !canExport}
                onClick={ev => {
                    ev.stopPropagation();
                    handleClick(ev);
                }}
            >
                <DownloadIcon />
            </Button>
        </>
    );
};

export default ExportAllButton;
