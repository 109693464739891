import React from 'react';
import { Admin } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { QueryClient } from 'react-query';
import { dataProvider } from './dataProvider';
import authProvider from './authProvider';
import { PusherProvider } from './PusherProvider';
import { CustomLoginForm, Layout } from './Layout';
import getResources from './getResources';
import ServiceWorkerNotification from './ServiceWorkerNotification';

const i18nProvider = polyglotI18nProvider(locale => englishMessages, 'en', {
    allowMissing: true,
    onMissingKey: key => (React.isValidElement(key) ? React.cloneElement(key) : key),
});

const pusherConfig = {
    clientKey: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const Dashboard = React.lazy(() =>
    // @ts-ignore
    import('./Components/Dashboard')
        .then(module => ({ default: module.Dashboard }))
        .catch(() => window.location.reload())
);

const DashboardAsync = props => (
    <React.Suspense fallback={<div>Loading...</div>}>
        <Dashboard {...props} />
    </React.Suspense>
);

const App = () => (
    <PusherProvider {...pusherConfig}>
        <ServiceWorkerNotification />
        <Admin
            requireAuth
            queryClient={queryClient}
            i18nProvider={i18nProvider}
            layout={Layout}
            dashboard={DashboardAsync}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={CustomLoginForm}
            title="Ranch Management Web App"
            disableTelemetry
        >
            {getResources}
        </Admin>
    </PusherProvider>
);

export default App;
