import React from 'react';
import { Button } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import CustomizeColumnGroup from './CustomizeColumnGroup';
import { TColumn, useViewContext } from '../../../contexts/ViewContext';

interface ICustomizeDialogProps {
    displaySelection: string[];
    closeDialog: () => void;
    changeColumns: (selectedCols, fragileDisplaySelection) => void;
    resetToViewColumns: () => void;
    resetToDefaultColumns: () => void;
}

const SelectionDialog: React.FC<ICustomizeDialogProps> = ({
    displaySelection,
    closeDialog,
    changeColumns,
    resetToViewColumns,
    resetToDefaultColumns,
    ...props
}) => {
    const { columns } = useViewContext()!;

    const [orderedColumns, setOrderedColumns] = React.useState<Array<TColumn>>(columns);
    const [fragileDisplaySelection, setFragileDisplaySelection] = React.useState<Array<string>>(displaySelection);

    const toggleColumn = React.useCallback(
        columnName => {
            const previousValues: string[] = fragileDisplaySelection;
            let newValues;
            if (previousValues.includes(columnName)) {
                newValues = previousValues.filter(prevVal => prevVal !== columnName);
            } else {
                newValues = [...previousValues, columnName];
            }
            setFragileDisplaySelection(newValues);
        },
        [fragileDisplaySelection]
    );

    const checkboxClick = React.useCallback(
        ({ target: { value: columnName } }) => {
            toggleColumn(columnName);
        },
        [toggleColumn]
    );

    const closeButtonClick = React.useCallback(() => {
        closeDialog();
        changeColumns(orderedColumns, fragileDisplaySelection);
    }, [changeColumns, closeDialog, fragileDisplaySelection, orderedColumns]);

    const cancelButtonClick = React.useCallback(() => {
        setFragileDisplaySelection(displaySelection);
        closeDialog();
    }, [closeDialog, displaySelection]);

    const resetToViewButtonClick = React.useCallback(() => {
        setFragileDisplaySelection([]);
        resetToViewColumns();
    }, [resetToViewColumns]);

    const resetToDefaultButtonClick = React.useCallback(() => {
        setFragileDisplaySelection([]);
        resetToDefaultColumns();
    }, [resetToDefaultColumns]);

    return (
        <Dialog maxWidth="xs" aria-labelledby="ra-columns-dialog-title" open>
            <DialogTitle id="ra-columns-dialog-title">Select & Reorder Visible Columns</DialogTitle>
            <DialogContent>
                <Button label="Reset To View" size="medium" onClick={resetToViewButtonClick} color="primary">
                    <ViewAgendaIcon />
                </Button>
                <Button label="Reset To Defaults" size="medium" onClick={resetToDefaultButtonClick} color="secondary">
                    <RestoreIcon />
                </Button>
                <CustomizeColumnGroup
                    orderedColumns={orderedColumns}
                    setOrderedColumns={setOrderedColumns}
                    displaySelection={displaySelection}
                    fragileDisplaySelection={fragileDisplaySelection}
                    checkboxClick={checkboxClick}
                    {...props}
                />
            </DialogContent>
            <DialogActions>
                <Button label="Cancel" size="medium" onClick={cancelButtonClick} color="secondary">
                    <CloseIcon />
                </Button>
                <Button label="Close" size="medium" onClick={closeButtonClick} color="primary">
                    <CheckIcon />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectionDialog;
