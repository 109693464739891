import React from 'react';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    'div:nth-of-type(odd)': {
        '& .MuiAccordionSummary-root': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
    'div:nth-of-type(even)': {
        '& .MuiAccordionSummary-root': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));

const Accordion = styled(MuiAccordion)(() => ({
    root: {
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    [`& .content`]: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}));

const LinesFormAccordionWrapper: ({
    children,
    ...props
}: {
    [x: string]: any;
    children: React.ReactNode;
}) => JSX.Element = ({ children, ...props }) => (
    <Root>
        {React.Children.map(children, child =>
            React.isValidElement(child) ? (
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" gutterBottom>
                            {child?.props?.title || child?.props?.label}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {React.cloneElement(child, {
                            label: '',
                            ...props,
                        })}
                    </AccordionDetails>
                </Accordion>
            ) : null
        )}
    </Root>
);

export default LinesFormAccordionWrapper;
