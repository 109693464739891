import React from 'react';
import 'react-sweet-progress/lib/style.css';
import { CustomConfirm as Confirm } from '../../index';
import useGetErrors from './useGetErrors';

const AlertWindow: ({
    isOpen,
    onClose,
    errors,
    subentity,
}: {
    isOpen: boolean;
    onClose: (value?: React.SetStateAction<boolean>) => void;
    errors: { [x: string]: any };
    subentity?: { [x: string]: string[] };
}) => JSX.Element = ({ isOpen, onClose, errors, subentity }) => {
    const { hasNoErrors, errorContent } = useGetErrors({ errors });
    const title = React.useMemo(
        () => (!hasNoErrors ? 'Submit Failed' : 'We are processing your request!'),
        [hasNoErrors]
    );

    return (
        <Confirm
            isOpen={isOpen}
            cancelText="Close"
            onClose={() => (!hasNoErrors ? onClose() : null)}
            errorText="The form is not valid"
            hasConfirm={false}
            simpleMessage={hasNoErrors}
            content={hasNoErrors ? 'We are processing your request!' : errorContent}
            title={title}
            loading={hasNoErrors}
            error={!hasNoErrors}
            maxWidth="xs"
            fullWidth
        />
    );
};

export default AlertWindow;
