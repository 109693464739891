import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getItemSubtypesFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="parent_name" label="Parent" reference="item-types" passProps />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const ItemSubtypesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getItemSubtypesFields} {...props} />
);

const ItemSubtypesTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getItemSubtypesFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const ItemSubtypesSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <ItemSubtypesShowFields {...props} />
    </Show>
);

export const ItemSubtypesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <ItemSubtypesSimpleShow {...props} />
    ) : (
        <ItemSubtypesTabbedShow {...props} />
    );
