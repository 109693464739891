import React from 'react';
import { BooleanInput, useNotify, useRecordContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { Divider, Tabs, Tab } from '@mui/material';
import { requestGetByID } from '../../../../../dataProvider/RestClient';
import {
    GeneralInputs,
    IntervalInputs,
    ScheduleInputs,
} from '../../../../UserReports/UserReportForms/UserReportFormInputs';
import { resourceMapping } from '../../../../../dataProvider';

type TRecurringTabs = {
    schemaDateFields?: any;
    record?: any;
    resource?: string;
    setFiltersFromPayload?: React.SetStateAction<any>;
    fullWidth?: boolean;
};

const tabs = [
    { name: 'general', value: 'general' },
    { name: 'interval', value: 'interval' },
    { name: 'schedule', value: 'schedule' },
];

const hiddenStyle = { display: 'none' };

export const RecurringTabs: React.FC<TRecurringTabs> = ({
    schemaDateFields: schemaDatesFromList,
    fullWidth,
    resource,
    ...rest
}) => {
    const [visibleTab, setVisibleTab] = React.useState(tabs[0].value);
    const [schemaDateFields, setSchemaDateFields] = React.useState([]);

    const notify = useNotify();
    const record = useRecordContext();

    const requestPayload = record && record.request_payload;
    const { setFiltersFromPayload } = rest;

    const hasConditions = (requestPayload && requestPayload.filters && requestPayload.filters.conditions) || false;

    const hasRelationsFilters =
        (requestPayload &&
            requestPayload.relations &&
            requestPayload.relations.some(rel => rel.filters && rel.filters.conditions)) ||
        false;

    React.useMemo(() => {
        const formattedFilters = {};
        if (hasConditions) {
            requestPayload.filters.conditions.forEach(filter => {
                let filterField = filter.field;

                if ('>=' === filter.operator) {
                    filterField = `${filter.field}_from`;
                } else if ('<=' === filter.operator) {
                    filterField = `${filter.field}_to`;
                }
                if (['in', '='].includes(filter.operator)) {
                    formattedFilters[filterField] = filter.value;
                } else {
                    formattedFilters[filterField] = `${filter.operator} ${filter.value}`;
                }
            });
        }
        if (hasRelationsFilters) {
            requestPayload.relations.forEach(rel => {
                if (rel && rel.filters && rel.filters.conditions) {
                    rel.filters.conditions.forEach(relFil => {
                        let relFilterField = relFil.field;

                        if ('>=' === relFil.operator) {
                            relFilterField = `${relFil.field}_from`;
                        } else if ('<=' === relFil.operator) {
                            relFilterField = `${relFil.field}_to`;
                        }

                        formattedFilters[`${rel.name}-${relFilterField}`] = relFil.value;
                    });
                }
            });
        }

        if (formattedFilters && Object.entries(formattedFilters).length && setFiltersFromPayload) {
            setFiltersFromPayload(formattedFilters);
        }
    }, [hasConditions, hasRelationsFilters, requestPayload, setFiltersFromPayload]);

    React.useEffect(() => {
        const getSchemaFromCall = () =>
            requestGetByID(resource, 'schema')
                .then(res => {
                    const timeDateFields = res.data.fields.filter(
                        field => 'datetime' === field.type || 'timestamp' === field.type
                    );
                    setSchemaDateFields(timeDateFields);
                })
                .catch(err => notify(`Problem getting schema for ${resource}`, { type: 'warning' }));

        if (schemaDatesFromList && schemaDatesFromList.length) {
            setSchemaDateFields(schemaDatesFromList);
        } else if (resource) {
            getSchemaFromCall();
        }
    }, [notify, resource, schemaDatesFromList]);

    const handleTabChange = React.useCallback((ev, val) => {
        setVisibleTab(val);
    }, []);

    return (
        <div style={{ margin: 'auto', minWidth: '90%' }}>
            <Tabs variant="fullWidth" centered value={visibleTab} indicatorColor="primary" onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab key={tab.name} label={tab.name} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            <GeneralInputs style={'general' !== visibleTab ? hiddenStyle : null} fullWidth {...rest} />
            <IntervalInputs
                style={'interval' !== visibleTab ? hiddenStyle : null}
                resource={resource}
                schemaDateFields={schemaDateFields}
                fullWidth
                {...rest}
            />
            <ScheduleInputs style={'schedule' !== visibleTab ? hiddenStyle : null} fullWidth {...rest} />
        </div>
    );
};

const RecurringReportInputs = ({
    schemaDateFields,
    setResourceOverride,
    setReportsClassification,
    resource,
    ...rest
}) => {
    const mappedResource = resourceMapping[resource] || resource;

    const isRecurring = useWatch({ name: 'is_recurring' });
    const { setValue } = useFormContext();

    const recurrenceFormFields = React.useMemo(
        () => [
            'description',
            'started_at',
            'ended_at',
            'interval_field',
            'interval_date',
            'recurrence_interval',
            'recurrence_frequency',
            'recurrence_days',
            'recurrence_month_days',
            'schedule',
        ],
        []
    );

    const handleReccurring = React.useCallback(
        active => {
            if (active) {
                recurrenceFormFields.forEach(field => {
                    setValue(field, undefined);
                });

                setResourceOverride(`${mappedResource}/schedule-report`);
                setReportsClassification('recurring');
            } else {
                setResourceOverride(`${mappedResource}/export-to-csv`);
                setReportsClassification('nonrecurring');
            }
        },
        [setValue, mappedResource, recurrenceFormFields, setReportsClassification, setResourceOverride]
    );

    return (
        <>
            <BooleanInput source="is_recurring" label="Recurring" onChange={handleReccurring} />
            {isRecurring && (
                <RecurringTabs schemaDateFields={schemaDateFields} resource={resource} fullWidth {...rest} />
            )}
        </>
    );
};

export default RecurringReportInputs;
