import React from 'react';
import moment from 'moment';
import { useFormContext, useWatch } from 'react-hook-form';
import { BooleanInput, TextInput, SelectInput, required, useRecordContext, NumberInput } from 'react-admin';
import { ColumnShowView, SingleColumn } from '../../../lib/styles/StyledComponents';
import { AttachmentsInput, DateTimeInput } from '../../../lib/customComponents/formComponents';
import { requestGetByID } from '../../../../dataProvider/RestClient';
import {
    BreedingRecordInput,
    CodonInput,
    SpecimenInput,
} from '../../../lib/customComponents/formComponents/CommonFormInputs';

const genderChoices = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
];

const OverviewInputs = ({ edit = false, ...props }) => {
    const [shouldDisableParentFields, setShouldDisableParentFields] = React.useState<boolean>(false);

    const record = useRecordContext();

    const { setValue } = useFormContext();

    const bornAt = useWatch({ name: 'born_at' });
    const diedAt = useWatch({ name: 'died_at' });
    const motherID = useWatch({ name: 'mother_id' });
    const fatherID = useWatch({ name: 'father_id' });

    const [motherFilter, fatherFilter] = React.useMemo(
        () => [
            bornAt ? { gender: 'Female', born_at_to: bornAt } : { gender: 'Female' },
            bornAt ? { gender: 'Male', born_at_to: bornAt } : { gender: 'Male' },
        ],
        [bornAt]
    );

    const defaultBreedingCreateValues = React.useMemo(() => {
        const filterObj: any = {};
        if (motherID) {
            filterObj.specimen_id = motherID;
        }
        if (fatherID) {
            filterObj.male_specimen_id = fatherID;
        }
        return filterObj;
    }, [motherID, fatherID]);

    const breedingRecordFilter = React.useMemo(() => {
        const filterObj: any = {};
        if (motherID) {
            filterObj.specimen_id = motherID;
        }
        if (fatherID) {
            filterObj.male_specimen_id = fatherID;
        }
        if (bornAt) {
            filterObj.bred_at_to = bornAt;
        }
        return filterObj;
    }, [motherID, fatherID, bornAt]);

    const handleBreedingRecordChange = React.useCallback(
        async ev => {
            const { value } = ev.target;
            if (!value) {
                setValue('father_id', null);
                return setShouldDisableParentFields(false);
            }
            await requestGetByID('breeding-records', value).then(({ data }) => {
                const { male_specimen_id: maleSpecimenID, specimen_id: specimenID } = data;
                if (maleSpecimenID) {
                    setValue('father_id', maleSpecimenID);
                    setShouldDisableParentFields(true);
                }
            });
        },
        [setValue]
    );

    const handleDiedAtChange = React.useCallback(val => setValue('is_active', !moment(val).isValid()), [setValue]);

    return (
        <>
            <ColumnShowView>
                <TextInput source="name" validate={[required()]} />
            </ColumnShowView>
            <ColumnShowView>
                <SelectInput
                    source="gender"
                    validate={required()}
                    optionValue="value"
                    choices={genderChoices}
                    emptyText="Clear selection"
                />
            </ColumnShowView>
            <ColumnShowView>
                <DateTimeInput
                    source="born_at"
                    label="Born At"
                    validate={required()}
                    options={{ disableFuture: true, maxDate: diedAt }}
                />
            </ColumnShowView>
            <ColumnShowView>
                <DateTimeInput
                    source="died_at"
                    label="Died At"
                    options={{ disableFuture: true, minDate: bornAt }}
                    onChange={handleDiedAtChange}
                />
            </ColumnShowView>
            <SingleColumn>
                <BooleanInput label="Active" source="is_active" disabled={!!diedAt} defaultValue />
            </SingleColumn>
            <ColumnShowView>
                <SpecimenInput
                    label="Mother"
                    source="mother_id"
                    defaultCreateValues={{ gender: 'Female' }}
                    filter={edit ? { ...motherFilter, NOT_id: record.id } : motherFilter}
                    disabled={shouldDisableParentFields}
                />
            </ColumnShowView>
            <ColumnShowView>
                <BreedingRecordInput
                    enableGetChoices={({ specimen_id: motherIDFilterValue }) => Number.isInteger(motherIDFilterValue)}
                    filter={breedingRecordFilter}
                    disabled={!motherID}
                    defaultCreateValues={defaultBreedingCreateValues}
                    onChange={handleBreedingRecordChange}
                />
            </ColumnShowView>
            <ColumnShowView>
                <SpecimenInput
                    label="Father"
                    source="father_id"
                    defaultCreateValues={{ gender: 'Male' }}
                    filter={edit ? { ...fatherFilter, NOT_id: record.id } : fatherFilter}
                    disabled={shouldDisableParentFields}
                />
            </ColumnShowView>
            <ColumnShowView>
                <TextInput label="DNA No." source="dna_number" />
            </ColumnShowView>
            <ColumnShowView>
                <TextInput label="Tag No." source="tag_number" />
            </ColumnShowView>
            <ColumnShowView>
                <TextInput label="State No." source="state_number" />
            </ColumnShowView>
            <ColumnShowView>
                <TextInput label="Electronic No." source="electronic_number" />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="codon_37_id" label="Codon 37" />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="codon_95_id" label="Codon 95" />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="codon_96_id" label="Codon 96" />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="codon_116_id" label="Codon 116" />
            </ColumnShowView>
            <ColumnShowView>
                <CodonInput source="codon_226_id" label="Codon 226" />
            </ColumnShowView>
            <ColumnShowView>
                <NumberInput source="50k_gebv" label="50k_gebv" />
            </ColumnShowView>
            <AttachmentsInput />
        </>
    );
};

export default OverviewInputs;
