import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './PensFormHelpers';

const transformPen = rec => {
    const newRecord: any = {
        name: rec.name,
        unit_of_measure_id: rec.unit_of_measure_id,
        facility_id: rec.facility_id,
        length: rec.length,
        width: rec.width,
        is_active: rec.is_active,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Pen">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformPen} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Pen">
        <TabbedForm toolbar={<CustomToolbar transform={transformPen} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Pen" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformPen} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Pen" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformPen} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const PenEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const PenCreateForm = () => <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />;
