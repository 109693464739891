import { stringify } from 'query-string';
import apiClient from './apiClient';
import setQuery from './setQuery';

const bypassFetchResources = ['inventory-by-location'];

export const resourceMapping = {
    codons: 'specimens/codons',
    'bill-lines': 'bills/lines',
    'bill-payments': 'bills/payments',
    'bill-payment-lines': 'bills/payments/lines',
    'bill-payment-types': 'bills/payments/types',
    'breeding-records': 'breeding/records',
    entities: 'reviews/entities',
    'item-types': 'items/types',
    'item-subtypes': 'items/subtypes',
    'item-locations': 'items/locations',
    'item-receipts': 'items/receipts',
    'item-receipt-lines': 'items/receipts/lines',
    'inventory-adjustments': 'items/inventory/adjustments',
    'inventory-reports': 'items/inventory/reports',
    'inventory-report-lines': 'items/inventory/reports/lines',
    'medication-records': 'medications/records',
    'medication-routes': 'medications/routes',
    'purchase-order-lines': 'purchase-orders/lines',
    'purchase-requisition-lines': 'purchase-requisitions/lines',
    'review-rules': 'reviews/rules',
    'review-rule-action': 'reviews/rule-actions',
    'specimen-transfers': 'specimens/transfers',
    'specimen-transfer-lines': 'specimens/transfers/lines',
};

const mapPayload = data => {
    const newPayload = {};

    if (data && 'object' === typeof data) {
        Object.entries(data).forEach(([name, val]) => {
            let newVal = '' === val ? null : val ?? null;

            if (Array.isArray(newVal)) {
                newVal = newVal.map(value => mapPayload(value));
            }
            newPayload[name] = newVal;
        });
    } else {
        return data;
    }
    return newPayload;
};

const processPayload = data => {
    const mappedPayload = mapPayload(data);

    delete mappedPayload.attachments;
    delete mappedPayload.deleted_attachments;

    return mappedPayload;
};

export const dataProvider = {
    getList: (resource, params) => {
        if (bypassFetchResources.includes(resource)) {
            return Promise.resolve({
                data: [],
                total: 1,
            });
        }
        const mappedResource = resourceMapping[resource] || resource;
        const query = setQuery(params);
        return apiClient.get(`${mappedResource}?${stringify(query)}`).then(({ status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data || [],
                total: data.total,
            };
        });
    },

    getListWithPages: (resource, params) => {
        if (bypassFetchResources.includes(resource)) {
            return Promise.resolve({
                data: [],
                total: 1,
            });
        }

        const mappedResource = resourceMapping[resource] || resource;

        const query = setQuery(params);

        const newURL = `${mappedResource}?${stringify(query)}`;
        return apiClient.get(newURL).then(({ status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return data;
        });
    },

    getOne: (resource, params) => {
        if ('users' === resource) {
            const userID = JSON.parse(localStorage.getItem('user')).id;
            if (userID && params.id && parseInt(params.id) === userID) {
                return apiClient.get('user').then(res => ({ data: res.data.data }));
            }
        }

        const mappedResource = resourceMapping[resource] || resource;

        return apiClient(`${mappedResource}/${params.id}`).then(res => ({
            data: res.data.data,
        }));
    },

    getOneWithQuery: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const { id, fields, relations, join } = params;
        const query = {
            fields: JSON.stringify(fields),
            relations: JSON.stringify(relations),
            join,
        };
        return apiClient(`${mappedResource}/${id}?${stringify(query)}`).then(res => ({
            data: res.data.data,
        }));
    },

    getMany: (resource, params) => {
        const query = {
            filters: JSON.stringify({
                logic: 'and',
                conditions: [{ field: 'id', operator: 'in', value: params.ids }],
            }),
            range: JSON.stringify([0, 499]),
            relations: JSON.stringify(params.relations),
            join: params.join,
        };
        const mappedResource = resourceMapping[resource] || resource;
        const url = `${mappedResource}?${stringify(query)}`;
        return apiClient.get(url).then(({ data }) => ({ data: data.data }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([{ field, order }]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };

        const mappedResource = resourceMapping[resource] || resource;

        return apiClient.get(`${mappedResource}?${stringify(query)}`).then(({ headers, data }) => ({
            data: data.data,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getAudits: params => {
        const auditsQuery = setQuery(params);
        const auditsURL = `audits/list?${stringify(auditsQuery)}`;

        return apiClient.get(auditsURL).then(({ headers, status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data.data || [],
                total: parseInt(headers['content-range'].split('/').pop(), 10),
            };
        });
    },

    getNotificationsList: () =>
        apiClient.get('user/notifications').then(({ headers, status, data }) => {
            if (205 === status) {
                return {
                    data: [],
                    total: 0,
                };
            }
            return {
                data: data.data || [],
                total: parseInt(headers['content-range'].split('/').pop(), 10),
            };
        }),

    getCurrentUser: () => apiClient.get('user').then(res => res.data.data),

    updateCurrentUser: params => apiClient.put('user', params.data).then(({ data }) => data),

    refresh: () => apiClient.post('refresh').then(res => res),

    update: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;
        const processedPayload = processPayload(params.data);

        return apiClient
            .put(`${mappedResource}${params.id ? `/${params.id}` : ''}`, processedPayload)
            .then(({ data }) => {
                const { status } = data;
                if (202 === status) {
                    return {
                        data: params.data,
                        total: 1,
                    };
                }
                return data;
            });
    },

    updateMany: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return apiClient.put(`${mappedResource}?${stringify(query)}`).then(({ data }) => ({ data }));
    },

    create: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;
        const processedPayload = processPayload(params.data);
        if (mappedResource.includes('export-to-csv') || mappedResource.includes('schedule-report')) {
            const reportConfig = {
                headers: {
                    Accept: 'text/csv',
                },
            };
            return apiClient.post(mappedResource, processedPayload, reportConfig).then(({ data }) => {
                const { status } = data;
                if (202 === status) {
                    return {
                        data: { ...params.data, id: Infinity },
                    };
                }
                return data;
            });
        }

        return apiClient.post(mappedResource, processedPayload).then(({ data }) => {
            const { status } = data;
            if (202 === status) {
                return {
                    data: { ...params.data, id: Infinity },
                };
            }
            return data;
        });
    },

    setStatus: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;

        const { status, id, data } = params;

        return apiClient.put(`${mappedResource}/${id}/${status}`, data).then(res => res.data);
    },

    addAttachments: (resource, id, files) => {
        const mappedResource = resourceMapping[resource] || resource;
        const bodyFormData = new FormData();

        files.forEach((file, index) => {
            bodyFormData.append(`files[${index}]`, file.rawFile);
        });

        bodyFormData.append('entity', mappedResource);
        bodyFormData.append('entity_id', id);

        return apiClient.post('attachments', bodyFormData).then(response => response.data);
    },

    downloadReport: id =>
        apiClient.get(`user/reports/lines/${id}/download`, { responseType: 'blob' }).then(response => response.data),

    downloadAttachment: id =>
        apiClient.get(`attachments/${id}/download`, { responseType: 'blob' }).then(response => response.data),

    downloadArrayBufferImage: id => apiClient.get(`attachments/${id}/download`).then(response => response.data),

    deleteAttachments: (resource, id, filePaths) => {
        const mappedResource = resourceMapping[resource] || resource;
        const query = {
            entity: mappedResource,
            entity_id: id,
            paths: filePaths,
        };

        return apiClient.delete('attachments', { data: query }).then(response => response.data);
    },

    updatePassword: payload => apiClient.put('update-password', payload).then(({ data }) => data),

    forgotPassword: payload => apiClient.post(`forgot-password`, payload).then(({ data }) => data),

    delete: (resource, params) => {
        const mappedResource = resourceMapping[resource] || resource;
        return apiClient.delete(`${mappedResource}/${params.id}`).then(({ data }) => ({ data }));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const mappedResource = resourceMapping[resource] || resource;
        return apiClient.delete(`${mappedResource}?${stringify(query)}`).then(({ data }) => ({ data }));
    },
};
