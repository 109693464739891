import React from 'react';
import moment from 'moment';
import { Chip } from '@mui/material';
import { DialogTable } from '../..';

const filtersFormatter = ({ field, value }) => {
    const splitRelationFilter = field && field.split('-RELATION-');

    const splitDateValue = field && field.includes('_at') && value && value.split('= ') && value.split('= ')[1];

    if (value && value.length && 0 === value.indexOf('%') && value.length - 1 === value.lastIndexOf('%')) {
        return (
            <Chip
                color="primary"
                size="small"
                sx={{ margin: '0 .25em' }}
                key={field}
                label={value.replaceAll('%', '')}
            />
        );
    }
    if (
        field.startsWith('is_') ||
        (splitRelationFilter && splitRelationFilter[1] && splitRelationFilter[1].startsWith('is_'))
    ) {
        return (
            <Chip
                color={value ? 'primary' : 'secondary'}
                size="small"
                sx={{ margin: '0 .25em' }}
                key={field}
                label={value.toString()}
            />
        );
    }

    if (Array.isArray(value)) {
        return value.map((val, i) => (
            <Chip color="primary" size="small" sx={{ margin: '0 .25em' }} key={i} label={val} />
        ));
    }

    if (moment(value).isValid() || (splitDateValue && moment(splitDateValue).isValid)) {
        return (
            <Chip
                color={value ? 'primary' : 'secondary'}
                size="small"
                sx={{ margin: '0 .25em' }}
                key={field}
                label={splitDateValue ? moment(splitDateValue).toLocaleString() : moment(value).toLocaleString()}
            />
        );
    }

    return value;
};

const FiltersTable = ({ data }) => (
    <DialogTable
        data={Object.entries(data).map(([field, value]) => ({ field, value }))}
        columns={{
            field: 'Field',
            value: 'Value',
        }}
        formatToCustom={[
            {
                name: 'value',
                formatter: val => filtersFormatter(val),
            },
        ]}
    />
);

export default FiltersTable;
