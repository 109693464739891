import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-named-default
import Pusher, { Options } from 'pusher-js';
import Echo from 'laravel-echo';
import lodash from 'lodash';
import apiClient from '../dataProvider/apiClient';

export interface PusherContextValues {
    client?: Echo;
    triggerEndpoint?: string;
}

export interface PusherProviderProps extends Options {
    clientKey: string | undefined;
    cluster: 'mt1' | 'us2' | 'us3' | 'eu' | 'ap1' | 'ap2' | 'ap3' | 'ap4' | string | undefined;
    defer?: boolean;
    // for testing purposes
    value?: PusherContextValues;
    children?: React.ReactNode;
}

// context setup
const PusherContext = React.createContext<PusherContextValues>({});
// eslint-disable-next-line @typescript-eslint/naming-convention
export const __PusherContext = PusherContext;

const anyWindow = window as any;

anyWindow.Pusher = Pusher;

/**
 * Provider that creates your pusher instance and provides it to child hooks throughout your app.
 * Note, you can pass in value={{}} as a prop if you'd like to override the pusher client passed.
 * This is handy when simulating pusher locally, or for testing.
 * @param props Config for Pusher client
 */

export const PusherProvider: React.FC<PusherProviderProps> = ({
    clientKey,
    cluster,
    defer = false,
    children,
    ...props
}) => {
    // errors when required props are not passed.
    useEffect(() => {
        if (!clientKey) console.error('A client key is required for pusher');
        if (!cluster) console.error('A cluster is required for pusher');
    }, [clientKey, cluster]);

    // track config for comparison
    const previousConfig = useRef<Options | undefined>(props);
    useEffect(() => {
        previousConfig.current = props;
    });
    const [client, setClient] = useState<any | undefined>();

    const PusherContextValue = React.useMemo(
        () => ({
            client,
        }),
        [client]
    );

    useEffect(() => {
        // Skip creation of client if deferring, a value prop is passed, or config props are the same.
        if (defer || props.value || (lodash.isEqual(previousConfig.current, props) && client !== undefined)) {
            return;
        }

        const createdClient = new Echo({
            broadcaster: 'pusher',
            key: clientKey,
            cluster,
            encrypted: true,
            authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
            authorizer: (channel, options) => ({
                authorize: (socketId, callback) => {
                    apiClient
                        .post('broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name,
                        })
                        .then(response => {
                            callback(false, response.data);
                        })
                        .catch(error => {
                            callback(true, error);
                        });
                },
            }),
        });

        setClient(createdClient);
    }, [client, clientKey, cluster, props, defer]);

    return (
        <PusherContext.Provider value={PusherContextValue} {...props}>
            {children}
        </PusherContext.Provider>
    );
};
