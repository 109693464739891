import React from 'react';
import { BooleanField, DateField, TextField, Show, TabbedShowLayout } from 'react-admin';
import { FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getOrganizationsFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <TextField source="type" label="Type" />,
    <BooleanField label="Is Active" source="is_active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const OrganizationsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getOrganizationsFields} {...props} />
);

const OrganizationsTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getOrganizationsFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const OrganizationsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <OrganizationsShowFields {...props} />
    </Show>
);

export const OrganizationsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <OrganizationsSimpleShow {...props} />
    ) : (
        <OrganizationsTabbedShow {...props} />
    );
