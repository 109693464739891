import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { CustomToolbar, formMode, ResponsiveForm } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import { OverviewInputs } from './ItemLocationsFormHelpers';

const transformItemLocation = (rec: any) => {
    const newRecord: any = {
        name: rec.name,
        parent_id: rec.parent_id,
        notes: rec.notes,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Item Location">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformItemLocation} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Item Location">
        <TabbedForm toolbar={<CustomToolbar transform={transformItemLocation} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Item Location" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformItemLocation} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Item Location" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformItemLocation} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ItemLocationsCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);

export const ItemLocationsEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;
