import React from 'react';
import { ArrayInput, SelectInput, required, useNotify, useResourceContext } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { SimpleFormIterator } from '../../../lib/MarmeCustomComponents';
import { requestGetList } from '../../../../dataProvider/RestClient';

type TMedRouteChoice = {
    id: number;
    name: string;
    disabled?: boolean;
};

const mustHaveMethodsIfMedication = (value, allValues) => {
    const { item_type_name: itemTypeName } = allValues;

    if ('Medication' === itemTypeName && (!value || 0 === value?.length)) {
        return 'Medications must have at least one medication route';
    }

    return undefined;
};

const MedRoutesLinesForm = ({ edit = false, ...props }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [medicationRouteChoices, setMedicationRouteChoices] = React.useState<Array<TMedRouteChoice>>([]);
    const [processedRouteChoices, setProcessedRouteChoices] = React.useState<Array<TMedRouteChoice>>([]);

    const notify = useNotify();
    const resource = useResourceContext(props);

    const medicationRoutesFormValues = useWatch({ name: 'medication_routes' });

    const getmedicationRouteChoices = React.useCallback(() => {
        setIsLoading(true);
        requestGetList('medication-routes', {}, 'name', 'ASC', 100, ['id', 'name'])
            .then(response => {
                const simpleMedRouteChoices: TMedRouteChoice[] = [];
                response.data.forEach(route =>
                    simpleMedRouteChoices.push({ id: route.id, name: route.name, disabled: false })
                );
                setMedicationRouteChoices(simpleMedRouteChoices);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                notify('Problem getting medication route choices', { type: 'warning' });
            });
    }, [notify]);

    React.useEffect(() => {
        if (!medicationRouteChoices.length && 'medications' === resource) {
            getmedicationRouteChoices();
        }
    }, [medicationRouteChoices, getmedicationRouteChoices, resource]);

    React.useEffect(() => {
        const currentRouteIDs = medicationRoutesFormValues?.map(method => method !== undefined && method.id);
        const unselectedRoutes: TMedRouteChoice[] = [];
        medicationRouteChoices.map(route => {
            const routeObj = route;
            if (route?.id && currentRouteIDs?.length && currentRouteIDs.includes(route.id)) {
                routeObj.disabled = true;
            } else {
                routeObj.disabled = false;
            }
            return unselectedRoutes.push(routeObj);
        });
        setProcessedRouteChoices(unselectedRoutes);
    }, [medicationRoutesFormValues, medicationRouteChoices]);

    if ('medications' !== resource) {
        return null;
    }

    if (isLoading) {
        return <CircularProgress size={100} sx={{ marginLeft: '45%' }} />;
    }

    return (
        <ArrayInput source="medication_routes" label="Medication Routes" validate={mustHaveMethodsIfMedication}>
            <SimpleFormIterator
                disableReordering
                disableAdd={!processedRouteChoices.some(route => false === route.disabled)}
            >
                <SelectInput
                    source="id"
                    label="Route"
                    choices={processedRouteChoices}
                    validate={required()}
                    emptyText="Clear selection"
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default MedRoutesLinesForm;
