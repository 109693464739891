import React from 'react';
import { TextInput, required } from 'react-admin';
import { ColumnShowView } from '../../../lib/styles/StyledComponents';
import { ItemTypeInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const OverviewInputs = ({ edit = false, ...props }) => (
    <>
        <ColumnShowView>
            <ItemTypeInput label="Parent Item Type" source="parent_id" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="name" validate={required()} />
        </ColumnShowView>
    </>
);

export default OverviewInputs;
