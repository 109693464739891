import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode, QueryWrapper } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './TransferFormHelpers';
import TransferLinesForm from './TransferLinesForm';

const getSpecimenTransferLines = lines =>
    lines.map(line => ({ id: line.id, specimen_id: line.specimen_id, notes: line.notes }));

export const transformTransfer = rec => {
    const newRecord: any = {
        transferred_by_id: rec.transferred_by_id,
        transferred_at: rec.transferred_at,
        type: rec.type,
        from_id: rec.from_id,
        to_id: rec.to_id,
        is_active: rec.is_active,
        notes: rec.notes,
        specimen_transfer_lines: getSpecimenTransferLines(rec.specimen_transfer_lines),
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

const specimenTransferSubentity = {
    specimen_transfer_lines: ['specimen_id'],
};

const SimpleCreate = () => (
    <Create title="Create Transfer">
        <SimpleForm
            toolbar={<CustomToolbar transform={transformTransfer} subentity={specimenTransferSubentity} />}
            mode={formMode}
        >
            <OverviewInputs />
            <TransferLinesForm />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Transfer">
        <TabbedForm
            toolbar={<CustomToolbar transform={transformTransfer} subentity={specimenTransferSubentity} />}
            mode={formMode}
        >
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
            <FormTab label="Items">
                <TransferLinesForm />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Transfer" actions={<CustomActionsEditForm />}>
        <QueryWrapper relations={['specimen_transfer_lines']}>
            <SimpleForm
                toolbar={<CustomToolbar transform={transformTransfer} subentity={specimenTransferSubentity} />}
                mode={formMode}
            >
                <OverviewInputs edit />
                <TransferLinesForm edit />
            </SimpleForm>
        </QueryWrapper>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Transfer" actions={<CustomActionsEditForm />}>
        <QueryWrapper relations={['specimen_transfer_lines']}>
            <TabbedForm
                toolbar={<CustomToolbar transform={transformTransfer} subentity={specimenTransferSubentity} />}
                mode={formMode}
            >
                <FormTab label="Overview">
                    <OverviewInputs edit />
                </FormTab>
                <FormTab label="Items">
                    <TransferLinesForm edit />
                </FormTab>
            </TabbedForm>
        </QueryWrapper>
    </Edit>
);

export const TransferEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const TransferCreateForm = () => <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />;
