import React from 'react';
import { ListProps, EmailField, SimpleShowLayout } from 'react-admin';
import { formatDate } from '../../lib/helpers';
import { ListWithViewContext, ReferenceDrawerField, RelationTable } from '../../lib/customComponents/showComponents';

export const ContactUser = props => (
    <RelationTable
        columns={{
            id: 'ID',
            name: 'Name',
            email: 'Email',
            organization_name: 'Organization',
        }}
        title="User"
        relation="users"
        idField="id"
        recordIdField="user_id"
        additionalFields={['organization_id']}
        formatToDrawerLink={[
            {
                name: 'name',
                resource: 'users',
                idField: 'id',
            },
            {
                name: 'organization_name',
                resource: 'organizations',
                idField: 'organization_id',
            },
        ]}
        {...props}
    />
);

const ContactsExpand = props => (
    <SimpleShowLayout {...props}>
        <ContactUser {...props} />
    </SimpleShowLayout>
);

const contactsDefaultColumns = ['id', 'name', 'email', 'organization_name'];

const contactsSpecialColumns = () => [
    <ReferenceDrawerField source="organization_name" label="Organization" />,
    <EmailField source="email" />,
];

export const ContactsList: React.FC<ListProps> = React.memo(props => (
    <ListWithViewContext
        title="Contacts"
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOnFilters={['name']}
        expand={<ContactsExpand />}
        defaultColumns={contactsDefaultColumns}
        specialCols={contactsSpecialColumns}
        primaryText={record => record?.name}
        secondaryText={record => record?.email}
        tertiaryText={record => record?.updated_at && `Updated: ${formatDate(record.updated_at, 'short-date-time')}`}
        {...props}
    />
));

export default ContactsList;
