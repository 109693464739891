import React from 'react';
import { BooleanField, DateField, NumberField, TextField, Show, TabbedShowLayout, Tab } from 'react-admin';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import {
    ExpandableAuditsTable,
    FilteredFieldsRenderer,
    LocalReferenceField,
} from '../../lib/customComponents/showComponents';

const getItemLocationFields = () => [
    <NumberField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <BooleanField source="is_default" label="Is Default" />,
    <LocalReferenceField source="parent_name" reference="item-locations" label="Parent" passProps />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
    <TextField source="notes" label="Notes" className="fullScreen" />,
];

export const ItemLocationsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getItemLocationFields} {...props}>
        {/* <ExpandableAuditsTable {...props} /> */}
    </FilteredFieldsRenderer>
);

const ItemLocationsTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getItemLocationFields} isTabbedShow {...props} />
            {/* <Tab label="Audits" path="audits">
                <ExpandableAuditsTable {...props} />
            </Tab> */}
        </TabbedShowLayout>
    </Show>
);

const ItemLocationsSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <ItemLocationsShowFields {...props} />
    </Show>
);

export const ItemLocationsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <ItemLocationsSimpleShow {...props} />
    ) : (
        <ItemLocationsTabbedShow {...props} />
    );
