import React from 'react';
import { Drawer, Button, IconButton, Typography, LinearProgress, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import RecordShowFields from './RecordShowFields';
import { requestGetByID } from '../../../../../dataProvider/RestClient';

const PREFIX = 'PreviewDrawerWrapper';
const classes = {
    title: `${PREFIX}-title`,
    link: `${PREFIX}-link`,
};
const Root = styled('div')(({ theme }) => ({
    margin: '1em',
    [`& .${classes.title}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    [`& .${classes.link}`]: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
}));

type TRecord = {
    id?: number;
};

interface IPreviewDrawerWrapper {
    [x: string]: any;
    position?: 'right' | 'left' | 'top' | 'bottom' | undefined;
    isDrawer?: boolean;
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const PreviewDrawerWrapper: React.FC<IPreviewDrawerWrapper> = ({
    position = 'right',
    isDrawer = false,
    children,
    ...props
}) => {
    const [previewRecord, setPreviewRecord] = React.useState<TRecord>({});
    const [entityResource, setEntityResource] = React.useState<string | null>(null);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isErrored, setIsErrored] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState('Problem getting drawer data!');

    const handleCloseClick = React.useCallback(() => {
        setShowPanel(false);
        setIsErrored(false);
    }, []);

    const handleClick: (id: number, resource: string) => React.MouseEventHandler<HTMLButtonElement> | Promise<void> =
        React.useCallback(async (id, resource) => {
            setIsErrored(false);
            setIsLoading(true);
            setShowPanel(true);
            await requestGetByID(resource, id)
                .then(res => {
                    setEntityResource(resource);
                    setPreviewRecord(res.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setIsErrored(true);
                    const { status } = error;
                    if (404 === status) {
                        return setErrorMessage(`${resource} #${id} not found!`);
                    }
                    if (403 === status) {
                        return setErrorMessage(`You do not have permissions to view ${resource}!`);
                    }
                    return setErrorMessage(`Error while trying to load ${resource}!`);
                });
        }, []);

    const openPreviewDrawer = (id, resource, displayField) =>
        false === isDrawer ? (
            <Button
                disableRipple
                sx={{
                    padding: 1,
                    textDecoration: 'none',
                    color: 'palette.primary.main',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
                size="small"
                onClick={() => handleClick(id, resource)}
            >
                {displayField}
            </Button>
        ) : (
            <Link href={`/#/${resource}/${id}/show`} className={classes.link}>
                {displayField}
            </Link>
        );

    return (
        <>
            <Drawer
                anchor={position}
                open={showPanel}
                onClose={handleCloseClick}
                sx={{
                    '& .MuiPaper-root': { maxWidth: '80vw', minWidth: '25vw', zIndex: 100 },
                }}
                onClick={ev => ev.stopPropagation()}
            >
                <Root>
                    <div className={classes.title}>
                        {isErrored ? (
                            <Typography variant="h6" align="center" color="error">
                                Error
                            </Typography>
                        ) : (
                            <Typography variant="h6">
                                {`${
                                    (entityResource &&
                                        entityResource
                                            .toLowerCase()
                                            .split('-')
                                            .map(word => word[0].toUpperCase() + word.substr(1))
                                            .join(' ')) ||
                                    `Record`
                                } #${previewRecord.id || ''}`}
                            </Typography>
                        )}
                        <IconButton onClick={handleCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {isLoading ? (
                        <LinearProgress sx={{ mt: 2 }} />
                    ) : (
                        <RecordShowFields
                            openPreviewDrawer={openPreviewDrawer}
                            resource={entityResource}
                            record={previewRecord}
                        />
                    )}
                    {!isLoading && isErrored && (
                        <Typography variant="h6" color="error">
                            {errorMessage}
                        </Typography>
                    )}
                </Root>
            </Drawer>
            {React.cloneElement(children, { ...props, isDrawer, openPreviewDrawer })}
        </>
    );
};

export default PreviewDrawerWrapper;
