import React from 'react';
import { Button, useRedirect } from 'react-admin';
import { Settings as SettingsIcon } from '@mui/icons-material';

const DebugLinkButton = ({ isUserProfile = false }) => {
    const redirect = useRedirect();

    if (!isUserProfile) {
        return null;
    }

    return (
        <Button
            size="small"
            color="primary"
            sx={{ paddingLeft: '0.5em' }}
            label="Debug"
            onClick={ev => {
                ev.stopPropagation();
                redirect('/debug');
            }}
        >
            <SettingsIcon />
        </Button>
    );
};

export default DebugLinkButton;
