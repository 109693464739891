import React from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Collapse, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FilePreview from './FilePreview';
import ImagePreview from './ImagePreview';
import { LoadingWindow } from '../../index';

export const AttachmentsList = styled('ul')(({ theme }) => ({
    listStyleType: 'none',
    display: 'inline',
    maxWidth: '100%',
}));

const getFilteredImages = (files, printView = false) => {
    if (!files) {
        return [];
    }
    const filteredFiles = files.filter(item => /\.(gif|jpg|jpeg|tiff|png|bmp|svg)$/i.test(item.path));

    if (!printView) {
        return filteredFiles;
    }

    return filteredFiles.filter(file => file.is_locked);
};

const getFilteredFiles = files =>
    files && files.filter(item => /\.(msg|xls|xlsx|xlsm|csv|pdf|tsx|doc|docx|txt)$/i.test(item.path));

const AttachmentPreviewField = props => {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const record = useRecordContext();
    const { setIsLoadingQRForPrint, printView } = props;

    const [filteredImages, filteredFiles] = React.useMemo(
        () => [
            getFilteredImages(record?.attachments, printView),
            printView ? [] : getFilteredFiles(record?.attachments) || [],
        ],
        [record?.attachments, printView]
    );

    React.useEffect(() => {
        if (printView && 0 === filteredImages.length) {
            setIsLoadingQRForPrint(false);
        }
    }, [filteredImages.length, setIsLoadingQRForPrint, printView]);

    return React.useMemo(
        () =>
            (printView && 0 === filteredImages.length) || !(filteredImages || filteredFiles) ? null : (
                <>
                    {isDownloading && (
                        <LoadingWindow
                            isLoading={isDownloading}
                            simpleMessage
                            // completed={progress.percent}
                            linear
                            content="Downloading attachment..."
                        />
                    )}
                    <Labeled label="Attachments">
                        <>
                            <IconButton onClick={() => setIsOpen(!isOpen)} size="small" sx={{ width: 'fit-content' }}>
                                {isOpen ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                            </IconButton>
                            <Collapse in={isOpen} timeout="auto">
                                {filteredImages?.length || filteredFiles?.length ? (
                                    <AttachmentsList>
                                        {filteredImages.map((image, index) => (
                                            <ImagePreview
                                                key={`image${index}`}
                                                image={image}
                                                setIsDownloading={setIsDownloading}
                                                setIsLoadingQRForPrint={setIsLoadingQRForPrint}
                                            />
                                        ))}
                                        {filteredFiles.map((file, index) => (
                                            <FilePreview
                                                key={`file${index}`}
                                                file={file}
                                                setIsDownloading={setIsDownloading}
                                            />
                                        ))}
                                    </AttachmentsList>
                                ) : (
                                    <Typography variant="h6" gutterBottom align="center">
                                        No Attachments found
                                    </Typography>
                                )}
                            </Collapse>
                        </>
                    </Labeled>
                </>
            ),
        [filteredImages, filteredFiles, isOpen, isDownloading, printView, setIsLoadingQRForPrint]
    );
};

export default AttachmentPreviewField;
