import React from 'react';
import { Button } from 'react-admin';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { sortByTemplateColumnIndex, useViewContext } from '../../../contexts/ViewContext';

import CustomizeDialog from './CustomizeDialog';

const CustomizeColumnsButton = () => {
    const [modalOpened, setModalOpened] = React.useState<boolean>(false);
    const [displaySelection, setDisplaySelection] = React.useState<Array<string>>([]);

    const { columns, setColumns, defaultListViewColumns, currentViewColumns } = useViewContext()!;

    const handleColumnsReset = React.useCallback(
        cols => {
            setModalOpened(false);
            setDisplaySelection([]);
            setColumns(cols);
        },
        [setColumns]
    );

    const handleResetToViewColumns = React.useCallback(() => {
        handleColumnsReset(currentViewColumns);
    }, [handleColumnsReset, currentViewColumns]);

    const handleResetToDefaultColumns = React.useCallback(() => {
        handleColumnsReset(defaultListViewColumns);
    }, [defaultListViewColumns, handleColumnsReset]);

    const handleOpen = React.useCallback(() => {
        const filteredDisplayColumns: string[] = [];
        columns.map(item => (item.active ? filteredDisplayColumns.push(item.source) : null));

        setDisplaySelection(filteredDisplayColumns);
        setModalOpened(true);
    }, [columns]);

    const handleClose = React.useCallback(() => {
        setModalOpened(false);
    }, []);

    const handleChangeColumns = React.useCallback(
        (orderedCols, fragileDisplaySelection) => {
            const activeOrderedColumns: string[] = [];
            const newDisplayColumns = orderedCols.map(col => {
                if (fragileDisplaySelection.includes(col.source)) {
                    activeOrderedColumns.push(col.source);
                    return { ...col, active: true };
                }
                return { ...col, active: false };
            });

            const sortedCols = [...newDisplayColumns].sort((a, b) =>
                sortByTemplateColumnIndex(a, b, activeOrderedColumns)
            );

            setDisplaySelection(fragileDisplaySelection);
            setColumns(sortedCols);
        },
        [setColumns]
    );

    return (
        <>
            <Button label="Columns" onClick={handleOpen}>
                <ListAltIcon />
            </Button>
            {modalOpened && (
                <CustomizeDialog
                    displaySelection={displaySelection}
                    closeDialog={handleClose}
                    changeColumns={handleChangeColumns}
                    resetToViewColumns={handleResetToViewColumns}
                    resetToDefaultColumns={handleResetToDefaultColumns}
                />
            )}
        </>
    );
};

export default CustomizeColumnsButton;
