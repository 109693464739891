import React from 'react';
import { UserMenu, MenuItemLink, Logout, useUserMenu, usePermissions } from 'react-admin';
import {
    AccountBox as AccountBoxIcon,
    Assignment as AssignmentIcon,
    RateReview as RateReviewIcon,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { shouldRender } from '../../Components/lib/helpers';
import LoginAsOriginalUserButton from './LoginAsOriginalUserButton';

const ProfileMenuItem = ({ userData }) => {
    const { onClose } = useUserMenu();

    if (!userData) {
        return null;
    }

    return (
        <MenuItemLink
            to={`/users/${userData.id}/show`}
            primaryText="My Profile"
            leftIcon={<AccountBoxIcon />}
            onClick={() => onClose()}
        />
    );
};

const ReportsMenuItem = () => {
    const { onClose } = useUserMenu();
    return (
        <MenuItemLink to="/reports" primaryText="My Reports" leftIcon={<AssignmentIcon />} onClick={() => onClose()} />
    );
};

const ReviewsMenuItem = () => {
    const { onClose } = useUserMenu();
    return (
        <MenuItemLink to="/reviews" primaryText="My Reviews" leftIcon={<RateReviewIcon />} onClick={() => onClose()} />
    );
};

const CustomUserMenu = () => {
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    const originalUserData = JSON.parse(localStorage.getItem('originalUser') || '{}');
    const userName = userData && userData.name ? userData.name : 'Name Not Found';

    const { permissions, isLoading } = usePermissions();

    const userCanViewReviews = React.useMemo(() => shouldRender(permissions, 'reviews'), [permissions]);
    const hasOriginalUser = originalUserData && Object.entries(originalUserData).length;

    if (isLoading) {
        return null;
    }

    if ((!userData || !permissions) && originalUserData) {
        return (
            <div data-cy="userMenu">
                <UserMenu label={userName}>
                    <LoginAsOriginalUserButton user={originalUserData} />
                </UserMenu>
            </div>
        );
    }

    if ((!userData || !permissions) && !originalUserData) {
        return (
            <div data-cy="userMenu">
                <UserMenu label={userName} />
            </div>
        );
    }

    return (
        <div data-cy="userMenu">
            <UserMenu label={userName}>
                <div />
                <Typography
                    sx={{ padding: '12px 16px' }}
                    color="textSecondary"
                    align="center"
                    variant="subtitle1"
                    gutterBottom
                >
                    {userName}
                </Typography>
                <ProfileMenuItem userData={userData} />
                <ReportsMenuItem />
                {userCanViewReviews && <ReviewsMenuItem />}
                {hasOriginalUser ? <LoginAsOriginalUserButton user={originalUserData} /> : null}
                <Logout />
            </UserMenu>
        </div>
    );
};

export default CustomUserMenu;
