import React, { useState, useEffect, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const Notification: ({
    message,
    handleUndo,
    undoable,
    undoButtonText,
    type,
    autoHideDuration,
    notification,
    ...props
}: {
    [x: string]: any;
    message: any;
    handleUndo?: () => void;
    undoable?: boolean;
    undoButtonText?: string;
    type?: string;
    autoHideDuration?: number;
    notification: any;
}) => JSX.Element = ({
    message,
    handleUndo,
    undoable = false,
    undoButtonText = 'Undo',
    type = 'info',
    autoHideDuration = 4000,
    notification,
    ...props
}) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(!!notification);
    }, [notification]);

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Snackbar
            open={open}
            message={message}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            disableWindowBlurListener={undoable}
            onClose={handleRequestClose}
            action={
                undoable ? (
                    <Button
                        color="primary"
                        sx={theme => ({
                            error: {
                                backgroundColor: theme.palette.error.dark,
                                color: theme.palette.error.contrastText,
                            },
                            warning: {
                                backgroundColor: theme.palette.error.light,
                                color: theme.palette.error.contrastText,
                            },
                            undo: {
                                color: theme.palette.primary.light,
                            },
                        })}
                        size="small"
                        onClick={handleUndo}
                    >
                        {undoButtonText}
                    </Button>
                ) : null
            }
            {...props}
        />
    );
};

export default Notification;
