import React from 'react';
import { useRedirect } from 'react-admin';
import {
    Typography,
    Divider,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    ListSubheader,
    MenuItem,
} from '@mui/material';
import { red, green, yellow, orange } from '@mui/material/colors';
import LensIcon from '@mui/icons-material/Lens';
import { formatDate } from '../../../Components/lib/helpers';
import OptionsButton from './NotificationOptionsButton';
import getHeaderText from './getHeaderText';
import { OptionsMenu } from '../../../Components/lib/customComponents/showComponents';

const getColor = type => {
    switch (type) {
        case 'low':
            return yellow[500];
        case 'normal':
            return green[500];
        case 'high':
            return orange[500];
        case 'critical':
        default:
            return red[500];
    }
};

const NotificationList = ({ notifications, handleNotificationClick }) => {
    const redirect = useRedirect();

    const handleShowClick = React.useCallback(pathname => redirect(pathname), [redirect]);

    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            subheader={
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <ListSubheader>Notifications</ListSubheader>
                    <OptionsMenu>
                        <MenuItem
                            onClick={() =>
                                handleNotificationClick(
                                    'read',
                                    notifications.map(item => item.id)
                                )
                            }
                        >
                            Mark All as Read
                        </MenuItem>
                    </OptionsMenu>
                </div>
            }
        >
            {notifications ? (
                notifications.map(
                    (
                        {
                            subject,
                            sent_at: sentAt,
                            data,
                            priority,
                            id,
                            is_read: isRead,
                            redirect: redirectData,
                            notification_type: notificationType,
                        },
                        i
                    ) => (
                        <React.Fragment key={i}>
                            <ListItem id={id} button onClick={() => handleShowClick(`/notifications/${id}/show`)}>
                                <ListItemIcon>
                                    <OptionsButton
                                        id={id}
                                        isRead={isRead}
                                        handleNotificationClick={handleNotificationClick}
                                        redirect={redirectData}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            component="p"
                                            variant="h6"
                                            gutterBottom
                                            color={isRead ? 'textSecondary' : 'textPrimary'}
                                        >
                                            {subject}
                                        </Typography>
                                    }
                                    secondary={
                                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography
                                                component="span"
                                                variant="subtitle2"
                                                gutterBottom
                                                color={isRead ? 'textSecondary' : 'textPrimary'}
                                            >
                                                {getHeaderText(notificationType, data)}
                                            </Typography>
                                            <Typography component="span" variant="caption" color="textSecondary">
                                                {formatDate(sentAt, 'date-time')}
                                            </Typography>
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title={`Priority ${priority}`}>
                                        <LensIcon sx={{ color: getColor(priority) }} />
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {i !== notifications.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    )
                )
            ) : (
                <p>No Notifications</p>
            )}
        </List>
    );
};

export default NotificationList;
