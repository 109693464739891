import React from 'react';
import { DateField, TextField, usePermissions, useNotify, useRecordContext } from 'react-admin';
import { LinearProgress, Button, Typography } from '@mui/material';
import PanToolIcon from '@mui/icons-material/PanTool';
import DrawerPreview from './Drawers/DrawerPreview';
import { requestGetByID } from '../../../../dataProvider/RestClient';
import { useReferenceSource } from '../../hooks';
import { shouldRender } from '../../helpers';
import RecordShowFields from './Drawers/RecordShowFields';

interface IReferenceDrawerField {
    className?: string;
    idSource?: string;
    descSource?: string;
    reference?: string;
    record?: any;
    drawerRecord?: any;
    sortable?: boolean;
    redirectPath?: string;
    source?: string;
    label?: string;
    isDateField?: boolean;
    onClick?: (ev: any) => Promise<void>;
}

const sanitizeRestProps = ({ label, record, sortable, ...rest }: any) => rest;

const ReferenceDrawerField: React.FC<IReferenceDrawerField> = React.memo(
    ({
        className,
        idSource: idOverride,
        descSource: descOverride,
        reference: refOverride,
        drawerRecord = {},
        source,
        onClick,
        isDateField = false,
        ...rest
    }) => {
        const [showPanel, setShowPanel] = React.useState<boolean>(false);
        const [previewRecord, setPreviewRecord] = React.useState(drawerRecord);
        const [noRecordFound, setNoRecordFound] = React.useState<boolean>(false);
        const [isErrored, setIsErrored] = React.useState<boolean>(false);
        const [errorMessage, setErrorMessage] = React.useState<string>('Error while trying to load drawer data!');

        const record = useRecordContext(rest);
        const notify = useNotify();

        const { nameField, refResource, recordID } = useReferenceSource({
            source,
            record,
            idOverride,
            descOverride,
            refOverride,
        });

        const { isLoading, permissions } = usePermissions();

        const getShowPanelRecord = React.useCallback(
            () =>
                requestGetByID(refResource, recordID)
                    .then(res => {
                        if (!res.data) {
                            setNoRecordFound(true);
                        }
                        setPreviewRecord(res.data);
                    })
                    .catch(error => {
                        const {
                            status,
                            data: { message },
                        } = error;
                        if (status && 403 === status) {
                            setErrorMessage(`Permissions required to view ${refResource || 'this resource.'} `);
                        }
                        if (message) {
                            setErrorMessage(prev => `${prev}: ${message}`);
                        }
                        setIsErrored(true);
                    }),
            [recordID, refResource]
        );

        const handleClick = React.useCallback(
            async ev => {
                const userAllowed = shouldRender(permissions, refResource, 'can_read');

                ev.stopPropagation();

                if (!userAllowed) {
                    return notify(`Read access for ${refResource} required! Please contact supervisor.`, {
                        type: 'warning',
                    });
                }

                setShowPanel(true);
                if (!drawerRecord.id) {
                    await getShowPanelRecord();
                }
            },
            [drawerRecord.id, getShowPanelRecord, notify, permissions, refResource]
        );

        const handleCloseClick = React.useCallback(async ev => setShowPanel(false), []);

        if (!record) {
            return null;
        }

        if (!recordID && !drawerRecord.id) {
            return isDateField ? (
                <DateField source={nameField} record={record} showTime />
            ) : (
                <TextField source={nameField} record={record} />
            );
        }

        return (
            <>
                <Button
                    disableRipple
                    sx={{
                        padding: '0',
                        textDecoration: 'none',
                        color: 'palette.primary.main',
                        textTransform: 'none',
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    onClick={handleClick}
                >
                    {isDateField ? (
                        <DateField source={nameField} record={record} showTime />
                    ) : (
                        <TextField source={nameField} record={record} />
                    )}
                </Button>
                <DrawerPreview
                    position="right"
                    showPanel={showPanel}
                    handleCloseClick={handleCloseClick}
                    title={`${refResource
                        .toLowerCase()
                        .split('-')
                        .map(word => word[0].toUpperCase() + word.substr(1))
                        .join(' ')} #${recordID}`}
                >
                    {isErrored ? (
                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                            {errorMessage && <PanToolIcon sx={{ fill: 'red' }} />}
                            <Typography sx={{ paddingLeft: '1em' }} color="error" variant="h6" gutterBottom>
                                {errorMessage}
                            </Typography>
                        </span>
                    ) : (
                        <>
                            {noRecordFound && (
                                <Typography variant="h6" align="center">
                                    No {refResource} found
                                </Typography>
                            )}
                            {(!previewRecord.id && !noRecordFound) || (isLoading && !noRecordFound) ? (
                                <LinearProgress sx={{ mt: 2 }} />
                            ) : (
                                !noRecordFound && (
                                    <RecordShowFields
                                        {...sanitizeRestProps(rest)}
                                        resource={refResource}
                                        record={previewRecord}
                                    />
                                )
                            )}
                        </>
                    )}
                </DrawerPreview>
            </>
        );
    }
);

export default ReferenceDrawerField;
