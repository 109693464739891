import React from 'react';
import { SelectArrayInput } from 'react-admin';
import { DynamicFilter } from '../../lib/customComponents/filterComponents';

const mediumDropDown = { minWidth: '9em' };

const genderChoices = [
    { id: 1, name: 'Male', value: 'Male' },
    { id: 2, name: 'Female', value: 'Female' },
];

const SpecimenFilters = ({ ...props }) => (
    <DynamicFilter {...props}>
        <SelectArrayInput
            source="gender"
            sx={mediumDropDown}
            label="Gender"
            optionValue="value"
            choices={genderChoices}
        />
    </DynamicFilter>
);

export default SpecimenFilters;
