import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm, usePermissions, useRedirect } from 'react-admin';
import { CustomToolbar, ResponsiveForm } from '../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../lib/helpers/customActions';
import { GeneralInputs, IntervalInputs, ScheduleInputs } from './UserReportFormInputs';
import { transform as transformReportInputs } from '../../Reports/CreateReportButton';

type TTabbedCreate = {
    [x: string]: any;
    analyticsReport?: boolean;
    fullWidth?: boolean;
};

type TSimpleCreate = {
    [x: string]: any;
    analyticsReport: boolean;
    mode?: 'onBlur' | 'onChange' | 'all';
};

const sanitizeToolbarProps = ({ recordResource, hasList, hasEdit, hasShow, hasCreate, ...rest }: any) => rest;

const SimpleCreate: React.FC<TSimpleCreate> = ({ analyticsReport, mode = 'onBlur', ...props }) => (
    <Create>
        <SimpleForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} mode={mode}>
            <GeneralInputs />
            <IntervalInputs analyticsReport={analyticsReport} />
            <ScheduleInputs />
        </SimpleForm>
    </Create>
);

export const TabbedCreate: React.FC<TTabbedCreate> = ({ analyticsReport, fullWidth, mode = 'onBlur', ...props }) => (
    <Create>
        <TabbedForm toolbar={<CustomToolbar {...sanitizeToolbarProps(props)} />} mode={mode}>
            <FormTab label="General">
                <GeneralInputs fullWidth={fullWidth} />
            </FormTab>
            <FormTab label="Interval">
                <IntervalInputs
                    recordResource={props.resource}
                    analyticsReport={analyticsReport}
                    fullWidth={fullWidth}
                />
            </FormTab>
            <FormTab label="Schedule">
                <ScheduleInputs fullWidth={fullWidth} />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = ({ transform }) => (
    <Edit actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transform} />} mode="all">
            <GeneralInputs edit />
            <IntervalInputs edit />
            <ScheduleInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = ({ transform }) => (
    <Edit actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transform} />} mode="all">
            <FormTab label="General">
                <GeneralInputs edit />
            </FormTab>
            <FormTab label="Interval">
                <IntervalInputs edit />
            </FormTab>
            <FormTab label="Schedule">
                <ScheduleInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const UserReportCreateForm = ({ analyticsReport = false }) => {
    const redirect = useRedirect();

    const { permissions, isLoading } = usePermissions();

    React.useEffect(() => {
        if (!isLoading && !permissions) {
            redirect('/unauthorized');
        }
    }, [isLoading, permissions, redirect]);

    return (
        <ResponsiveForm
            simpleForm={<SimpleCreate analyticsReport={analyticsReport} />}
            tabbedForm={<TabbedCreate analyticsReport={analyticsReport} />}
        />
    );
};

export const UserReportEditForm = () => {
    const redirect = useRedirect();

    const { permissions, isLoading } = usePermissions();

    React.useEffect(() => {
        if (!isLoading && !permissions) {
            redirect('/unauthorized');
        }
    }, [isLoading, permissions, redirect]);

    return (
        <ResponsiveForm
            simpleForm={<SimpleEdit transform={transformReportInputs} />}
            tabbedForm={<TabbedEdit transform={transformReportInputs} />}
        />
    );
};
