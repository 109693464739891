import React from 'react';
import { BooleanInput, SelectInput, TextInput, required, useNotify } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { DateTimeInput } from '../../../lib/customComponents/formComponents';
import { ColumnShowView, FullScreen, SingleColumn } from '../../../lib/styles/StyledComponents';
import { ContactInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';
import { requestGetList } from '../../../../dataProvider/RestClient';
import ConditionalToFromInput from './ConditionalToFromInput';

const transferTypeChoices = [
    { id: 1, value: 'Facility-To-Facility', name: 'Facility-To-Facility' },
    { id: 2, value: 'Facility-To-Pen', name: 'Facility-To-Pen' },
    { id: 3, value: 'Pen-To-Pen', name: 'Pen-To-Pen' },
    { id: 4, value: 'Release', name: 'Release' },
    // {id: 5, value: 'Decease', name: 'Decease'},
];

const OverviewInputs = ({ edit = false, ...props }) => {
    const [contactRecord, setContactRecord] = React.useState({ id: null });

    const type = useWatch({ name: 'type' });
    const fromID = useWatch({ name: 'from_id' });
    const toID = useWatch({ name: 'to_id' });
    const { setValue } = useFormContext();

    const notify = useNotify();

    const [fromEntity, toEntity] = React.useMemo(() => (type ? type.split('-To-') : [undefined, undefined]), [type]);

    const today = React.useMemo(() => new Date(), []);

    const user = React.useMemo(
        () => localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}'),
        []
    );

    const getDefaultContact = React.useCallback(
        async userID => {
            await requestGetList('contacts', { user_id: userID }, 'id', 'ASC', 2, ['id', 'name', 'user_id'])
                .then(res => {
                    if (res.data[0]) {
                        setContactRecord(res.data[0]);
                    }
                })
                .catch(() => notify('Problem getting contact record, defaults could not load', { type: 'warning' }));
        },
        [notify]
    );

    React.useEffect(() => {
        if (user?.id) {
            getDefaultContact(user.id);
        }
    }, [user, getDefaultContact]);

    const resetTypeSpecificFormElements = React.useCallback(() => {
        setValue('from_id', null);
        setValue('to_id', null);
    }, [setValue]);

    return (
        <>
            <ColumnShowView>
                <ContactInput source="transferred_by_id" label="Transferred By" defaultValue={contactRecord.id} />
            </ColumnShowView>
            <ColumnShowView>
                <DateTimeInput
                    source="transferred_at"
                    label="Transferred At"
                    validate={required()}
                    defaultValue={today}
                    options={{ disableFuture: true }}
                />
            </ColumnShowView>
            <ColumnShowView>
                <SelectInput
                    source="type"
                    label="Type"
                    choices={transferTypeChoices}
                    optionValue="value"
                    disabled={edit}
                    validate={required()}
                    onChange={() => resetTypeSpecificFormElements()}
                    emptyText="Clear selection"
                />
            </ColumnShowView>
            <ColumnShowView>
                <ConditionalToFromInput
                    source="from_id"
                    label="From"
                    entity={fromEntity}
                    filter={fromEntity && toEntity && fromEntity === toEntity ? { NOT_id: toID } : undefined}
                    edit={edit}
                />
            </ColumnShowView>
            <ColumnShowView>
                <ConditionalToFromInput
                    source="to_id"
                    label="To"
                    entity={toEntity}
                    filter={fromEntity && toEntity && fromEntity === toEntity ? { NOT_id: fromID } : undefined}
                    edit={edit}
                />
            </ColumnShowView>
            <SingleColumn>
                <BooleanInput source="is_active" label="Active" defaultValue />
            </SingleColumn>
            <FullScreen>
                <TextInput source="notes" label="Notes" />
            </FullScreen>
        </>
    );
};

export default OverviewInputs;
