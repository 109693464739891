import setCustomFilters from '../../../../../dataProvider/setQuery/setCustomFilters';
import getRelations from './getRelations';

const getRequestPayload: ({
    listFilters,
    columns,
    relation,
    relation_columns: relationColumns,
    relation_filter_fields: relationFilterFields,
    relation_filter_values: relationFilterValues,
    ...vals
}: {
    [x: string]: any;
    listFilters: any;
    columns?: any;
    relation?: any;
    relation_columns?: any;
    relation_filter_fields?: any;
    relation_filter_values?: any;
}) => {
    filters?: object | undefined;
    fields?: string[] | undefined;
    relations?: {}[] | undefined;
} = ({
    listFilters,
    columns,
    relation,
    relation_columns: relationColumns,
    relation_filter_fields: relationFilterFields,
    relation_filter_values: relationFilterValues,
    ...vals
}) => {
    const data: { filters?: object; fields?: string[]; relations?: {}[] } = {};
    const headerFilters = {};

    const relations = getRelations(relation, relationColumns, relationFilterFields, vals, listFilters);

    if (0 < Object.keys(listFilters).length) {
        const filteredFilters = Object.keys(listFilters).filter(item => !item.includes('-RELATION-'));
        filteredFilters.forEach(fil => (headerFilters[fil] = listFilters[fil]));
    }

    if (0 < Object.keys(headerFilters).length) {
        const formattedFilters = setCustomFilters(headerFilters);
        data.filters = formattedFilters;
    }
    if (0 < columns.length) {
        data.fields = columns;
    }
    if (0 < relations.length) {
        data.relations = relations;
    }

    return data;
};

export default getRequestPayload;
