import * as React from 'react';
import { FC, ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonProps as MuiButtonProps, Link } from '@mui/material';
import { useCreatePath, RaRecord, Button, ButtonProps, useRecordContext, useResourceContext } from 'react-admin';

interface Props {
    record?: RaRecord;
    icon?: ReactElement;
    redirectPath?: string;
}

type TEditButtonProps = Props & ButtonProps & MuiButtonProps;

const defaultIcon = <EditIcon />;

const CustomEditButton: FC<TEditButtonProps> = ({
    label = 'ra.action.edit',
    icon = defaultIcon,
    redirectPath,
    ...rest
}) => {
    const record = useRecordContext(rest);
    const createPath = useCreatePath();
    const resource = useResourceContext(rest);
    if (!record) return null;
    return (
        <Button
            component={Link}
            href={`/#${createPath({ type: 'edit', resource, id: record.id })}${
                redirectPath ? `?redirect=${redirectPath}` : ''
            }`}
            label={label}
            {...(rest as any)}
        >
            {icon}
        </Button>
    );
};

export default CustomEditButton;
