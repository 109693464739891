import React from 'react';
import { useGetDynamicComponents } from './useGetDynamicComponents';
import { useViewContext } from '../contexts/ViewContext';

const useCustomizableColumns = (getSpecialComponents?: (extraProps: any) => any) => {
    const [columnChoices, setColumnChoices] = React.useState<Array<React.ReactNode>>([]);
    const [selectedSources, setSelectedSources] = React.useState<Array<string>>([]);

    const viewContext = useViewContext();
    const generatedColumns = useGetDynamicComponents({
        standardComponents: viewContext && viewContext.columns,
        getSpecialComponents,
    });

    const hasSelectedSources = selectedSources && 0 < selectedSources.length;

    const hasColumnChoices = columnChoices && 0 < columnChoices.length;

    React.useMemo(() => {
        if (viewContext && viewContext.columns && viewContext.columns.length) {
            const activeSources: string[] = [];
            viewContext.columns.forEach(item => item.active && activeSources.push(item.source));
            setSelectedSources(activeSources);
        }
    }, [viewContext]);

    React.useMemo(() => {
        if (generatedColumns && generatedColumns.length) {
            setColumnChoices(generatedColumns);
        }
    }, [generatedColumns]);

    if (hasSelectedSources && hasColumnChoices) {
        const SelectedColumnsComponent = selectedSources.map(sourceName => {
            const child = columnChoices.find(
                (item: any) => item.props && item.props.source && item.props.source === sourceName
            );
            if (child) {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { key: child.key || child.props.source });
                }
                return child;
            }
            return null;
        });

        return SelectedColumnsComponent;
    }

    return null;
};

export default useCustomizableColumns;
