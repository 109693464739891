import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Checkbox, List, ListItem, ListItemText } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { TColumn } from '../../../contexts/ViewContext';

interface ICustomizeColumnGroup {
    orderedColumns: TColumn[];
    displaySelection: string[];
    fragileDisplaySelection: string[];
    setOrderedColumns: (orderedCols) => void;
    checkboxClick: ({ target: { value: columnName } }: { target: { value: any } }) => void;
}

const DraggableListItem = ({
    index,
    source,
    label,
    active,
    disabled = false,
    checkboxClick,
    displaySelection,
    fragileDisplaySelection,
    ...props
}) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,

        ...(isDragging && {
            background: 'primary.light',
        }),
        ...{ userSelect: 'none' },
    });

    return (
        <Draggable key={source} draggableId={source} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    ContainerComponent="li"
                    ContainerProps={{ ref: provided.innerRef }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <Checkbox
                        checked={fragileDisplaySelection.includes(source)}
                        onChange={checkboxClick}
                        value={source}
                        disabled={disabled}
                    />

                    <ListItemText primary={label} />
                    <DragHandleIcon />
                </ListItem>
            )}
        </Draggable>
    );
};

const CustomizeColumnGroup: React.FC<ICustomizeColumnGroup> = ({
    orderedColumns,
    displaySelection,
    fragileDisplaySelection,
    setOrderedColumns,
    checkboxClick,
    ...props
}) => {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = reorder(orderedColumns, result.source.index, result.destination.index);

        setOrderedColumns(items);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                        <List sx={{ background: 'background.default' }}>
                            {orderedColumns.map(({ source, label, active, enabled }, index) => (
                                <DraggableListItem
                                    key={index}
                                    index={index}
                                    source={source}
                                    active={active}
                                    label={label}
                                    disabled={!enabled}
                                    checkboxClick={checkboxClick}
                                    displaySelection={displaySelection}
                                    fragileDisplaySelection={fragileDisplaySelection}
                                    {...props}
                                />
                            ))}
                            {provided.placeholder}
                        </List>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CustomizeColumnGroup;
