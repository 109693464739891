import * as React from 'react';
import { Title, Button, useNotify, usePermissions } from 'react-admin';
import moment from 'moment-timezone';
import { Avatar, Card, CardActions, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AccessGrid, FieldsCell, UserSettingsTable } from '../Components/Admin/Users';
import { ItemsListing } from '../Components/lib/customComponents/showComponents';
import { useGetSessionExpires } from '../Components/lib/hooks';
import authProvider, { setLocalStorage } from '../authProvider';
import { requestGetCurrentUser } from '../dataProvider/RestClient';

const PREFIX = 'DebugPage';
const classes = {
    card: `${PREFIX}-card`,
    avatar: `${PREFIX}-avatar`,
    icon: `${PREFIX}-icon`,
};
const Root = styled('div')(({ theme }) => ({
    minHeight: '100vh',
    background: 'none',
    [`& .${classes.card}`]: {
        justifyContent: 'center',
        minWidth: '90%',
        marginTop: '1em',
        padding: '1em',
    },
    [`& .${classes.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${classes.icon}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const UserTimeData = props => {
    const [timeData, setTimeData] = React.useState({ current: null, timeZone: null, expires: null, warning: null });
    const { sessionExpires, currentTime, warningTime } = useGetSessionExpires(false);

    const userTimeZone = moment.tz.guess();
    const userTimeZoneAbbr = moment.tz(userTimeZone).zoneAbbr();

    React.useEffect(() => {
        setTimeData(prev => ({
            ...prev,
            current: currentTime,
            expires: sessionExpires,
            timeZone: userTimeZoneAbbr,
            warning: warningTime,
        }));
    }, [currentTime, sessionExpires, userTimeZoneAbbr, warningTime]);

    return (
        <ItemsListing
            columns={{
                timeZone: 'User Timezone',
                current: 'Current Time (On Page Load)',
                expires: 'Session Expires',
                warning: 'Warn Session Expires At',
            }}
            dataOverride={[timeData]}
            title="User Time Data"
            formatToDate={[
                { name: 'current', format: 'date-time-seconds' },
                { name: 'expires', format: 'date-time-seconds' },
                { name: 'warning', format: 'date-time-seconds' },
            ]}
            {...props}
        />
    );
};

type TPerm = {
    permission: string;
    capabilities: { [x: string]: any };
    can_read_fields: string[] | null;
};

const LocalStoragePermissions = props => {
    const [permissionsData, setPermissionsData] = React.useState<TPerm[]>([]);

    const { permissions, isLoading } = usePermissions();

    React.useEffect(() => {
        if (permissions && !permissionsData.length) {
            const permsArr: TPerm[] = [];

            const sortedPermissions = Object.keys(permissions).sort();
            sortedPermissions.forEach(slug => {
                const val = permissions[slug];
                const { can_read_fields: canReadFields } = val;
                return permsArr.push({
                    permission: slug,
                    capabilities: val,
                    can_read_fields: canReadFields || [],
                });
            });
            setPermissionsData(permsArr);
        }
    }, [permissions, permissionsData.length]);

    return React.useMemo(
        () =>
            isLoading ? null : (
                <ItemsListing
                    columns={{
                        permission: 'Permission',
                        capabilities: 'Capabilities',
                        can_read_fields: 'Fields',
                    }}
                    dataOverride={permissionsData}
                    title="Permissions"
                    formatToCustom={[
                        {
                            name: 'capabilities',
                            formatter: record => record?.capabilities && AccessGrid(record.capabilities),
                        },
                        {
                            name: 'can_read_fields',
                            formatter: record => record && FieldsCell(record.can_read_fields),
                        },
                    ]}
                    {...props}
                />
            ),
        [isLoading, permissionsData, props]
    );
};

const DebugWrapper = ({ children, actions }) => (
    <Root>
        <Card className={classes.card}>
            <Title title="App Debug" />
            <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                    <SettingsIcon />
                </Avatar>
            </div>
            <Typography align="center" variant="h6" gutterBottom>
                App Debug
            </Typography>
            <CardActions>{actions}</CardActions>
            <CardContent>{children}</CardContent>
        </Card>
    </Root>
);

const DebugPage = props => {
    const settings = JSON.parse(localStorage.getItem('userSettings'));

    const notify = useNotify();

    const refreshUser = React.useCallback(() => {
        requestGetCurrentUser()
            .then(user => {
                if (false === user.is_active) {
                    return authProvider.logout();
                }
                setLocalStorage({ user });
                notify('User record refreshed!', { type: 'success' });
                window.location.reload();
            })
            .catch(err => {
                notify('No current user found', { type: 'warning' });
                authProvider.logout();
            });
    }, [notify]);

    return (
        <DebugWrapper
            actions={
                <Button
                    label="Refresh User Record"
                    variant="contained"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={refreshUser}
                />
            }
        >
            <UserTimeData bypassFetch {...props} />
            <UserSettingsTable settingsOverrideData={settings} bypassFetch {...props} />
            <LocalStoragePermissions bypassFetch {...props} />
        </DebugWrapper>
    );
};

export default DebugPage;
