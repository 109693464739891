import * as React from 'react';
import { useListContext, useNotify, useResourceContext } from 'react-admin';
import { useNavigate } from 'react-router';
import StorageIcon from '@mui/icons-material/Storage';
import { stringify } from 'query-string';
import { DialogForm, SimpleFormWrapper } from '../../formComponents';
import { CustomConfirm as Confirm } from '../../index';
import SaveAndLoadTabs from './SaveAndLoadTabs';
import { useViewContext } from '../../../contexts/ViewContext';

const tabs = [
    { name: 'save', value: 'save' },
    { name: 'load', value: 'load' },
];

const SaveViewButton = props => {
    const [visibleTab, setVisibleTab] = React.useState<string>(tabs[0].value);
    const [overwriteVal, setOverwriteVal] = React.useState<string | null>(null);
    const [deleteVal, setDeleteVal] = React.useState<string | null>(null);

    const { preferences, selectView, saveView, currentViewName, favoriteView, deleteView } = useViewContext()!;
    const { displayedFilters, filterValues, sort } = useListContext();
    const resource = useResourceContext<any>(props);
    const notify = useNotify();
    const navigate = useNavigate();

    const handleChangeListView = React.useCallback(
        ({ filters, sort: viewSort, displayedFilters: viewDisplayedFilters }) => {
            navigate({
                search: stringify({
                    filter: JSON.stringify(filters),
                    sort: viewSort.field,
                    order: viewSort.order,
                    page: 1,
                    displayedFilters: JSON.stringify(viewDisplayedFilters),
                }),
            });
        },
        [navigate]
    );

    const handleSubmit = React.useCallback(
        name => {
            if (name === currentViewName || (preferences && Object.keys(preferences).includes(name))) {
                return setOverwriteVal(name);
            }
            setVisibleTab('load');
            saveView(name, filterValues, sort, displayedFilters);
        },
        [sort, currentViewName, filterValues, preferences, displayedFilters, saveView]
    );

    const handleLoad = React.useCallback(
        (name, value) => {
            handleChangeListView(value);
            selectView(name);
            notify(`List view "${name}" loaded; Columns, sort, and filters set`);
        },
        [handleChangeListView, notify, selectView]
    );

    const handleFavorite = React.useCallback(
        (name, unFavorite = false) => {
            favoriteView(name, resource, unFavorite);
        },
        [favoriteView, resource]
    );

    const handleSetDeleteValue = React.useCallback(name => {
        setDeleteVal(name);
    }, []);

    const handleDelete = React.useCallback(() => {
        if (deleteVal) {
            deleteView(deleteVal, resource);
            setDeleteVal(null);
        }
    }, [resource, deleteVal, deleteView]);

    const handleOverwriteSubmit = React.useCallback(() => {
        const prevFavorite =
            preferences && Object.entries(preferences).find(([, value]: [string, any]) => value.favorite);
        if (overwriteVal) {
            const isFavorite = (prevFavorite && prevFavorite[0] === overwriteVal) || false;
            saveView(overwriteVal, filterValues, sort, displayedFilters, isFavorite);
            setOverwriteVal(null);
        }
    }, [sort, filterValues, overwriteVal, preferences, displayedFilters, saveView]);

    return (
        <>
            <Confirm
                isOpen={!!overwriteVal}
                title="Overwrite Saved List View"
                content={
                    overwriteVal !== currentViewName
                        ? `A saved list view named "${overwriteVal}" already exists, would you like to overwrite it?`
                        : `Overwrite view "${currentViewName}" with current list values?`
                }
                onConfirm={handleOverwriteSubmit}
                onClose={() => setOverwriteVal('')}
            />
            <Confirm
                isOpen={!!deleteVal}
                title="Delete Saved List View"
                content={`Are you sure you want to delete list view "${deleteVal}?"`}
                onConfirm={handleDelete}
                onClose={() => setDeleteVal('')}
            />
            <DialogForm
                title="Save / Load User List View"
                label={`View${currentViewName ? `: ${currentViewName}` : ''}`}
                icon={<StorageIcon />}
            >
                <SimpleFormWrapper>
                    <SaveAndLoadTabs
                        tabs={tabs}
                        visibleTab={visibleTab}
                        setVisibleTab={setVisibleTab}
                        handleSubmit={handleSubmit}
                        handleFavorite={handleFavorite}
                        handleLoad={handleLoad}
                        handleDelete={handleSetDeleteValue}
                    />
                </SimpleFormWrapper>
            </DialogForm>
        </>
    );
};

export default SaveViewButton;
