import React from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { FilterButton, FilterForm, FilterContext, useListContext } from 'react-admin';
import { getDynamicComponents, modifiedName } from '../../hooks/useGetDynamicComponents';
import { useViewContext } from '../../contexts/ViewContext';

type TDynamicFilter = {
    alwaysOnFilters?: string[];
    excludeFilters?: string[];
    [x: string]: any;
};

const PREFIX = 'RaFilter';
const FilterClasses = {
    button: `${PREFIX}-button`,
    form: `${PREFIX}-form`,
};

const sortByTemplateColumnIndex = (a, b, defaultArray) => {
    if (-1 === defaultArray.indexOf(a.props.source)) {
        return 1;
    }
    if (-1 === defaultArray.indexOf(b.props.source)) {
        return -1;
    }

    return defaultArray.indexOf(a.props.source) - defaultArray.indexOf(b.props.source);
};

const DynamicFilter: React.FC<TDynamicFilter> = ({ alwaysOnFilters, excludeFilters = [], ...props }) => {
    const { setFilters, displayedFilters, filterValues } = useListContext();
    const viewContext = useViewContext();
    const [inputs, setInputs] = React.useState<Array<React.ReactNode>>([]);
    const [isInitialRender, setIsInitialRender] = React.useState<boolean>(true);

    const { children: childrenFromProps } = props;

    const isMountedRef = React.useRef(false);

    React.useEffect(() => {
        const filterChildren =
            'undefined' !== typeof props.children && !Array.isArray(props.children) ? [props.children] : props.children;
        const filterSources =
            (filterChildren &&
                filterChildren.map((child: any) => child && child.props && modifiedName(child.props.source))) ||
            [];

        if (viewContext && viewContext.schema && viewContext.schema.fields) {
            const { schema } = viewContext;
            if (schema.fields?.length) {
                let { fields } = schema;
                if (excludeFilters?.length) {
                    fields = fields.filter(({ name }) => !excludeFilters.includes(name));
                }
                const dInputs = getDynamicComponents(fields, filterSources, 'inputs', alwaysOnFilters);
                const sortedDynamicInputs: React.ReactNode[] = dInputs.sort((a, b) => {
                    if (a.props.label < b.props.label) {
                        return -1;
                    }
                    if (a.props.label > b.props.label) {
                        return 1;
                    }
                    return 0;
                });
                if (alwaysOnFilters?.length) {
                    sortedDynamicInputs.sort((a, b) => sortByTemplateColumnIndex(a, b, alwaysOnFilters));
                }
                setInputs(sortedDynamicInputs);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewContext]);

    React.useEffect(() => {
        if (isInitialRender && viewContext?.preferences && isMountedRef.current) {
            const favView = Object.entries(viewContext.preferences).find(([, view]: [string, any]) => view.favorite);
            if (favView) {
                const [, view]: [string, any] = favView;
                const { filters: favFilters } = view;
                if (!lodash.isEqual(favFilters, filterValues)) {
                    setFilters(favFilters, displayedFilters);
                }
            }
            setIsInitialRender(false);
        }
    }, [displayedFilters, filterValues, isInitialRender, setFilters, viewContext]);

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    // eslint-disable-next-line no-nested-ternary
    const fils = childrenFromProps
        ? Array.isArray(childrenFromProps)
            ? React.Children.toArray([...childrenFromProps, ...inputs])
            : React.Children.toArray([childrenFromProps, ...inputs])
        : React.Children.toArray([...inputs]);

    const renderButton = () => <FilterButton className={FilterClasses.button} />;

    const renderForm = () => <FilterForm className={FilterClasses.form} />;

    return (
        <FilterContext.Provider value={fils}>
            {'button' === props.context ? renderButton() : renderForm()}
        </FilterContext.Provider>
    );
};

DynamicFilter.propTypes = {
    children: PropTypes.node,
    context: PropTypes.oneOf(['form', 'button']),
    alwaysOnFilters: PropTypes.array,
};

export default DynamicFilter;
