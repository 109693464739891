import React from 'react';
import { useCreateSuggestionContext, usePermissions, useResourceContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { shouldRender } from '../../helpers';
import { CustomConfirm as Confirm } from '..';
import { SimpleCreate as AccountCreate } from '../../../SupplyChain/Accounts/AccountsForms';
import { SimpleCreate as BreedingRecordCreate } from '../../../Operations/BreedingRecords/BreedingRecordsForms';
import { SimpleCreate as CodonsCreateForm } from '../../../Settings/Codons/CodonsForms';
import { SimpleCreate as ContactCreate } from '../../../Settings/Contacts/ContactsForms';
import { SimpleCreate as FacilityCreate } from '../../../Settings/Facilities/FacilitiesForms';
import { SimpleCreate as ItemCreate } from '../../../Settings/Items/ItemsForms';
import { SimpleCreate as ItemLocationCreate } from '../../../SupplyChain/ItemLocations/ItemLocationsForms';
import { SimpleCreate as ItemSubtype } from '../../../Settings/ItemSubtypes/ItemSubtypesForms';
import { SimpleCreate as OrganizationCreate } from '../../../Settings/Organizations/OrganizationsForms';
import { SimpleCreate as PenCreate } from '../../../Settings/Pens/PensForms';
import { SimpleCreate as SpecimenCreate } from '../../../Operations/Specimen/SpecimenForms';
import { SimpleCreate as UnitOfMeasureCreate } from '../../../Settings/UnitsOfMeasure/UnitsOfMeasureForms';

const PermissionsMissingWindow = props => {
    const { resource, onCancel } = props;
    return (
        <Confirm
            isOpen
            error
            title="Permissions Missing"
            errorText={`You do not have permission to create ${resource}`}
            cancelText="Close"
            hasConfirm={false}
            onClose={onCancel}
        />
    );
};

const ResourceFormFields = ({ defaultValues, onSuccess, ...props }) => {
    const { resource } = props;
    const defaultprops = { defaultValues, onSuccess };

    switch (resource) {
        case 'accounts':
            return <AccountCreate {...defaultprops} />;
        case 'breeding-records':
            return <BreedingRecordCreate {...defaultprops} />;
        case 'codons':
            return <CodonsCreateForm {...defaultprops} />;
        case 'contacts':
            return <ContactCreate {...defaultprops} />;
        case 'customers':
        case 'organzations':
        case 'vendors':
            return <OrganizationCreate {...defaultprops} />;
        case 'facilities':
            return <FacilityCreate {...defaultprops} />;
        case 'items':
        case 'medications':
            return <ItemCreate {...defaultprops} />;
        case 'item-locations':
            return <ItemLocationCreate {...defaultprops} />;
        case 'item-subtypes':
            return <ItemSubtype {...defaultprops} />;
        case 'specimens':
            return <SpecimenCreate {...defaultprops} />;
        case 'pens':
            return <PenCreate {...defaultprops} />;
        case 'units-of-measure':
            return <UnitOfMeasureCreate {...defaultprops} />;
        default:
            return null;
    }
};

const CreateSuggestionFormWrapper = props => {
    const { filterField = 'name', defaultCreateValues = {}, label = '' } = props;
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const { permissions } = usePermissions();
    const formContext = useFormContext();
    const resource = useResourceContext();

    const handleSuccess = data => {
        onCreate(data);
        formContext.trigger();
    };

    const canCreate = React.useMemo(() => shouldRender(permissions, resource, 'can_create'), [permissions, resource]);

    if (!canCreate) {
        return <PermissionsMissingWindow resource={resource} onCancel={onCancel} />;
    }

    const processedResource =
        label ||
        resource
            .toLowerCase()
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.substring(1))
            .join('')
            .slice(0, -1);

    return (
        <Dialog maxWidth="md" open onClose={onCancel} aria-label={`create-${resource}`}>
            <DialogTitle>{`Create ${processedResource}`}</DialogTitle>
            <DialogContent>
                <ResourceFormFields
                    resource={resource}
                    defaultValues={{ ...defaultCreateValues, [filterField]: filter }}
                    onSuccess={handleSuccess}
                />
            </DialogContent>
        </Dialog>
    );
};

export default CreateSuggestionFormWrapper;
