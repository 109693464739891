import React from 'react';
import { DateField, NumberField, TextField, Show, TabbedShowLayout, Tab } from 'react-admin';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import {
    CustomResourceTitle,
    FilteredFieldsRenderer,
    LocalReferenceField,
    RelationTable,
    USDFormattedNumberField,
} from '../../lib/customComponents/showComponents';

const RequisitionTable = props => (
    <RelationTable
        columns={{
            id: 'ID',
            reference_number: 'Ref No.',
            amount: 'Amount',
            status: 'Status',
            memo: 'Memo',
        }}
        relation="purchase_requisitions"
        recordIdField="purchase_requisition_id"
        idField="id"
        formatToDrawerLink={[
            {
                name: 'id',
                resource: 'purchase-requisitions',
            },
        ]}
        formatToMoney={['amount']}
        {...props}
    />
);

const getRequisitionLineFields = () => [
    <TextField source="type" />,
    <NumberField source="quantity" label="QTY" />,
    <USDFormattedNumberField source="rate" label="Rate" />,
    <USDFormattedNumberField source="amount" label="Amount" />,
    <LocalReferenceField source="account_name" label="Account" passProps />,
    <LocalReferenceField source="item_name" label="Item" passProps />,
    <LocalReferenceField
        source="unit_of_measure_name"
        label="Unit of Measure"
        reference="units-of-measure"
        passProps
    />,
    <TextField source="status" />,
    <TextField source="description" className="fullScreen" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const RequisitionLinesShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getRequisitionLineFields} {...props}>
        <RequisitionTable {...props} />
    </FilteredFieldsRenderer>
);

const RequisitionLinesTabbedShow = props => (
    <Show
        title={<CustomResourceTitle customName="Requisition Line" />}
        actions={<CustomActionsShowView {...props} />}
        {...props}
    >
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getRequisitionLineFields} isTabbedShow {...props} />
            <Tab label="Requisition" path="requisition">
                <RequisitionTable {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const RequisitionLinesSimpleShow = props => (
    <Show
        title={<CustomResourceTitle customName="Requisition Line" />}
        actions={<CustomActionsShowView {...props} />}
        {...props}
    >
        <RequisitionLinesShowFields {...props} />
    </Show>
);

export const RequisitionLinesShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <RequisitionLinesSimpleShow {...props} />
    ) : (
        <RequisitionLinesTabbedShow {...props} />
    );
