import React from 'react';
import { DateField, TextField, Show, Tab, TabbedShowLayout } from 'react-admin';
import { MedicateButton, FilteredFieldsRenderer, LocalReferenceField } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';
import { LocationsTable, MedicationRoutesTable, mapItemsToMedications } from './index';

const getItemsFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="item_type_name" label="Item Type" passProps />,
    <LocalReferenceField source="item_subtype_name" label="Subtype" passProps />,
    <TextField source="account_type" label="Account Type" />,
    <LocalReferenceField
        source="unit_of_measure_name"
        label="Unit of Measure"
        reference="units-of-measure"
        passProps
    />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
    <TextField source="recommended_use" label="Recommended Use" className="fullScreen" />,
    <TextField source="notes" label="Notes" className="fullScreen" />,
];

export const ItemsShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getItemsFields} mapResourcePath={mapItemsToMedications} {...props}>
        <LocationsTable {...props} />
        <MedicationRoutesTable {...props} />
    </FilteredFieldsRenderer>
);

const ItemActions = props => (
    <CustomActionsShowView {...props}>
        <MedicateButton {...props} />
    </CustomActionsShowView>
);

const ItemsTabbedShow = props => (
    <Show actions={<ItemActions {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getItemsFields} isTabbedShow {...props} />
            <Tab label="Locations" path="locations">
                <LocationsTable {...props} />
            </Tab>
            <Tab label="Med. Routes" path="medication-routes">
                <MedicationRoutesTable {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const ItemsSimpleShow = props => (
    <Show actions={<ItemActions {...props} />} {...props}>
        <ItemsShowFields {...props} />
    </Show>
);

export const ItemsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <ItemsSimpleShow {...props} />
    ) : (
        <ItemsTabbedShow {...props} />
    );
