import React, { useState, useCallback, useRef, useMemo, memo } from 'react';
import { Labeled, RaRecord, useNotify, useRecordContext, useResourceContext } from 'react-admin';
import { Collapse, IconButton, LinearProgress, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import PropTypes from 'prop-types';
import { requestGetByID, requestGetList } from '../../../../dataProvider/RestClient';
import { DialogTable } from '../index';
import { PreviewDrawerWrapper } from './index';

const useRefreshData: ({
    type: relation,
    resourcePath,
    bypassFetch,
    ...props
}: {
    [x: string]: any;
    type?: string;
    resourcePath?: string;
    bypassFetch?: boolean;
}) => {
    lineItems: RaRecord | RaRecord[];
    isLoading: boolean;
    hasItems: boolean;
} = ({ type: relation, resourcePath, bypassFetch, ...props }) => {
    const [lineItems, setLineItems] = useState<RaRecord | RaRecord[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const notify = useNotify();

    const hasItems = useMemo(
        () =>
            lineItems && Array.isArray(lineItems)
                ? 0 < lineItems.length
                : lineItems && 'object' === typeof lineItems && 0 < Object.keys(lineItems).length,
        [lineItems]
    );

    const refreshData = useCallback(async () => {
        if (relation && resource && record && record.id) {
            await requestGetByID(resource, record.id)
                .then(response => {
                    setLineItems(response.data[relation]);
                    setIsLoading(false);
                })
                .catch(error =>
                    notify(`Relation ${relation} not found for ${resource} #${record.id}`, { type: 'warning' })
                );
        } else if (resourcePath) {
            await requestGetList(resourcePath)
                .then(response => {
                    setLineItems(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    notify(`Relation data not found`, { type: 'warning' });
                });
        }
    }, [notify, record, relation, resource, resourcePath]);

    useMemo(() => {
        if (bypassFetch) {
            return;
        }
        if (!relation && record) {
            setLineItems(record);
            return;
        }
        if (relation && record && record[relation]) {
            setLineItems(record[relation]);
        } else {
            setIsLoading(true);
            refreshData();
        }
    }, [bypassFetch, record, refreshData, relation]);

    return {
        lineItems,
        isLoading,
        hasItems,
    };
};

type TItemsListingViewProps = {
    [x: string]: any;
    title?: string;
    relation?: string;
    isLoading?: boolean;
    hasItems?: boolean;
    dataOverride?: any;
    lineItems: RaRecord | RaRecord[];
    isErrored?: boolean;
    errorMessage?: string;
};

export const ItemsListingView: React.FC<TItemsListingViewProps> = ({
    title = 'Line Items',
    relation,
    isLoading,
    hasItems,
    dataOverride,
    lineItems,
    isErrored,
    errorMessage,
    ...props
}) => {
    const [dataToPass, setDataToPass] = useState([]);
    const [isOpen, setIsOpen] = useState(true);

    const { isDrawer } = props;

    const isMountedRef = useRef(false);

    const data = dataOverride?.length ? dataOverride : lineItems;

    const hasData = React.useMemo(() => 0 < dataToPass?.length, [dataToPass]);

    const formatTypeOfLineItems = React.useCallback(
        items => {
            if (items && relation && items[0] && Object.keys(items[0]).includes(relation)) {
                if (items[0][relation] instanceof Array && items[0][relation].length) {
                    return items[0][relation];
                }
                if ('object' === typeof items[0][relation] && !!items[0][relation] && 0 !== items[0][relation].length) {
                    return [items[0][relation]];
                }
            } else if (
                items &&
                'object' === typeof items &&
                !(items instanceof Array) &&
                Object.keys(items).includes('0')
            ) {
                const convertedData = [];
                Object.values(items).forEach(itemsItem => {
                    convertedData.push(itemsItem);
                });
                return convertedData;
            } else if (
                items &&
                'object' === typeof items &&
                !(items instanceof Array) &&
                !Object.keys(items).includes('0')
            ) {
                return [items];
            } else if (items && items.length) {
                return items;
            }
        },
        [relation]
    );

    React.useEffect(() => {
        isMountedRef.current = true;

        if (isMountedRef.current && !hasData) {
            const formattedData = formatTypeOfLineItems(data);

            setDataToPass(formattedData);
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [data, hasData, formatTypeOfLineItems]);

    if (isLoading) {
        return <LinearProgress sx={{ mt: 2 }} />;
    }

    const Listing = memo(() =>
        isErrored ? (
            <span style={{ maxWidth: '85vw' }}>
                {errorMessage && <PanToolIcon sx={{ fill: 'red' }} />}
                <Typography sx={{ paddingLeft: '1em' }} color="error" variant="h6" gutterBottom>
                    {`Error while trying to load ${title || relation || 'records'}${`: ${errorMessage}` || ''}`}
                </Typography>
            </span>
        ) : (
            <>
                <IconButton onClick={() => setIsOpen(!isOpen)} size="small" sx={{ width: 'fit-content' }}>
                    {isOpen ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                </IconButton>
                <Collapse sx={isDrawer ? { maxWidth: '72vw' } : undefined} in={isOpen} timeout="auto" unmountOnExit>
                    {(dataOverride && dataOverride.length) || (hasItems && hasData) ? (
                        <PreviewDrawerWrapper data={dataToPass} {...props}>
                            <DialogTable />
                        </PreviewDrawerWrapper>
                    ) : (
                        <Typography variant="h6" gutterBottom align="center">
                            {`No ${title || relation || 'records'} found`}
                        </Typography>
                    )}
                </Collapse>
            </>
        )
    );

    return (
        <div style={{ flexGrow: 1 }}>
            {title ? (
                <Labeled label={title}>
                    <Listing />
                </Labeled>
            ) : (
                <Listing />
            )}
        </div>
    );
};

const ItemsListing = ({ dataOverride = [], ...props }) => {
    const refreshProps = useRefreshData(props);

    return <ItemsListingView dataOverride={dataOverride} {...props} {...refreshProps} />;
};

ItemsListing.propTypes = {
    columns: PropTypes.object,
    columnGroups: PropTypes.object,
    CustomAction: PropTypes.func,
    formatToCustom: PropTypes.array,
    formatToDate: PropTypes.array,
    formatToMoney: PropTypes.array,
    resourcePath: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.number,
};

export default ItemsListing;
