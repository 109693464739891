import React from 'react';
import { warning, useRecordContext, useResourceContext, RaRecord } from 'react-admin';
import { requestGetOneWithQuery } from '../../../../dataProvider/RestClient';

interface IrelObj {
    name: string;
    fields?: string[];
}

const QueryWrapper: ({
    relations,
    sortByFunction,
    fieldSelectOnRelation,
    children: formComponent,
}: {
    relations: string[];
    sortByFunction?: any;
    fieldSelectOnRelation?: any;
    children?: any;
}) =>
    | React.FunctionComponentElement<{
          record: any;
      }>
    | React.DetailedReactHTMLElement<{}, HTMLElement> = ({
    relations,
    sortByFunction,
    fieldSelectOnRelation,
    children: formComponent,
}) => {
    const [recordWithRelations, setRecordWithRelations] = React.useState<RaRecord | null>(null);

    const resource = useResourceContext();
    const { id: recordID } = useRecordContext();

    warning(formComponent === undefined, `You must provide a form component (e.g. SimpleForm) inside a QueryWrapper`);

    const getRelationArr = React.useCallback(
        relationArray => {
            const relationsArr: IrelObj[] = [];
            relationArray.forEach(rel => {
                const relObj: IrelObj = {
                    name: rel,
                };
                if (
                    fieldSelectOnRelation &&
                    Object.keys(fieldSelectOnRelation) &&
                    Object.keys(fieldSelectOnRelation).includes(rel)
                ) {
                    relObj.fields = fieldSelectOnRelation[rel];
                }
                relationsArr.push(relObj);
            });
            return relationsArr;
        },
        [fieldSelectOnRelation]
    );

    const getByIDWithRelations = React.useCallback(async () => {
        await requestGetOneWithQuery(resource, recordID, [], getRelationArr(relations))
            .then(res => {
                const responseRecord = res.data;

                if (sortByFunction && 'object' === typeof sortByFunction) {
                    Object.keys(sortByFunction).forEach(item => {
                        const sortFunc = sortByFunction[item];
                        const sortedLines = sortFunc(responseRecord[item], responseRecord);

                        Object.keys(sortedLines).forEach(line => {
                            responseRecord[line] = sortedLines[line];
                        });
                    });
                }
                setRecordWithRelations(responseRecord);
            })
            .catch(err => {
                warning(err, `Error getting ${resource} relations!`);
            });
    }, [getRelationArr, recordID, relations, resource, sortByFunction]);

    React.useEffect(() => {
        if (relations && !recordWithRelations) {
            getByIDWithRelations();
        }
    }, [getByIDWithRelations, relations, recordWithRelations]);

    return React.useMemo(() => {
        if (formComponent && recordWithRelations) {
            const formProps = {
                record: recordWithRelations,
            };
            return React.cloneElement(formComponent, formProps);
        }
        if (formComponent && !recordWithRelations) {
            return React.cloneElement(formComponent, {});
        }

        return null;
    }, [formComponent, recordWithRelations]);
};

export default QueryWrapper;
