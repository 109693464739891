import React from 'react';
import moment from 'moment';
import { Button, List, usePermissions, useRedirect } from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';
import { getUserSettings, formatDate } from '../lib/helpers';
import { CustomActionsList } from '../lib/helpers/customActions';
import { requestDownloadReport } from '../../dataProvider/RestClient';
import { saveFile } from '../lib/customComponents/showComponents/AttachmentPreviewField/helpers';
import { CustomPagination, TabbedList } from '../lib/customComponents/showComponents';
import RecurringReportList from './RecurringReportList';
import NonrecurringReportList from './NonrecurringReportList';
import UserReportFilters from './UserReportFilters';

const user = JSON.parse(localStorage.getItem('user') || '{}');
const userID = user && user.id;
export const recordAllowed = record => record && userID && userID === record.user_id;

export const getDownloadLink = (id, fileName, errorFunction) => {
    const downloadReport = () =>
        requestDownloadReport(id)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                saveFile(url, fileName);
            })
            .catch(err => {
                errorFunction();
            });

    return (
        <Button label="Download" onClick={downloadReport}>
            <DownloadIcon />
        </Button>
    );
};

export const getScheduleTime = (schedule, format = 'time') => {
    if (!schedule) {
        return null;
    }

    const [time, zulu] =
        'string' === typeof schedule ? schedule.split('.') : moment(schedule).toISOString().split('T')[1].split('.');
    const [hour, minute, second] = time.split(':');
    const scheduleDate = moment()
        .utcOffset(zulu)
        .set({ hour: parseInt(hour), minute: parseInt(minute), second: parseInt(second) });

    return formatDate(scheduleDate, format);
};

export const UserReportList = props => {
    const redirect = useRedirect();

    const { permissions, isLoading } = usePermissions();

    React.useEffect(() => {
        if (!permissions && !isLoading) {
            redirect('/unauthorized');
        }
    }, [isLoading, permissions, redirect]);

    return (
        <List
            title="Reports"
            sort={{ field: 'id', order: 'DESC' }}
            filter={{ user_id: userID, relationsOnFilterLine: [{ name: 'report_lines' }] }}
            filters={<UserReportFilters />}
            filterDefaultValues={{ is_active: true }}
            perPage={getUserSettings('default_per_page', 25)}
            pagination={<CustomPagination />}
            actions={<CustomActionsList hasExport={false} />}
            exporter={false}
        >
            <TabbedList source="classification">
                <RecurringReportList value="recurring" />
                <NonrecurringReportList value="nonrecurring" />
            </TabbedList>
        </List>
    );
};
