import React from 'react';
import { register } from './serviceWorkerRegistration';
import { Notification } from './Components/lib/customComponents/showComponents';
import { requestRefresh } from './dataProvider/RestClient';

const ServiceWorkerNotification = () => {
    const [waitingWorker, setWaitingWorker] = React.useState(null);
    const [reloadMessage, setReloadMessage] = React.useState(false);
    const [hasDisplayedMessage, setHasDisplayedMessage] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccessfulUpdate, setIsSuccessfulUpdate] = React.useState(false);

    const userData = JSON.parse(localStorage.getItem('user') || '{}');

    const onSWUpdate = registration => {
        setWaitingWorker(registration.waiting);
    };

    const handleNotificationClick = React.useCallback(() => {
        setReloadMessage(false);
        if (waitingWorker) {
            console.log('[Refresh] Waiting worker', waitingWorker);
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }
        setIsLoading(true);
    }, [waitingWorker]);

    navigator.serviceWorker.addEventListener('controllerchange', ev => {
        console.log('[Refresh] Controller change, ev, isLoading', ev, isLoading);
        if (isLoading && userData && userData.id) {
            requestRefresh()
                .then(res => {
                    console.log('[Refresh] Successful call to refresh');
                    setIsLoading(false);
                    setIsSuccessfulUpdate(true);
                })
                .catch(err => {
                    console.log('Error:', err);
                    setIsLoading(false);
                });
        } else {
            console.log('[Refresh] Problem during call to refresh');
            setIsLoading(false);
        }
    });

    React.useEffect(() => {
        register({ onUpdate: onSWUpdate });
    }, []);

    React.useEffect(() => {
        if (waitingWorker && !hasDisplayedMessage && Object.entries(userData).length) {
            setReloadMessage(true);
            setHasDisplayedMessage(true);
        }
    }, [hasDisplayedMessage, waitingWorker, userData]);

    return (
        <>
            <Notification
                handleUndo={handleNotificationClick}
                undoable
                undoButtonText="Refresh"
                message="New version available"
                autoHideDuration={10000}
                notification={reloadMessage}
            />
            <Notification message="Loading..." autoHideDuration={10000} notification={isLoading} />
            <Notification message="Update successful!" autoHideDuration={10000} notification={isSuccessfulUpdate} />
        </>
    );
};

export default ServiceWorkerNotification;
