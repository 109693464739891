import * as React from 'react';
import { Title } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface CustomizedState {
    shouldRefresh?: boolean;
    erroredResource?: string;
    additionalMessage?: string;
}

const Root = styled('div')(() => ({
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '3em 1em',
}));

const NoAccess = () => {
    const location = useLocation();
    const { search } = location;
    const state = location.state as CustomizedState;

    let erroredResource;
    let additionalMessage;

    if (state) {
        if (state.shouldRefresh) {
            window.location.reload();
        }
        if (state.erroredResource) {
            erroredResource = state.erroredResource;
        }
        if (state.additionalMessage) {
            additionalMessage = state.additionalMessage;
        }
    } else if (search) {
        erroredResource = search.replace('?resource=', '');
        additionalMessage = `If you were not explicitely attempting to access ${erroredResource || 'this resource'}, \n 
            then additional permissions may be required in order to complete your request.`;
    }

    return (
        <Card>
            <Title title="Access Denied" />
            <Root>
                <ReportProblemIcon sx={{ width: '9em', height: '9em' }} />
                <h1>
                    You do not have permission to access
                    {erroredResource ? ` resource: ${erroredResource}` : ' this resource'}
                </h1>

                <div>Please consult your supervisor for access.</div>
                <br />
                {additionalMessage && <div>{additionalMessage}</div>}
            </Root>
        </Card>
    );
};

export default NoAccess;
