import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import CustomUserMenu from './UserMenu';
import NotificationBell from './NotificationBell';
import SessionTimer from './SessionTimer';
import UserChangedNotification from './UserChangedNotification';
import InstallNotification from './InstallNotification';
import { ThemeSelectButton } from './ThemeSelectButton';

const CustomAppBar = props => (
    <>
        <SessionTimer />
        <InstallNotification />
        <UserChangedNotification />
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography
                sx={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                variant="h6"
                color="inherit"
                id="react-admin-title"
            />
            {'test' === process.env.REACT_APP_APP_ENV && (
                <Typography sx={{ flex: 'initial' }} variant="caption" color="inherit" id="react-admin-title">
                    Union Hill Whitetails Test
                </Typography>
            )}
            <ThemeSelectButton />
            <NotificationBell />
        </AppBar>
    </>
);
export default CustomAppBar;
