import React from 'react';
import { required, TextInput } from 'react-admin';
import { FacilityInput, PenInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const ConditionalToFromInput = ({ source, entity, edit, label, ...props }) => {
    const defaultProps = {
        source,
        label: `${label} (${entity})`,
        disabled: edit,
        validate: required(),
        ...props,
    };

    switch (entity) {
        case 'Pen':
            return <PenInput {...defaultProps} />;
        case 'Facility':
            return <FacilityInput {...defaultProps} />;
        case 'Release':
        default:
            return <TextInput source={source} label={label} disabled {...props} />;
    }
};

export default ConditionalToFromInput;
