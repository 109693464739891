import React from 'react';
import { BooleanInput, Filter } from 'react-admin';

const UserReportFilters = props => (
    <Filter {...props}>
        <BooleanInput source="is_active" label="Active" alwaysOn />
    </Filter>
);

export default UserReportFilters;
