import React from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    TopToolbar,
    CreateButton,
    RefreshButton,
    ShowButton,
    ListButton,
    sanitizeListRestProps,
    useListContext,
    useResourceContext,
    useResourceDefinition,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import ExportAllButton from './ExportAllButton';
import ExportSingleButton from './ExportSingleButton';
import { CustomEditButton as EditButton } from '../MarmeCustomComponents';
import { CustomizeColumnsButton, SaveViewButton, ExportSelectedButton } from '../customComponents/showComponents';
import { useViewContext } from '../contexts/ViewContext';
import { shouldRender } from '.';

const sanitizeCustomActions = ({
    total,
    hasCreate,
    hasEdit,
    hasShow,
    hasList,
    syncWithLocation,
    title,
    ...rest
}: any) => sanitizeListRestProps(rest);

const StyledTopToolbar = styled(TopToolbar)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: { marginBottom: '1em' },
}));

const CustomActionsList: ({
    children,
    permanentFilter,
    exporter,
    filters,
    resourceOverride,
    hasExport,
    ...rest
}: {
    [x: string]: any;
    children?: any;
    permanentFilter?: any;
    exporter?: boolean;
    filters?: any;
    resourceOverride?: string;
    hasExport?: boolean;
}) => JSX.Element = ({
    children,
    permanentFilter,
    exporter = true,
    filters,
    resourceOverride,
    hasExport = true,
    ...rest
}) => {
    const resource = useResourceContext();
    const processedResource = resourceOverride || resource;
    const { hasCreate } = useResourceDefinition({ resource: processedResource });
    const { showFilter, displayedFilters, filterValues } = useListContext();
    const { permissions } = usePermissions();
    const isNotSmall = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const viewContext = useViewContext();

    const hasContext = viewContext && null !== viewContext.columns;
    const exportButton = hasContext ? (
        <ExportSelectedButton resourceOverride={resourceOverride} permanentFilter={permanentFilter} />
    ) : (
        <ExportAllButton resourceOverride={resourceOverride} permanentFilter={permanentFilter} />
    );

    const userAllowed = React.useMemo(
        () => shouldRender(permissions, processedResource, 'can_create'),
        [permissions, processedResource]
    );

    return (
        <StyledTopToolbar {...sanitizeCustomActions(rest)}>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {children}
            {hasCreate && userAllowed && <CreateButton resource={processedResource} />}
            {hasExport && exporter && exportButton}
            <RefreshButton />
            {isNotSmall && hasContext && (
                <>
                    <CustomizeColumnsButton /> <SaveViewButton />
                </>
            )}
        </StyledTopToolbar>
    );
};

const CustomActionsEditForm: ({ children, ...props }: { [x: string]: any; children?: any }) => JSX.Element = ({
    children,
    ...props
}) => {
    const resource = useResourceContext();
    const record = useRecordContext(props);
    const { hasShow, hasList } = useResourceDefinition({ resource });

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            record,
        })
    );

    return (
        <StyledTopToolbar>
            {childrenWithProps}
            {true === hasShow && <ShowButton record={record} />}
            {hasList && <ListButton />}
            <RefreshButton />
        </StyledTopToolbar>
    );
};

const CustomActionsShowView: ({
    children,
    recordAllowed,
    allowAllUsers,
    print,
    isDrawer,
    redirectPath,
    userAllowed,
    resourceOverride,
    ...props
}: {
    [x: string]: any;
    children?: any;
    recordAllowed?: boolean | ((rec: any) => boolean);
    allowAllUsers?: boolean;
    print?: any;
    isDrawer?: boolean;
    redirectPath?: string;
    userAllowed?: (permissionsProp: any, resourceProp: any) => any;
    resourceOverride?: string;
}) => JSX.Element = ({
    children,
    recordAllowed = true,
    allowAllUsers = false,
    print,
    isDrawer,
    redirectPath,
    userAllowed = (permissionsProp, resourceProp) => shouldRender(permissionsProp, resourceProp, 'can_update'),
    resourceOverride,
    ...props
}) => {
    const resource = useResourceContext();
    const processedResource = resourceOverride || resource;
    const record = useRecordContext(props);
    const { hasEdit, hasList } = useResourceDefinition({ resource: processedResource });
    const { permissions } = usePermissions();

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            record,
        })
    );

    const isAllowed = React.useMemo(() => {
        let actionsRecordAllowed = recordAllowed;

        if (recordAllowed && 'function' === typeof recordAllowed) {
            actionsRecordAllowed = true === recordAllowed(record);
        }

        return actionsRecordAllowed && (true === userAllowed(permissions, processedResource) || allowAllUsers);
    }, [record, permissions, recordAllowed, processedResource, userAllowed, allowAllUsers]);

    return (
        <StyledTopToolbar>
            {childrenWithProps}
            {isDrawer && <ShowButton />}
            {hasEdit && isAllowed && <EditButton redirectPath={redirectPath} />}
            {null !== print && print}
            {record && (
                <ExportSingleButton
                    resource={resource}
                    sort={{ field: 'id', order: 'DESC' }}
                    filterValues={{ id: record.id }}
                />
            )}
            {hasList && <ListButton />}
            {!isDrawer && <RefreshButton />}
        </StyledTopToolbar>
    );
};

export { CustomActionsEditForm, CustomActionsList, CustomActionsShowView };
