import React from 'react';
import { useLocation } from 'react-router';
import { DashboardMenuItem, usePermissions, useSidebarState } from 'react-admin';
import Box from '@mui/material/Box';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';

import { shouldRender } from '../../Components/lib/helpers';
import { menuItems, menuRoles, menuPaths, renderMenuItem } from './mainMenuHelpers';
import SubMenu from './SubMenu';

const MainMenu = ({ onMenuClick, ...props }) => {
    const [menu, setMenu] = React.useState({
        operationsMenu: false,
        supplyChainMenu: false,
        settingsMenu: false,
        adminMenu: false,
    });

    const [open] = useSidebarState();
    const { pathname } = useLocation();
    const { permissions } = usePermissions();
    const { hasDashboard, dense, ...rest } = props;

    const handleToggle = menuItem => {
        setMenu(prevMenu => ({ ...prevMenu, [menuItem]: !prevMenu[menuItem] }));
    };

    React.useEffect(() => {
        const setActiveMenusOnMount = () => {
            const modifiedPathname = pathname.match(/^\/([a-zA-Z/-]*[^/\d])/)[1];

            if (menuPaths[modifiedPathname]) {
                if (menuPaths[modifiedPathname].subName) {
                    setMenu(prevMenu => ({
                        ...prevMenu,
                        [menuPaths[modifiedPathname].name]: true,
                        [menuPaths[modifiedPathname].subName]: true,
                    }));
                } else {
                    setMenu(prevMenu => ({ ...prevMenu, [menuPaths[modifiedPathname].name]: true }));
                }
            }
        };

        if (pathname && '/' !== pathname) {
            setActiveMenusOnMount();
        }
    }, [pathname]);

    return (
        <Box
            sx={{
                width: open ? 230 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            {shouldRender(permissions, menuRoles.operationsMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('operationsMenu')}
                    isOpen={menu.operationsMenu}
                    name="Operations"
                    icon={<GpsNotFixedIcon />}
                    dense={dense}
                >
                    {menuItems.operations.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
            {shouldRender(permissions, menuRoles.supplyChainMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('supplyChainMenu')}
                    isOpen={menu.supplyChainMenu}
                    name="Supply Chain"
                    icon={<WorkOutlinedIcon />}
                    dense={dense}
                >
                    {menuItems.supplyChain.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
            {shouldRender(permissions, menuRoles.settingsMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('settingsMenu')}
                    isOpen={menu.settingsMenu}
                    name="Settings"
                    icon={<SettingsIcon />}
                    dense={dense}
                >
                    {menuItems.settings.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
            {shouldRender(permissions, menuRoles.adminMenu) && (
                <SubMenu
                    handleToggle={() => handleToggle('adminMenu')}
                    isOpen={menu.adminMenu}
                    name="Admin"
                    icon={<PermIdentityOutlinedIcon />}
                    dense={dense}
                >
                    {menuItems.admin.map(item => renderMenuItem({ item, permissions, dense, open, ...rest }))}
                </SubMenu>
            )}
        </Box>
    );
};

export default MainMenu;
