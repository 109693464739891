import React from 'react';
import moment from 'moment';
import { AutocompleteArrayInput, ReferenceArrayInput, TextInput } from 'react-admin';
import { DateInput } from '../inputComponents';
import { DateTimeInput } from '../formComponents';

const DateTimeFilter = ({ source, label, ...props }) => (
    <>
        <DateTimeInput source={`${source}_from`} label={`${label} (Start Date)`} {...props} />
        <div style={{ width: 10 }}>&nbsp;</div>
        <DateTimeInput source={`${source}_to`} label={`${label} (End Date)`} {...props} />
    </>
);

const DateFilter = ({ source, label, ...props }) => (
    <>
        <DateInput
            source={`${source}_from`}
            label={`${label} (Start Date)`}
            options={{
                format: 'MM/dd/yyyy',
                clearable: true,
                value: data => moment(data).utc().format(),
            }}
            {...props}
        />
        <div style={{ width: 10 }}>&nbsp;</div>
        <DateInput
            source={`${source}_to`}
            label={`${label} (End Date)`}
            options={{
                format: 'MM/dd/yyyy',
                clearable: true,
                value: data => moment(data).utc().format(),
            }}
            {...props}
        />
    </>
);

const CustomerFilter = ({
    label = 'Customer',
    source = 'customer_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteArrayInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            emptyText="Clear Selection"
        />
    ),
    ...rest
}) => (
    <ReferenceArrayInput
        source={source}
        reference="customers"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceArrayInput>
);

const ItemFilter = ({
    label = 'Item',
    source = 'item_id',
    filter = { is_active: true },
    fieldSelectOnFilterLine = ['id', 'name', 'type', 'is_active', 'unit_of_measure_name'],
    sort = { field: 'name', order: 'ASC' },
    isLoading = false,
    inputComponent = (
        <AutocompleteArrayInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({
                getChoicesTrigger: searchText,
                name: `%${searchText}%`,
            })}
            emptyText="Clear Selection"
        />
    ),
    ...rest
}) => (
    <ReferenceArrayInput
        source={source}
        reference="items"
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ getChoicesTrigger }) =>
            'undefined' !== typeof getChoicesTrigger && 2 < getChoicesTrigger.trim().length
        }
        sort={sort}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceArrayInput>
);

const VendorFilter = ({
    label = 'Vendor',
    source = 'vendor_id',
    filter = {},
    fieldSelectOnFilterLine = ['id', 'name'],
    inputComponent = (
        <AutocompleteArrayInput
            label={label}
            shouldRenderSuggestions={val => 'undefined' !== typeof val && 2 < val.trim().length}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            emptyText="Clear Selection"
        />
    ),
    ...rest
}) => (
    <ReferenceArrayInput
        source={source}
        reference="vendors"
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ ...filter, fieldSelectOnFilterLine }}
        enableGetChoices={({ name }) => 'undefined' !== typeof name && 2 < name.trim().length}
    >
        {React.cloneElement(inputComponent, { ...rest })}
    </ReferenceArrayInput>
);

const TextFilter = props => (
    <TextInput
        parse={searchText => `%${searchText}%`}
        format={searchText => (searchText ? searchText.replace(/%/g, '') : '')}
        {...props}
    />
);

export { DateTimeFilter, DateFilter, CustomerFilter, ItemFilter, VendorFilter, TextFilter };
