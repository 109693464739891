import React from 'react';
import { ShowButton, useRecordContext, useResourceDefinition } from 'react-admin';
import { Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { VariableEditButton } from '../index';

const PREFIX = 'DrawerPreview';
const classes = {
    card: `${PREFIX}-card`,
    title: `${PREFIX}-title`,
};
const Root = styled('div')(() => ({
    margin: '1em',
    [`& .${classes.card}`]: {
        justifyContent: 'center',
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${classes.title}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
}));

const stopPropagation = ev => ev.stopPropagation();

type TDrawerPreviewProps = {
    position?: 'right' | 'left' | 'top' | 'bottom';
    showPanel: boolean;
    handleCloseClick: ((ev: any) => Promise<void>) | (() => void);
    title: string;
    children: any;
};

const DrawerPreview: React.FC<TDrawerPreviewProps> = ({
    position = 'right',
    showPanel,
    handleCloseClick,
    title,
    children,
}) => (
    <Drawer
        anchor={position}
        open={showPanel}
        onClose={handleCloseClick}
        onClick={stopPropagation}
        sx={{
            '& .MuiDrawer-paper': { maxWidth: '80vw', zIndex: 100 },
        }}
    >
        <Root>
            <div className={classes.title}>
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={handleCloseClick}>
                    <CloseIcon />
                </IconButton>
            </div>
            {children}
        </Root>
    </Drawer>
);

const sanitizedButtonProps = ({
    passProps,
    hasEdit,
    hasCreate,
    hasShow,
    hasList,
    openPreviewDrawer,
    setIsLoadingQRForPrint,
    ...rest
}: any) => rest;

type TDrawerFeaturesProps = {
    [x: string]: any;
    isDrawer?: boolean;
    userAllowed?: boolean | Function;
    recordAllowed?: boolean | Function;
    mapResourcePath?: Function;
};

export const DrawerFeatures: React.FC<TDrawerFeaturesProps> = ({
    isDrawer,
    userAllowed,
    recordAllowed,
    mapResourcePath,
    ...props
}) => {
    const { hasEdit } = useResourceDefinition(props);
    const record = useRecordContext(props);

    const mappedResource =
        (record && mapResourcePath && 'function' === typeof mapResourcePath && mapResourcePath(record)) || null;

    return isDrawer ? (
        <>
            <ShowButton resource={mappedResource} {...sanitizedButtonProps(props)} />
            {hasEdit && (
                <VariableEditButton
                    resourceOverride={mappedResource}
                    recordAllowed={recordAllowed}
                    userAllowed={userAllowed}
                    {...sanitizedButtonProps(props)}
                />
            )}
        </>
    ) : null;
};

export default DrawerPreview;
