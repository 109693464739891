import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout } from 'react-admin';
import { LocalReferenceField, MedicateButton, FilteredFieldsRenderer } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getCodonsFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <LocalReferenceField source="parent_name" label="Parent" reference="codons" passProps />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const CodonsShowFields = props => <FilteredFieldsRenderer getFieldsFunction={getCodonsFields} {...props} />;

const ItemActions = props => (
    <CustomActionsShowView {...props}>
        <MedicateButton {...props} />
    </CustomActionsShowView>
);

const CodonsTabbedShow = props => (
    <Show actions={<ItemActions {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getCodonsFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const CodonsSimpleShow = props => (
    <Show actions={<ItemActions {...props} />} {...props}>
        <CodonsShowFields {...props} />
    </Show>
);

export const CodonsShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <CodonsSimpleShow {...props} />
    ) : (
        <CodonsTabbedShow {...props} />
    );
