import React from 'react';
import { DateField, TextField, Show, TabbedShowLayout, BooleanField } from 'react-admin';
import { FilteredFieldsRenderer } from '../../lib/customComponents/showComponents';
import { getUserSettings, sanitizeTabbedShowProps } from '../../lib/helpers';
import { CustomActionsShowView } from '../../lib/helpers/customActions';

const getUnitsOfMeasureFields = () => [
    <TextField source="id" label="ID" />,
    <TextField source="name" label="Name" />,
    <TextField source="plural_name" label="Plural Name" />,
    <TextField source="abbreviation" label="Abbreviation" />,
    <TextField source="plural_abbreviation" label="Plural Abbreviation" />,
    <TextField source="type" label="Type" />,
    <BooleanField source="is_active" label="Is Active" />,
    <DateField source="created_at" label="Created At" showTime />,
    <DateField source="updated_at" label="Updated At" showTime />,
];

export const UnitsOfMeasureShowFields = props => (
    <FilteredFieldsRenderer getFieldsFunction={getUnitsOfMeasureFields} {...props} />
);

const UnitsOfMeasureTabbedShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <TabbedShowLayout {...sanitizeTabbedShowProps(props)}>
            <FilteredFieldsRenderer getFieldsFunction={getUnitsOfMeasureFields} isTabbedShow {...props} />
        </TabbedShowLayout>
    </Show>
);

const UnitsOfMeasureSimpleShow = props => (
    <Show actions={<CustomActionsShowView {...props} />} {...props}>
        <UnitsOfMeasureShowFields {...props} />
    </Show>
);

export const UnitsOfMeasureShow = props =>
    'simple' === getUserSettings('show_view_format', 'tabbed') ? (
        <UnitsOfMeasureSimpleShow {...props} />
    ) : (
        <UnitsOfMeasureTabbedShow {...props} />
    );
