import React from 'react';
import { Button } from 'react-admin';
import { Typography } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { ErrorWindow } from '../../../lib/customComponents';

interface ICheckMedRoutesSaveButton {
    handleClick?: (ev?: any, args?: any) => void;
    disabled?: boolean;
}

const CheckMedRoutesSaveButton: React.FC<ICheckMedRoutesSaveButton> = ({ handleClick, disabled, ...props }) => {
    const [errorWindow, setErrorWindow] = React.useState<boolean>(false);

    const { getValues } = useFormContext();

    const handleSave = React.useCallback(
        (ev: any) => {
            if (disabled) {
                if (handleClick && 'function' === typeof handleClick) {
                    return handleClick(ev);
                }
            }

            const [itemTypeName, medRoutesValues] = getValues(['item_type_name', 'medication_routes']);

            const hasLineErrors =
                !medRoutesValues || 0 === medRoutesValues?.length || medRoutesValues.some(line => !line || !line.id);

            if ('Medication' === itemTypeName && hasLineErrors) {
                return setErrorWindow(true);
            }

            handleClick(ev);
        },
        [handleClick, disabled, getValues]
    );

    return (
        <>
            <ErrorWindow
                isOpen={errorWindow}
                title="Form error"
                content={
                    <Typography color="error" variant="caption">
                        Lines form is not valid: Medications require at least one administration method
                    </Typography>
                }
                onConfirm={() => setErrorWindow(false)}
            />
            <Button label="Save" variant="contained" size="medium" disabled={disabled} onMouseUp={handleSave}>
                <SaveIcon />
            </Button>
        </>
    );
};

export default CheckMedRoutesSaveButton;
