import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { TextInput, useNotify, usePermissions } from 'react-admin';
import lodash from 'lodash';
import { shouldRender } from '../../helpers';
import { CustomFileField } from '../showComponents';
import { LoadingWindow } from '../index';
import { FileInput } from '../../MarmeCustomComponents';

const AttachmentsInput = () => {
    const [isDownloading, setIsDownloading] = React.useState(false);

    const { permissions } = usePermissions();
    const canUpdateAttachments = React.useMemo(
        () => shouldRender(permissions, 'attachments', 'can_update'),
        [permissions]
    );

    const { setValue } = useFormContext();

    const attachments = useWatch({ name: 'attachments' });
    const deletedAttachments = useWatch({ name: 'deleted_attachments' });
    const prevValues = React.useRef({ attachments, deletedAttachments });

    const notify = useNotify();

    React.useEffect(() => {
        const { attachments: prevAttachments } = prevValues.current;

        const handleAttachments = () => {
            prevAttachments.forEach(file => {
                if (-1 === lodash.findIndex(attachments, file) && -1 === lodash.findIndex(deletedAttachments, file)) {
                    const updatedDeletedFiles = deletedAttachments ? [...deletedAttachments, file] : [file];

                    setValue('deleted_attachments', updatedDeletedFiles);
                }
            });
        };

        if (prevAttachments && Array.isArray(prevAttachments)) {
            handleAttachments();
        }
    }, [attachments, deletedAttachments, setValue]);

    React.useEffect(() => {
        prevValues.current = { attachments, deletedAttachments };
    }, [attachments, deletedAttachments]);

    if (!canUpdateAttachments) {
        return null;
    }

    return (
        <>
            {isDownloading && (
                <LoadingWindow
                    isLoading={isDownloading}
                    simpleMessage
                    // completed={progress.percent}
                    linear
                    content="Downloading attachment..."
                />
            )}
            <TextInput disabled source="deleted_attachments" sx={{ display: 'none' }} />
            <FileInput
                source="attachments"
                label="Related files"
                accept="image/*, application/pdf, .msg, .xls, .xlsx, .csv, .xlsm, .doc, .docx"
                multiple
                disableRemove={file => file.is_locked}
            >
                <CustomFileField
                    setIsDownloading={setIsDownloading}
                    onDownload={() => notify('Download successful!', { type: 'success' })}
                />
            </FileInput>
        </>
    );
};

export default AttachmentsInput;
