import * as React from 'react';
import { Title, useTranslate } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ReportIcon from '@mui/icons-material/Report';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const PREFIX = 'ErrorPage';
const classes = {
    title: `${PREFIX}-title`,
    panel: `${PREFIX}-panel`,
    icon: `${PREFIX}-icon`,
    panelDetails: `${PREFIX}-panelDetails`,
    toolbar: `${PREFIX}-toolbar`,
};
const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        padding: '1em',
    },
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    [`& .${classes.title}`]: {
        display: 'flex',
        alignItems: 'center',
    },
    [`& .${classes.panel}`]: {
        marginTop: '1em',
    },
    [`& .${classes.icon}`]: {
        width: '2em',
        height: '2em',
        marginRight: '0.5em',
    },
    [`& .${classes.panelDetails}`]: {
        whiteSpace: 'pre-wrap',
    },
    [`& .${classes.toolbar}`]: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: '2em',
    },
}));

const goBack = () => {
    window.history.go(-1);
};

const refresh = () => {
    window.location.reload();
};

const Error = ({ error, errorInfo, title, ...rest }) => {
    const translate = useTranslate();

    const componentStack = React.useMemo(() => (errorInfo && errorInfo.componentStack) || null, [errorInfo]);

    return (
        <>
            <Title defaultTitle={title} />
            <Root>
                <h1 className={classes.title} role="alert">
                    <ReportIcon className={classes.icon} />
                    {translate('ra.page.error')}
                </h1>
                <div>{translate('ra.message.error')}</div>
                {'production' !== process.env.NODE_ENV && (
                    <Accordion className={classes.panel}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {translate('ra.message.details')}
                        </AccordionSummary>
                        <AccordionDetails className={classes.panelDetails}>
                            <div>
                                <h2>{translate(error.toString())}</h2>
                                {componentStack}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
                <div className={classes.toolbar}>
                    <Button variant="contained" startIcon={<HistoryIcon />} onClick={refresh}>
                        Refresh
                    </Button>
                    <Button variant="contained" startIcon={<HistoryIcon />} onClick={goBack}>
                        {translate('ra.action.back')}
                    </Button>
                </div>
            </Root>
        </>
    );
};

export default Error;
