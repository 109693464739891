import React from 'react';
import { Datagrid, DateField, FunctionField, SimpleShowLayout, SimpleList, TextField, useNotify } from 'react-admin';
import { ResponsiveList } from '../lib/customComponents/showComponents';
import { formatDate } from '../lib/helpers';
import ConvertToRecurringButton from './UserReportForms/ConvertToRecurringButton';
import { PayloadShow } from './RecurringReportList';
import { getDownloadLink } from './index';

const MobileList = props => {
    const notify = useNotify();

    const notifyError = React.useCallback(() => {
        notify('Error downloading report!', { type: 'warning' });
    }, [notify]);

    return (
        <SimpleList
            primaryText={record => record && record.description}
            secondaryText={record => record && record.created_at && formatDate(record.created_at)}
            tertiaryText={record =>
                record?.report_lines[0]?.id && record?.report_lines[0]?.file_name
                    ? getDownloadLink(record.report_lines[0].id, record.report_lines[0].file_name, notifyError)
                    : null
            }
            linkType={false}
            {...props}
        />
    );
};

const NonrecurringReportExpand = props => (
    <SimpleShowLayout {...props}>
        <PayloadShow {...props} />
    </SimpleShowLayout>
);

const DesktopList = props => {
    const notify = useNotify();

    const notifyError = React.useCallback(() => {
        notify('Error downloading report!', { type: 'warning' });
    }, [notify]);

    return (
        <Datagrid {...props} expand={<NonrecurringReportExpand />} bulkActionButtons={false}>
            <TextField source="description" label="Description" />
            <DateField source="created_at" label="Created" showTime />
            <FunctionField
                label="Status"
                render={record =>
                    record && record.report_lines && record.report_lines[0] ? record.report_lines[0].status : null
                }
            />
            <FunctionField
                render={record =>
                    record &&
                    record.report_lines &&
                    record.report_lines[0] &&
                    record.report_lines[0].id &&
                    record.report_lines[0].file_name
                        ? getDownloadLink(record.report_lines[0].id, record.report_lines[0].file_name, notifyError)
                        : null
                }
            />
            <FunctionField
                render={record =>
                    record &&
                    record.report_lines &&
                    record.report_lines[0] &&
                    record.report_lines[0].status &&
                    'completed' === record.report_lines[0].status &&
                    record.settings &&
                    record.settings.config &&
                    record.settings.config.resource ? (
                        <ConvertToRecurringButton record={record} {...props} />
                    ) : null
                }
            />
        </Datagrid>
    );
};

const NonrecurringReportList = props => (
    <ResponsiveList desktopList={<DesktopList {...props} />} mobileList={<MobileList {...props} />} />
);

export default NonrecurringReportList;
