import React from 'react';
import { BooleanInput, SelectInput, TextInput, required, useRecordContext } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { FormattedNumberInput } from '../../../lib/customComponents/formComponents';
import { ColumnShowView, SingleColumn, FullScreen } from '../../../lib/styles/StyledComponents';
import { AccountInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const accountTypeChoices = [
    { id: 1, value: 'AccountsPayable', name: 'Accounts Payable' },
    { id: 2, value: 'AccountsReceivable', name: 'Accounts Receivable' },
    { id: 3, value: 'Bank', name: 'Bank' },
    { id: 4, value: 'CostOfGoodsSold', name: 'Cost Of Goods Sold' },
    { id: 5, value: 'Equity', name: 'Equity' },
    { id: 6, value: 'Expense', name: 'Expense' },
    { id: 7, value: 'FixedAsset', name: 'Fixed Asset' },
    { id: 8, value: 'Income', name: 'Income' },
    { id: 9, value: 'LongTermLiability', name: 'Long Term Liability' },
    { id: 10, value: 'NonPosting', name: 'Non Posting' },
    { id: 11, value: 'OtherAsset', name: 'Other Asset' },
    { id: 12, value: 'OtherCurrentAsset', name: 'Other Current Asset' },
    { id: 13, value: 'OtherCurrentLiability', name: 'Other Current Liability' },
    { id: 14, value: 'OtherExpense', name: 'Other Expense' },
];

const OverviewInputs = ({ edit = false, ...props }) => {
    const type = useWatch({ name: 'type' });
    const record = useRecordContext();

    return (
        <>
            <ColumnShowView>
                <SelectInput
                    source="type"
                    optionValue="value"
                    choices={accountTypeChoices}
                    validate={required()}
                    emptyText="Clear selection"
                />
            </ColumnShowView>
            {!!type && (
                <>
                    <ColumnShowView>
                        <TextInput source="name" validate={required()} />
                    </ColumnShowView>
                    <ColumnShowView>
                        <TextInput source="description" validate={required()} />
                    </ColumnShowView>
                    <ColumnShowView>
                        <AccountInput source="parent_id" label="Parent" filter={edit ? { NOT_id: record.id } : {}} />
                    </ColumnShowView>
                    <ColumnShowView>
                        <FormattedNumberInput source="balance" label="Balance" prefix="$" />
                    </ColumnShowView>
                    <ColumnShowView>
                        <FormattedNumberInput source="total_balance" label="Total Balance" prefix="$" />
                    </ColumnShowView>
                    <FullScreen>
                        <TextInput multiline source="notes" />
                    </FullScreen>
                    <SingleColumn>
                        <BooleanInput source="is_active" label="Active" defaultValue />
                    </SingleColumn>
                </>
            )}
        </>
    );
};

export default OverviewInputs;
