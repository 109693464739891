import React from 'react';
import { BooleanInput, TextInput, SelectInput, required } from 'react-admin';
import { ColumnShowView, SingleColumn } from '../../../lib/styles/StyledComponents';

const typeChoices = [
    { value: 'Count', name: 'Count' },
    { value: 'Distance', name: 'Distance' },
    { value: 'Temperature', name: 'Temperature' },
    { value: 'Time', name: 'Time' },
    { value: 'Volume', name: 'Volume' },
    { value: 'Weight', name: 'Weight' },
];

const OverviewInputs = ({ edit = false, ...props }) => (
    <>
        <ColumnShowView>
            <SelectInput
                source="type"
                choices={typeChoices}
                optionValue="value"
                validate={required()}
                emptyText="Clear Selection"
            />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="name" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="plural_name" label="Plural Name" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="abbreviation" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="plural_abbreviation" label="Plural Abbreviation" validate={required()} />
        </ColumnShowView>
        <SingleColumn>
            <BooleanInput source="is_active" label="Active" defaultValue />
        </SingleColumn>
    </>
);

export default OverviewInputs;
