import React from 'react';
import {
    Create,
    SimpleForm,
    useUnselectAll,
    useRefresh,
    useResourceContext,
    useListContext,
    useNotify,
} from 'react-admin';
import { requestGetMany } from '../../../dataProvider/RestClient';
import { DialogForm, CustomToolbar } from '../../lib/customComponents/formComponents';
import OverviewInputs from '../Transfers/TransfersForms/TransferFormHelpers';
import TransferLinesForm from '../Transfers/TransfersForms/TransferLinesForm';
import { transformTransfer } from '../Transfers/TransfersForms';

const sanitizeProps = ({ record, hasShow, hasCreate, hasEdit, hasList, ...rest }: any) => rest;

const BulkTransferButton = ({ label = 'Transfer', ...props }) => {
    const [records, setRecords] = React.useState([]);

    const resource = useResourceContext(props);
    const unselectAll = useUnselectAll(resource);
    const { selectedIds } = useListContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const getSpecimenRecords = React.useCallback(async () => {
        if (!selectedIds.length) {
            return;
        }

        const linesRecords = await requestGetMany('specimens', selectedIds).then(({ data }) => data);

        if (!linesRecords || 0 === linesRecords.length) {
            notify('No valid specimen selected.', { type: 'warning' });
        }

        setRecords(linesRecords);
    }, [selectedIds, notify]);

    const refreshAndUnselectAll = React.useCallback(() => {
        refresh();
        unselectAll();
    }, [refresh, unselectAll]);

    const onSuccess = React.useCallback(
        ({ data, closeDialog }) => {
            closeDialog();
            refreshAndUnselectAll();
        },
        [refreshAndUnselectAll]
    );

    const defaultValues = React.useMemo(
        () =>
            // ZTODO: Fix the form when we have acces to specimen location
            records?.length ? { specimen_transfer_lines: records.map(rec => ({ specimen_id: rec.id })) } : {},
        [records]
    );

    return (
        <DialogForm title="Create Transfer" label="Transfer" maxWidth="md" fullWidth onBeforeOpen={getSpecimenRecords}>
            <Create component="div" resource="specimen-transfers" {...sanitizeProps(props)}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            resourceOverride="specimen-transfers"
                            transform={transformTransfer}
                            onSuccess={onSuccess}
                            redirectEnabled={false}
                            sx={{ width: 'auto' }}
                            {...sanitizeProps(props)}
                        />
                    }
                    defaultValues={defaultValues}
                >
                    <OverviewInputs />
                    <TransferLinesForm />
                </SimpleForm>
            </Create>
        </DialogForm>
    );
};

export default BulkTransferButton;
