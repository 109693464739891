import React from 'react';
import { Typography } from '@mui/material';
import { useRecordContext, useResourceContext } from 'react-admin';

type TPrintTitle = {
    printView?: boolean;
    record?: any;
    resource?: string;
};

const PrintTitle: React.FC<TPrintTitle> = ({ printView, ...props }) => {
    const record = useRecordContext(props);
    const resource = useResourceContext(props);
    const { id } = record;

    const processedResource =
        (resource &&
            resource
                .toLowerCase()
                .split('-')
                .map(word => word[0].toUpperCase() + word.substr(1))
                .join(' ')) ||
        'Record';

    if (!printView || !record || !resource || !id) {
        return null;
    }

    return <Typography variant="h6">{`${processedResource} #${id}`}</Typography>;
};

export default PrintTitle;
