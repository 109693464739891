import React from 'react';
import { useNotify, useRedirect } from 'react-admin';
import 'react-sweet-progress/lib/style.css';
import { useEvent, usePrivateChannel } from '../../../hooks';

type TUseEntityProcessingProps = {
    resource: string;
    sendFiles?: (data) => void;
    redirectPath?: string;
    recordSent: boolean;
    onSuccess?: (data) => void;
    onError?: (message) => void;
    useNotification?: boolean;
};

const useEntityProcessing = ({
    resource,
    sendFiles,
    redirectPath,
    recordSent,
    onSuccess,
    onError,
    useNotification = true,
}: TUseEntityProcessingProps) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const userID = React.useMemo(
        () => (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}').id) || 0,
        []
    );

    const dataParser = React.useCallback(
        async ({ data, is_success: isSuccess, action, message, user_id: parserUser }) => {
            if (isSuccess) {
                if ('function' === typeof sendFiles) {
                    await sendFiles({ data });
                }

                if ('function' === typeof onSuccess) {
                    await onSuccess(data);
                }
                if (useNotification) {
                    notify(`${resource} #${data.id} ${'create' === action ? 'created' : 'updated'}`, {
                        type: 'success',
                    });
                }
            } else {
                if ('function' === typeof onError) {
                    onError(message);
                }

                notify(`Error: ${message}`, { type: 'warning' });
            }

            if (redirectPath) {
                redirect(redirectPath, resource);
            }
        },
        [notify, onError, onSuccess, redirect, redirectPath, resource, sendFiles, useNotification]
    );

    const entityProcessedChannel = usePrivateChannel(`api.entity-processed.${userID}`);

    useEvent(entityProcessedChannel, 'EntityProcessed', dataParser);

    React.useEffect(() => {
        let timer;

        if (recordSent && redirectPath) {
            timer = setTimeout(() => {
                redirect(redirectPath);
                notify(`Process timed-out`, { type: 'warning' });
            }, 600000);
        }

        return () => clearTimeout(timer);
    }, [notify, recordSent, redirect, redirectPath]);

    return {};
};

export default useEntityProcessing;
