import React from 'react';
import { SimpleForm } from 'react-admin';

const SimpleFormWrapper: ({
    children,
    toolbar,
    ...props
}: {
    [x: string]: any;
    children: any;
    toolbar?: React.ReactElement | false;
}) => JSX.Element = ({ children, toolbar = false, ...props }) => {
    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            ...props,
        })
    );

    const toolbarWithProps = toolbar
        ? React.cloneElement(toolbar, {
              ...props,
          })
        : false;
    return <SimpleForm toolbar={toolbarWithProps}>{childrenWithProps}</SimpleForm>;
};

export default SimpleFormWrapper;
