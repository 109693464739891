import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useChoices, useTheme } from 'react-admin';
import { List, Card, CardHeader, ListItem, ListItemIcon, ListItemText, Checkbox, Divider } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const DraggableCheckboxItem = ({
    id,
    choice,
    source,
    index,
    handleToggle,
    optionText,
    optionValue,
    translateChoice,
    isChecked,
}) => {
    const [theme] = useTheme();
    const processedTheme = theme as any;

    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,

        ...(isDragging && {
            background: processedTheme.palette.primary.dark,
        }),
        ...{ userSelect: 'none' },
    });

    return (
        <Draggable key={source} draggableId={source} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    role="listitem"
                    button
                    onClick={handleToggle(choice)}
                    ref={provided.innerRef}
                    ContainerComponent="li"
                    ContainerProps={{ ref: provided.innerRef }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <ListItemIcon>
                        <Checkbox
                            id={`${id}_${getChoiceValue(choice)}`}
                            color="primary"
                            checked={isChecked}
                            value={String(getChoiceValue(choice))}
                            onChange={handleToggle(choice)}
                        />
                    </ListItemIcon>
                    <ListItemText id={id} primary={getChoiceText(choice)} />
                    <DragHandleIcon />
                </ListItem>
            )}
        </Draggable>
    );
};

const CustomDraggableList = ({
    title,
    id,
    items,
    setActive,
    handleToggleAll,
    numberOfChecked,
    handleToggle,
    optionText,
    optionValue,
    translateChoice,
    checked,
    source,
    listStyling,
}) => {
    const { setValue } = useFormContext();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(items, result.source.index, result.destination.index);

        setValue(
            source,
            reorderedItems.map((item: any) => item.source)
        );

        setActive(reorderedItems);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && 0 !== items.length}
                        indeterminate={numberOfChecked(items) !== items.length && 0 !== numberOfChecked(items)}
                        disabled={0 === items.length}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} checked`}
            />
            <Divider />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                            <List dense component="div" role="list" sx={listStyling}>
                                {items.map((choice, index) => {
                                    if (choice === undefined) {
                                        return null;
                                    }
                                    return (
                                        <DraggableCheckboxItem
                                            key={index}
                                            index={index}
                                            id={id}
                                            source={choice.source}
                                            choice={choice}
                                            isChecked={-1 !== checked.indexOf(choice)}
                                            handleToggle={handleToggle}
                                            optionText={optionText}
                                            optionValue={optionValue}
                                            translateChoice={translateChoice}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </List>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Card>
    );
};

export default CustomDraggableList;
