import { useEffect } from 'react';

/**
 * Subscribes to a channel event and registers a callback.
 * @param channel Pusher channel to bind to
 * @param eventName Name of event to bind to
 * @param callback Callback to call on a new event
 */
const NO_EVENTNAME_WARNING = 'Must supply eventName and callback to onEvent';
const NO_CALLBACK_WARNING = 'Must supply callback to onEvent';

export function useEvent<D>(
    channel: any,
    eventName: string,
    callback: (data?: D, metadata?: { user_id: string }) => void
) {
    // bind and unbind events whenever the channel, eventName or callback changes.
    useEffect(() => {
        /** Return early and warn if there's no eventName or callback */
        if (!eventName) {
            console.warn(NO_EVENTNAME_WARNING);
            return;
        }

        if (!callback || (callback && !('function' === typeof callback))) {
            console.warn(NO_CALLBACK_WARNING);
            return;
        }

        if (channel === undefined) {
            return;
        }

        channel.listen(eventName, data => {
            callback(data);
        });

        return () => {};
    }, [channel, eventName, callback]);
}
