import React, { useState, isValidElement } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IOptionsMenu {
    handleNotificationClick?: any;
    className?: string;
    redirect?: any;
    id?: number;
    isRead?: boolean;
    children?: React.ReactNode;
}

const ITEM_HEIGHT = 48;

const sanitizedProps = ({ id, ...rest }: any) => rest;

const OptionsMenu: React.FC<IOptionsMenu> = ({ children, className, ...props }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = ev => {
        ev.stopPropagation();
        setAnchorEl(null);
    };

    const childrenWithProps = React.Children.map(children, child => {
        if (!isValidElement(child)) {
            return;
        }

        const { onClick, toggleClose = true } = child.props;
        return React.cloneElement(child, {
            onClick: ev => {
                handleMenuClose(ev);
                if (toggleClose && 'function' === typeof onClick) {
                    onClick();
                }
            },
            ...props,
        });
    });

    return (
        <div style={{ zIndex: 1000 }}>
            <IconButton
                edge="end"
                aria-controls="long-menu"
                aria-haspopup="true"
                aria-label="Options"
                color="inherit"
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    sx: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                    },
                }}
                {...sanitizedProps(props)}
            >
                {childrenWithProps}
            </Menu>
        </div>
    );
};

export default OptionsMenu;
