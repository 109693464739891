import React from 'react';
import { TextInput, email, required } from 'react-admin';
import { ColumnShowView } from '../../../lib/styles/StyledComponents';
import { OrganizationInput } from '../../../lib/customComponents/formComponents/CommonFormInputs';

const OverviewInputs = ({ edit = false, ...props }) => (
    <>
        <ColumnShowView>
            <TextInput source="name" validate={required()} />
        </ColumnShowView>
        <ColumnShowView>
            <TextInput source="email" label="Email" type="email" validate={[required(), email()]} />
        </ColumnShowView>
        <ColumnShowView>
            <OrganizationInput validate={required()} />
        </ColumnShowView>
    </>
);

export default OverviewInputs;
