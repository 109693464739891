import React, { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Divider,
    FormHelperText,
    FormLabel,
    FormControl,
} from '@mui/material';
import {
    FieldTitle,
    InputHelperText,
    Labeled,
    LinearProgress,
    sanitizeInputRestProps,
    useInput,
    warning,
    useChoices,
} from 'react-admin';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import CustomDraggableList from './CustomDraggableList';

const emptyArray = [];

const listStyling = {
    width: 250,
    height: 230,
    overflow: 'auto',
};

const sanitizeRestProps = ({ setFilter, setPagination, setSort, loaded, closeDialog, ...rest }: any) =>
    sanitizeInputRestProps(rest);

const notIncluded = (a: any, b: any) => a.filter(value => -1 === b.indexOf(value));

const CheckboxItem = ({ id, choice, handleToggle, optionText, optionValue, translateChoice, isChecked }) => {
    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    return (
        <ListItem role="listitem" button onClick={handleToggle(choice)}>
            <ListItemIcon>
                <Checkbox
                    id={`${id}_${getChoiceValue(choice)}`}
                    color="primary"
                    checked={isChecked}
                    value={String(getChoiceValue(choice))}
                    onChange={handleToggle(choice)}
                />
            </ListItemIcon>
            <ListItemText id={id} primary={getChoiceText(choice)} />
        </ListItem>
    );
};

const TransferInput = props => {
    const {
        choices = [],
        format,
        fullWidth,
        helperText,
        label,
        loaded,
        loading,
        margin = 'dense',
        onBlur,
        onChange,
        onFocus,
        optionText,
        optionValue,
        options,
        parse,
        reorderableRightInput,
        resource,
        row,
        source,
        translate,
        translateChoice,
        validate,
        leftMessage = 'Unselected',
        rightMessage = 'Selected',
        ...rest
    } = props;
    warning(
        source === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the source prop`
    );

    warning(
        choices === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the choices prop`
    );

    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
        id,
        isRequired,
    } = useInput({
        format,
        parse,
        resource,
        source,
        validate,
        onBlur,
        onChange,
        ...rest,
    });

    const [checked, setChecked] = useState<any[]>([]);
    const [inactive, setInactive] = useState<any[]>([]);
    const [active, setActive] = useState<any[]>([]);

    const inactiveChecked = checked.filter(valueToFilter => -1 !== inactive.indexOf(valueToFilter));
    const activeChecked = checked.filter(valueToFilter => -1 !== active.indexOf(valueToFilter));
    const currentValues = field.value || emptyArray;

    useEffect(() => {
        if (choices) {
            const choiceItems = () => choices.filter(val => !currentValues.includes(val.source));
            setInactive(choiceItems);
            setActive([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choices, optionValue]);

    useEffect(() => {
        if (choices) {
            const getSelectedFromValue = val => choices.find(choice => get(choice, optionValue) === val);
            const defaultItems = () => currentValues.map(getSelectedFromValue).filter(item => item !== undefined);

            setActive(defaultItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choices, optionValue]);

    const handleToggle = (valueToToggle: number | string) => () => {
        const currentIndex = checked.indexOf(valueToToggle);
        const newChecked = [...checked];

        if (-1 === currentIndex) {
            newChecked.push(valueToToggle);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: number[]) =>
        checked.filter(valueToFilter => -1 !== items.indexOf(valueToFilter)).length;

    const handleToggleAll = (items: number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(notIncluded(checked, items));
        } else {
            setChecked([...checked, ...notIncluded(items, checked)]);
        }
    };

    const handleFieldUpdate = useCallback(
        (movedToActive, checkedVals) => {
            const newValues = checkedVals.map(item => item[optionValue]);
            if (movedToActive) {
                field.onChange([...(currentValues || []), ...newValues]);
            } else {
                field.onChange(currentValues.filter(val => !newValues.includes(val)));
            }
            field.onBlur();
        },
        [optionValue, currentValues, field]
    );

    const moveToActive = () => {
        setActive(active.concat(inactiveChecked));
        handleFieldUpdate(true, inactiveChecked);
        setInactive(notIncluded(inactive, inactiveChecked));
        setChecked(notIncluded(checked, inactiveChecked));
    };

    const moveToInactive = () => {
        setInactive(inactive.concat(activeChecked));
        handleFieldUpdate(false, activeChecked);
        setActive(notIncluded(active, activeChecked));
        setChecked(notIncluded(checked, activeChecked));
    };

    const customList = (title: React.ReactNode, items: number[]) => (
        <Card>
            <CardHeader
                sx={theme => ({ padding: theme.spacing(1, 2) })}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && 0 !== items.length}
                        indeterminate={numberOfChecked(items) !== items.length && 0 !== numberOfChecked(items)}
                        disabled={0 === items.length}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} checked`}
            />
            <Divider />
            <List sx={listStyling} dense component="div" role="list">
                {items.map((choice: number | string, index: number) => (
                    <CheckboxItem
                        key={index}
                        id={id}
                        choice={choice}
                        isChecked={-1 !== checked.indexOf(choice)}
                        handleToggle={handleToggle}
                        optionText={optionText}
                        optionValue={optionValue}
                        translateChoice={translateChoice}
                    />
                ))}
                <ListItem />
            </List>
        </Card>
    );

    if (loading) {
        return (
            <Labeled label={label} source={source} resource={resource} isRequired={isRequired}>
                <LinearProgress />
            </Labeled>
        );
    }

    return (
        <FormControl
            component="fieldset"
            margin={margin}
            error={(isTouched || isSubmitted) && invalid}
            sx={{ margin: 'auto' }}
            fullWidth={fullWidth}
            {...sanitizeRestProps(rest)}
        >
            <FormLabel component="legend">
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </FormLabel>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ margin: 'auto' }}>
                <Grid item>{customList(leftMessage, inactive)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            sx={theme => ({ margin: theme.spacing(0.5, 0) })}
                            onClick={moveToActive}
                            disabled={0 === inactiveChecked.length}
                            aria-label="move selected to active"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={theme => ({ margin: theme.spacing(0.5, 0) })}
                            onClick={moveToInactive}
                            disabled={0 === activeChecked.length}
                            aria-label="move selected to inactive"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {reorderableRightInput ? (
                        <CustomDraggableList
                            title={rightMessage}
                            id={id}
                            items={active}
                            handleToggleAll={handleToggleAll}
                            numberOfChecked={numberOfChecked}
                            handleToggle={handleToggle}
                            setActive={setActive}
                            optionText={optionText}
                            optionValue={optionValue}
                            translateChoice={translateChoice}
                            checked={checked}
                            source={source}
                            listStyling={listStyling}
                        />
                    ) : (
                        customList(rightMessage, active)
                    )}
                </Grid>
            </Grid>
            <FormHelperText>
                <InputHelperText touched={isTouched} error={error?.message} helperText={helperText} />
            </FormHelperText>
        </FormControl>
    );
};

TransferInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
    optionValue: PropTypes.string,
    row: PropTypes.bool,
    reorderableRightInput: PropTypes.bool,
    fullWidth: PropTypes.bool,
    resource: PropTypes.string,
    translateChoice: PropTypes.bool,
};

TransferInput.defaultProps = {
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    fullWidth: true,
    row: true,
};

export default TransferInput;
