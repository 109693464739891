import React from 'react';
import { Create, Edit, FormTab, SimpleForm, TabbedForm } from 'react-admin';
import { ResponsiveForm, CustomToolbar, formMode } from '../../../lib/customComponents/formComponents';
import { CustomActionsEditForm } from '../../../lib/helpers/customActions';
import OverviewInputs from './ItemSubtypesFormHelpers';

const transformItemSubtype = rec => {
    const newRecord: any = {
        parent_id: rec.parent_id,
        name: rec.name,
    };
    if (rec.id) {
        newRecord.id = rec.id;
    }
    return newRecord;
};

export const SimpleCreate = ({ defaultValues = {}, onSuccess = null, ...rest }) => (
    <Create title="Create Item Subtype">
        <SimpleForm
            defaultValues={defaultValues}
            toolbar={<CustomToolbar transform={transformItemSubtype} onSuccess={onSuccess} />}
            mode={formMode}
        >
            <OverviewInputs />
        </SimpleForm>
    </Create>
);

const TabbedCreate = () => (
    <Create title="Create Item Subtype">
        <TabbedForm toolbar={<CustomToolbar transform={transformItemSubtype} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs />
            </FormTab>
        </TabbedForm>
    </Create>
);

const SimpleEdit = () => (
    <Edit title="Edit Item Subtype" actions={<CustomActionsEditForm />}>
        <SimpleForm toolbar={<CustomToolbar transform={transformItemSubtype} />} mode={formMode}>
            <OverviewInputs edit />
        </SimpleForm>
    </Edit>
);

const TabbedEdit = () => (
    <Edit title="Edit Item Subtype" actions={<CustomActionsEditForm />}>
        <TabbedForm toolbar={<CustomToolbar transform={transformItemSubtype} />} mode={formMode}>
            <FormTab label="Overview">
                <OverviewInputs edit />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ItemSubtypesEditForm = () => <ResponsiveForm simpleForm={<SimpleEdit />} tabbedForm={<TabbedEdit />} />;

export const ItemSubtypesCreateForm = () => (
    <ResponsiveForm simpleForm={<SimpleCreate />} tabbedForm={<TabbedCreate />} />
);
